import { Component, OnInit, AfterViewInit, PLATFORM_ID, Inject, Renderer2 } from '@angular/core';
import { isPlatformBrowser, isPlatformServer, DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import * as $ from 'jquery';
import { LinkService } from '../../_services/langhfre.service';
declare function ire(string,{}): any;
@Component({
  selector: 'app-publication-checks',
  templateUrl: './publication-checks.component.html',
  styleUrls: ['./publication-checks.component.scss']
})
export class PublicationChecksComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
    private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document,
    private LinkService: LinkService
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Trinka Technical Checks increase your chances of getting published');
    this.metaTagService.updateTag({ name: 'description', content: "Evaluate your document on 20+ checkpoints, ranging from Journal Selection to Ethical Compliance, to increase your chances of getting published." });
    this.metaTagService.updateTag({ property: 'og:title', content: 'Trinka Technical Checks increase your chances of getting published' });
    this.metaTagService.updateTag({ property: 'og:description', content: "Evaluate your document on 20+ checkpoints, ranging from Journal Selection to Ethical Compliance, to increase your chances of getting published." });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/features/technical-checks' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.trinka.ai/assets/images/features/s3.svg' });
    this.metaTagService.updateTag({ name: 'twitter:title', content: 'Trinka Technical Checks increase your chances of getting published' });
    this.metaTagService.updateTag({ name: 'twitter:description', content: "Evaluate your document on 20+ checkpoints, ranging from Journal Selection to Ethical Compliance, to increase your chances of getting published." });
    this.metaTagService.updateTag({ name: 'twitter:image', content: 'https://www.trinka.ai/assets/images/features/s3.svg' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/features/technical-checks' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/features/technical-checks' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/features/technical-checks' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ja-jp', href: 'https://www.trinka.ai/jp/features/publication-readiness-checks' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-cn', href: 'https://trinka.enago.cn/features/publication-readiness-checks' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-tw', href: 'https://www.trinka.ai/tw/features/publication-readiness-checks' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ko-kr', href: 'https://www.trinka.ai/kr/features/publication-readiness-checks' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'es-es', href: 'https://www.trinka.ai/es/features/publication-readiness-checks' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'pt-pt', href: 'https://www.trinka.ai/pt/features/publication-readiness-checks' });
    if (isPlatformBrowser(this.platformId)) {
      // $.getScript('../../../assets/js/particles.min.js', function(){});
      //$.getScript('../../../assets/js/wisepop.js', function () { });
    }
    let script = this._renderer2.createElement('script');
    script.type = `application/ld+json`;
    script.text = `
    {"@context":"https://schema.org/","@type":"BreadcrumbList","itemListElement":[{"@type":"ListItem","position":1,"name":"Trinka AI","item":"https://www.trinka.ai/"},{"@type":"ListItem","position":2,"name":"Features","item":"https://www.trinka.ai/features"},{"@type":"ListItem","position":3,"name":"Technical Checks","item":"https://www.trinka.ai/features/publication-readiness-checks"}]}
    `;
    this._renderer2.appendChild(this._document.head, script);

    let script1 = this._renderer2.createElement('script');
    script1.type = `application/ld+json`;
    script1.text = `
    {"@context":"http://schema.org","@type":"VideoObject","name":"Publication Readiness Check: A Product Walkthrough for Ensuring Your Writing is Publication-Ready","description":"Welcome to our product walkthrough series for the Trinka AI grammar checker tool! In this video, we'll be demonstrating the power of our Technical Checks feature. This feature is designed to help writers, students, and professionals ensure that their writing is polished and ready for publication.   With Trinka, you can catch grammatical errors, awkward phrasing, and other issues that can negatively impact your writing before it's too late. Our step-by-step demonstration will show you how to use this feature effectively, so you can take your writing to the next level.   Watch now and start improving your writing today!  Check Now : https://www.trinka.ai/features  Join us on our Social Channels  Twitter - https://twitter.com/thetrinkaai  Instagram - https://www.instagram.com/thetrinkaai/  Facebook - https://www.facebook.com/thetrinkaai/  LinkedIn - https://www.linkedin.com/company/thetrinkaai","thumbnailUrl":"https://i.ytimg.com/vi/c8yyiW2tEjE/default.jpg","uploadDate":"2021-03-22T06:36:07Z","duration":"PT1M51S","embedUrl":"https://www.youtube.com/embed/c8yyiW2tEjE","interactionCount":"341"}
    `;
    this._renderer2.appendChild(this._document.head, script1);

    let script2 = this._renderer2.createElement('script');
    script2.type = `application/ld+json`;
    script2.text = `
    {"@context":"https://schema.org","@type":"FAQPage","mainEntity":[{"@type":"Question","name":"How many credits does the Technical Checks use?","acceptedAnswer":{"@type":"Answer","text":"Every time you run Technical Checks, 1 credit is consumed."}},{"@type":"Question","name":"Can I download the Publication Readiness report?","acceptedAnswer":{"@type":"Answer","text":"Currently you cannot download the Publication Readiness report. However, your report is always available on Trinka Cloud. You will receive a link to each report on your registered email ID too."}},{"@type":"Question","name":"Will I get the Technical Checks feature in Trinka's Basic plan?","acceptedAnswer":{"@type":"Answer","text":"Yes. This feature uses credits. Users on the Basic subscription receive 2 free credits every month which can be used to get the Publication Readiness report."}},{"@type":"Question","name":"How can I use the Publication Readiness report?","acceptedAnswer":{"@type":"Answer","text":"You can use the Publication Readiness report as a checklist to easily compare with your target journal’s requirements and assess your paper’s readiness easily. We have prepared a video to help you understand the report too."}}]}
    `;
    this._renderer2.appendChild(this._document.head, script2);
    ire('identify', { 'customerId': '', customerEmail: '', customProfileId: '' });
  }
  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      $('#closedPopup, #wordPluginPopup').click(function () {
        $('iframe.youtubeVideoForTryDemo').remove()
      });

      $('#VewiVideoSection').click(function () {
        if (!$('#iframe').length) {
          $('#iframeHolder').html('<iframe class="youtubeVideoForTryDemo" id="iframe" src="https://www.youtube.com/embed/c8yyiW2tEjE" width="100%" height="450"></iframe>');
        }
      });

    }
  }

  

}
