import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, Subject } from "rxjs";
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';


@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {
  
  constructor(
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private http: HttpClient
  ) { }

  
   intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // let check = "token"
    // let check = localStorage.getItem('credentials');
    // request = this.addAuthorizationToken(request, check);
    return next.handle(request)  
    }
    
 private addAuthorizationToken(request: HttpRequest<any>, token: string) {
   return request.clone({
     setHeaders: {
       'Authorization': `Bearer ${token}`
     }
   });
 }

}
