<section class="section-pd customAIBanner">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-8 col-lg-10">
                <div class="customAIContent">
                    <h2>Own Your AI</h2>
                    <h1>Build Your Intellectual Property</h1>
                    <p class="mt-4">Custom AI Models Tailored for Your Enterprise, Controlled by You.</p>
                    <div class="btn-group justify-content-center mt-40">
                        <button data-toggle="modal" data-target="#EnterPriseContactPopup" class="filled-btn nowrap" type="button" class="white-btn">Let's Talk</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="custom-shape">
        <img src="/assets/images/custom-ai/custom-ai-banner.png" alt="custom ai" />
    </div>
</section>

<section class="section-pd pb-0">
    <div class="trinka_container container">
        <div class="row">
            <div class="col-md-10 col-lg-8">
                <div class="trinka-title mb-0">
                    <h2>Build Your Custom AI Model with Trinka</h2>
                    <div class="HeadingSubLine1"><span></span><span></span><span></span></div>
                </div>
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col-md-7 col-lg-8">
                <ul class="list-box mb-3">
                    <li>At Trinka AI, we offer a unique solution to enterprises: the ability to build and own custom AI models, leveraging your proprietary data to ensure precision and relevance in your specific domain. </li>
                    <li>In a world where generic AI models often miss the mark, our tailored approach puts you in control. By building your model, you not only gain a competitive advantage but also protect your intellectual property. </li>
                    <li>Our solutions are designed to meet your current needs while remaining adaptable to future challenges.</li>
                </ul>
            </div>
            <div class="col-md-5 col-lg-4">
                <div class="text-center mb-3">
                    <img src="/assets/images/custom-ai/ai-model.svg" width="313" height="303" class="img-fluid" alt="build custom ai model" />
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="text-center mb-2 mt-2">
                    <svg width="100%" height="45" viewBox="0 0 960 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M223.311 37.8774H468.758L500.017 6.61816H738.296" stroke="#E1E1E1" stroke-width="1.47431"/>
                        <circle cx="468.117" cy="37.8773" r="6.59288" fill="#E1E1E1"/>
                        <circle cx="501.875" cy="6.61803" r="6.59288" fill="#E1E1E1"/>
                    </svg>
                </div>
            </div>
            <div class="col-lg-10">
                <div class="media stm-media mt-4">
                    <div class="media-left">
                        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.5 10C0.5 4.47715 4.97715 0 10.5 0H32.5V22C32.5 27.5228 28.0228 32 22.5 32H10.5C4.97715 32 0.5 27.5228 0.5 22V10Z" fill="#763393"/>
                            <rect x="2.97363" y="1.20117" width="27.5977" height="27.5977" rx="13.7988" fill="white"/>
                            <path d="M16.7725 28.7988C24.3933 28.7988 30.5713 22.6209 30.5713 15C30.5713 7.37911 24.3933 1.20117 16.7725 1.20117C9.15158 1.20117 2.97363 7.37911 2.97363 15C2.97363 22.6209 9.15158 28.7988 16.7725 28.7988ZM17.8286 22.3037L17.2357 19.5732C18.973 19.1886 20.5705 18.6885 20.4412 16.7484H18.3996V9.48178H24.5495V16.2734C24.5473 21.6709 20.5846 22.11 17.8286 22.3037ZM9.59006 22.3037L8.99546 19.5732C10.7328 19.1886 12.3303 18.6885 12.2009 16.7484H10.1594V9.48178H16.3092V16.2734C16.3071 21.6709 12.346 22.11 9.59006 22.3037Z" fill="#763393"/>
                        </svg>
                    </div>
                    <div class="media-body">
                        <p class="mb-0">With our expertise, we ensure that the AI you create is not just another tool but a core part of your business strategy—one that is fully owned and controlled by you. No third-party dependencies, no black-box algorithms—just a model that’s truly yours.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="section-pd position-relative">
    <div class="shape">
        <img src="/assets/images/custom-ai/how-it-works-blur.svg" width="1146" height="1393" class="img-fluid" alt="how it works" />
    </div>
    <div class="trinka_container container">
        <div class="row justify-content-center">
            <div class="col-md-12 col-lg-11">
                <div class="trinka-title mb-0 text-center">
                    <h2>How it Works</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    <p class="w-70">Our solutions are designed to meet your current needs while remaining adaptable to future challenges.</p>
                </div>
            </div>
            <div class="col-md-12 col-lg-11">
                <div class="text-center mt-40">
                    <img src="/assets/images/custom-ai/how-it-works-graph.svg" class="img-fluid" alt="how it works" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="section-pd position-relative whatWeOffer">
    <div class="shape w-100 text-center">
        <img src="/assets/images/custom-ai/what-we-offer-bg.svg" width="1242" height="1486" class="img-fluid" alt="shape" />
    </div>
    <div class="trinka_container container">
        <div class="row justify-content-center">
            <div class="co-lg-9 col-md-10">
                <div class="row justify-content-center match-box">
                    <div class="col-md-6 col-lg-6 col-sm-6 dflex">
                        <div class="c-card title-card">
                            <h3>What We Offer</h3>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-6 dflex">
                        <div class="c-card">
                            <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.4892 50.1215C10.9302 48.0434 9.73216 45.7738 8.89518 43.3126C8.05629 40.8496 7.63684 38.2352 7.63684 35.4696C7.63684 28.655 10.0032 22.8813 14.7358 18.1487C19.4685 13.416 25.2422 11.0497 32.0567 11.0497H36.1133L30.0227 4.95906L32.0022 2.98248L41.5029 12.4861L32.0022 21.9898L29.9509 19.9385L35.9697 13.9226H32.0567C26.0983 13.9226 21.017 16.0237 16.813 20.2258C12.6089 24.4279 10.5079 29.5092 10.5098 35.4696C10.5098 37.8388 10.8679 40.0911 11.5842 42.2267C12.3006 44.3622 13.3032 46.311 14.5922 48.0731L12.4892 50.1215ZM29.9566 50.3858C29.7728 48.9532 29.337 47.6891 28.6494 46.5935C27.9638 45.498 27.2398 44.4379 26.4775 43.4132C25.7133 42.3866 25.0343 41.3303 24.4406 40.2444C23.8469 39.1584 23.55 37.9345 23.55 36.5728C23.55 34.238 24.3831 32.2365 26.0494 30.5683C27.7196 28.9001 29.722 28.066 32.0567 28.066C34.3915 28.066 36.3929 28.9001 38.0612 30.5683C39.7294 32.2365 40.5644 34.238 40.5664 36.5728C40.5664 37.9364 40.2685 39.1517 39.6729 40.2185C39.0791 41.2834 38.4002 42.3387 37.636 43.3845C36.8737 44.4283 36.1497 45.498 35.464 46.5935C34.7764 47.6891 34.3407 48.9532 34.1569 50.3858H29.9566ZM30.0687 57.5681V54.3648H34.0448V57.5681H30.0687ZM51.6271 50.1215L49.5213 48.0731C50.8103 46.311 51.8129 44.3622 52.5292 42.2267C53.2455 40.0911 53.6037 37.8388 53.6037 35.4696C53.6037 32.384 53.01 29.4977 51.8225 26.8105C50.635 24.1234 48.984 21.7963 46.8696 19.8293L48.918 17.7781C51.291 20.0266 53.144 22.6544 54.4771 25.6614C55.8101 28.6684 56.4766 31.9378 56.4766 35.4696C56.4766 38.2352 56.0581 40.8496 55.2212 43.3126C54.3823 45.7738 53.1843 48.0434 51.6271 50.1215Z" fill="url(#paint0_linear_436_680)"/>
                                <defs>
                                <linearGradient id="paint0_linear_436_680" x1="7.63684" y1="57.5681" x2="67.8655" y2="37.9542" gradientUnits="userSpaceOnUse">
                                <stop offset="0.05" stop-color="#202771"/>
                                <stop offset="0.6" stop-color="#763393"/>
                                </linearGradient>
                                </defs>
                            </svg>
                            <h4>Customized <br class="d-lg-block d-none"/>AI Models</h4>
                            <p>Trained specifically for your business using your data, ensuring high accuracy and relevance.</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-6 dflex">
                        <div class="c-card">
                            <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M32.0559 18.4091H56.098M8.01367 18.4091H18.0312M18.0312 18.4091C18.0312 20.5346 18.8756 22.573 20.3785 24.0759C21.8814 25.5788 23.9198 26.4232 26.0453 26.4232C28.1708 26.4232 30.2092 25.5788 31.7121 24.0759C33.215 22.573 34.0594 20.5346 34.0594 18.4091C34.0594 16.2836 33.215 14.2452 31.7121 12.7423C30.2092 11.2394 28.1708 10.3951 26.0453 10.3951C23.9198 10.3951 21.8814 11.2394 20.3785 12.7423C18.8756 14.2452 18.0312 16.2836 18.0312 18.4091ZM8.01367 46.4583H32.0559M46.0805 46.4583H56.098M46.0805 46.4583C46.0805 48.5838 45.2361 50.6222 43.7332 52.1251C42.2303 53.628 40.1919 54.4724 38.0664 54.4724C35.9409 54.4724 33.9025 53.628 32.3996 52.1251C30.8967 50.6222 30.0523 48.5838 30.0523 46.4583C30.0523 44.3329 30.8967 42.2945 32.3996 40.7915C33.9025 39.2886 35.9409 38.4443 38.0664 38.4443C40.1919 38.4443 42.2303 39.2886 43.7332 40.7915C45.2361 42.2945 46.0805 44.3329 46.0805 46.4583Z" stroke="url(#paint0_linear_436_685)" stroke-width="4.00703"/>
                                <defs>
                                <linearGradient id="paint0_linear_436_685" x1="8.01367" y1="54.4724" x2="64.6675" y2="31.9775" gradientUnits="userSpaceOnUse">
                                <stop offset="0.05" stop-color="#202771"/>
                                <stop offset="0.6" stop-color="#763393"/>
                                </linearGradient>
                                </defs>
                            </svg>
                            <h4>Reinforcement Learning with Human Feedback</h4>
                            <p>We use advanced methods like Reinforcement Learning with Human Feedback (RLHF) to fine-tune the model for precision.</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-6 dflex">
                        <div class="c-card">
                            <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21.3706 43.1191C18.5366 43.1191 15.8187 44.2449 13.8148 46.2488C11.8109 48.2527 10.6851 50.9706 10.6851 53.8045H32.1895V56.4759H8.01379V53.8045C8.01379 49.5536 8.98862 46.7688 11.9945 43.7629C15.0003 40.7571 17.1196 40.4478 21.3706 40.4478H32.056V43.1191H21.3706ZM52.0911 34.4372V38.113C54.0011 38.6045 55.7028 39.609 57.0438 40.9767L60.2307 39.1361L62.9021 43.7629L59.7178 45.6008C60.238 47.4727 60.238 49.451 59.7178 51.3228L62.9021 53.1607L60.2307 57.7875L57.0438 55.947C55.6839 57.3339 53.9716 58.324 52.0911 58.8107V62.4864H46.7484V58.8107C44.8679 58.324 43.1556 57.3339 41.7957 55.947L38.6088 57.7875L35.9374 53.1607L39.1217 51.3228C38.6015 49.451 38.6015 47.4727 39.1217 45.6008L35.9374 43.7629L38.6088 39.1361L41.7957 40.974C43.156 39.588 44.8682 38.5989 46.7484 38.113V34.4372H52.0911ZM44.7422 45.8786C44.3047 46.6694 44.075 47.5581 44.0744 48.4618C44.0744 49.3968 44.3175 50.2783 44.7422 51.045L44.8384 51.2133C45.3131 52.0045 45.9845 52.6592 46.7874 53.1138C47.5903 53.5684 48.4971 53.8073 49.4198 53.8073C50.3424 53.8073 51.2493 53.5684 52.0521 53.1138C52.855 52.6592 53.5265 52.0045 54.0011 51.2133L54.0973 51.045C54.522 50.2783 54.7625 49.3995 54.7625 48.4618C54.7625 47.5269 54.522 46.6453 54.0973 45.8786L54.0011 45.7103C53.5265 44.9192 52.855 44.2644 52.0521 43.8099C51.2493 43.3553 50.3424 43.1164 49.4198 43.1164C48.4971 43.1164 47.5903 43.3553 46.7874 43.8099C45.9845 44.2644 45.3131 44.9192 44.8384 45.7103L44.7422 45.8786Z" fill="url(#paint0_linear_436_690)"/>
                                <circle cx="30.6091" cy="20.5931" r="13.2701" stroke="url(#paint1_linear_436_690)" stroke-width="3.20562"/>
                                <circle cx="49.4315" cy="48.4618" r="7.95691" fill="white"/>
                                <defs>
                                <linearGradient id="paint0_linear_436_690" x1="8.01379" y1="62.4864" x2="57.6833" y2="27.1099" gradientUnits="userSpaceOnUse">
                                <stop offset="0.05" stop-color="#202771"/>
                                <stop offset="0.6" stop-color="#763393"/>
                                </linearGradient>
                                <linearGradient id="paint1_linear_436_690" x1="15.7362" y1="35.4661" x2="51.5626" y2="22.4263" gradientUnits="userSpaceOnUse">
                                <stop offset="0.05" stop-color="#202771"/>
                                <stop offset="0.6" stop-color="#763393"/>
                                </linearGradient>
                                </defs>
                            </svg>
                            <h4>Complete IP <br class="d-lg-block d-none"/>Ownership</h4>
                            <p>You own the model and the intellectual property, ensuring full control over its functionality and future development.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="section-pd position-relative bg-light">
    <div class="trinka_container container">
        <div class="row justify-content-center">
            <div class="col-md-12 col-lg-12">
                <div class="trinka-title text-center">
                    <h2>Why Own Your AI?</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    <p class="w-70">Owning your AI gives you full control, ensuring that your proprietary data remains secure and your AI evolves with your business needs. By building your intellectual property, you safeguard your future while gaining a strategic edge in your industry.</p>
                </div>
                <div class="btn-group justify-content-center mt-40">
                    <button data-toggle="modal" data-target="#EnterPriseContactPopup" class="filled-btn nowrap" type="button" class="filled-btn">Let's Talk</button>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="customAI_LearnMore hideCard1">
    <app-learn-more-section></app-learn-more-section>
</section>

<section class="section-pd custom-ai-gradient-light">
    <div class="trinka_container container">
        <div class="row justify-content-center">
            <div class="col-md-12 col-lg-10">
                <div class="trinka-title text-center">
                    <h2>Interested in Building Your Own AI? Contact us today to learn how.</h2>
                </div>
                <div class="btn-group justify-content-center mt-40">
                    <button data-toggle="modal" data-target="#EnterPriseContactPopup" class="filled-btn nowrap" type="button" class="filled-btn">Let's Talk</button>
                </div>
            </div>
        </div>
    </div>
</section>

<app-enterprise-modal></app-enterprise-modal>