<app-header></app-header>
<router-outlet (activate)="onActivate($event)"></router-outlet>
<app-footer></app-footer>
<div class="back_to_top_section" *ngIf="isShow" (click)="gotoTop()">
    <button title="Back to top">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 492.002 492.002" style="enable-background:new 0 0 492.002 492.002;" xml:space="preserve">
   <g>
       <g>
           <path d="M484.136,328.473L264.988,109.329c-5.064-5.064-11.816-7.844-19.172-7.844c-7.208,0-13.964,2.78-19.02,7.844
               L7.852,328.265C2.788,333.333,0,340.089,0,347.297c0,7.208,2.784,13.968,7.852,19.032l16.124,16.124
               c5.064,5.064,11.824,7.86,19.032,7.86s13.964-2.796,19.032-7.86l183.852-183.852l184.056,184.064
               c5.064,5.06,11.82,7.852,19.032,7.852c7.208,0,13.96-2.792,19.028-7.852l16.128-16.132
               C494.624,356.041,494.624,338.965,484.136,328.473z"/>
       </g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   <g>
   </g>
   </svg>
    </button>
</div>
<!-- <div class="cookiesSection" *ngIf="cookiesContainer">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="cookiesContent">
                    <p>We use cookies to offer you a personalized experience. By continuing to use this website, you consent to the use of cookies in accordance with our <a  [routerLink]="['/privacypolicy']">Cookie Policy.</a></p>
                    <div class="cookiesButtons">
                        <button (click)="setCookie()">Got it</button>
                        <button (click)="declineCookies()">Decline</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- <div class="downtime-banner" *ngIf="downTimeBanner">
    <div class="downtime-inner">
        <button type="button" (click)="downTime()">
            <img src="../assets/icons/times.svg" alt="close" /> 
        </button>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-9">
                    <p>Trinka will be unavaliable because of a system upgrade on Feburary 27, 2024, from 01:30 PM to 02:30 PM UTC. You may need to login again.</p>
                </div>
            </div>
        </div>
    </div>
</div> -->
