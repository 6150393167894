<div class="top-tools-tab-mobile" id="tabs-tools" role="tablist">
    <a [routerLink]="['/grammar-checker']" role="tab" aria-selected="false" tabindex="-1" id="tab-grammar">
        <img src="../../../../assets/images/menu-icons/grammar-checker.svg" alt="grammar-checker">
        <p>Grammar Checker</p>
    </a>
    <a [routerLink]="['/paraphrasing-tool']" role="tab" aria-selected="false" class="active"  tabindex="-1" id="tab-paraphraser">
        <img src="../../../../assets/images/menu-icons/pharaphraser.svg" alt="Paraphraser">
        <p>Paraphraser</p>
    </a>
    <a [routerLink]="['/journal-finder']" role="tab" aria-selected="true" tabindex="0" id="tab-jorunal-finder">
        <img src="../../../../assets/images/menu-icons/journal-finder.svg" alt="Journal Finder">
        <p>Journal Finder</p>
    </a>
    <a [routerLink]="['/ai-content-detector']" role="tab" aria-selected="false" tabindex="-1" id="tab-ai-detector">
        <img src="../../../../assets/images/menu-icons/ai-detector.svg" alt="AI Detector">
        <p>AI Detector</p>
    </a>
    <a [routerLink]="['/academic-phrasebank']" role="tab" aria-selected="true"  tabindex="0" id="tab-phrasebank">
        <img src="../../../../assets/images/menu-icons/phrasebank.svg" alt="Academic Phrasebank">
        <p>Academic Phrasebank</p>
    </a>
</div>


<section class="banner-section" style="overflow: inherit;">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <div class="banner-title">
                    <div class="toolLinkingTabs">
                        <ul>
                            <li>
                                <a [routerLink]="['/grammar-checker']" routerLinkActive="active">
                                    <img class="grammarCheckerIcons" src="../../../assets/icons/GrammarChecker.svg" alt="grammar-checker">
                                    <img class="grammarCheckerActiveIcons" src="../../../assets/icons/GrammarCheckerActive.svg" alt="grammar-checker">
                                    <span>Grammar checker</span>
                                </a>
                            </li>
                            <li>
                                <a  [routerLink]="['/paraphrasing-tool']" routerLinkActive="active">
                                    <img class="grammarCheckerIcons" src="../../../assets/icons/ParaphrasingTool.svg" alt="paraphrasing tool">
                                    <img class="grammarCheckerActiveIcons" src="../../../assets/icons/ParaphrasingToolActive.svg" alt="paraphrasing tool">
                                    <span>Paraphrasing tool</span>
                                </a>
                            </li>

                            <li>
                                <a [routerLink]="['/journal-finder']" routerLinkActive="active">
                                  <svg
                                    width="21"
                                    height="19"
                                    viewBox="0 0 21 19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M13.92 14.42L17 17.5M16.111 9.081C16.111 13.268 12.728 16.662 8.556 16.662C4.383 16.662 1 13.268 1 9.082C1 4.893 4.383 1.5 8.555 1.5"
                                      stroke="#7A28A0"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <rect
                                      x="10.25"
                                      y="3.75"
                                      width="10.5"
                                      height="0.5"
                                      rx="0.25"
                                      fill="black"
                                      stroke="#7A28A0"
                                      stroke-width="0.5"
                                    />
                                    <rect
                                      x="10.25"
                                      y="5.75"
                                      width="6.5"
                                      height="0.5"
                                      rx="0.25"
                                      fill="black"
                                      stroke="#7A28A0"
                                      stroke-width="0.5"
                                    />
                                  </svg>
                                  <span>Journal Finder <i>New</i></span>
                                </a>
                              </li>
                            <li>
                                <a [routerLink]="['/ai-content-detector']" routerLinkActive="active">
                                    <img class="grammarCheckerIcons" src="../../../assets/icons/AIContentDetector.svg" alt="AI Content Detector">
                                    <img class="grammarCheckerActiveIcons" src="../../../assets/icons/AIContentDetectorActive.svg" alt="AI Content Detector">
                                    <span>AI Content Detector</span>
                                </a>
                            </li>
                            <li>
                                <a [routerLink]="['/academic-phrasebank']" routerLinkActive="active">
                                    <img class="grammarCheckerIcons" src="../../../assets/icons/AcademicPhraseBank.svg" alt="academic-phrasebank">
                                    <img class="grammarCheckerActiveIcons" src="../../../assets/icons/AcademicPhraseBankActive.svg" alt="academic-phrasebank">
                                    <span>Academic PhraseBank</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <h1><span>Online Paraphrasing tool</span></h1>
                    <p>Bring clarity to your writing with Trinka’s Paraphrasing tool.</p>
                    <p>Get coherent paraphrases while preserving the original meaning.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="card paraphraser-card">
                    <div class="card-header">
                        <h3 class="d-none d-md-block">Paraphrase Your Text</h3>
                        <div class="degreeChange">
                            <ul>
                                <li>
                                    <div class="slidecontainer">
                                        <span>Degree of Change</span>
                                        <div class="" style="display: flex;align-items: center;">
                                            <i class="lowTextSection" style="left: -26px;right: auto;">Low</i>
                                            <input id="myRange" class="slider" type="range" min="1" max="2" step="0.5" [value]="paraphraseTemperature" [(ngModel)]="paraphraseTemperature" [ngModelOptions]={standalone:true} (change)="updateParaphraseTemperature($event)">
                                            <i class="highTextSection" style="right: -26px;left: auto;">High</i>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="toggleDiffOption">
                                        <i>Show Revisions</i>
                                        <label class="switch">
                                          <input id="toggleDiffOptionCheckbox" type="checkbox" [(ngModel)]="toggleDiffOptionModel" [ngModelOptions]={standalone:true} [checked]="toggleDiffOptionModel" (change)="onToggleDiffOption()">
                                          <span class="slider round"></span>
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div class="feedback" (click)="openFeedbackModal()">
                                        <svg class="feedbackIcons" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.99996 19.0082C9.42496 19.0082 8.88329 18.7165 8.49996 18.2082L7.24996 16.5415C7.22496 16.5082 7.12496 16.4665 7.08329 16.4582H6.66663C3.19163 16.4582 1.04163 15.5165 1.04163 10.8332V6.6665C1.04163 2.98317 2.98329 1.0415 6.66663 1.0415H13.3333C17.0166 1.0415 18.9583 2.98317 18.9583 6.6665V10.8332C18.9583 14.5165 17.0166 16.4582 13.3333 16.4582H12.9166C12.85 16.4582 12.7916 16.4915 12.75 16.5415L11.5 18.2082C11.1166 18.7165 10.575 19.0082 9.99996 19.0082ZM6.66663 2.2915C3.68329 2.2915 2.29163 3.68317 2.29163 6.6665V10.8332C2.29163 14.5998 3.58329 15.2082 6.66663 15.2082H7.08329C7.50829 15.2082 7.99163 15.4498 8.24996 15.7915L9.49996 17.4582C9.79163 17.8415 10.2083 17.8415 10.5 17.4582L11.75 15.7915C12.025 15.4248 12.4583 15.2082 12.9166 15.2082H13.3333C16.3166 15.2082 17.7083 13.8165 17.7083 10.8332V6.6665C17.7083 3.68317 16.3166 2.2915 13.3333 2.2915H6.66663Z" fill="#7A28A0"/>
                                            <path d="M14.1667 7.2915H5.83337C5.49171 7.2915 5.20837 7.00817 5.20837 6.6665C5.20837 6.32484 5.49171 6.0415 5.83337 6.0415H14.1667C14.5084 6.0415 14.7917 6.32484 14.7917 6.6665C14.7917 7.00817 14.5084 7.2915 14.1667 7.2915Z" fill="#7A28A0"/>
                                            <path d="M10.8334 11.4585H5.83337C5.49171 11.4585 5.20837 11.1752 5.20837 10.8335C5.20837 10.4918 5.49171 10.2085 5.83337 10.2085H10.8334C11.175 10.2085 11.4584 10.4918 11.4584 10.8335C11.4584 11.1752 11.175 11.4585 10.8334 11.4585Z" fill="#7A28A0"/>
                                        </svg>
                                        <svg class="feedbackIconsHover" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.1667 1.66669H5.83334C3.53334 1.66669 1.66667 3.52502 1.66667 5.81669V10.8V11.6334C1.66667 13.925 3.53334 15.7834 5.83334 15.7834H7.08334C7.30834 15.7834 7.60834 15.9334 7.75001 16.1167L9.00001 17.775C9.55001 18.5084 10.45 18.5084 11 17.775L12.25 16.1167C12.4083 15.9084 12.6583 15.7834 12.9167 15.7834H14.1667C16.4667 15.7834 18.3333 13.925 18.3333 11.6334V5.81669C18.3333 3.52502 16.4667 1.66669 14.1667 1.66669ZM10.8333 11.4584H5.83334C5.49167 11.4584 5.20834 11.175 5.20834 10.8334C5.20834 10.4917 5.49167 10.2084 5.83334 10.2084H10.8333C11.175 10.2084 11.4583 10.4917 11.4583 10.8334C11.4583 11.175 11.175 11.4584 10.8333 11.4584ZM14.1667 7.29169H5.83334C5.49167 7.29169 5.20834 7.00835 5.20834 6.66669C5.20834 6.32502 5.49167 6.04169 5.83334 6.04169H14.1667C14.5083 6.04169 14.7917 6.32502 14.7917 6.66669C14.7917 7.00835 14.5083 7.29169 14.1667 7.29169Z" fill="#7A28A0"/>
                                        </svg>
                                        <b>Feedback</b>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="card-body" style="min-height: 360px;">
                        <div class="left-side">
                            <div class="form-group" style="height: calc(100% - 88px);">

                                <div class="editor-wrapper" style="position: relative; height: 100%; padding: 24px 40px 0px 24px;">
                                  <span *ngIf="wordCounts.input" class="pp-icon-times" (click)="clearEditors();" style="position: absolute; right: 10px; top: 15px; cursor: pointer;"></span>
                                  <div #inputEditor contenteditable="true" spellcheck="false" id="inputEditor" class="editor" style="height: 100%; outline: 0px solid transparent; overflow: hidden; padding-bottom: 30px;" (input)="onInputEditorChange($event)" placeholder="Type or paste your text to paraphrase... "></div>
                                </div>

                            </div>

                            <div class="card-footer">
                                <div  class="counter">
                                    <span class="count" [ngClass]="wordCounts.input >= 500 ? 'red': ''">{{ wordCounts.input }}/500 words</span>
                                </div>
                                <div class="button-wrap">
                                    <button type="button" (click)="paraphrase()" [disabled]="loader">
                                        <span [style.display]="loader ? 'none' : 'block'">Paraphrase</span>
                                        <div class="loadingContainer" [style.display]="loader ? 'block' : 'none'">
                                            <div class="ball1"></div>
                                            <div class="ball2"></div>
                                            <div class="ball3"></div>
                                            <div class="ball4"></div>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="right-side"> <!-- blur -->
                            <div class="form-group" style="height: calc(100% - 88px);">

                                <div class="editor-wrapper" style="height: 100%; padding: 24px 40px 0px 24px; position: relative;">
                                  <div #popoverOverlay id="popoverOverlay" style="top: 24px; left: 24px; right: 24px; bottom: 0;">
                                    <div style="position: relative; width: 100%; height: 100%;">
                                      <div #popover id="popover">
                                        <div class="popover-content">
                                          <div>
                                            <span class="likeDislikeSection" (click)="popoverOptionSelected($event, 'like', currentSelectedSentenceState.alternatives.original_sentence, currentSelectedSentenceState.sentenceTag.textContent)">
                                                <span class="pp-icon-like"></span>
                                                <span class="pp-icon-like pp-icon-like-hover"></span>
                                            </span>
                                          </div>
                                          <div>
                                            <span class="likeDislikeSection" (click)="popoverOptionSelected($event, 'dislike', currentSelectedSentenceState.alternatives.original_sentence, currentSelectedSentenceState.sentenceTag.textContent)">
                                                <span class="pp-icon-dislike"></span>
                                                <span class="pp-icon-dislike pp-icon-dislike-hover"></span>
                                            </span>
                                          </div>
                                          <div>
                                            <span class="textCopiedSection" (click)="popoverOptionSelected($event, 'copy', currentSelectedSentenceState.alternatives.original_sentence, currentSelectedSentenceState.sentenceTag.textContent)">
                                                <span class="pp-icon-copy"></span>
                                                <span class="pp-icon-copy pp-icon-copy-hover"></span>
                                            </span>
                                          </div>
                                        </div>
                                      </div>

                                      <div #alternativesPopover id="alternativesPopover">
                                        <div class="alternativesPopover-content">
                                          <div class="heading">
                                            <span>Alternatives</span>
                                            <span class="closePopoverBtn" (click)="closePopover()" style="cursor: pointer;">
                                                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_330_14955)">
                                                    <path d="M17.4082 6.66472C17.7614 6.31148 17.7614 5.73877 17.4082 5.38553C17.0549 5.0323 16.4822 5.0323 16.129 5.38553L11.3414 10.1731L6.55388 5.38553C6.20064 5.0323 5.62793 5.0323 5.27469 5.38553C4.92146 5.73877 4.92146 6.31148 5.27469 6.66472L10.0622 11.4523L5.27469 16.2398C4.92146 16.593 4.92146 17.1658 5.27469 17.519C5.62793 17.8722 6.20064 17.8722 6.55388 17.519L11.3414 12.7315L16.129 17.519C16.4822 17.8722 17.0549 17.8722 17.4082 17.519C17.7614 17.1658 17.7614 16.593 17.4082 16.2398L12.6206 11.4523L17.4082 6.66472Z" fill="#414E62"/>
                                                    </g>
                                                    <defs>
                                                    <clipPath id="clip0_330_14955">
                                                    <rect width="21.7085" height="21.7085" fill="white" transform="translate(0.487305 0.5979)"/>
                                                    </clipPath>
                                                    </defs>
                                                </svg>
                                            </span>
                                          </div>
                                          <div class="body">
                                            <ul *ngIf="currentSelectedSentenceState.alternatives">
                                              <ng-container *ngFor="let alternative of currentSelectedSentenceState.alternatives.output_sentence; let i = index;">
                                                <li *ngIf="currentSelectedSentenceState.index != i" (click)="switchToTheAlternative(alternative, i)">
                                                  <sentence [innerHTML]="alternative.suggestion_diff" *ngIf="toggleDiffOptionModel; else nonDiff"></sentence>
                                                  <ng-template #nonDiff>
                                                    <sentence>{{alternative.suggestion}}</sentence>
                                                  </ng-template>
                                                  <span class="likeAndDislikeButtons">
                                                    <span class="likeDislikeSection" (click)="popoverOptionSelected($event, 'like', currentSelectedSentenceState.alternatives.original_sentence, alternative.suggestion)">
                                                        <span class="pp-icon-like"></span>
                                                        <span class="pp-icon-like pp-icon-like-hover"></span>
                                                    </span>
                                                    <span class="likeDislikeSection" (click)="popoverOptionSelected($event, 'dislike', currentSelectedSentenceState.alternatives.original_sentence, alternative.suggestion)">
                                                        <span class="pp-icon-dislike"></span>
                                                        <span class="pp-icon-dislike pp-icon-dislike-hover"></span>
                                                    </span>
                                                  </span>
                                                </li>
                                              </ng-container>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div #outputEditor contenteditable="false" spellcheck="false" id="outputEditor" class="editor" style="outline: 0px solid transparent; overflow: hidden; padding-bottom: 30px;"></div>

                                  <div class="limit-exhausted-banner" *ngIf="wordCounts.output > 1 && isLimitExhausted && !loader">
                                    <h3>
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M18.4645 14.3459L13.248 4.95619C12.5471 3.69282 11.5771 3 10.5094 3C9.44161 3 8.47166 3.69282 7.77069 4.95619L2.55419 14.3459C1.89397 15.5441 1.82062 16.6933 2.35042 17.5981C2.88022 18.5028 3.92352 19 5.29285 19H15.7259C17.0952 19 18.1385 18.5028 18.6683 17.5981C19.1981 16.6933 19.1247 15.5359 18.4645 14.3459ZM9.89805 8.70555C9.89805 8.37137 10.1752 8.09424 10.5094 8.09424C10.8435 8.09424 11.1207 8.37137 11.1207 8.70555V12.7809C11.1207 13.1151 10.8435 13.3923 10.5094 13.3923C10.1752 13.3923 9.89805 13.1151 9.89805 12.7809V8.70555ZM11.0881 15.8049C11.0473 15.8375 11.0066 15.8701 10.9658 15.9027C10.9169 15.9353 10.868 15.9598 10.8191 15.9761C10.7702 16.0005 10.7213 16.0168 10.6642 16.025C10.6153 16.0331 10.5583 16.0413 10.5094 16.0413C10.4605 16.0413 10.4034 16.0331 10.3463 16.025C10.2974 16.0168 10.2485 16.0005 10.1996 15.9761C10.1507 15.9598 10.1018 15.9353 10.0529 15.9027C10.0122 15.8701 9.97141 15.8375 9.93065 15.8049C9.78394 15.65 9.69428 15.4381 9.69428 15.2262C9.69428 15.0143 9.78394 14.8023 9.93065 14.6475C9.97141 14.6149 10.0122 14.5823 10.0529 14.5497C10.1018 14.5171 10.1507 14.4926 10.1996 14.4763C10.2485 14.4519 10.2974 14.4356 10.3463 14.4274C10.4523 14.403 10.5664 14.403 10.6642 14.4274C10.7213 14.4356 10.7702 14.4519 10.8191 14.4763C10.868 14.4926 10.9169 14.5171 10.9658 14.5497C11.0066 14.5823 11.0473 14.6149 11.0881 14.6475C11.2348 14.8023 11.3244 15.0143 11.3244 15.2262C11.3244 15.4381 11.2348 15.65 11.0881 15.8049Z" fill="#FFBD4A"/>
                                        </svg>
                                        Word limit reached
                                    </h3>
                                    <p>You can paraphrase <strong>500 words</strong> at a time. For longer paragraphs, split the paragraph into smaller ones and then paraphrase. </p>
                                  </div>

                                </div>
                            </div>
                            <div class="card-footer">
                                <div  class="counter">
                                    <!-- <span class="count">{{ wordCounts.output }} words</span> -->
                                </div>
                                <div class="copy-text">
                                    <a href="javascript:void(0)" [ngClass]="toggleDiffOptionModel ? 'disableCopyOption': ''" >
                                        <span class="disabledTooltip">Turn revisions off to copy</span>
                                        <span class="copyTooltip">Copy text</span>
                                        <img src="../../../assets/images/copy.svg" alt="copy" (click)="copyOutputEditorContent()" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <ul class="wordcrumbs">
                    <li>Changed Text</li>
                    <li>Paraphrased text</li>
                </ul>
            </div>
        </div>
    </div>
    <span class="shape-1"></span>
    <span class="shape-2 d-lg-block d-none"></span>
    <span class="shape-3"></span>
</section>

<div class="trinka-banner section-pd-sm">
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-md-10 col-lg-8">
                <div class="trinka-title text-center">
                    <h3 class="text-white">Trinka offers an entire world of tools designed to enhance your writing journey - and the best part? - It's free!</h3>
                </div>
                <div class="btn-group justify-content-center">
                    <a href="https://cloud.trinka.ai/signup?_rg=GB" class="white-btn" target="_blank">Try Now</a>
                </div>
            </div>
        </div>  
    </div>
</div>

<section class="section-pd">
    <div class="list-wrapper-style-2">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-10 col-lg-8">
                    <div class="trinka-title text-center">
                        <h2>Why Choose Trinka Online Paraphrasing Tool?</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    </div>
                </div>
            </div>
            <div class="row flex-row-reverse align-items-center">
                <div class="col-lg-6">
                    <div class="list-wrapper-content-2" style="padding: 0;">
                        <p>Trinka’s paraphrasing tool is designed to enhance the fluency of your content while also ensuring you have the appropriate vocabulary, tone, and style for any occasion.</p>
                        <p>You can choose from multiple paraphrasing options for each sentence. Also, you can adjust the degree of change in the paraphrase alternatives so you can use them to suit your writing needs.</p>
                        <p>Trinka Paraphraser tool is free and easy to use—with just the click of a button, the paraphrasing tool will rephrase your text to your liking, with many options available to customize and perfect the reworded content.</p>
                        <p><b>Trinka’s paraphrasing tool – the ultimate solution for seamless content rephrasing!</b></p>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="list-wrapper-image">
                        <img src="../../../assets/images/paraphrase.svg" alt="paraphrasing tool" class="img-fluid" />
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    <div class="list-wrapper-style-2 section-pd pb-0">
        <div class="trinka_container">
            <div class="row justify-content-center align-items-center">
                <div class="col-md-10 col-lg-8">
                    <div class="trinka-title text-center">
                        <h2>Trinka’s Unmatched Features: Elevating Paraphrasing!</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="list-wrapper-content-2 pr-80" style="padding: 0;">
                        <ul class="list-box">
                            <li><b>Multiple options at your disposal</b>, achieving optimal paraphrasing has never been easier.</li>
                            <li><b>Adjust the degree of variation to suit your needs</b> and achieve the perfect rephrase.</li>
                            <li><b>Say goodbye to grammar errors</b> as Trinka automatically corrects them for polished and error-free writing.</li>
                            <li><b>Quick rephrasing at your fingertips</b>, get paraphrased content with a single click.</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="list-wrapper-image">
                        <img src="../../../assets/images/academic-parapharsing.svg" alt="paraphrasing tool"  class="img-fluid" />
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</section>

<div class="cloud_key_features section-pd section-purple list-wrapper-style-2">
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-md-11 col-lg-10">
                <div class="trinka-title mb-0 text-center">
                    <h2>Key Benefits of Online Paraphrasing Tool</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    <p>Explore the full range of Trinka Online Paraphrasing Tool features.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards bt-none">
                    <img src="../../../assets/images/paraphrasing-icons/Efficiency.png" width="50" height="50" alt="Paraphrase Efficiency" style="min-width: 50px;">
                    <h3>Paraphrase Efficiently</h3>
                    <p>Trinka helps reduce time and effort required for paraphrasing content, enabling you to focus on your work, rather than the language used.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards">
                    <img src="../../../assets/images/paraphrasing-icons/Accuracy.png" width="40" height="50" alt="Improved Clarity" style="max-width: auto;">
                    <h3>Improve<br class="d-lg-block d-none"/> Clarity</h3>
                    <p>Trinka ensures that the paraphrased versions maintain the original meaning and integrity of the text, mitigating the risk of misinterpretation. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards">
                    <img src="../../../assets/images/paraphrasing-icons/Vocabulary.png" width="40" height="50" alt="Remove Grammar Errors" style="max-width: auto;">
                    <h3>Remove Grammar Errors</h3>
                    <p>Trinka instantly helps remove grammar errors, ensuring precise and error-free written communication.</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="trinka-banner section-pd-sm">
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-md-10 col-lg-8">
                <div class="trinka-title text-center">
                    <h3 class="text-white">Get an all-round improvement in your writing with Trinka</h3>
                </div>
                <div class="btn-group justify-content-center">
                    <a href="https://cloud.trinka.ai/signup?_rg=GB" class="white-btn" target="_blank">Try Now</a>
                </div>
            </div>
        </div>  
    </div>
</div>

<div class="faqQuestionAnwser section-pd" #target>
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-10">
                <div class="trinka-title text-center">
                    <h2>Trinka’s Paraphrasing Tool FAQ</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                </div>
            </div>
        </div>
        <div class="faqQuestionsList">
            <div class="accordion" id="faq">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="faqContainer">
                            <div class="card">
                                <div class="card-header" id="QuestionGCOne">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCOneContent" aria-expanded="true"
                                        aria-controls="QuestionGCOneContent">
                                        What is Trinka Paraphraser?</a>
                                </div>

                                <div id="QuestionGCOneContent" class="collapse"
                                    aria-labelledby="QuestionGCOne" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Trinka Paraphraser is a free online paraphrasing tool that leverages advanced NLP techniques to generate high-quality paraphrases while preserving the original meaning of the text inputted.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGCTwo">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCTwoContent" aria-expanded="true"
                                        aria-controls="QuestionGCTwoContent">How to use Trinka Paraphraser for a first time user?</a>
                                </div>

                                <div id="QuestionGCTwoContent" class="collapse"
                                    aria-labelledby="QuestionGCTwo" data-parent="#faq">
                                    <div class="card-body">
                                        <p>For first-time users, here are the steps to use Trinka Paraphrasing tool:</p>
                                        <ul>
                                            <li><b>Access Trinka Paraphraser:</b> Go to the Trinka Paraphraser website.</li>
                                            <li><b>Input your content:</b> Copy and paste the text you want to paraphrase into the provided box.</li>
                                            <li><b>Start paraphrasing:</b> Click on the paraphrase button to begin the process.</li>
                                            <li><b>Review the results: </b> Trinka Paraphraser will generate the paraphrased version of your content. Read through it to see if it meets your requirements.</li>
                                            <li><b>Make adjustments (if needed):</b> If necessary, you can make manual adjustments to the paraphrased text to further tailor it to your liking.</li>
                                            <li><b>Finalize and use:</b> Once you are satisfied with the paraphrased content, use it in your writing or any other desired application.</li>
                                        </ul>
                                        <p>By following these steps, you can easily and effectively use Trinka Paraphrasing tool to paraphrase your content.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGCThree">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCThreeContent" aria-expanded="true"
                                        aria-controls="QuestionGCThreeContent">How does Trinka Paraphrasing tool work?</a>
                                </div>

                                <div id="QuestionGCThreeContent" class="collapse"
                                    aria-labelledby="QuestionGCThree" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Trinka Paraphrasing tool uses sophisticated algorithms to analyse and rephrase text, providing users with alternative versions that maintain coherence and readability.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGCFour">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCFourContent" aria-expanded="true"
                                        aria-controls="QuestionGCFourContent">Is Trinka Paraphraser accessible online or requires installation?</a>
                                </div>

                                <div id="QuestionGCFourContent" class="collapse"
                                    aria-labelledby="QuestionGCFour" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Trinka Paraphraser is an online paraphraser tool, making it easily accessible without the need for installation.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGCFive">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCFiveContent" aria-expanded="true"
                                        aria-controls="QuestionGCFiveContent">What makes Trinka Paraphraser stand out from other paraphrasing tools like quillbot paraphraser?</a>
                                </div>

                                <div id="QuestionGCFiveContent" class="collapse"
                                    aria-labelledby="QuestionGCFive" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Trinka Paraphraser offers real-time paraphrasing capabilities, a choice for the degree of change, multiple paraphrased options, and automatic grammar error correction by utilizing sentence rephraser unlike other paraphrasing tools available in the market.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGCSix">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCSixContent" aria-expanded="true"
                                        aria-controls="QuestionGCSixContent">Who can use Trinka Paraphraser?</a>
                                </div>

                                <div id="QuestionGCSixContent" class="collapse"
                                    aria-labelledby="QuestionGCSix" data-parent="#faq">
                                    <div class="card-body">
                                        <p>The Trinka Paraphraser is designed to cater to a wide range of users, including academicians from various disciplines such as science and technology, content writers, bloggers, students, researchers, and professionals seeking to generate unique content variations.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGCSeven">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCSevenContent" aria-expanded="true"
                                        aria-controls="QuestionGCSevenContent">Is there a word limit for paraphrasing with Trinka Paraphraser?</a>
                                </div>

                                <div id="QuestionGCSevenContent" class="collapse"
                                    aria-labelledby="QuestionGCSeven" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Currently, Trinka Paraphraser allows you to paraphrase up to 500 words at a time, but you can use it as much as you want without any restrictions. Unlike other online paraphrasing tools that might have word limits, Trinka Paraphraser offers unlimited usage for its users.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGCEight">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCEightContent" aria-expanded="true"
                                        aria-controls="QuestionGCEightContent">Is Trinka Paraphraser suitable for academic writers?</a>
                                </div>

                                <div id="QuestionGCEightContent" class="collapse"
                                    aria-labelledby="QuestionGCEight" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Yes, Trinka Paraphraser is specifically developed to provide valuable support to academic writers with their research papers and articles.  It assists them in rephrasing their content effectively, creating original and unique variations, all while maintaining the same meaning.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGCNine">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCNineContent" aria-expanded="true"
                                        aria-controls="QuestionGCNineContent">Can bloggers use Trinka Paraphraser to improve their content?</a>
                                </div>

                                <div id="QuestionGCNineContent" class="collapse"
                                    aria-labelledby="QuestionGCNine" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Absolutely, bloggers can use Trinka Paraphraser online to rewrite articles, explore alternative ways to express their ideas, and enhance the quality of their posts.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGCTen">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCTenContent" aria-expanded="true"
                                        aria-controls="QuestionGCTenContent">How does Trinka Paraphraser address the issue of time-consuming paraphrasing?</a>
                                </div>

                                <div id="QuestionGCTenContent" class="collapse"
                                    aria-labelledby="QuestionGCTen" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Trinka Paraphraser's real-time paraphrasing feature helps users save time by generating alternative versions as they write.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGCeleven">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCelevenContent" aria-expanded="true"
                                        aria-controls="QuestionGCelevenContent">How does Trinka Paraphraser enhance efficiency in writing tasks?</a>
                                </div>

                                <div id="QuestionGCelevenContent" class="collapse"
                                    aria-labelledby="QuestionGCeleven" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Trinka Paraphraser boosts writing efficiency by assisting users who experience writer's block or have limited vocabulary. It helps writers find the right words to express their ideas perfectly and communicate effectively.</p>
                                    </div>
                                </div>
                            </div>


                            <div class="card">
                                <div class="card-header" id="QuestionGCTwelve">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCTwelveContent" aria-expanded="true"
                                        aria-controls="QuestionGCTwelveContent">Can users customize the level of variation required in the paraphrased text with Trinka Paraphraser?</a>
                                </div>

                                <div id="QuestionGCTwelveContent" class="collapse"
                                    aria-labelledby="QuestionGCTwelve" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Yes, users can tailor the degree of change in the paraphrased content based on their requirements with Trinka Paraphraser.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="faqContainer">
                            <div class="card">
                                <div class="card-header" id="QuestionGCThirteen">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCThirteenContent" aria-expanded="true"
                                        aria-controls="QuestionGCThirteenContent">How does Trinka Paraphraser rectify grammar errors in paraphrased text? </a>
                                </div>

                                <div id="QuestionGCThirteenContent" class="collapse"
                                    aria-labelledby="QuestionGCThirteen" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Trinka Paraphraser automatically corrects grammar errors with its built-in grammar correction engine. Ensuring that your paraphrased content is polished and free from writing mistakes.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGCFouteen">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCFouteenContent" aria-expanded="true"
                                        aria-controls="QuestionGCFouteenContent">How does Trinka Paraphraser promote originality in academic work? </a>
                                </div>

                                <div id="QuestionGCFouteenContent" class="collapse"
                                    aria-labelledby="QuestionGCFouteen" data-parent="#faq">
                                    <div class="card-body">
                                        <p>With its innovative paraphrasing techniques, Trinka Paraphraser fosters creativity, leading to fresh and original paraphrased content.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGCFivteen">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCFivteenContent" aria-expanded="true"
                                        aria-controls="QuestionGCFivteenContent">Can Trinka Paraphraser maintain consistency in paraphrased content?</a>
                                </div>

                                <div id="QuestionGCFivteenContent" class="collapse"
                                    aria-labelledby="QuestionGCFivteen" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Yes, Trinka Paraphraser ensures consistency and coherence throughout the paraphrased content, enhancing readability.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGCSixteen">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCSixteenContent" aria-expanded="true"
                                        aria-controls="QuestionGCSixteenContent">How does Trinka Paraphraser help users avoid unintentional plagiarism?</a>
                                </div>

                                <div id="QuestionGCSixteenContent" class="collapse"
                                    aria-labelledby="QuestionGCSixteen" data-parent="#faq">
                                    <div class="card-body">
                                        <p>The advanced algorithms of Trinka Paraphrasing tool make sure to paraphrase responsibly, checking and safeguarding against unintentional plagiarism. This ensures that your content remains original and avoids any accidental copying.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGCSeventeen">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCSeventeenContent" aria-expanded="true"
                                        aria-controls="QuestionGCSeventeenContent">Does Trinka Paraphraser offer browser extensions or add-ons for quick access?</a>
                                </div>

                                <div id="QuestionGCSeventeenContent" class="collapse"
                                    aria-labelledby="QuestionGCSeventeen" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Trinka Paraphraser will be available on browser extension soon for convenient access during writing tasks.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGCEighteen">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCEighteenContent" aria-expanded="true"
                                        aria-controls="QuestionGCEighteenContent">Will Trinka Paraphraser be available on Word Add-in for quick access? </a>
                                </div>

                                <div id="QuestionGCEighteenContent" class="collapse"
                                    aria-labelledby="QuestionGCEighteen" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Trinka Paraphraser will soon be available as a Word Add-in, making it easy to access while writing in your familiar and comfortable writing environment.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGCNinteen">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCNinteenContent" aria-expanded="true"
                                        aria-controls="QuestionGCNinteenContent">Can Trinka Paraphraser be integrated with other writing tools or platforms?</a>
                                </div>

                                <div id="QuestionGCNinteenContent" class="collapse"
                                    aria-labelledby="QuestionGCNinteen" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Trinka Paraphraser may offer integration options with popular writing tools or platforms soon, to enhance user convenience.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGC20">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGC20Content" aria-expanded="true"
                                        aria-controls="QuestionGC20Content">Is customer support available for users experiencing issues with Trinka Paraphraser?</a>
                                </div>

                                <div id="QuestionGC20Content" class="collapse"
                                    aria-labelledby="QuestionGC20" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Yes, Trinka has a dedicated customer support team to assist users with any problems they may encounter at any point of time. Please reach out to us at <a href="mailto:support@trinka.ai">support@trinka.ai</a> </p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGC21">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGC21Content" aria-expanded="true"
                                        aria-controls="QuestionGC21Content">How does Trinka Paraphraser handle user data and content? </a>
                                </div>

                                <div id="QuestionGC21Content" class="collapse"
                                    aria-labelledby="QuestionGC21" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Trinka Paraphraser follows strict data privacy measures and ensures the security of user data and content.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGC22">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGC22Content" aria-expanded="true"
                                        aria-controls="QuestionGC22Content">Does Trinka Paraphraser receive regular updates and improvements?</a>
                                </div>

                                <div id="QuestionGC22Content" class="collapse"
                                    aria-labelledby="QuestionGC22" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Trinka Paraphraser is always getting better, and our team is continuously working on updates and enhancements to improve its performance and user experience. We are committed to making it even more effective and user-friendly for our users
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="QuestionGC23">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGC23Content" aria-expanded="true"
                                        aria-controls="QuestionGC23Content">Are user feedback and suggestions considered for the improvement of Trinka Paraphraser?</a>
                                </div>

                                <div id="QuestionGC23Content" class="collapse"
                                    aria-labelledby="QuestionGC23" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Absolutely! At Trinka, we highly value user feedback and suggestions. They play a crucial role in refining and enhancing Trinka Paraphraser's features. We welcome input from our users as it helps us make the tool even better and more useful for everyone.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>