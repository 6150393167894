<div class="content_wrap_section">
  <div class="wrap_header_sectons">
    <div class="top_header_overlay"></div>
    <h1>Terms of Services</h1>
  </div>

  <div class="body_wrap_contents">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <p>Welcome to Trinka AI. Trinka AI is a fully owned product of Crimson AI Pvt. Ltd. Along with its subsidiaries and
            other affiliates, Crimson AI Pvt. Ltd makes
            available the
            "Site" (websites including, without limitation, <a href="https://trinka.ai/">www.trinka.ai</a> and all sub-domains), "Software"
            (software
            and mobile applications), and "Services" (including, without limitation, writing feedback
            suggestions and/or
            corrections) to help write effectively. Access to and use of Trinka AI LLP’s existing "Site",
            "Software",
            and "Services" (hereafter Services) as well as any future Services provided by Crimson AI Pvt. Ltd are
            governed
            by this Terms of Service (hereafter "Terms").</p>
          <p>In this document, “we”, “us”, and “our” refer to Crimson AI Pvt. Ltd (hereafter Crimson AI). The terms
            “you” and “your” refer to our customers, users of our services, or visitors to our websites.</p>
          <p>Crimson AI cares about your privacy. Please read the Crimson AI Privacy Policy carefully for
            information relating to our collection, use, storage, disclosure of your personal information.
            The Crimson AI <a routerLink="/privacypolicy">Privacy Policy</a> is incorporated by this reference into, and
            made a part of, these
            Terms.</p>
          <p><span>By clicking “I Accept”, or by downloading, installing, or otherwise accessing or using the service,
              you agree that you have read and understood, and, as a condition to your use of the service, you agree to
              be bound by, the following terms of Service, including Crimson AI’s privacy policy, fair use policy, and
              any additional terms and policies Crimson AI may provide from time to time. If you are not eligible, or do
              not agree to the Terms, then you do not have our permission to use the service. Your use of the service,
              and Crimson AI’s provision of the service to you, constitutes an agreement by Crimson AI and by you to be
              bound by these Terms.</span></p>
          <div class="question_answer_section">
            <div id="accordion">
              <div class="card">
                <div class="card-header" id="headingOne">
                  <h5 class="mb-0">
                    <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                      aria-controls="collapseOne"> <i class="fa" aria-hidden="true"></i>
                      Limitation of Liability
                    </button>
                  </h5>
                </div>

                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                  <div class="card-body">
                    <p>Under no circumstances shall Crimson AI be liable for any losses or damages whatsoever, whether
                      in contract or otherwise, from the use of or reliance on, the materials on the website. Crimson AI
                      shall not be liable for any loss howsoever arising or any indirect, special, incidental, or
                      consequential damages, even if advised of the possibility of such damages.</p>
                    <p>Crimson AI includes all its divisions, subsidiaries, successors, parent companies and their
                      employees, partners, principals, agents, and representatives as well as any third-party providers
                      or sources of material. While Crimson AI takes all necessary precautions to safeguard all
                      submitted documents, it shall not be held liable for the loss of documents due to power failure,
                      hardware/software failure, computer viruses, natural disasters, or any other unforeseen matters
                      and factors beyond our control. You will be responsible for creating back-up and archival copies
                      of your documents. In no event will Crimson AI be responsible to you or any other person for any
                      loss, corruption, or alteration of documents.</p>
                    <p>Crimson AI is only responsible for providing selected services or products as per our company
                      definitions. All views and thoughts expressed in the manuscripts or content are solely of the
                      writer and members using the product or service of Crimson AI.</p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingTwo">
                  <h5 class="mb-0">
                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo"
                      aria-expanded="false" aria-controls="collapseTwo">
                      <i class="fa" aria-hidden="true"></i>
                      Website
                    </button>
                  </h5>
                </div>
                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                  <div class="card-body">
                    <p>The "Site" is owned and operated by Crimson AI. You may not modify, copy, reproduce, republish,
                      upload, post, transmit, or distribute in any way, any material from this site, including code and
                      software. You may not download material from this site for your personal, non-commercial use.</p>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" id="headingThree">
                  <h5 class="mb-0">
                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree"
                      aria-expanded="false" aria-controls="collapseThree">
                      <i class="fa" aria-hidden="true"></i>
                      Use of Customer Data
                    </button>
                  </h5>
                </div>
                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                  <div class="card-body">
                    <ul>
                      <li>Our use of your data is governed by Crimson AI’s <a routerLink="/privacypolicy">Privacy
                          Policy</a>.
                        You acknowledge that you have read the Privacy Policy and understand how we collect, store, and
                        use Customer Data. If you do not agree with our Privacy Policy, you must stop using our
                        services.</li>
                      <li>Crimson AI reserves the right to save documents on its systems according to its policy and
                        Indian government regulations for conducting business.</li>
                      <li>We may delete our copies of Customer Data from time to time and you agree that we will not
                        have any liability for any damages that you may incur from such deletion.</li>
                      <li>You agree that we may disclose Customer Data, including content of communications, if we
                        believe that such disclosure is necessary (i) to comply with any applicable laws, (ii) to
                        enforce our policies, and (iii) to protect our services and products.</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" id="ownership_of_data">
                  <h5 class="mb-0">
                    <button class="btn btn-link collapsed" data-toggle="collapse"
                      data-target="#ownership_of_data_section" aria-expanded="false"
                      aria-controls="ownership_of_data_section">
                      <i class="fa" aria-hidden="true"></i>
                      Ownership of Data</button>
                  </h5>
                </div>
                <div id="ownership_of_data_section" class="collapse" aria-labelledby="ownership_of_data"
                  data-parent="#accordion">
                  <div class="card-body">
                    <ul>
                      <li>You reserve all rights for the content (henceforth User Content) you upload to our Services. User Content includes, among other things, any mistakes in the content or information uploaded, pasted, or provided by you.
                      </li>
                      <li>By uploading or sending any content, you give Crimson AI (It’s parent company, its subsidiaries and associated companies) a non-exclusive, worldwide, royalty-free and fully-paid, transferable, and sub-licensable, perpetual, and irrevocable license to copy, store, and use your User Content for the provision of the services/software and to analyze, improve, develop and build algorithms, services, tools and systems. This content is anonymised to ensure that no personally identifiable data is being used.
                      </li>
                      <li>Our services may employ various technologies, machine learning models, and third-party services. We retain the right to decide which technologies will be integrated into the overall services or specific features, at our sole discretion. 
                      </li>
                      <li>
                        Where your content includes personal data, we commit to processing such data in accordance with applicable data protection laws, including the General Data Protection Regulation (GDPR) for users in Europe. For more information on how we process personal data, including your rights to access, rectify, delete, or object to the processing of your personal data, please refer to our Privacy Policy.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" id="ownership_of_data">
                  <h5 class="mb-0">
                    <button class="btn btn-link collapsed" data-toggle="collapse"
                      data-target="#plan_related_privacy_item" aria-expanded="false"
                      aria-controls="ownership_of_data_section">
                      <i class="fa" aria-hidden="true"></i>
                      Plan Related Privacy Items</button>
                  </h5>
                </div>
                <div id="plan_related_privacy_item" class="collapse" aria-labelledby="ownership_of_data"
                  data-parent="#accordion">
                  <div class="card-body">
                    <p>We will safeguard your User Content and never share it with third parties. However, you acknowledge that we may use your User Content to build algorithms and models, and such models and algorithms will be the exclusive intellectual property of Crimson AI.</p>
                    <p>We offer various plans with distinct data management practices for each plan. We reserve the right to modify these plans and their associated data management policies at any time, as deemed necessary.</p>
                    <p><b class="body-title">Trinka Enterprise (with Sensitive Data Plan):</b></p>
                    <ul>
                      <li>Data is deleted in real-time as soon as it is processed (Each alphabet in the sentence is replaced as a star (*) character to preserve it for analytics.) </li>
                      <li>Actual text is not saved.</li>
                      <li>In Proofread File, original file is immediately deleted.</li>
                      <li>No AI training is done on your data.</li>
                      <li>Logs are not printed.</li>
                    </ul>
                    <p><b class="body-title">Trinka Paid Plan(s):</b></p>
                    <ul>
                      <li>Processed data is automatically deleted from the server every 90 days.</li>
                      <li>No AI training is done on your data.</li>
                      <li>Logs are printed.</li>
                    </ul>
                    <p><b class="body-title">Trinka Free Plan(s):</b></p>
                    <ul>
                      <li>Data is retained for 1 year.</li>
                      <li>Used for AI Training.</li>
                      <li>Logs are printed.</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" id="email_address">
                  <h5 class="mb-0">
                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#email_address_section"
                      aria-expanded="false" aria-controls="email_address_section">
                      <i class="fa" aria-hidden="true"></i>
                      E-mail Address
                    </button>
                  </h5>
                </div>
                <div id="email_address_section" class="collapse" aria-labelledby="email_address"
                  data-parent="#accordion">
                  <div class="card-body">
                    <p>We will send you marketing communications on our Services with your prior consent. You may
                      withdraw your consent at any time by clicking the “Unsubscribe” link in emails from our Services.
                      Please note you will continue to receive transactional messages related to our Services, even if
                      you unsubscribe from marketing emails.</p>
                    <p>Your email address will not be shared with any third party. If your e-mail address is not
                      functional and we encounter issues such as e-mail bouncing, we are not liable for delivery of your
                      files. You need to contact us separately to receive your files in such situations.</p>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" id="indemnification">
                  <h5 class="mb-0">
                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#indemnification_section"
                      aria-expanded="false" aria-controls="indemnification_section">
                      <i class="fa" aria-hidden="true"></i>
                      Indemnification
                    </button>
                  </h5>
                </div>
                <div id="indemnification_section" class="collapse" aria-labelledby="indemnification"
                  data-parent="#accordion">
                  <div class="card-body">
                    <p>You shall indemnify, release and hold harmless Crimson AI and each of its officers, directors,
                      employees and agents, from and against any loss, liability (including settlements, judgments,
                      fines and penalties) and costs (including reasonable attorney fees, court costs and other
                      litigation expenses) relating to any claim or demand made by any third party due to or arising out
                      of your access to and use of our Services, violation of these Terms, or infringement of any
                      intellectual property or other right of any person or entity.</p>
                  </div>
                </div>
              </div>


              <div class="card">
                <div class="card-header" id="severability">
                  <h5 class="mb-0">
                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#severability_section"
                      aria-expanded="false" aria-controls="severability_section">
                      <i class="fa" aria-hidden="true"></i>
                      Severability
                    </button>
                  </h5>
                </div>
                <div id="severability_section" class="collapse" aria-labelledby="severability" data-parent="#accordion">
                  <div class="card-body">
                    <p>If one or more provisions of Terms are found to be unlawful or unenforceable, such provision(s)
                      shall be deemed severable and will not affect the validity and/or enforceability of the remaining
                      provisions of the Terms, which will remain in full force and effect.</p>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" id="warranties">
                  <h5 class="mb-0">
                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#warranties_section"
                      aria-expanded="false" aria-controls="warranties_section">
                      <i class="fa" aria-hidden="true"></i>
                      Warranties
                    </button>
                  </h5>
                </div>
                <div id="warranties_section" class="collapse" aria-labelledby="warranties" data-parent="#accordion">
                  <div class="card-body">
                    <p>To the maximum extent permitted by applicable law, Crimson AI expressly disclaims any and all
                      warranties and conditions, express or implied, regarding the Services, including, but not limited
                      to, any implied warranties or conditions of merchantability, merchantable quality, fitness for a
                      particular purpose, title, non-infringement, satisfactory quality or arising from a course of
                      dealing, law, usage, or trade practice, or regarding security, quiet enjoyment, reliability,
                      timeliness and performance. You agree that your use of the Services is at your own sole risk and
                      that the Services are provided on an “AS IS,” “WHERE IS,” “AS AVAILABLE,” “WITH ALL FAULTS” basis,
                      without warranties of any kind, either express or implied. Without limiting the foregoing, Crimson
                      AI does not warrant that the operation of the Services will meet your requirements or will be
                      uninterrupted or error-free.</p>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" id="governng_law">
                  <h5 class="mb-0">
                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#governng_law_section"
                      aria-expanded="false" aria-controls="governng_law_section">
                      <i class="fa" aria-hidden="true"></i>
                      Governing Law</button>
                  </h5>
                </div>
                <div id="governng_law_section" class="collapse" aria-labelledby="governng_law" data-parent="#accordion">
                  <div class="card-body">
                    <p>These Terms are governed by and to be construed in accordance with the Indian law. Any disputes
                      shall be subject to the exclusive jurisdiction of Mumbai courts.</p>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" id="changes_to_service">
                  <h5 class="mb-0">
                    <button class="btn btn-link collapsed" data-toggle="collapse"
                      data-target="#changes_to_service_section" aria-expanded="false"
                      aria-controls="changes_to_service_section">
                      <i class="fa" aria-hidden="true"></i>
                      Changes to Services
                    </button>
                  </h5>
                </div>
                <div id="changes_to_service_section" class="collapse" aria-labelledby="changes_to_service"
                  data-parent="#accordion">
                  <div class="card-body">
                    <p>Crimson AI reserves the right at any time to (i) change any information, specifications, features
                      or functions of the Services, including any Trial or Demonstration, (ii) suspend or discontinue,
                      temporarily or permanently, any or all of the Services or any Trial or Demonstration, including
                      the availability of any feature, database or content, or (iii) impose limits on certain features
                      and Services or restrict access to parts or all of the Services, including any Trial or
                      Demonstration, in each case with or without prior notice and without any liability to you or any
                      third party.</p>
                    <p>Crimson AI will use its commercially reasonable efforts to notify you of changes to the Services
                      that, in our reasonable opinion, have the effect of materially and adversely diminishing the
                      functionality of the Services to which you have subscribed.</p>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" id="changes_to_terms">
                  <h5 class="mb-0">
                    <button class="btn btn-link collapsed" data-toggle="collapse"
                      data-target="#changes_to_terms_section" aria-expanded="false"
                      aria-controls="changes_to_terms_section">
                      <i class="fa" aria-hidden="true"></i>
                      Changes to Terms</button>
                  </h5>
                </div>
                <div id="changes_to_terms_section" class="collapse" aria-labelledby="changes_to_terms"
                  data-parent="#accordion">
                  <div class="card-body">
                    <p>We may revise these terms from time to time and the revised version will supersede all previous
                      versions. Unless noted otherwise, the revisions will be effective upon the effective date
                      indicated at the top of this document. Your continued use of services after the revisions
                      acknowledges that you agree to the revisions. You can send an e-mail to Crimson AI with any
                      questions relating to these Terms via our <a routerLink='/contactus'>contact form</a>.</p>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" id="referProgram">
                  <h5 class="mb-0">
                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#referProgram_section"
                      aria-expanded="false" aria-controls="referProgram_section">
                      <i class="fa" aria-hidden="true"></i>
                      Referral Program
                    </button>
                  </h5>
                </div>
                <div id="referProgram_section" class="collapse" aria-labelledby="others_div" data-parent="#accordion">
                  <div class="card-body">
                    <p class="mb-10">Trinka’s referral program is a great way for you to share the benefits of our services with your friends, family members, or any other acquaintances. By referring them to Trinka, you not only help them discover a useful platform but also earn discounts on your subscription purchases. Please refer to the below guidelines for more details:</p>
                    <div class="referal-outer"> 
                      <h4>Eligibility Criteria:</h4>
                      <div class="referal-body">
                        <p><b>a) Eligibility to be a Referrer:</b></p>
                        <p>To qualify as a referrer for Trinka, you must:</p>
                        <div class="refer_program_text">
                          <ul>
                            <li>Have a Trinka account.</li>
                            <li>Not be an organizational user, meaning your account should not be upgraded through a special plan associated with an institution. For instance, if your university or organization has a subscription to Trinka, you cannot use your organizational or university email ID to refer friends.</li>
                          </ul>
                        </div>
                      </div>
                      <div class="referal-body">
                        <p><b>b) Eligibility to be a Referee:</b></p>
                        <p>To qualify as a referee, you must:</p>
                        <div class="refer_program_text">
                          <ul>
                            <li>Be a new user or a current user who has never purchased any Trinka paid plans such as Trinka Premium/ Premium Plus or be part of any institutional or enterprise plans.</li>
                            <li>Have not purchased any Trinka credits. </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div class="referal-outer"> 
                      <h4>Definition of a Successful Referral:</h4>
                      <div class="referal-body">
                        <p>A referral is considered successful when the referee uses a coupon code shared by the referrer to successfully complete a purchase of a Trinka subscription.</p>
                        
                      </div>
                    </div>

                    <div class="referal-outer"> 
                      <h4>How to Participate in the Referral Program:</h4>
                      <div class="referal-body">
                        <p>To join the referral program, please follow these steps:</p>
                        <p><b>Step 1: </b>Share your unique referral coupon with a referee, who could be a friend, family member, colleague, or anyone else in your network. You can make an unlimited number of referrals. You will receive rewards for each successful referral.</p>
                        <p><b>Step 2: </b>When the referee uses your unique referral coupon to purchase a subscription, they will receive a 10% discount on the subscription price. For example, if the referee buys a plan worth $125, they will apply your coupon code at checkout to receive an instant discount of $12.5, thus only needing to pay $112.5. Note that each referee can use the referral coupon only once. You may refer multiple referees, but each can use your coupon code only once.</p>
                        <p><b>Step 3: </b>Once the referee completes their purchase using your unique referral coupon, you will receive a referral reward worth 10% of their purchase amount. For instance, if the referee purchases a plan worth $125, you will receive a reward of $12.5 in your Trinka account. </p>
                      </div>
                    </div>

                    <div class="referal-outer"> 
                      <h4>Rewards:</h4>
                      <div class="referal-body">
                        <p>When the referee purchases a subscription plan on Trinka using the unique referral coupon you shared, you will receive rewards worth 10% of the purchase amount. This will be added to your Trinka account as Referral Rewards which can be used in the following ways:</p>
                        <div class="refer_program_text">
                          <ul>
                            <li>To purchase a Trinka subscription.</li>
                            <li>To upgrade your existing Trinka subscription.</li>
                            <li>It will be automatically applied on the renewal of your existing subscription.</li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div class="referal-outer"> 
                      <h4>Restrictions</h4>
                      <div class="referal-body">
                        <p>Below are some of the guidelines regarding the restrictions on participating in the referral program:</p>
                        <div class="refer_program_text">
                          <ul>
                            <li>Referral coupons can be used only once per user. While you can refer as many people as you wish, each referee can only use the coupon once.</li>
                            <li>Once a user has used a referral coupon for a discount, other referral coupons cannot be applied to their account.</li>
                            <li>Referral rewards and coupons apply solely to subscription purchases.</li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <p>Check FAQ for frequently asked questions regarding the referral program.</p>

                    
                    <h3>Updated: 15 April 2024.</h3>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" id="fairUsePolicy">
                  <h5 class="mb-0">
                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#fairUsePolicy_section"
                      aria-expanded="false" aria-controls="fairUsePolicy_section">
                      <i class="fa" aria-hidden="true"></i>
                      Fair Use Policy
                    </button>
                  </h5>
                </div>
                <div id="fairUsePolicy_section" class="collapse" aria-labelledby="others_div" data-parent="#accordion">
                  <div class="card-body">
                    <div class="refer_program_text">
                      <p>A Fair Use Policy, limiting the total monthly usage of <a [routerLink]="['/grammar-checker']">language check</a> to 150,000 words and <a [routerLink]="['/features/plagiarism-check']">plagiarism checks</a> to 120 scans is applicable. The limits may change in the future. Any changes will be disclosed in the terms.</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" id="QuestionRefundPolicy">
                  <h5 class="mb-0">
                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#QuestionRefundPolicy_section"
                      aria-expanded="false" aria-controls="QuestionRefundPolicy_section">
                      <i class="fa" aria-hidden="true"></i>
                      Refund Policy
                    </button>
                  </h5>
                </div>
                <div id="QuestionRefundPolicy_section" class="collapse" aria-labelledby="others_div" data-parent="#accordion">
                  <div class="card-body">
                    <div class="refer_program_text">
                      <p style="margin-bottom: 10px;">Refunds are not allowed for purchased subscriptions. Once you purchase a subscription, you can cancel it whenever you wish to avoid getting charged for the renewal of the subscription. When you cancel your subscription, the subscription continues till the end of the period you purchased it for. </p>
                      <p style="margin-bottom: 10px;">You can move from a shorter subscription period to a longer subscription period (for example, monthly to yearly Trinka subscription); in such cases, the amount to be paid by you for the new subscription will be adjusted to subtract the remaining amount of the current subscription. </p>
                      <p>You can cancel your subscription from your account settings. For any support, just email us at <a href="mailto:support@trinka.ai">support@trinka.ai</a>.</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" id="others_div">
                  <h5 class="mb-0">
                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#others_div_section"
                      aria-expanded="false" aria-controls="others_div_section">
                      <i class="fa" aria-hidden="true"></i>
                      Others
                    </button>
                  </h5>
                </div>
                <div id="others_div_section" class="collapse" aria-labelledby="others_div" data-parent="#accordion">
                  <div class="card-body">
                    <p>Crimson AI may, in its sole and absolute discretion, immediately terminate Services should the
                      client's conduct fail to conform to the Terms. By using our Services, the client agrees to be
                      bound by the Terms. Should the client object to any such terms of service or any subsequent
                      modifications to the Terms or become dissatisfied with the Services in any way, the client’s sole
                      recourse is to discontinue his/her use of the Services.</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>