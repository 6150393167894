<!-- chart.component.html -->
<!-- <div [id]="id" class="graph"></div> -->


<div>
    <div style="display: block;">
      <canvas baseChart
              [datasets]="chartData"
              [labels]="chartLabels"
              [options]="chartOptions"
              [colors]="chartColors"
              [chartType]="chartType1"
              [legend]="false"
              [fill]="false">
      </canvas>
    </div>
  </div>
  