import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'app-detection-limit-exhaust-modal',
  templateUrl: './detection-limit-exhaust-modal.component.html',
  styleUrls: ['./detection-limit-exhaust-modal.component.scss']
})
export class DetectionLimitExhaustModalComponent implements OnInit {
  constructor (
    public dialogRef: MatDialogRef<DetectionLimitExhaustModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit (): void {}

  onCloseClick (): void {
    this.dialogRef.close()
  }

  redirectToSignupPage() {
    let urlToBeRedirected = "https://cloud.trinka.ai/signup";
    window.open(urlToBeRedirected, '_blank').focus();
  }
}
