<!-- <div class="freeGrammarHeader" id="freeGrammarHeader">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="grammarCheckerHeader">
                    <div class="grammarCheckerLogo">
                        <a href="">
                            <img src="../../../assets/images/trinka-logo.png" alt="logo">
                        </a>
                    </div>
                    <div class="registerFreeSection">
                        <a href="https://cloud.trinka.ai/signup?_rg=GB" target="_blank">Register for Free</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<div class="freeGrammarCheckerSlider">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="maineSectionFreeGrammerChecker contactus_contents">
                    <h2>Check your<br />
                        Grammar for Free</h2>
                    <p>Trinka finds difficult errors from advanced English grammar errors to scientific tone and style,
                        instantly. </p>
                    <div class=" contact_forms">
                        <form [formGroup]="compareTrinkaGrammarly" (ngSubmit)="onSubmitEmail()">
                            <div class="form-group"
                                [ngClass]="(!compareTrinkaGrammarly.get('userEmailId').valid && compareTrinkaGrammarly.get('userEmailId').dirty) || (compareTrinkaGrammarly.get('userEmailId').touched && compareTrinkaGrammarly.get('userEmailId').pristine) ? 'TextFiledError' : '' ">
                                <mat-form-field appearance="outline">
                                    <mat-label>Email *</mat-label>
                                    <input id="contactUserEmail" matInput placeholder="" formControlName="userEmailId"
                                        class="form-control">
                                </mat-form-field>
                                <button type="submit">It's Free</button>
                            </div>
                        </form>
                        <span>*No Credit Card Required</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="GrammarRightImg">
        <img src="../../../assets/images/Top-Silder-img.png" alt="top slider">
    </div>
</div>

<div class="TrustedSection">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <ul class="row">
                    <li class="col-md-4 col-sm-12">
                        <img src="../../../assets/images/TrustedBy_img.png" alt="tusted by">
                        <p>Trusted by 1M+ Writers/Authors</p>
                    </li>
                    <li class="col-md-4 col-sm-12"> 
                        <img src="../../../assets/images/WorksOnAllSubject_Icons.png" alt="works on all">
                        <p>Works on all subjects</p>
                    </li>
                    <li class="col-md-4 col-sm-12">
                        <img src="../../../assets/images/AIDrivenResults_Icons.png" alt="ai driven">
                        <p>AI Driven Results</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="madeForAcademicSection">
    <div class="container">
        <!-- <div class="row">
            <div class="col-md-12">
                <div class="madeForAcademicHeading">
                    <h2>Made for Academic and Technical Writing</h2>
                    <div class="HeadingSubLine"></div>
                    <p>Trinka finds difficult errors unique to academic writing that other grammar checker tools don’t. From<br />
                        advanced English grammar errors to scientific tone and style, Trinka checks it all!</p>
                </div>
            </div>
        </div> -->
        <div class="row">
            <div class="col-md-6">
                <div class="madeForAcademicContects">
                    <div class="madeForAcademicHeading mb-0">
                        <h2>Finds tricky errors that others don’t</h2>
                        <div class="HeadingSubLine"></div>
                        <p>Trinka is specially made for academic and technical writing; it corrects 3000+ complex grammar errors.</p>
                    </div>
                    <ul>
                        <li> <img src="../../../assets/images/PricingCheckMark.png" alt="icon"> Subject-verb, pronoun-antecedent disagreements</li>
                        <li> <img src="../../../assets/images/PricingCheckMark.png" alt="icon"> Syntax, word choice</li>
                        <li> <img src="../../../assets/images/PricingCheckMark.png" alt="icon"> Pronoun, article usage</li>
                        <li> <img src="../../../assets/images/PricingCheckMark.png" alt="icon"> Technical spellings</li>
                    </ul>
                </div>
            </div>
            <div class="col-md-6">
                <div class="madeForAcademicImg">
                    <img src="../../../assets/images/MadeForAcademic_img.png" alt="academia">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="madeForAcademicSection colReviseSection">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="madeForAcademicImg">
                    <img src="../../../assets/images/improves-writing.png" alt="Improves writing beyond grammar and spelling">
                </div>
            </div>
            <div class="col-md-6">
                <div class="madeForAcademicContects">
                    <div class="madeForAcademicHeading mb-0">
                        <h2>Improves writing beyond grammar and spelling</h2>
                        <div class="HeadingSubLine"></div>
                        <p>Trinka goes beyond professional grammar checks and enhances your writing for vocabulary, tone, syntax,
                            and much more. Make your point confidently, with Trinka.</p>
                    </div>
                    <ul class="styleGuidesSection">
                        <li>
                            <img src="../../../assets/images/style_guides_icon_old.png" alt="trinka-service-apa-ama-style">
                            <span>Style guides (APA | AMA)</span>
                        </li>
                        <li>
                            <img src="../../../assets/images/scientific_tone_icon_old.png" alt="scientific-tone-grammar-check" />
                            <span>Formal tone</span>
                        </li>
                        <li>
                            <img src="../../../assets/images/technical_word_choice_icon_old.png" alt="technical-word-grammar-check" />
                            <span>Technical word choice</span>
                        </li>
                        <li>
                            <img src="../../../assets/images/word_count_reduction_icon_old.png" alt="word-count-reduction-grammar-checker" />
                            <span>Conciseness</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="madeForAcademicSection">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="madeForAcademicContects">
                    <div class="madeForAcademicHeading mb-0">
                        <h2>Suggests corrections relevant to your subject</h2>
                        <div class="HeadingSubLine"></div>
                        <p>Trinka has learned from the best-written papers in every subject to give you the best suggestions. With
                            Trinka, your academic writing will always be precise.</p>
                    </div>
                    <img class="Suggests_Corrections_Icon_img" src="../../../assets/images/Suggests_Corrections_Icon_img.png" alt="icon">
                    <!-- <ul class="medicineSection">
                        <i></i>
                        <li><img src="../../../assets/images/medicine.png" alt=""> Medicine</li>
                        <li><img src="../../../assets/images/biology.png" alt="">Biology</li>
                        <li><img src="../../../assets/images/physics-icons.png" alt="">Physics</li>
                        <li><img src="../../../assets/images/social-science.png" alt="">Social sciences</li>
                        <li><img src="../../../assets/images/engineering.png" alt="">Engineering</li>
                        <li><img src="../../../assets/images/chemistry.png" alt="">Chemistry</li>
                        <li><img src="../../../assets/images/geology.png" alt="">Geology</li>
                        <li><img src="../../../assets/images/computer-science.png" alt="">Computer science</li>
                        <li><img src="../../../assets/images/business-and-economics.png" alt="">Economics</li>
                    </ul> -->
                </div>
            </div>
            <div class="col-md-6">
                <div class="madeForAcademicImg">
                    <img src="../../../assets/images/suggestion-corrections.png" alt="Suggests corrections relevant to your subject">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="freeGrammarTestimonialSection">
    <div class="freeGrammarTestimonialSectionOverlay"></div>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h3>Testimonials</h3>
                <!-- <div class="HeadingSubLine"></div> -->
            </div>
            <div class="col-md-4">
                <div class="testimonialBoxSection">
                    <img src="../../../assets/images/clientPhotos/James-Smith.jfif" alt="tesitmonails">
                    <p>Firstly, I love Trinka. Congratulations on developing something that extensively aids editing, especially critical grammar conventions and word count reductions.</p>
                    <h4>James Smith</h4>
                    <span>Academic Editor, USA</span>
                </div>
            </div>
            <div class="col-md-4">
                <div class="testimonialBoxSection">
                    <img src="../../../assets/images/clientPhotos/Dinesh-Gupta.jfif" alt="tesitmonails">
                    <p>The grammar checking tool exceeds Grammarly! I re-checked some documents that I had checked earlier with Grammarly. I was pleasantly surprised to see such a product from India!</p>
                    <h4>Dinesh Gupta</h4>
                    <span>Radiologist, India</span>
                </div>
            </div>
            <div class="col-md-4">
                <div class="testimonialBoxSection">
                    <img src="../../../assets/images/clientPhotos/Georgieva-Milena.jfif" alt="tesitmonails">
                    <p>Trinka.ai is my favorite choice for grammar and spelling edit. The application works smoothly, is fast and accurate! Completely satisfied!</p>
                    <h4>Georgieva Milena</h4>
                    <span>Researcher</span>
                </div>
            </div>
        </div>
    </div>
</div>