<div class="affiliate_page_wrap">
    <div class="affiliate_top_banners">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="affi_top_contents">
                        <h1>Promote Trinka & Earn Big!</h1>
                        <div class="bottom_shareAsale_buttons">
                            <a id="signUpAtShareASale" href="https://www.shareasale.com/shareasale.cfm?merchantID=98815" target="_blank">
                                <i class="fa fa-sign-in"></i>
                                Sign Up at ShareASale
                            </a>
                            <span class="text-white">OR</span>
                            <a id="signUpAtShareASale" href="https://app.impact.com/advertiser-advertiser-info/Trinka-AI.brand" target="_blank">
                                <i class="fa fa-sign-in"></i>
                                Sign Up at Impact
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="benefits_sections section-pd">
        <div class="benefits_outer">
            <div class="commission_sections">
                <div class="commission_header">
                    <img src="../../../assets/images/CommisionDetails.png" alt="Commision Details">
                    <h2>Commission Details</h2>
                </div>
                <div class="commission_body">
                    <ul>
                        <li>Paid plan subscription - 25%</li>
                    </ul>
                </div>
            </div>

            <div class="commission_sections OtherBenefits_section">
                <div class="commission_header">
                    <img src="../../../assets/images/otherBenefits.png" alt="other benefits">
                    <h2>Other Benefits</h2>
                </div>
                <div class="commission_body">
                    <ul>
                        <li>Great conversion rates</li>
                            <li>Cross-device tracking</li>
                            <li>Extended 90-day cookie window</li>
                            <li>Early bird rewards</li>
                            <li>Top-class affiliate support</li>
                    </ul>
                </div>
            </div>

        </div>
    </div>

    <div class="trinka-banner section-pd-sm">
        <div class="container">
            <div class="row align-items-center justify-content-center">
                <div class="col-md-7 col-lg-8">
                    <div class="trinka-title">
                        <h3 class="text-white mb-4">Why become an Affiliate?</h3>
                        <p class="text-white">Grammar and sentence corrections can be a daunting task, and most online grammar checker tools
                            struggle when it comes to academic and research paper proofreading. Trinka harnesses AI and ML
                            technologies coupled with decades of industry expertise to provide all the essential suggestions
                            required for <a routerLink="/grammar-checker">writing an error-free academic or research paper</a>. Trinka is completely free and
                            available to use online, as a browser plugin and MS Word plugin.</p>
                        <p class="text-white">By partnering with Trinka, you will not just be helping the academic professionals and
                            institutions but also earn a steady stream of revenue with minimal efforts. Trinka will handle
                            all the heavy lifting so you can continue to focus on what you do best and maintain your client
                            relationships by providing them the latest and useful content and recommendations.</p>
                    </div>
                </div>
                <div class="col-md-5 col-lg-4">
                    <div class="text-center">
                        <img src="../../../assets/images/anAffiliate.png" loading="lazy" alt="affiliate" class="img-fluid" />
                    </div>
                </div>
            </div>  
        </div>
    </div>


    <!-- <div class="why_become_affiliate_sectioin">
        <div class="container">
            <div class="row">
                <div class="col-md-9">
                    <h2>Why become an Affiliate?</h2>
                    <div style="background-color: #FFF;" class="HeadingSubLine"></div>
                    <p>Grammar and sentence corrections can be a daunting task, and most online grammar checker tools
                        struggle when it comes to academic and research paper proofreading. Trinka harnesses AI and ML
                        technologies coupled with decades of industry expertise to provide all the essential suggestions
                        required for <a href="/grammar-checker">writing an error-free academic or research paper</a>. Trinka is completely free and
                        available to use online, as a browser plugin and MS Word plugin.</p>
                    <p>By partnering with Trinka, you will not just be helping the academic professionals and
                        institutions but also earn a steady stream of revenue with minimal efforts. Trinka will handle
                        all the heavy lifting so you can continue to focus on what you do best and maintain your client
                        relationships by providing them the latest and useful content and recommendations.</p>
                </div>
                <div class="col-md-3">
                    <div class="affiliate_img">
                        <img src="../../../assets/images/anAffiliate.png" alt="affiliate">
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <div class="faqQuestionAnwser section-pd" #target>
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-lg-8 col-md-10">
                    <div class="trinka-title text-center">
                        <h2>Affiliate FAQs</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    </div>
                </div>
            </div>
            <div class="faqQuestionsList">
                <div class="accordion" id="faq">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="faqContainer">
                                <div class="card">
                                    <div class="card-header" id="heading-1">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#collapse-1" aria-expanded="true"
                                            aria-controls="collapse-1">
                                            About Trinka Affiliate Program
                                        </a>
                                    </div>
                                    <div id="collapse-1" class="collapse" data-parent="#faq"
                                        aria-labelledby="heading-1">
                                        <div class="card-body">
                                            <p>Trinka's affiliate program functions in a simple and efficient manner ‒ you either put a banner or a tracking text link on your website (or social media profile, email, e-book, etc.), and you get paid every time a referred user upgrades to a premium plan.  As we have a high conversion rate and attractive commission rate for each successful paid upgrade, the affiliates can earn a lot without an over-assertive sales pitch. Putting the word out there is enough, but the top performers get higher payouts.</p>
                                            <p>For all queries, please email <a
                                                    href="mailto:marketing@trinka.ai.">marketing@trinka.ai.</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="heading-2">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#collapse-2" aria-expanded="true"
                                            aria-controls="collapse-2">
                                            Who can become a Trinka Affiliate?
                                        </a>
                                    </div>
                                    <div id="collapse-2" class="collapse" data-parent="#faq" aria-labelledby="heading-2">
                                        <div class="card-body">
                                            <p>Trinka helps people all around the globe ‒ students, researchers, scientists,
                                                professionals, language enthusiasts, and everyone who writes in English.
                                                Trinka’s affiliate program fits perfectly with websites or blogs linked to
                                                writing. Moreover, we have several thriving affiliates whose content emphasizes
                                                on education, science, technology, career development, and numerous other areas
                                                that appeal to Trinka’s diverse user base.</p>
                                            <p>You can maximize your chances of getting into our affiliate program by creating
                                                content that’s in sync with our mission and ensuring that it’s relevant, well
                                                structured, and up to date.</p>
                                                
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="heading-3">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#collapse-3" aria-expanded="true"
                                            aria-controls="collapse-3">
                                            How can I become a Trinka Affiliate?
                                        </a>
                                    </div>
                                    <div id="collapse-3" class="collapse" data-parent="#faq" aria-labelledby="heading-3">
                                        <div class="card-body">
                                            <p>Once you are accepted into the program, you will be given a range of affiliate
                                                banners and links. If you prefer, you can utilize newsletters and emails to
                                                promote Trinka.</p>
                                            <p>When you put our banners and/or links, please ensure that you add them to pages
                                                with valuable and suitable content. As a best practice, do not place the links
                                                and banners next to several other banners/links. </p>
                                            <p>Please do not give customers incentives/rewards for joining Trinka.</p>
                                            <p>Furthermore, avoid bidding on the trademarks “Trinka” or "Trinka.ai,” or any
                                                variants thereof, including incorrect spellings. Also, please refrain from using
                                                those trademarked names in domain names or Meta tags or link directly to our
                                                website from pay-per-click ads.</p>
                                            <p style="margin: 0;">Moreover, we forbid bidding on the below-listed brand and non-brand keywords:</p>
                                            <p>Trinka, Trinka AI, trinka.ai, www.trinka.ai, plagiarism checker, <a href="/grammar-checker">AI grammar checker</a>, grammar check, plagiarism check, free plagiarism checker, grammar checker free, online grammar checker, writing assistant, academic writing assistant, technical writing assistant, proofreading.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="faqContainer">
                                <div class="card">
                                    <div class="card-header" id="heading-4">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#collapse-4" aria-expanded="true"
                                            aria-controls="collapse-4">
                                            What are the benefits of becoming an Affiliate?
                                        </a>
                                    </div>
                                    <div id="collapse-4" class="collapse" data-parent="#faq" aria-labelledby="heading-4">
                                        <div class="card-body">
                                            <p>Our exceptional commission structure offers you a sales commission of 25% of the transaction value per paid upgrade. Every link on your website has a specific tracking code. Each time a customer clicks via your website to trinka.ai and upgrades to a paid plan, we document that conversion and assign it to your account. At any time, you can see your conversions and earnings from the dashboard or the Reports section in ShareASale or PostAffiliatePro. Affiliates earn commissions when users upgrade to premium plans within 90 days of first visiting the website thanks to the extended 90-day cookie window.</p>
                                                <p><strong>Note: </strong>All payments will be made in USD only. Please ensure your bank accepts USD if you are not in the US.</p>
                                        </div>
                                    </div>
                                </div>
        
                                <div class="card">
                                    <div class="card-header" id="heading-5">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#collapse-5" aria-expanded="true"
                                            aria-controls="collapse-5">
                                            What are the terms and conditions of the Trinka Affiliate Program?
                                        </a>
                                    </div>
                                    <div id="collapse-5" class="collapse" data-parent="#faq" aria-labelledby="heading-5">
                                        <div class="card-body">
                                            <p>Please visit <a href="https://www.trinka.ai/termsofservices"
                                                    target="_blank">this link</a> to read our terms and conditions.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="section-pd pt-0">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center">
                    <div class="bottom_shareAsale_buttons">
                        <a id="signUpAtShareASaleFooter" href="https://www.shareasale.com/shareasale.cfm?merchantID=98815" target="_blank">
                            <i class="fa fa-sign-in"></i>
                            Sign Up at ShareASale
                        </a>
                        <span>OR</span>
                        <a id="signUpAtShareASale" href="https://app.impact.com/advertiser-advertiser-info/Trinka-AI.brand" target="_blank">
                            <i class="fa fa-sign-in"></i>
                            Sign Up at Impact
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>