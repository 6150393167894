import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { DetectionLimitExhaustModalComponent } from '../detection-limit-exhaust-modal/detection-limit-exhaust-modal.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay'
import { FeedbackModalComponent } from 'src/app/Components/feedback-modal/feedback-modal.component';

@Component({
  selector: 'app-spanish-grammar-checker',
  templateUrl: './spanish-grammar-checker.component.html',
  styleUrls: ['./spanish-grammar-checker.component.scss']
})
export class SpanishGrammarCheckerComponent implements OnInit {
  public minLength = 0;
  public maxLength = 300;
  editorConfig: any = {
    endpoint: environment.server_url.multilingual_server,
    lang: 'es',
    minHeight: 360,
    sessionLimit: 3
  };

  constructor(
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }
  showSnackBar($event: any) {
    console.log('showSnackBar ===> ', $event);
    this._snackBar.open($event, '', {
      duration: 3000
    });
  }
  sessionExhausted($event: any) {
    console.log('limitExhausted ===> ', $event);
    this._dialog.open(DetectionLimitExhaustModalComponent, {
      backdropClass: 'custom-dialog-background',
      width: '490px',
      height: 'auto',
      scrollStrategy: new NoopScrollStrategy(),
      panelClass: "border-radius16",
    });
  }
  openFeedbackModal($event: any) {
    console.log('openFeedbackModal ===> ', $event);
    const dialogRef = this._dialog.open(FeedbackModalComponent, {
      width: '464px',
      height: 'auto',
      disableClose: true,
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        feature: 'multilingual',
        language: this.editorConfig.lang
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed', result);
    });
  }

}
