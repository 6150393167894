import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormControl, FormGroup } from '@angular/forms'
import { WebsiteService } from 'src/app/_services/website.service'
import { ToastrService } from 'ngx-toastr'
import { NgxSpinnerService } from 'ngx-spinner'

@Component({
  selector: 'app-ai-content-feedback-modal',
  templateUrl: './ai-content-feedback-modal.component.html',
  styleUrls: ['./ai-content-feedback-modal.component.scss']
})
export class AiContentFeedbackModalComponent implements OnInit {
  feedbackText: string = ''
  ratingFeedback: number = 0
  ratings: number[] = [1, 2, 3, 4, 5]
  feedbackForm!: FormGroup
  feedbackSubmitted: boolean = false
  options: any = [{
    'text': 'It was human generated'
  }, {
    'text': 'It was human generated but edited / paraphrased by AI tools '
  }, {
    'text': 'It was AI generated '
  }, {
    'text': 'It was AI generated but edited / paraphrased by human'
    }]
  selectedOptionNumber: number = -1;
  isLiked: boolean = false;
  inputText: string = "";
  userID: string = "";
  apiResponse: any = undefined;

  constructor (
    public dialogRef: MatDialogRef<AiContentFeedbackModalComponent>,
    private _appService: WebsiteService,
    private spinner: NgxSpinnerService,
    private _toast: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.feedbackForm = new FormGroup({
      feedbackText: new FormControl('')
    });
    this.isLiked = this.data.isLiked
    this.inputText = this.data.inputText
    this.userID = this.data.userID
    this.apiResponse = this.data.apiResponse
  }

  ngOnInit(): void { }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  onSubmitClick() {
    const payload = {
      "like": this.isLiked,
      "optionSelection": this.options[this.selectedOptionNumber].text,
      "textFeedback": this.feedbackForm.get('feedbackText').value,
      "inputText": this.inputText,
      "source": 'Trinka Marketing',
      "sessionID": this.userID,
      "finalResult": this.apiResponse
    };

    this._appService.submitAIFeedbackCall(payload).subscribe({
      next: (response: any) => {
        if (response.message) {
          this.feedbackSubmitted = true;
        } else {
          this.feedbackSubmitted = false;
        }
      },
      complete: () => { },
      error: (err: any) => {
        this.feedbackSubmitted = false;
        this._toast.error("Failed to submit feedback!");
      }
    })
  }
}
