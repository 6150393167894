<section class="section-pd customAIBanner">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-11 col-lg-12">
                <div class="customAIContent">
                    <h2>Why Choose Us? </h2>
                    <h1>Because We Help You Build and Own Your AI.</h1>
                    <p class="mt-4">Custom AI that Becomes Your Business Asset, Not Just a Tool.</p>
                    <div class="btn-group justify-content-center mt-40">
                        <button data-toggle="modal" data-target="#EnterPriseContactPopup" class="filled-btn nowrap" type="button" class="white-btn">Let's Talk</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="custom-shape">
        <img src="/assets/images/custom-ai/custom-ai-banner.png" width="2000" height="667" alt="custom ai" />
    </div>
</section>

<section class="section-pd pb-0">
    <div class="trinka_container container">
        <div class="row justify-content-center">
            <div class="col-md-12 col-lg-12">
                <div class="trinka-title mb-0 text-center">
                    <h2>Make AI Your Strategic Asset</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    <p>At Trinka AI, we believe that AI should be more than just another service. It should be an asset—a core part of your intellectual property that drives business outcomes and offers a long-term competitive advantage.</p>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-11">
                <div class="media stm-media mt-4">
                    <div class="media-left">
                        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.5 10C0.5 4.47715 4.97715 0 10.5 0H32.5V22C32.5 27.5228 28.0228 32 22.5 32H10.5C4.97715 32 0.5 27.5228 0.5 22V10Z" fill="#763393"/>
                            <rect x="2.97363" y="1.20117" width="27.5977" height="27.5977" rx="13.7988" fill="white"/>
                            <path d="M16.7725 28.7988C24.3933 28.7988 30.5713 22.6209 30.5713 15C30.5713 7.37911 24.3933 1.20117 16.7725 1.20117C9.15158 1.20117 2.97363 7.37911 2.97363 15C2.97363 22.6209 9.15158 28.7988 16.7725 28.7988ZM17.8286 22.3037L17.2357 19.5732C18.973 19.1886 20.5705 18.6885 20.4412 16.7484H18.3996V9.48178H24.5495V16.2734C24.5473 21.6709 20.5846 22.11 17.8286 22.3037ZM9.59006 22.3037L8.99546 19.5732C10.7328 19.1886 12.3303 18.6885 12.2009 16.7484H10.1594V9.48178H16.3092V16.2734C16.3071 21.6709 12.346 22.11 9.59006 22.3037Z" fill="#763393"/>
                        </svg>
                    </div>
                    <div class="media-body">
                        <p class="mb-0">Our approach to custom AI ensures that the models we build are not only aligned with your business needs but are fully owned by you, giving you complete control over their functionality and future development.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="section-pd pb-0 whyChooseUs">
    <div class="shape w-100 text-center">
        <img src="/assets/images/custom-ai/shape1.svg" width="1520" height="1575" alt="shape" class="img-fluid" />
    </div>
    <div class="trinka_container container">
        <div class="row justify-content-center">
            <div class="col-md-12 col-lg-12">
                <div class="trinka-title mb-0 text-center">
                    <h2>Why Choose Us?</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                </div>
            </div>
        </div>
        <div class="row match-box">
            <div class="col-sm-6 dflex">
                <div class="media">
                    <div class="media-body">
                        <h4>Full Ownership, <br/>Full Control <span><img src="/assets/images/custom-ai/ownership.svg" width="65" height="65" alt="Full Ownership" /></span></h4>
                        <p>Unlike many AI services that retain control over models, we ensure that your custom AI model is your intellectual property. You control how it’s used, where it’s deployed, and how it evolves.</p>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 dflex">
                <div class="media">
                    <div class="media-body">
                        <h4>Deep Expertise in <br/>Custom Models <span><img src="/assets/images/custom-ai/expertise.svg" width="65" height="65" alt="Custom Models" /></span></h4>
                        <p>We specialize in building models that are fine-tuned for specific tasks, using advanced techniques like RLHF to ensure high accuracy and alignment with your business processes.</p>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 dflex">
                <div class="media">
                    <div class="media-body">
                        <h4>Secure and <br/>Scalable <span><img src="/assets/images/custom-ai/secure.svg" width="65" height="65" alt="Secure and Scalable" /></span></h4>
                        <p>We prioritize secure deployments, ensuring that your model is both scalable and optimized for fast, cost-effective inference.</p>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 dflex">
                <div class="media">
                    <div class="media-body">
                        <h4>Long-Term <br/>Business Value <span><img src="/assets/images/custom-ai/value.svg" width="65" height="65" alt="Business Value" /></span></h4>
                        <p>By owning your AI, you’re building a valuable asset for your business that grows with your data and scales with your needs.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="customAI_LearnMore hideCard4">
    <app-learn-more-section></app-learn-more-section>
</section>

<section class="section-pd custom-ai-gradient-light">
    <div class="trinka_container container">
        <div class="row justify-content-center">
            <div class="col-md-12 col-lg-10">
                <div class="trinka-title text-center">
                    <h2>Interested in transforming your business with custom AI?</h2>
                </div>
                <div class="btn-group justify-content-center mt-40">
                    <button data-toggle="modal" data-target="#EnterPriseContactPopup" class="filled-btn nowrap" type="button" class="filled-btn">Let's Talk</button>
                </div>
            </div>
        </div>
    </div>
</section>

<app-enterprise-modal></app-enterprise-modal>