import { Component, AfterViewInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { Label, Color } from 'ng2-charts';
// declare var FusionCharts: any; // Declare FusionCharts from the CDN


@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements AfterViewInit, OnChanges {
  @Input() id: string; // Input for the chart container ID
  @Input() dataSource: any; // Input for the data source
  @Input() chartType: any; // Input for the data source

  // Define chart options
  public chartOptions: ChartOptions = {
    responsive: true,
    elements: {
      line: {
        tension: 0,
        fill: false
      }
    },
    scales : {
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'No. of Articles Published'
        },
         ticks: {
            beginAtZero: true,
            stepSize: 5  // Set maximum Y-axis value to 5
          },
          gridLines: {
            display: true, // Set this to false to hide horizontal grid lines
          },
      }],
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Year'
        },
        ticks: {
           beginAtZero: true,
         },
         gridLines: {
          display: false, // Set this to false to hide horizontal grid lines
        },
     }]
    }
  };

  // Define labels
  public chartLabels: Label[] = [];

  // Chart data
  public chartData = [
    {data:[]}
  ];

  // Chart type
  public chartType1: ChartType = 'bar';

  // Colors for the chart
  public chartColors: Color[] = [
  ];
  
  private chart: any;

  ngAfterViewInit() {
    // this.renderChart();
    let chartDataItems = [];
    console.log("this.dataSource ", this.dataSource)
    for (let index = 0; index < this.dataSource.data.length; index++) {
      this.chartLabels.push(this.dataSource.data[index].label);
      this.chartColors.push({
          backgroundColor: this.dataSource.data[index].color,
          borderColor: this.dataSource.data[index].color,
          hoverBackgroundColor: this.dataSource.data[index].color,
      });
      chartDataItems.push(this.dataSource.data[index].value)
    }
    this.chartData[0].data = chartDataItems;

    //  FusionCharts.options.license({
    //   key: 'KqA8ydjB3B3E2A3A15B10A7C6D5D5C1H2C1ixrB1F2gcowc1B6A7A-13mB-9A3H2C2B2C1E7C7E1F5C4A1B3A6B2D3D2ippE1C3C11d1C7A3B4utcH3B5D7moB-9D3C4D2H-7geE3A3B2F4H2D3H2C8B1E4B1zllA33A8a1B9d1dB-11G4F4D3jyA7RA1E3wwi1A1A7A4B6B5F5E4A2H3G3A5B4B3C7E-11==',
    //   creditLabel: false
    // });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.chartType && !changes.chartType.isFirstChange()) {
      // this.renderChart();
      this.chartType1 = this.chartType; // Re-render chart when type changes
    }
  }

  // private renderChart() {
  //   if (this.chart) {
  //     this.chart.dispose(); // Dispose of the old chart if it exists
  //   }
  //   this.chart = new FusionCharts({
  //     type: this.chartType || "bar",
  //     renderAt: this.id, // container where chart will render
  //     width: "100%",
  //     height: "220",
  //     dataFormat: "json",
  //     dataSource: this.dataSource
  //   });
  //   this.chart.render();
  // }
}
