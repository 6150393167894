<div class="trinka_cloud_wrap">
    <div id="particles-js"></div>
    <div class="cloud_top_banners">
        <!-- <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="trinka_cloud_banner_text">
                        <h1>Trinka Integrations
                            <br />for Your Enterprise</h1>
                        <p style="margin: 0 0 10px 0;">Easily integrate Trinka into your enterprise workflows and give your team access to all of Trinka's
                            powerful capabilities.</p>
                        <button style="margin: 0; padding: 0;" (click)="sectionValue('Enterprise Form')" id="salesContactButtons" data-toggle="modal"
                            data-target="#EnterPriseContactPopup" class="plansButtons" type="submit">Contact Us</button>
                            <a href="https://calendly.com/trinkaai/meet" target="_blank"  id="autoEditGetStart" class="cta-outline-btn m25">Book a Demo</a>

                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="trinka_cloud_banner_img">
                        <img src="../../../assets/images/enterprise_banner_img.png" alt="enterpise">
                    </div>
                </div>
            </div>
        </div> -->

        <section class="mainTopBanner">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="topBannerWrapper">
                            <div class="top-banner-left">
                                <h1>Trinka for Enterprise</h1>
                                <h2>Trinka Integrations
                                    for Your Enterprise</h2>
                                    <p>Easily integrate Trinka into your enterprise workflows and give your team access to all of Trinka's
                                        powerful capabilities.</p>
                                    <div class="btn-group">
                                        <button style="margin: 0; padding: 0;" (click)="sectionValue('Enterprise Form')" id="salesContactButtons" data-toggle="modal"
                                        data-target="#EnterPriseContactPopup" class="filled-btn" type="submit">Contact Us</button>
                                        <a href="https://calendly.com/trinkaai/meet" target="_blank"  id="autoEditGetStart" class="outline-btn">Book a Demo</a>
                                    </div>
                            </div>
                            <div class="top-banner-right">
                                <img src="../../../assets/images/enterprise_banner_img.png" alt="enterpise">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

    <div class="brandLogo_sections">
        <div class="container">
            <div class="row">
                <div class="col-sm-2">
                    <h2>Trusted by Global Leaders</h2>
                </div>
                <div class="col-sm-10">
                    <div class="cover_wrapper">
                        <div id="client-logos-enterprise" class="owl-carousel">
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/j-j.png" alt="universities">
                                </div>
                            </div>

                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/oxford.png" alt="universities">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/HarvardMedicalSchool.png" alt="universities">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/merck.png" alt="universities">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/cambridge.png" alt="universities">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/PrincetonUniversity.png" alt="universities">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/biogen.png" alt="universities">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/UNSW.png" alt="universities">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/accelera.png" alt="universities">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/pfizer.png" alt="universities">
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="cloud_key_features section-pd list-wrapper-style-2 tailoredSolutions">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-12 col-lg-12">
                    <div class="trinka-title mb-0 text-center">
                        <h2>Industry Tailored Solutions for<br class="d-lg-block d-none"/> Improved Writing</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                        <p>Trinka helps your teams create professional documents efficiently, while ensuring quality and consistency.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-none">
                        <h3>Life Sciences, Medicine & Pharma</h3>
                        <p>Trinka's powerful AI understands the technical complexities and precision required in medical and
                            scientific writing. Trinka's suggestions are contextual and subject-specific.
                        </p>
                        <a [routerLink]="['/enterprise/life-sciences-medicine-and-pharma']" class="text-link">Read More
                            <i class="fa fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards">
                        <h3>Language Services, Publishing & Media</h3>
                        <p>Enable your teams to write consistently, publish faster, and boost customer experience. Reduce writing
                            time and costs without compromising on quality.</p>
                        <a [routerLink]="['/enterprise/language-services-publishing-and-media']" class="text-link">Read More
                            <i class="fa fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards">
                        <h3>Academic Institutions</h3>
                        <p>Trinka polishes your writing to comply with academic writing conventions and best practices to make
                            it publication ready.</p>
                        <a [routerLink]="['/enterprise/academic-institutions']"  class="text-link">Read More
                            <i class="fa fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-1" style="min-height: 370px;">
                        <h3>K-12 & E-Learning</h3>
                        <p>Trinka’s advanced AI not only helps students improve their writing but also learn best academic writing practices as they write.
                        </p>
                        <a [routerLink]="['/enterprise/k12-and-elearning']" class="text-link">Read More
                            <i class="fa fa-arrow-right"></i>
                        </a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-1" style="min-height: 370px;">
                        <h3>Technology Platforms</h3>
                        <p>Trinka allows you to add real-time writing support within your application with ease. Build unique
                            solutions by offering Trinka's writing suggestions.</p>
                        <a [routerLink]="['/enterprise/technology-platforms']" class="text-link">Read More
                            <i class="fa fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <!-- <div class="other_comparision">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="integreate_heading_section">
                        <h2>Industry Tailored Solutions for Improved Writing</h2>
                        <div class="HeadingSubLine"></div>
                        <p>Trinka helps your teams create professional documents efficiently, while ensuring quality and consistency.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 paddingRightRemove">
                    <div class="features_cards">
                        <div class="features_cardsHeading">
                            <h3>Life Sciences, Medicine & Pharma</h3>
                        </div>
                        <p>Trinka's powerful AI understands the technical complexities and precision required in medical and
                            scientific writing. Trinka's suggestions are contextual and subject-specific.
                        </p>
                        <a [routerLink]="['/enterprise/life-sciences-medicine-and-pharma']">Read More
                            <i class="fa fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 paddingRightRemove">
                    <div class="features_cards">
                        <div class="features_cardsHeading">
                            <h3>Language Services, Publishing & Media</h3>
                        </div>
                        <p>Enable your teams to write consistently, publish faster, and boost customer experience. Reduce writing
                            time and costs without compromising on quality.</p>
                        <a [routerLink]="['/enterprise/language-services-publishing-and-media']">Read More
                            <i class="fa fa-arrow-right"></i>
                        </a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 paddingRightRemove">
                    <div class="features_cards">
                        <div class="features_cardsHeading">
                            <h3>Academic Institutions</h3>
                        </div>
                        <p>Trinka polishes your writing to comply with academic writing conventions and best practices to make
                            it publication ready.</p>
                        <a [routerLink]="['/enterprise/academic-institutions']">Read More
                            <i class="fa fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 paddingRightRemove">
                    <div class="features_cards">
                        <div class="features_cardsHeading">
                            <h3>K-12 & E-Learning</h3>
                        </div>
                        <p>Trinka’s advanced AI not only helps students improve their writing but also learn best academic writing
                            practices as they write.</p>
                        <a [routerLink]="['/enterprise/k12-and-elearning']">Read More
                            <i class="fa fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 paddingRightRemove">
                    <div class="features_cards">
                        <div class="features_cardsHeading">
                            <h3>Technology Platforms</h3>
                        </div>
                        <p>Trinka allows you to add real-time writing support within your application with ease. Build unique
                            solutions by offering Trinka's writing suggestions.</p>
                        <a [routerLink]="['/enterprise/technology-platforms']">Read More
                            <i class="fa fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <div class="enterprise-secure">
        <app-safe-secure></app-safe-secure>
    </div>

    <div class="wayToIntegrateTrinka section-pd">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-12 col-lg-12">
                    <div class="trinka-title mb-50 text-center">
                        <h2>3 Ways to Integrate Trinka</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                        <p>Power up all your teams to write better. Enterprise users get unlimited access and attractive benefits.
                            Choose the integration option best suited for your need.</p>
                    </div>
                </div>
            </div>
            <div class="row divider_line_section">
                <div class="col-lg-5">
                    <div class="integreate_img_section GetTrinkaJs_img">
                        <img class="getTrinkajs_gif_img" src="../../../assets/images/GetTrinkaJs.gif" alt="get trinka">
                        <img class="getTrinkaJs_backgrond" src="../../../assets/images/API_backgrond_layer.png" alt="api">
                    </div>
                </div>
                <div class="col-lg-7">
                    <div class="integreate_text_content" id="javascript">
                        <h3>
                            <span>01</span> Get Trinka
                            <b style="padding: 0px 12px 4px 12px;">JS</b>
                        </h3>
                        <p>Enable Trinka for all your team members by simply inserting a small JavaScript (JS) code snippet
                            into your browser or any application. Avoid the need for complicated integrations and get the
                            power of Trinka with this easy-to-use feature.</p>
                        <ul>
                            <li>
                                <h4>Simple integration</h4>
                                <p>Just insert a small JavaScript snippet into your application and enable your users to start
                                    writing better with Trinka.</p>
                            </li>
                            <li>
                                <h4>Safe and secure</h4>
                                <p>All your data is under a highly encrypted environment. TrinkaJS works in real-time and does
                                    not save anything on our servers. </p>
                            </li>
                            <li>
                                <h4>Unmatched flexibility</h4>
                                <p>The simple-to-use JS snippet is easy to integrate and resilient too. It easily scales with
                                    your growing team & business.</p>
                            </li>
                        </ul>

                    </div>
                </div>
            </div>

            <div class="row divider_line_section columu_reverse">
                <div class="col-lg-7">
                    <div class="integreate_text_content">
                        <h3>
                            <span>02</span> Get Trinka
                            <b>API</b>
                        </h3>
                        <p>Need Trinka within your product? Trinka’s powerful API lets you easily integrate it into any application.
                            The simple-to-use API offers high reliability and scalability.</p>
                        <ul>
                            <li>
                                <h4>Robust API</h4>
                                <p>Trinka API offers high service availability so you get the help you need any time you want.
                                </p>
                            </li>
                            <li>
                                <h4>API customizations</h4>
                                <p>Have unique needs for your application? Just ask. Trinka API is highly customizable.
                                </p>
                            </li>
                            <li>
                                <h4>Unmatched flexibility</h4>
                                <p>The powerful API is easy to use and resilient too. Trinka matches your product scale to scale.</p>
                            </li>
                        </ul>
                        <div class="btn-group">
                            <a [routerLink]="['/enterprise/grammar-checker-api']" class="filled-btn">Read More</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5">
                    <div class="integreate_img_section getTrinka_API">
                        <img class="API_bg_layer" src="../../../assets/images/API_backgrond_layer.png" alt="api">
                        <img class="TrinkaAPI_img" src="../../../assets/images/TrinkaAPI_img.png" alt="trinka api">
                        <div class="API_text_section" data-aos="zoom-in">
                            <span></span>
                            <div class="API_text_overlay"></div>
                            <img src="../../../assets/images/API_text.png" alt="api text">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-5">
                    <div class="integreate_img_section getTrinka_on_premise">
                        <img src="../../../assets/images/GetTrinkaOn_Premise.png" alt="trinka premise">
                        <span class="TrinkaPurpleColor"></span>
                        <span class="TrinkaredColor"></span>
                        <span class="TrinkaYellowColor"></span>
                    </div>
                </div>
                <div class="col-lg-7">
                    <div class="integreate_text_content" id="onpremise">
                        <h3>
                            <span>03</span> Get Trinka
                            <b>On Premise</b>
                        </h3>
                        <p>Working with highly sensitive data? Trinka On Premise is the best solution for you! Get Trinka to
                            work for you on your enterprise infrastructure. </p>
                        <ul>
                            <li>
                                <h4>Dedicated support</h4>
                                <p>With Trinka On Premise, you get dedicated service personnel working specifically with your
                                    IT staff for hassle-free integration.</p>
                            </li>
                            <li>
                                <h4>Safe and secure</h4>
                                <p>With Trinka AI working on your enterprise infrastructure, your data is completely secure
                                    & safe.</p>
                            </li>
                            <li>
                                <h4>Unmatched flexibility</h4>
                                <p>With easy integration and dedicated support service, Trinka easily scales with your organization's
                                    growing team & business.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="trinka-banner section-pd-sm">
        <div class="trinka_container">
            <div class="row align-items-center justify-content-center">
                <div class="col-md-6 col-lg-6">
                    <div class="trinka-title">
                        <h3 class="text-white mb-4">Trinka Simplifies Invoices with Support for Local Currency.</h3>
                        <p class="text-white">Trinka offers seamless invoices  (Seikyu-sho, Fapiao, Yeongeumjeung, Fatura) in multiple local currencies of JPY, CNY, BRL, TRY, KRW, INR, USD, TWD. Organizations can also ease the invoicing process  by  clubbing multiple accounts into a single invoice in their own local currency.  </p>
                    </div>
                    <div class="btn-group">
                        <a [routerLink]="['/local-invoice']" class="white-btn">Check Invoice Options</a>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6">
                    <div class="text-center">
                        <img src="../../../assets/images/local-inovice-element.png" alt="local invoice" class="img-fluid" />
                    </div>
                </div>
            </div>  
        </div>
    </div>

    <div class="whiteListing section-pd">
        <div class="trinka_container">
            <div class="row align-items-center" style="margin-bottom: 40px;">
                <div class="col-md-8 col-sm-8">
                    <div class="trinka-title mb-0">
                        <h2>IP Whitelisting</h2>
                        <div class="HeadingSubLine1"><span></span><span></span><span></span></div>
                        <p>IP whitelisting is a security feature to help protect sensitive data and systems from unauthorized access. It is beneficial for your business as it can enhance security by controlling who can view those resources, complying with regulations, improving productivity, and saving money. IP whitelisting is achieved by limiting access only to specific IP addresses from a trusted source.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-4">
                    <div class="benefitImage">
                        <img src="../../../assets/images/the-benefit-whitepaper.png" width="200" alt="The benefits" class="img-fluid" />
                    </div>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-lg-8 col-sm-12">
                    <div class="trinka-title mb-0">
                        <h3 class="mb-2">Benefits</h3>
                        <p>Our IP whitelisting security feature allows access to Trinka only from specified IP addresses and below are the benefits.</p>
                    </div>
                </div>
                
            </div>
            <div class="row match-box">
                <div class="col-sm-6 dflex">
                    <div class="media">
                        <div class="media-body">
                            <h4>Enhanced security <span><img src="../../../assets/images/icons/cyber-security.png" alt="Enhanced security" /></span></h4>
                            <p>Trinka can be accessed only from trusted IP addresses, reducing the risk of unauthorized access and cyber attacks</p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 dflex">
                    <div class="media">
                        <div class="media-body">
                            <h4>Controlled Access <span><img src="../../../assets/images/icons/browser.png" alt="Controlled Access" /></span></h4>
                            <p>Administrators have granular control of who can access Trinka by quickly blocking or unblocking access to specific IP addresses</p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 dflex">
                    <div class="media">
                        <div class="media-body">
                            <h4>Reduced downtime <span><img src="../../../assets/images/icons/clock.png" alt="Reduced downtime" /></span></h4>
                            <p>IP whitelisting controls and reduces the risk of denial-of-service (DoS) attacks and stoppages that can affect genuine users</p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 dflex">
                    <div class="media">
                        <div class="media-body">
                            <h4>Better compliance <span><img src="../../../assets/images/icons/growth.png" alt="Better compliance" /></span></h4>
                            <p>Allows organizations to meet strict industry regulatory compliances, especially where IP whitelisting is a mandate.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="trinka-banner section-pd-sm">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-10 col-lg-8">
                    <div class="trinka-title text-center">
                        <h3 class="text-white">Get Trinka Integration for Your Enterprise</h3>
                    </div>
                    <div class="btn-group justify-content-center">
                        <button (click)="sectionValue('Enterprise Form')" id="salesContactButtons" data-toggle="modal" data-target="#EnterPriseContactPopup"
                        class="white-btn" type="submit">Request a Quote</button>
                    </div>
                </div>
            </div>  
        </div>
    </div>

</div>


<div class="modal custom_modal_popup enterprisePopup" id="EnterPriseContactPopup">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-body">
                <div class="closed_icons">
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="contactPopupHeading">
                            <h2>Enterprise Inquiry</h2>
                            <p *ngIf="formSection">Whom are you inquiring for?</p>
                        </div>
                    </div>
                    <div class="inquiryTypeSection">
                        <ul class="nav nav-tabs" role="tablist" *ngIf="formSection">
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#IndividualType" role="tab">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                                        <defs>
                                            <style>
                                                .cls-1 {
                                                    fill: none;
                                                    stroke: #783694;
                                                    stroke-linecap: round;
                                                    stroke-linejoin: round;
                                                    stroke-width: 2px;
                                                }
                                            </style>
                                        </defs>
                                        <title>TrinkaIcons-new</title>
                                        <g id="Individual">
                                            <path class="cls-1" d="M44.83,45.13V39.82a4.65,4.65,0,0,0-1.7-3.6,62.85,62.85,0,0,0-10.89-7V22.5a4.4,4.4,0,0,0,1.56-3.36V11.39A7.9,7.9,0,0,0,25.91,3.5H24.24a7.9,7.9,0,0,0-7.89,7.89v7.75a4.32,4.32,0,0,0,1.54,3.36h0v6.68A62.22,62.22,0,0,0,7,36.22a4.62,4.62,0,0,0-1.7,3.6v5.31"
                                            />
                                        </g>
                                    </svg>
                                    <div class="text-left">
                                        <h3>Individual</h3>
                                        <span>(Single user)</span>
                                    </div>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#OrganizationType" role="tab">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                                        <defs>
                                            <style>
                                                .cls-1 {
                                                    fill: none;
                                                    stroke: #783694;
                                                    stroke-linecap: round;
                                                    stroke-linejoin: round;
                                                    stroke-width: 2px;
                                                }
                                            </style>
                                        </defs>
                                        <title>TrinkaIcons-new</title>
                                        <g id="Organization">
                                            <path class="cls-1" d="M42.87,45.13V40.06a4.45,4.45,0,0,0-1.62-3.44,60,60,0,0,0-10.41-6.72V23.51a4.2,4.2,0,0,0,1.49-3.21V12.89a7.55,7.55,0,0,0-7.54-7.54H23.19a7.55,7.55,0,0,0-7.54,7.54V20.3a4.13,4.13,0,0,0,1.47,3.21h0v6.38A59.44,59.44,0,0,0,6.71,36.62a4.42,4.42,0,0,0-1.62,3.44v5.07"
                                            />
                                            <path class="cls-1" d="M14.34,30.75a24.56,24.56,0,0,0-3.32-2V26.18a1.66,1.66,0,0,0,.59-1.27V22a3,3,0,0,0-3-3H8a3,3,0,0,0-3,3v2.93a1.63,1.63,0,0,0,.58,1.27h0v2.53a23.54,23.54,0,0,0-4.12,2.66,1.75,1.75,0,0,0-.64,1.36v2"
                                            />
                                            <path class="cls-1" d="M34.29,30.75a24.56,24.56,0,0,1,3.32-2V26.18A1.66,1.66,0,0,1,37,24.91V22a3,3,0,0,1,3-3h.63a3,3,0,0,1,3,3v2.93A1.63,1.63,0,0,1,43,26.18h0v2.53a23.54,23.54,0,0,1,4.12,2.66,1.75,1.75,0,0,1,.64,1.36v2"
                                            />
                                        </g>
                                    </svg>
                                    <div class="text-left">
                                        <h3>Organization</h3>
                                        <span>(Multiple users)</span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content">
                            <div class="tab-pane" id="IndividualType" role="tabpanel" *ngIf="formSection">
                                <div class="IndividualTypeSection">
                                    <p>Trinka is free for individual users.
                                        <br /> You can simply register with your email address.</p>
                                    <a href="https://cloud.trinka.ai/signup" target="_blank">Register for Free</a>
                                </div>
                            </div>
                            <div class="tab-pane" id="OrganizationType" role="tabpanel" *ngIf="formSection">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="contact_us_form">
                                            <form [formGroup]="contactForm" (ngSubmit)="sendContactUsForm()">
                                                <h2>Share your requirements with us and our team will respond to you promptly.</h2>
                                                <div class="row">
                                                    <div class="col-md-6 rightPaddingRemove">
                                                        <div class="textbox_content form-group" [ngClass]="(!contactForm.get('NatureBusinessType').valid && contactForm.get('NatureBusinessType').dirty) || (contactForm.get('NatureBusinessType').touched && contactForm.get('NatureBusinessType').pristine) ? 'TextFiledError' : '' ">
                                                            <mat-form-field appearance="outline">
                                                                <mat-label>Nature of business*</mat-label>
                                                                <input matInput placeholder="" formControlName="NatureBusinessType" class="form-control">
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 leftPaddingRemove">
                                                        <div class="textbox_content form-group" [ngClass]="(!contactForm.get('contactFormSubjects').valid && contactForm.get('contactFormSubjects').dirty) || (contactForm.get('contactFormSubjects').touched && contactForm.get('contactFormSubjects').pristine) ? 'TextFiledError' : '' ">
                                                            <mat-form-field appearance="outline">
                                                                <mat-label>Organization name*</mat-label>
                                                                <input matInput placeholder="" formControlName="contactFormSubjects" class="form-control">
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="textbox_content form-group textareaBox" [ngClass]="(!contactForm.get('contactFormMessage').valid && contactForm.get('contactFormMessage').dirty) || (contactForm.get('contactFormMessage').touched && contactForm.get('contactFormMessage').pristine) ? 'TextFiledError' : '' ">
                                                            <label for="">Tell us a bit about your requirement</label>
                                                            <textarea formControlName="contactFormMessage" class="form-control" placeholder="E.g. approximare number of users, monthly word count volume per user, customizations needed, etc."></textarea>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6 rightPaddingRemove">
                                                        <div class="textbox_content form-group" [ngClass]="(!contactForm.get('contactFormName').valid && contactForm.get('contactFormName').dirty) || (contactForm.get('contactFormName').touched && contactForm.get('contactFormName').pristine) ? 'TextFiledError' : '' ">
                                                            <mat-form-field appearance="outline">
                                                                <mat-label>Name*</mat-label>
                                                                <input matInput placeholder="" formControlName="contactFormName" class="form-control">
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 leftPaddingRemove">
                                                        <div class="textbox_content form-group" [ngClass]="(!contactForm.get('DesignationTypes').valid && contactForm.get('DesignationTypes').dirty) || (contactForm.get('DesignationTypes').touched && contactForm.get('DesignationTypes').pristine) ? 'TextFiledError' : '' ">
                                                            <mat-form-field appearance="outline">
                                                                <mat-label>Designation*</mat-label>
                                                                <input matInput placeholder="" formControlName="DesignationTypes" class="form-control">
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 rightPaddingRemove">
                                                        <div class="textbox_content form-group" [ngClass]="(!contactForm.get('contactFormEmail').valid && contactForm.get('contactFormEmail').dirty) || (contactForm.get('contactFormEmail').touched && contactForm.get('contactFormEmail').pristine) ? 'TextFiledError' : '' ">
                                                            <mat-form-field appearance="outline">
                                                                <mat-label>Email*</mat-label>
                                                                <input matInput placeholder="" formControlName="contactFormEmail" class="form-control">
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 leftPaddingRemove">
                                                        <div class="textbox_content form-group" [ngClass]="(!contactForm.get('contactphoneNo').valid && contactForm.get('contactphoneNo').dirty) || (contactForm.get('contactphoneNo').touched && contactForm.get('contactphoneNo').pristine) ? 'TextFiledError' : '' ">
                                                            <mat-form-field appearance="outline">
                                                                <mat-label>Contact Number* (incl. ISD code)</mat-label>
                                                                <input type="number" matInput placeholder="" formControlName="contactphoneNo" class="form-control">
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group text-center">
                                                    <button type="submit" [disabled]="isSubmitting">Submit</button>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="thank_section contact_us_form" *ngIf="thankSection">
                                <div class="form-group">
                                    <h2>Thank You!</h2>
                                    <p>We will contact you soon.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>