// shared.module.ts

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalLeadersComponent } from '../Components/global-leaders/global-leaders.component';
import { TestimonialComponent } from '../Components/testimonial/testimonial.component';
import { GrammarTipsModalComponent } from '../Components/videos/grammar-tips/grammar-tips-modal/grammar-tips-modal.component';
import { ProductWalkthroughtModalComponent } from '../Components/product-walkthrought-modal/product-walkthrought-modal.component';

@NgModule({
  declarations: [
    GlobalLeadersComponent,
    TestimonialComponent,
    GrammarTipsModalComponent,
    ProductWalkthroughtModalComponent
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    GlobalLeadersComponent,
    TestimonialComponent,
    GrammarTipsModalComponent,
    ProductWalkthroughtModalComponent
  ]
})

export class SharedModule { }
