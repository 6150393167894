<!-- <div class="stripe-banner" id="stripBanner">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <p><b>Upgrade Alert!</b>  Trinka AI is now <b>40%</b> more powerful. It's much faster, more accurate, and corrects more errors than ever before. <span class="d-block">Also, note that Trinka  <b>does not use ChatGPT/GPT-X</b>. It uses our own proprietary patented AI technology for grammar corrections and suggestions. 🎉 </span></p>
            </div>
        </div>
    </div>
</div> -->

<header class="wrapper bg-soft-primary" id="header" [ngClass]="{'headerSticky' : true, 'sticky' : notSticky}">
    <nav class="navbar navbar-expand-xl center-nav transparent navbar-light">
      <div class="container flex-lg-row flex-nowrap align-items-center">
        <div class="navbar-brand w-100">
          <a [routerLink]="['/']">
            <img src="../../../assets/images/trinka_logo.svg" width="183" height="73" alt="trinka-grammar-checker">
          </a>
        </div>
        <div class="navbar-collapse offcanvas offcanvas-nav offcanvas-start justify-content-end" [ngClass]="{'show': isMenuActive}">
          <div class="offcanvas-header d-xl-none">
            <img src="../../../assets/images/trinka_logo.svg" loading="lazy" decoding="async" width="140" alt="trinka-grammar-checker">
            <button type="button" class="btn-close" (click)="closeCanvasMenu()" aria-label="Close">
                <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M46.5 15.5L15.5 46.5" stroke="#4D4D4D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M15.5 15.5L46.5 46.5" stroke="#4D4D4D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
          </div>
          <div class="offcanvas-body ms-lg-auto d-flex flex-column h-100">
            <ul class="navbar-nav">
                <li class="nav-item dropdown dropdown-mega" >
                    <p class="nav-link dropdown-toggle" (click)="toggleClass($event)" data-toggle="dropdown"><span>Product</span> <img src="../../../../assets/icons/chevron-up.svg" alt="icon" /></p>
                    <ul class="dropdown-menu mega-menu">
                        <li class="mega-menu-content row">
                            <div class="col-12 dflex mega-card">
                                <div class="menu-card features-card c-card">
                                    <h3 class="mb-40" (click)="closeCanvasMenu()" data-bs-dismiss="offcanvas"><a class="font-500" [routerLink]="['/features']">Features</a></h3>
                                    <div class="media-card">
                                        <a [routerLink]="['/features/grammar-checker']">
                                            <div class="media" (click)="closeCanvasMenu()" data-bs-dismiss="offcanvas">
                                                <div class="media-left">
                                                    <img src="../../../assets/images/menu-icons/grammar-checker.svg" loading="lazy" decoding="async" alt="grammar-checker" />
                                                </div>
                                                <div class="media-body">
                                                    <h5>Grammar Checker</h5>
                                                    <p>Enhance your writing holistically with advanced grammar checks.</p>
                                                </div>
                                            </div>
                                        </a>
                                        <a [routerLink]="['/features/paraphraser']">
                                            <div class="media" (click)="closeCanvasMenu()" data-bs-dismiss="offcanvas">
                                                <div class="media-left">
                                                    <img src="../../../assets/images/menu-icons/pharaphraser.svg" loading="lazy" decoding="async" alt="Paraphraser" />
                                                </div>
                                                <div class="media-body">
                                                    <h5>Paraphraser</h5>
                                                    <p>Understands your intent and offers best alternative phrases.</p>
                                                </div>
                                            </div>
                                        </a>
                                        <a [routerLink]="['/features/proofread-file']">
                                            <div class="media" (click)="closeCanvasMenu()" data-bs-dismiss="offcanvas">
                                                <div class="media-left">
                                                    <img src="../../../assets/images/menu-icons/proofread.svg" loading="lazy" decoding="async" alt="Proofread File" />
                                                </div>
                                                <div class="media-body">
                                                    <h5>Proofread File</h5>
                                                    <p>Save time with automatic edits on your MS Word documents.</p>
                                                </div>
                                            </div>
                                        </a>
                                        <a [routerLink]="['/features/latex-grammar-checker']">
                                            <div class="media" (click)="closeCanvasMenu()" data-bs-dismiss="offcanvas">
                                                <div class="media-left">
                                                    <img src="../../../assets/images/menu-icons/spell-check.svg" loading="lazy" decoding="async" alt="Spell Check" />
                                                </div>
                                                <div class="media-body">
                                                    <h5>LaTeX Proofreader</h5>
                                                    <p>Save time with automatic grammar check for your LaTeX files.</p>
                                                </div>
                                            </div>
                                        </a>
                                        <a [routerLink]="['/features/consistency-check']">
                                            <div class="media" (click)="closeCanvasMenu()" data-bs-dismiss="offcanvas">
                                                <div class="media-left">
                                                    <img src="../../../assets/images/menu-icons/consistency.svg" loading="lazy" decoding="async" alt="Consistency Check" />
                                                </div>
                                                <div class="media-body">
                                                    <h5>Consistency Check</h5>
                                                    <p>Automatically fixes inconsistencies that are hard-to-find.</p>
                                                </div>
                                            </div>
                                        </a>
                                        <a [routerLink]="['/features/inclusive-language-check']">
                                            <div class="media" (click)="closeCanvasMenu()" data-bs-dismiss="offcanvas">
                                                <div class="media-left">
                                                    <img src="../../../assets/images/menu-icons/inclusive-language.svg" loading="lazy" decoding="async" alt="Inclusive Language Check" />
                                                </div>
                                                <div class="media-body">
                                                    <h5>Inclusive Language Check</h5>
                                                    <p>Identifies and eliminates biased language and discriminatory terms.</p>
                                                </div>
                                            </div>
                                        </a>
                                        <ul class="tx-inline-menu bullets-menu">
                                            <li>
                                                <a [routerLink]="['/features/legal-writing']">
                                                    <div class="media" (click)="closeCanvasMenu()" data-bs-dismiss="offcanvas">
                                                        Legal Wirting
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/features/personal-dictionary']">
                                                    <div class="media" (click)="closeCanvasMenu()" data-bs-dismiss="offcanvas">
                                                        Personal Dictionary
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/features/credits']">
                                                    <div class="media" (click)="closeCanvasMenu()" data-bs-dismiss="offcanvas">
                                                        Trinka Credits
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>
                                        <!-- <a [routerLink]="['/features/personal-dictionary']">
                                            <div class="media" (click)="closeCanvasMenu()" data-bs-dismiss="offcanvas">
                                                <div class="media-left">
                                                    <img src="../../../assets/images/menu-icons/personal-dictionary.svg" loading="lazy" decoding="async" alt="Personal Disctionary" />
                                                </div>
                                                <div class="media-body">
                                                    <h5>Personal Dictionary</h5>
                                                    <p>Avoid spelling alerts for new terms with your own dictionary.</p>
                                                </div>
                                            </div>
                                        </a>
                                        <a [routerLink]="['/features/legal-writing']">
                                            <div class="media" (click)="closeCanvasMenu()" data-bs-dismiss="offcanvas">
                                                <div class="media-left">
                                                    <img src="../../../assets/images/menu-icons/legal-writing.svg" loading="lazy" decoding="async" alt="Legal Writing" />
                                                </div>
                                                <div class="media-body">
                                                    <h5>Legal Writing</h5>
                                                    <p>Ensures improved legal documents in seconds.</p>
                                                </div>
                                            </div>
                                        </a> -->
                                    </div>
                                </div>
                                <div class="menu-card hr-line c-card"></div>
                                <div class="menu-card reports-card c-card">
                                    <h3 class="mb-40">Reports</h3>
                                    <div class="media-card">
                                        <a [routerLink]="['/features/plagiarism-check']">
                                            <div class="media" (click)="closeCanvasMenu()" data-bs-dismiss="offcanvas">
                                                <div class="media-left">
                                                    <img src="../../../assets/images/menu-icons/plagiarism.svg" loading="lazy" decoding="async" alt="plagiarism" />
                                                </div>
                                                <div class="media-body">
                                                    <h5>Plagiarism Checker</h5>
                                                    <p>Get Standard and Advanced Plagiarism Check Reports.</p>
                                                </div>
                                            </div>
                                        </a>
                                        <a [routerLink]="['/ai-content-detector']">
                                            <div class="media" (click)="closeCanvasMenu()" data-bs-dismiss="offcanvas">
                                                <div class="media-left">
                                                    <img src="../../../assets/images/menu-icons/ai-detector.svg" loading="lazy" decoding="async" alt="Technical Checks" />
                                                </div>
                                                <div class="media-body">
                                                    <h5>AI Content Detector</h5>
                                                    <p>Identify AI generated texts produced by LLMs to preserve content integrity.</p>
                                                </div>
                                            </div>
                                        </a>
                                        <a [routerLink]="['/features/journal-finder']">
                                            <div class="media" (click)="closeCanvasMenu()" data-bs-dismiss="offcanvas">
                                                <div class="media-left">
                                                    <img src="../../../assets/images/menu-icons/journal-finder.svg" loading="lazy" decoding="async" alt="Journal Finder" />
                                                </div>
                                                <div class="media-body">
                                                    <h5>Journal Finder</h5>
                                                    <p>Helps you find the right journal for your paper quickly.</p>
                                                </div>
                                            </div>
                                        </a>
                                        <a [routerLink]="['/features/citation-checker']">
                                            <div class="media" (click)="closeCanvasMenu()" data-bs-dismiss="offcanvas">
                                                <div class="media-left">
                                                    <img src="../../../assets/images/menu-icons/citation-checker.svg" loading="lazy" decoding="async" alt="Citation Checker" />
                                                </div>
                                                <div class="media-body">
                                                    <h5>Citation Checker</h5>
                                                    <p>Improves the quality of your citation with automated citation analysis.</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div class="menu-card hr-line c-card"></div>
                                <div class="menu-card apps-card c-card">
                                    <h3 class="mb-40">Apps</h3>
                                    <div class="media-card gap-sm">
                                        <a [routerLink]="['/trinkacloud']">
                                            <div class="media align-items-center" (click)="closeCanvasMenu()" data-bs-dismiss="offcanvas">
                                                <div class="media-left">
                                                    <img src="../../../assets/images/menu-icons/cloud.svg" loading="lazy" decoding="async" alt="Cloud" />
                                                </div>
                                                <div class="media-body">
                                                    <h5>Cloud</h5>
                                                </div>
                                            </div>
                                        </a>
                                        <a [routerLink]="['/wordplugin']">
                                            <div class="media align-items-center" (click)="closeCanvasMenu()" data-bs-dismiss="offcanvas">
                                                <div class="media-left">
                                                    <img src="../../../assets/images/menu-icons/word-addin.svg" loading="lazy" decoding="async" alt="Word Add-in" />
                                                </div>
                                                <div class="media-body">
                                                    <h5>Word Add-in</h5>
                                                </div>
                                            </div>
                                        </a>
                                        <a [routerLink]="['/wordplugin-lite']">
                                            <div class="media align-items-center" (click)="closeCanvasMenu()" data-bs-dismiss="offcanvas">
                                                <div class="media-left">
                                                    <img src="../../../assets/images/menu-icons/word-addin.svg" loading="lazy" decoding="async" alt="Word Add-in Lite" />
                                                </div>
                                                <div class="media-body">
                                                    <h5>Word Add-in Lite</h5>
                                                </div>
                                            </div>
                                        </a>
                                        <a [routerLink]="['/windows-desktop-app']">
                                            <div class="media align-items-center" (click)="closeCanvasMenu()" data-bs-dismiss="offcanvas">
                                                <div class="media-left">
                                                    <img src="../../../assets/images/menu-icons/windows-app.svg" loading="lazy" decoding="async" alt="Word Add-in Lite" />
                                                </div>
                                                <div class="media-body">
                                                    <h5>Windows App <i style="position: absolute; right: -1px;top:1px">New</i></h5>
                                                </div>
                                            </div>
                                        </a>
                                        <a [routerLink]="['/browser-plugin']">
                                            <div class="media align-items-center" (click)="closeCanvasMenu()" data-bs-dismiss="offcanvas">
                                                <div class="media-left">
                                                    <img src="../../../assets/images/menu-icons/browser-plugin.svg" loading="lazy" decoding="async" alt="Browser Plugins" />
                                                </div>
                                                <div class="media-body">
                                                    <h5>Browser Plugins</h5>
                                                </div>
                                            </div>
                                        </a>
                                        <!-- <div class="btn-group d-xl-block d-none">
                                            <a  id="GCChromeButton" href="https://chrome.google.com/webstore/detail/trinka-ai-for-chrome/bbcmnbnmngpeofmpcdlcfalbniefegbp" target="_blank" class="filled-btn"><img src="../../../assets/images/NewChromeIcon.svg" loading="lazy" decoding="async" width="32" height="32" alt="chrome">Chrome</a>
                                            <a  id="GCFirFoxButton" href="https://addons.mozilla.org/en-US/firefox/addon/trinka-ai-firefox-browser/" target="_blank" class="filled-btn"><img src="../../../assets/images/NewFirefoxIcon.svg" loading="lazy" decoding="async"  width="32" height="32" alt="firefox ">Firefox</a>
                                            <a  id="GCEdgeButton" href="https://microsoftedge.microsoft.com/addons/detail/trinka-ai-for-edge/kjhkcgjfbpppkbhhaocagkmfkkiibeap" target="_blank" class="filled-btn"><img src="../../../assets/images/EdgeWhite_BGAndOutline.svg" loading="lazy" decoding="async"  width="32" height="32" alt="edge">Edge</a>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </li>
                    <!--/.mega-menu-content-->
                    </ul>
                    <!--/.dropdown-menu -->
                </li>
                <li class="nav-item dropdown dropdown-mega" >
                    <p class="nav-link dropdown-toggle" (click)="toggleClass($event)" data-toggle="dropdown"><span>Trinka Business</span> <img src="../../../../assets/icons/chevron-up.svg" alt="icon" /></p>
                    <ul class="dropdown-menu mega-menu">
                        <li class="mega-menu-content row">
                            <div class="col-12 dflex mega-card">
                                <div class="menu-card trinka-business-menu industries">
                                    <h3 (click)="closeCanvasMenu()" data-bs-dismiss="offcanvas"><a class="font-500" [routerLink]="['/enterprise']">Industries</a></h3>
                                    <div class="media-card noMediaCard gap-0">
                                        <div class="media align-items-center">
                                            <div class="media-left">
                                                <img src="../../../assets/images/menu-icons/universities.svg" loading="lazy" decoding="async" alt="Universities" />
                                            </div>
                                            <div class="media-body">
                                                <h5>For Universities</h5>
                                                <p>Complete writing assistance for students and faculty.</p>
                                            </div>
                                        </div>

                                        <ul class="tx-inline-menu">
                                            <li>
                                                <a [routerLink]="['/enterprise/academic-institutions']" (click)="closeCanvasMenu()">
                                                    Universities
                                                </a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/enterprise/k12-and-elearning']" (click)="closeCanvasMenu()">
                                                    K-12 & e-learning
                                                </a>
                                            </li>
                                        </ul>
                                    </div>

                                    <span class="enterpise-line"></span>

                                    <div class="media-card noMediaCard gap-0">
                                        <div class="media align-items-center" (click)="closeCanvasMenu()" data-bs-dismiss="offcanvas">
                                            <div class="media-left">
                                                <img src="../../../assets/images/menu-icons/language-services.svg" loading="lazy" decoding="async" alt="Publishers" />
                                            </div>
                                            <div class="media-body">
                                                <h5>For Publishers</h5>
                                                <p>Improve efficiencies with AI throughtout publishing workflows.</p>
                                            </div>
                                        </div>

                                        <ul class="tx-inline-menu">
                                            <li>
                                                <a [routerLink]="['/enterprise/language-services-publishing-and-media']" (click)="closeCanvasMenu()">
                                                    Publishers
                                                </a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/enterprise/language-services-publishing-and-media']" (click)="closeCanvasMenu()">
                                                    Language Services
                                                </a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/enterprise/language-services-publishing-and-media']" (click)="closeCanvasMenu()">
                                                    Media & Websites
                                                </a>
                                            </li>
                                        </ul>
                                    </div>

                                    <span class="enterpise-line"></span>

                                    <div class="media-card noMediaCard gap-0">
                                        <div class="media align-items-center" (click)="closeCanvasMenu()" data-bs-dismiss="offcanvas">
                                            <div class="media-left">
                                                <img src="../../../assets/images/menu-icons/premises.svg" loading="lazy" decoding="async" alt="for businesses" />
                                            </div>
                                            <div class="media-body">
                                                <h5>For Businesses</h5>
                                                <p>Industry-tailored solutions for creating professional documents.</p>
                                            </div>
                                        </div>

                                        <ul class="tx-inline-menu">
                                            <li>
                                                <a [routerLink]="['/enterprise/life-sciences-medicine-and-pharma']" (click)="closeCanvasMenu()">
                                                    Medicine & Pharma
                                                </a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/enterprise/technology-platforms']" (click)="closeCanvasMenu()">
                                                    Technology & Software
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="menu-card hr-line c-card"></div>
                                <div class="menu-card  trinka-business-menu industries">
                                    <h3>Data Protection</h3>
                                    <div class="media-card noMediaCard gap-0">
                                        <div class="media align-items-center" (click)="closeCanvasMenu()" data-bs-dismiss="offcanvas">
                                            <div class="media-left">
                                                <img src="../../../assets/images/menu-icons/data-sensitive.svg" loading="lazy" decoding="async" alt="Sensitive Data Plan" />
                                            </div>
                                            <div class="media-body">
                                                <h5>Sensitive Data Plan</h5>
                                                <p>Get the highest level of security with no data saving and no AI training.</p>
                                                
                                            </div>
                                        </div>
                                        <ul class="tx-inline-menu">
                                            <li>
                                                <a [routerLink]="['/enterprise/sensitive-data-plan']" (click)="closeCanvasMenu()">
                                                    Overview
                                                </a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/data-security']" (click)="closeCanvasMenu()">
                                                    Data Security
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <span class="enterpise-line"></span>
                                    <h3 class="mt-1">Integrations</h3>
                                    <div class="media-card noMediaCard gap-0">
                                        <div class="media align-items-center">
                                            <div class="media-left">
                                                <img src="../../../assets/images/menu-icons/api.svg" loading="lazy" decoding="async" alt="api" />
                                            </div>
                                            <div class="media-body">
                                                <h5>API / SDK</h5>
                                                <p>Grammar Check API and JavaScript SDK for real-time or passive grammar checks with full customizations.</p>
                                            </div>
                                        </div>
                                        <ul class="tx-inline-menu">
                                            <li>
                                                <a [routerLink]="['/enterprise/grammar-checker-api']" (click)="closeCanvasMenu()">
                                                    Overview
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://developer.trinka.ai/docs/getting-started" (click)="closeCanvasMenu()" target="_blank">
                                                    Developer Docs
                                                </a>
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="media-card noMediaCard gap-0 mt-10">
                                        <div class="media align-items-center">
                                            <div class="media-left">
                                                <img src="../../../assets/images/menu-icons/on-premises.svg" loading="lazy" decoding="async" alt="premises" />
                                            </div>
                                            <div class="media-body">
                                                <h5>On-Premise</h5>
                                                <p>Get Trinka to work on your enterprise's private IT infrastructure, offering complete control.</p>
                                            </div>
                                        </div>
                                        <ul class="tx-inline-menu">
                                            <li>
                                                <a [routerLink]="['/enterprise']" fragment="onpremise" (click)="closeCanvasMenu()">
                                                    Overview
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="menu-card hr-line c-card"></div>
                                <div class="menu-card  trinka-business-menu industries">
                                    <h3>Custom AI Models</h3>
                                    <div class="media-card noMediaCard gap-0">
                                        <div class="media align-items-center" style="padding: 0 12px;margin: 0;" (click)="closeCanvasMenu()" data-bs-dismiss="offcanvas">
                                            <div class="media-body">
                                                <p class="mt-0">Building custom AI Models tailored for your enterprise, controlled by you.</p>
                                            </div>
                                        </div>
                                        <ul class="tx-inline-menu flex-column" style="margin: 8px 12px;">
                                            <li>
                                                <a [routerLink]="['/enterprise/custom-ai-models/overview']" (click)="closeCanvasMenu()">
                                                    Overview
                                                </a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/enterprise/custom-ai-models/how-we-do-it']" (click)="closeCanvasMenu()">
                                                    How We Do It
                                                </a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/enterprise/custom-ai-models/process']" (click)="closeCanvasMenu()">
                                                    Process
                                                </a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/enterprise/custom-ai-models/why-choose-us']" (click)="closeCanvasMenu()">
                                                    Why Choose Us
                                                </a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/enterprise/custom-ai-models/rlhf']" (click)="closeCanvasMenu()">
                                                    Reinforcement Learning with Human Feedback (RLHF)
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="menu-card hr-line c-card"></div>
                                <div class="menu-card trinka-business-menu case-studies-menu">
                                    <h3 (click)="closeCanvasMenu()" data-bs-dismiss="offcanvas"><a class="font-500" href="/case-study">Case Study</a></h3>
                                    <div class="media-card gap-sm">
                                        <a [routerLink]="['/case-study/nit']">
                                            <div class="media align-items-center" (click)="closeCanvasMenu()"  data-bs-dismiss="offcanvas" >
                                                <div class="case-stude-card">
                                                    <div class="case-stude-img">
                                                        <img src="../../../assets/images/case-study/nit-case-study.jpg" alt="nit" class="img-fluid" />
                                                    </div>
                                                    <div class="case-study-content">
                                                        <p>NIT, Japan Implements Trinka AI to Improve Academic and Technical English Writing</p>
                                                        <span>Read More</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="media-card gap-sm mt-10">
                                        <a [routerLink]="['/case-study/tnq']">
                                            <div class="media align-items-center" (click)="closeCanvasMenu()"  data-bs-dismiss="offcanvas" >
                                                <div class="case-stude-card">
                                                    <div class="case-stude-img">
                                                        <img src="../../../assets/images/case-study/tnq-case-study.jpg" alt="tnq" class="img-fluid" />
                                                    </div>
                                                    <div class="case-study-content">
                                                        <p>One of the largest global publisher leverages Trinka AI for copyediting-level assessment of language quality</p>
                                                        <span>Read More</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                    <!--/.mega-menu-content-->
                    </ul>
                    <!--/.dropdown-menu -->
                </li>
                <li class="nav-item dropdown">
                    <p class="nav-link dropdown-toggle" (click)="toggleClass($event)" data-toggle="dropdown"><span>Plans & Pricing</span> <img src="../../../../assets/icons/chevron-up.svg" alt="icon" /></p>
                    <ul class="dropdown-menu" id="planPricing">
                        <li class="row single-menu">
                            <div class="col-12 dflex mega-card">
                                <div class="menu-card">
                                    <div class="media-card gap-sm">
                                        <a href="/pricing">
                                            <div class="media align-items-center" (click)="closeCanvasMenu()" data-bs-dismiss="offcanvas">
                                                <div class="media-left">
                                                    <img src="../../../assets/images/menu-icons/pricing.svg" loading="lazy" decoding="async" alt="pricing" />
                                                </div>
                                                <div class="media-body">
                                                    <h5>Pricing</h5>
                                                </div>
                                            </div>
                                        </a>
                                        <a [routerLink]="['/features/credits']">
                                            <div class="media align-items-center" (click)="closeCanvasMenu()" data-bs-dismiss="offcanvas">
                                                <div class="media-left">
                                                    <img src="../../../assets/images/menu-icons/credits-trinka.svg" loading="lazy" decoding="async" alt="credits" />
                                                </div>
                                                <div class="media-body">
                                                    <h5>Credits on Trinka</h5>
                                                </div>
                                            </div>
                                        </a>
                                        <a [routerLink]="['/local-invoice']">
                                            <div class="media align-items-center" (click)="closeCanvasMenu()" data-bs-dismiss="offcanvas">
                                                <div class="media-left">
                                                    <img src="../../../assets/images/menu-icons/local-invoice.svg" loading="lazy" decoding="async" alt="invoice" />
                                                </div>
                                                <div class="media-body">
                                                    <h5>Invoicing and Payments</h5>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </li>
                <li class="nav-item dropdown">
                    <p class="nav-link dropdown-toggle" (click)="toggleClass($event)" data-toggle="dropdown"><span>Free Tools</span> <img src="../../../../assets/icons/chevron-up.svg" alt="icon" /></p>
                    <ul class="dropdown-menu">
                        <li class="row single-menu">
                            <div class="col-12 dflex mega-card">
                                <div class="menu-card">
                                    <div class="media-card gap-sm">
                                        <a [routerLink]="['/grammar-checker']">
                                            <div class="media align-items-center" (click)="closeCanvasMenu()" data-bs-dismiss="offcanvas">
                                                <div class="media-left">
                                                    <img src="../../../assets/images/menu-icons/grammar-checker.svg" loading="lazy" decoding="async" alt="Grammar Checker tool" />
                                                </div>
                                                <div class="media-body">
                                                    <h5>Grammar Checker</h5>
                                                </div>
                                            </div>
                                        </a>
                                        <a [routerLink]="['/paraphrasing-tool']">
                                            <div class="media align-items-center" (click)="closeCanvasMenu()" data-bs-dismiss="offcanvas">
                                                <div class="media-left">
                                                    <img src="../../../assets/images/menu-icons/pharaphraser.svg" loading="lazy" decoding="async" alt="Paraphrasing Tool" />
                                                </div>
                                                <div class="media-body">
                                                    <h5>Paraphrasing Tool</h5>
                                                </div>
                                            </div>
                                        </a>
                                        <a [routerLink]="['/journal-finder']">
                                            <div class="media align-items-center" (click)="closeCanvasMenu()" data-bs-dismiss="offcanvas">
                                                <div class="media-left">
                                                    <img src="../../../assets/images/menu-icons/journal-finder.svg" loading="lazy" decoding="async" alt="Journal Finder" />
                                                </div>
                                                <div class="media-body">
                                                    <h5>Journal Finder <i>New</i></h5>
                                                </div>
                                            </div>
                                        </a>
                                        <a [routerLink]="['/ai-content-detector']">
                                            <div class="media align-items-center" (click)="closeCanvasMenu()" data-bs-dismiss="offcanvas">
                                                <div class="media-left">
                                                    <img src="../../../assets/images/menu-icons/ai-detector.svg" loading="lazy" decoding="async" alt="AI Content Detector" />
                                                </div>
                                                <div class="media-body">
                                                    <h5>AI Content Detector</h5>
                                                </div>
                                            </div>
                                        </a>
                                        <a [routerLink]="['/academic-phrasebank']">
                                            <div class="media align-items-center" (click)="closeCanvasMenu()" data-bs-dismiss="offcanvas">
                                                <div class="media-left">
                                                    <img src="../../../assets/images/menu-icons/phrasebank.svg" loading="lazy" decoding="async" alt="phrasebank" />
                                                </div>
                                                <div class="media-body">
                                                    <h5>Academic Phrasebank</h5>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </li>
                <li class="nav-item dropdown">
                    <p class="nav-link dropdown-toggle" (click)="toggleClass($event)" data-toggle="dropdown"><span>Resources</span> <img src="../../../../assets/icons/chevron-up.svg" alt="icon" /></p>
                    <ul class="dropdown-menu">
                        <li class="row single-menu">
                            <div class="col-12 dflex mega-card">
                                <div class="menu-card">
                                    <div class="media-card gap-sm">
                                        <a href="https://www.enago.com/" target="_blank">
                                            <div class="media align-items-center" (click)="closeCanvasMenu()" data-bs-dismiss="offcanvas">
                                                <div class="media-left">
                                                    <img src="../../../assets/images/menu-icons/human-editing.svg" loading="lazy" decoding="async" alt="case study" />
                                                </div>
                                                <div class="media-body">
                                                    <h5>Human Editing Service</h5>
                                                </div>
                                            </div>
                                        </a>
                                        <a href="/case-study">
                                            <div class="media align-items-center" (click)="closeCanvasMenu()" data-bs-dismiss="offcanvas">
                                                <div class="media-left">
                                                    <img src="../../../assets/images/menu-icons/use-case.svg" loading="lazy" decoding="async" alt="case study" />
                                                </div>
                                                <div class="media-body">
                                                    <h5>Case Study</h5>
                                                </div>
                                            </div>
                                        </a>
                                        <a target="_blank" href="https://www.trinka.ai/blog/">
                                            <div class="media align-items-center" (click)="closeCanvasMenu()" data-bs-dismiss="offcanvas">
                                                <div class="media-left">
                                                    <img src="../../../assets/images/menu-icons/blogs.svg" loading="lazy" decoding="async" alt="Blogs" />
                                                </div>
                                                <div class="media-body">
                                                    <h5>Blogs</h5>
                                                </div>
                                            </div>
                                        </a>
                                        <a href="/podcast">
                                            <div class="media align-items-center" (click)="closeCanvasMenu()" data-bs-dismiss="offcanvas">
                                                <div class="media-left">
                                                    <img src="../../../assets/images/menu-icons/podcast.svg" loading="lazy" decoding="async" alt="podcast" />
                                                </div>
                                                <div class="media-body">
                                                    <h5>Podcast</h5>
                                                </div>
                                            </div>
                                        </a>
                                        <a  href="/news">
                                            <div class="media align-items-center" (click)="closeCanvasMenu()" data-bs-dismiss="offcanvas">
                                                <div class="media-left">
                                                    <img src="../../../assets/images/menu-icons/news.svg" loading="lazy" decoding="async" alt="news" />
                                                </div>
                                                <div class="media-body">
                                                    <h5>News</h5>
                                                </div>
                                            </div>
                                        </a>
                                        <a  href="/webinars">
                                            <div class="media align-items-center" (click)="closeCanvasMenu()" data-bs-dismiss="offcanvas">
                                                <div class="media-left">
                                                    <img src="../../../assets/images/menu-icons/webinars.svg" loading="lazy" decoding="async" alt="webinars" />
                                                </div>
                                                <div class="media-body">
                                                    <h5>Webinars</h5>
                                                </div>
                                            </div>
                                        </a>
                                        <a  href="/videos">
                                            <div class="media align-items-center" (click)="closeCanvasMenu()" data-bs-dismiss="offcanvas">
                                                <div class="media-left">
                                                    <img src="../../../assets/images/menu-icons/videos.svg" loading="lazy" decoding="async" alt="videos" />
                                                </div>
                                                <div class="media-body">
                                                    <h5>Videos</h5>
                                                </div>
                                            </div>
                                        </a>
                                        <a [routerLink]="['/trinka-vs-grammarly-vs-language-tool']">
                                            <div class="media align-items-center" (click)="closeCanvasMenu()" data-bs-dismiss="offcanvas">
                                                <div class="media-left">
                                                    <img src="../../../assets/images/menu-icons/whitepaper.svg" loading="lazy" decoding="async" alt="Whitepaper" />
                                                </div>
                                                <div class="media-body">
                                                    <h5>Whitepaper</h5>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </li>
                <!-- <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/aboutus']" data-bs-dismiss="offcanvas"><span>About Us</span></a>
                </li> -->
            </ul>
            <!-- /.offcanvas-footer -->
          </div>
          <!-- /.offcanvas-body -->
        </div>
        <div class="offcanvas-backdrop fade" (click)="closeCanvasMenu()" [ngClass]="{'show': isBackdropActive}"></div>
        <!-- /.navbar-collapse -->
        <div class="navbar-other w-100 d-flex ms-auto justify-content-end">
          <ul class="navbar-nav flex-row align-items-center ms-auto">
            <li class="nav-item">
                <div class="btn-group-header">
                    <a id="SignupForFree" class="btn-signup" [href]="generateLinkWithSourceMedium()" target="_blank">
                        Start for Free
                    </a>
                    <a id="loginButtons" class="btn-login" [href]="generateLinkWithSourceMedium(false)" target="_blank">
                        <span>Login</span>
                    </a>
                </div>   
            </li>
            <!-- <li class="nav-item"><a class="nav-link" data-es-toggle="offcanvas" data-bs-target="#offcanvas-info"><i class="uil uil-info-circle"></i></a></li> -->
            <li class="nav-item d-xl-none">
                <button class="hamburger offcanvas-nav-btn" id="hamburger-menu" (click)="toggleOffCanvasMenu()"><span></span><span></span><span></span></button>
            </li>
          </ul>
        
        </div>
        <!-- /.navbar-other -->
        <div class="language_sections" id="languageDropDown">
            <i class="fa fa-globe" aria-hidden="true"></i>
            <span>English</span>
            <ul class="drop">
                <li class="selected">
                    <i class="fa fa-check" aria-hidden="true"></i>
                    <a href="https://www.trinka.ai/">English</a>
                </li>
                <li>
                    <a href="https://www.trinka.ai/jp/">
                        日本語
                    </a>
                </li>
                <li>
                    <a href="https://trinka.enago.cn/">
                        简体中文
                    </a>
                </li>
                <li>
                    <a href="https://www.trinka.ai/tw/">
                        繁体中文
                    </a>
                </li>
                <li>
                    <a href="https://www.trinka.ai/kr/">
                        한국어
                    </a>
                </li>
                <li>
                    <a href="https://www.trinka.ai/es/">
                        Español
                    </a>
                </li>
                <li>
                    <a href="https://www.trinka.ai/pt/">
                        Português
                    </a>
                </li>
            </ul>
        </div>
      </div>
      <!-- /.container -->
    </nav>
    <!-- /.navbar -->
</header>
<!-- /header -->