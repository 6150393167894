<div class="trinka_cloud_wrap">
    <div id="particles-js"></div>
        <div class="cloud_top_banners">
            <section class="mainTopBanner">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="topBannerWrapper">
                                <div class="top-banner-left">
                                    <h1>Premium Feature</h1>
                                    <h2>Trinka MS Word<br class="d-lg-block d-none"/> Add-In Lite</h2>
                                    <p>Experience the power of Trinka Word Add-In Lite straight from your Windows or Mac. It checks your writing and provides corrections as you write on your familiar MS Word app.</p>
                                    <div class="btn-group">
                                        <a (click)="scroll(target)" class="filled-btn" target="_blank">Get Started</a>
                                    </div>
                                </div>
                                <div class="top-banner-right">
                                    <img src="../../../assets/images/maclite-banner.svg" alt="ms word" class="img-fluid md50">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <!-- <div class="advanceGrammerSpellCheck_section publishEnhancements_slider accomplishSections">
            <div class="container">
                <div class="row">
                    <div class="col-xl-6">
                        <div class="accomplishSections_img">
    
                            <a href="" data-toggle="modal" data-target="#wordPluginPopup" id="VewiVideoSection">
                                <img src="../../../assets/images/microsoft-wordThumbnails.jpg" alt="microsoft" class="img-fluid eposter-img">
                            </a>
                        </div>
                    </div>
    
                    <div class="col-xl-6">
                        <div class="spellcheck_heading EnhancementsHeadingSection">
                            <h2>
                                Get started with the Trinka Add-in for Word on Windows
                            </h2>
                            <div class="HeadingSubLine"></div>
                            <p>Need help installing or want to know how to make corrections using the add-in?
                            </p>
                            <p>Check out the easy walk-through video to get started in no time.
                            </p>
                            <p>Have more questions? Browse through our FAQs <a (click)="scroll(target)" class="link">here</a>.
                            </p>
    
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

        <!-- <div class="how_it_work_section">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="how_it_work_content">
                            <div class="howItWorkHeading">
                                <h2>How It Works</h2>
                                <div class="HeadingSubLine"></div>
                            </div>
                            <ul>
                                <li>
                                    <div class="login_circle_section">
                                        <img src="../../../assets/images/DownloadTrackChangesEdit.svg" width="50" alt="download">
                                    </div>
                                    <h3>Download</h3>
                                </li>
                                <li>
                                    <div class="login_circle_section">
                                        <img src="../../../assets/images/TypeOrImportFile_icon.png" alt="import">
                                    </div>
                                    <h3>Install</h3>
                                </li>
                                <li>
                                    <div class="login_circle_section">
                                        <img src="../../../assets/images/WordCountreductions.png" alt="word count">
                                    </div>
                                    <h3>Start writing better</h3>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="trinka-banner section-pd-sm">
            <div class="trinka_container">
                <div class="row justify-content-center">
                    <div class="col-md-10 col-lg-8">
                        <div class="trinka-title mb-0 text-center">
                            <h3 class="text-white">Works seamlessly with Mac, Windows, and Office 365 versions!</h3>
                        </div>
                    </div>
                </div>  
            </div>
        </div>

        <div class="cloud_key_features section-pd list-wrapper-style-2">
            <div class="trinka_container">
                <div class="row justify-content-center">
                    <div class="col-md-12 col-lg-12">
                        <div class="trinka-title mb-0 text-center">
                            <h2>Key Features of Trinka Microsoft Word Add-In Lite</h2>
                            <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                            <p>Trinka for MS Word is available as an add-in to the familiar MS Word application that you have
                                always used. Get professional grammar corrections and suggestions as you write, with Trinka automatically
                                checking all your changes so you don’t miss anything!</p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="list-wrapper-content-2 list-style-cards bt-none">
                            <img src="../../../assets/images/grammar-checker-icon.svg" alt="Grammar Checker" />
                            <h3>English Grammar Checker</h3>
                            <p>Get real-time writing suggestions and advanced grammar checks for your content within your MS Word environment.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="list-wrapper-content-2 list-style-cards">
                            <img src="../../../assets/images/multilingual-icon.png" alt="Grammar Checker" />
                            <h3>Multilingual Grammar Check</h3>
                            <p>Easily perfect your writing in Spanish with our advanced Grammar Checker. Trinka automatically detects the language and improvements.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="list-wrapper-content-2 list-style-cards">
                            <img src="../../../assets/images/paraphraser-icon.svg" alt="Paraphraser">
                            <h3>Paraphraser <div class="beta-badges">New</div></h3>
                            <p><a [routerLink]="['/paraphrasing-tool']" class="text-link">Paraphrase</a> as you write with AI that understands your intent and offers suggestions to make your writing clear, coherent, and grammatically correct.
                            </p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 dflex">
                        <div class="list-wrapper-content-2 list-style-cards bt-1">
                            <img src="../../../assets/images/style_guide_icon.svg" alt="Style Guide Preferences">
                            <h3>Style Guide Preferences</h3>
                            <p>Tailor the grammar & word choice of your manuscript to comply with popular academic style guides. (APA, AMA, AGU, ACS, IEEE).</p>
                        </div>
                    </div>
    
                    <div class="col-lg-4 col-md-6 dflex">
                        <div class="list-wrapper-content-2 list-style-cards bt-1">
                            <img src="../../../assets/images/AddtoDictionary.svg" alt="Mac Word | Add to Dictionary" />
                            <h3>Add to Dictionary</h3>
                            <p>Add your own words to "My Dictionary" and Trinka will not flag them as spelling errors in your future writing.</p>
                        </div>
                    </div>
    
                    <div class="col-lg-4 col-md-6 dflex">
                        <div class="list-wrapper-content-2 list-style-cards bt-1">
                            <img src="../../../assets/images/DataSecurityIcon.svg" alt="Data Security">
                            <h3>Data<br class="d-lg-block d-none"/> Security</h3>
                            <p>Whatever you type is securely transmitted and analyzed. Your data is never saved with us.
                            </p>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>

        <div class="writeToolboxs_section section-pd section-grey" id="MSWordSteps" #target>
            <div class="trinka_container">
                <div class="row justify-content-center">
                    <div class="col-lg-12 col-md-12">
                        <div class="trinka-title mb-0 text-center">
                            <h2>How to install Trinka MS Word Add-In Lite</h2>
                            <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                            <p>Getting started with Trinka <a href="/grammar-checker" class="text-link">Grammar Checker</a> MS Word Add-In Lite on Windows &  MAC is fast and simple. </p>
                            <p>If you're a premium user, the Word Add-In is already included in your plan. For those who are currently on the basic plan, upgrade to Premium to unlock access to this plug-in, along with a wide array of additional features.</p>
                        </div>
                    </div>
                </div>
            </div>
    
            <section class="section-pd list-wrapper-style-2 pb-0">
                <div class="trinka_container">
                    <div class="row align-items-center flex-row-reverse">
                        <div class="col-md-6">
                            <div class="list-wrapper-content-2">
                                <h3>Step 1</h3>
                                <ul class="steps-pts">
                                    <li>Open Microsoft Word and click on <b>Insert</b> Tab.</li>
                                    <li>Click on <b>Store</b> icon. </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="list-wrapper-image">
                                <img src="../../../assets/images/word-plugin/step1.png" alt="step1" class="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    
            <section class="section-pd list-wrapper-style-2 pb-0">
                <div class="trinka_container">
                    <div class="row align-items-center">
                        <div class="col-md-6">
                            <div class="list-wrapper-content-2 pr-80">
                                <h3>Step 2</h3>
                                <ul class="steps-pts">
                                    <li>Search for <b>Trinka</b> in the Office Add-ins store.</li>
                                    <li>Click on <b>Add.</b>
                                    <li>Click on <b>Continue</b> to begin installation.</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="list-wrapper-image features-img">
                                <img src="../../../assets/images/word-plugin/step2.png" alt="step2" class="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="section-pd list-wrapper-style-2 pb-0">
                <div class="trinka_container">
                    <div class="row align-items-center flex-row-reverse">
                        <div class="col-md-6">
                            <div class="list-wrapper-content-2">
                                <h3>Step 3</h3>
                                <ul class="steps-pts">
                                    <li>Click on Trinka Tab in the ribbon.</li>
                                    <li>Click on <b>Open</b> Trinka.</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="list-wrapper-image">
                                <img src="../../../assets/images/word-plugin/step3.png" alt="step3" class="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    
            <section class="section-pd list-wrapper-style-2 pb-0">
                <div class="trinka_container">
                    <div class="row align-items-center">
                        <div class="col-md-6">
                            <div class="list-wrapper-content-2 pr-80">
                                <h3>Step 4</h3>
                                <ul class="steps-pts">
                                    <li><b>Login</b> with your Trinka Credentials. </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="list-wrapper-image features-img">
                                <img src="../../../assets/images/word-plugin/step4.png" style="width: 243px;margin: 0 auto;" alt="step4" class="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="section-pd list-wrapper-style-2 pb-0">
                <div class="trinka_container">
                    <div class="row align-items-center flex-row-reverse">
                        <div class="col-md-6">
                            <div class="list-wrapper-content-2">
                                <h3>Step 5</h3>
                                <ul class="steps-pts">
                                    <li>Start writing to experience Trinka suggestions within your app.</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="list-wrapper-image">
                                <img src="../../../assets/images/word-plugin/step5.png" alt="step5" class="img-fluid" />
                                    <small class="note">*Kindly note that the Lite version does not support sentence highlighting</small>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <!-- <section class="wordBlog bg-grey" >
            <div class="container">
                <div class="row">
                    <div class="col-md-12 col-lg-12">
                        <div class="key_features_heading">
                            <h2>How to install Trinka MS Word Add-In Lite</h2>
                            <div class="HeadingSubLine"></div>
                            <p>Getting started with Trinka <a href="/grammar-checker/">Grammar Checker</a> MS Word Add-In Lite on Windows &  MAC is fast and simple. </p>
                            <p>If you're a premium user, the Word Add-In is already included in your plan. For those who are currently on the basic plan, upgrade to Premium to unlock access to this plugin, along with a wide array of additional features.</p>
                        </div>
                    </div>
                </div>
                <div class="outerSide">
                    <div class="stepSide">
                        <div class="row justify-content-center match-box">
                            <div class="col-lg-5 col-md-6 dflex">
                                <div class="stepLeft c-card">
                                    <div class="section-tl">
                                        <h2>Step 1</h2>
                                        <div class="HeadingSubLine"></div>
                                    </div>
                                    <ul>
                                        <li>Open Microsoft Word and click on <b>Insert</b> Tab.</li>
                                        <li>Select on <b>Store</b> icon. </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-7 col-md-6 dflex">
                                <div class="stepImg c-card">
                                    <img src="../../../assets/images/word-plugin/step1.png" alt="step1" class="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="stepSide">
                        <div class="row justify-content-center match-box">
                            <div class="col-lg-5 col-md-6 dflex">
                                <div class="stepLeft c-card">
                                    <div class="section-tl">
                                        <h2>Step 2</h2>
                                        <div class="HeadingSubLine"></div>
                                    </div>
                                    <ul>
                                        <li>Search for <b>Trinka</b> in the Office Add-ins store.</li>
                                        <li>Click on <b>Add.</b>
                                        <li>Click on <b>Continue</b> to begin installation.</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-7 col-md-6 dflex">
                                <div class="stepImg c-card">
                                    <img src="../../../assets/images/word-plugin/step2.png" alt="step2" class="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="stepSide">
                        <div class="row justify-content-center match-box">
                            <div class="col-lg-5 col-md-6 dflex">
                                <div class="stepLeft c-card">
                                    <div class="section-tl">
                                        <h2>Step 3</h2>
                                        <div class="HeadingSubLine"></div>
                                    </div>
                                    <ul>
                                        <li>Click on Trinka Tab in the ribbon</li>
                                        <li>Click on <b>Open</b> Trinka.</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-7 col-md-6 dflex">
                                <div class="stepImg c-card">
                                    <img src="../../../assets/images/word-plugin/step3.png" alt="step3" class="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="stepSide">
                        <div class="row justify-content-center match-box">
                            <div class="col-lg-5 col-md-6 dflex">
                                <div class="stepLeft c-card">
                                    <div class="section-tl">
                                        <h2>Step 4</h2>
                                        <div class="HeadingSubLine"></div>
                                    </div>
                                    <ul>
                                        <li><b>Login</b> with your Trinka Credentials </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-7 col-md-6 dflex">
                                <div class="stepImg c-card">
                                    <img src="../../../assets/images/word-plugin/step4.png" style="width: 243px;margin: 0 auto;" alt="step4" class="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="stepSide">
                        <div class="row justify-content-center match-box">
                            <div class="col-lg-5 col-md-6 dflex">
                                <div class="stepLeft c-card">
                                    <div class="section-tl">
                                        <h2>Step 5</h2>
                                        <div class="HeadingSubLine"></div>
                                    </div>
                                    <ul>
                                        <li>Start writing to experience Trinka suggestions within your app</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-7 col-md-6 dflex">
                                <div class="stepImg c-card">
                                    <img src="../../../assets/images/word-plugin/step5.png" alt="step5" class="img-fluid" />
                                    <small class="note">*Kindly note that the Lite version does not support sentence highlighting</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->

    <section class="section-pd list-wrapper-style-2 pb-0">
        <div class="trinka_container">
            <div class="row align-items-center flex-row-reverse">
                <div class="col-md-6">
                    <div class="list-wrapper-content-2">
                        <div class="trinka-title mb-0 text-left">
                            <h2>Compatible With</h2>
                            <div class="HeadingSubLine1"><span></span><span></span><span></span></div>
                        </div>
                        <ul class="steps-pts">
                            <li>Word 2019 or later versions on Windows/Mac.</li>
                            <li>Word on Mac/Windows (Microsoft 365).</li>
                            <li>Word on the web (Microsoft 365). </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="list-wrapper-image">
                        <img src="../../../assets/images/compliance-mac.svg" alt="compare" class="img-fluid" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <div class="faqQuestionAnwser section-pd" #target>
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-lg-8 col-md-10">
                    <div class="trinka-title text-center">
                        <h2>Frequently Asked Questions</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    </div>
                </div>
            </div>
            <div class="faqQuestionsList">
                <div class="accordion" id="faq">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="faqContainer">
                                <div class="card">
                                    <div class="card-header" id="QuestionOne">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionOneContent" aria-expanded="true" aria-controls="QuestionOneContent">
                                            What are the system requirements for installing Trinka for MS Word on Mac?</a>
                                    </div>
    
                                    <div id="QuestionOneContent" class="collapse" aria-labelledby="QuestionOne" data-parent="#faq">
                                        <div class="card-body">
                                            <p class="mb-2">The operating system must be at least OS X v10.10 "Yosemite".</p>
                                            <p>The minimum client version of MS Word on Mac that support Office Add-ins required is MS Word version 15.18 (160109).</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="QuestionNine">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionNineContent" aria-expanded="true" aria-controls="QuestionNineContent">
                                            How to use Trinka Word Add-In for Mac/Windows?</a>
                                    </div>
    
                                    <div id="QuestionNineContent" class="collapse" aria-labelledby="QuestionNine" data-parent="#faq">
                                        <div class="card-body">
                                            <div class="history-tl-container">
                                                <ul class="tl">
                                                  <li class="tl-item" ng-repeat="item in retailer_history">
                                                    <div class="item-title">Login to Trinka in Word Add-In</div>
                                                    <div class="item-detail">Once the Add-In is loaded successfully, upgrade to Trinka Premium to get access.</div>
                                                  </li>
                                                  <li class="tl-item" ng-repeat="item in retailer_history">
                                                    <div class="item-title">Check for Grammar Errors</div>
                                                    <div class="item-detail">To get editing, open a new or an existing document and click on the "Open Trinka" icon. As you start writing, Trinka will start detecting errors in your document.</div>
                                                  </li>
                                                  <li class="tl-item" ng-repeat="item in retailer_history">
                                                    <div class="item-title">Apply Grammar Corrections</div>
                                                    <div class="item-detail">
                                                        <ul class="icon-list">
                                                            <li>Click on the detected error correction to apply it. Alternatively, you can click on the alert card to open and view the error details & error category. This will select and highlight the identified error text on the document.</li>
                                                            <li>Once you click on the correction the change will get applied.</li>
                                                            <li>You can click on the "thumbs down" icon to remove the error correction so it won't be shown again.</li>
                                                            <li>You can also click on anywhere on the top section of the card to close the open alert card.</li>
                                                        </ul>
                                                                
                                                    </div>
                                                   </li>
                                                   <li class="tl-item pb-0" ng-repeat="item in retailer_history">
                                                    <div class="item-title">Settings</div>
                                                    <div class="item-detail">
                                                        <p>Alert Categories - 4 types of correction alerts are shown.</p>
                                                        <ul class="icon-list">
                                                            <li>Grammar corrections</li>
                                                            <li>Enhancements</li>
                                                            <li>Spelling errors</li>
                                                            <li>Advisory corrections</li>
                                                        </ul>
                                                        <br/>
                                                        <p>By default, all 4 error categories will be selected. you can deselect it, if you want to filter
                                                            the corrections by categories. No. of the total alerts shown will change according to
                                                            selection.</p>
                                                        
                                                        <p class="mt-20 mb-20">
                                                            <strong>Language</strong> -  you can select between UK English and US English. Alerts will be
                                                            refreshed and shown according to the selection.
                                                        </p>
                        
                                                        <p class="mb-0">
                                                            <strong>Style Guide</strong> - you can select any style guide from the drop down. Alerts will be refreshed
                                                            and shown according to the selection.
                                                        </p>   
                                                    </div>
                                                   </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="QuestionTwo">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionTwoContent" aria-expanded="true"
                                            aria-controls="QuestionTwoContent">Does Trinka's MS Word Add-In check for spelling and grammar in real time?</a>
                                    </div>
    
                                    <div id="QuestionTwoContent" class="collapse" aria-labelledby="QuestionTwo" data-parent="#faq">
                                        <div class="card-body">
                                            <p>Yes, Trinka's MS Word Add-In checks and suggests grammar corrections in real-time. Moreover, it detects any changes you make and automatically corrects grammar errors.</p>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="card">
                                    <div class="card-header" id="QuestionThree">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionThreeContent" aria-expanded="true"
                                            aria-controls="QuestionThreeContent">
                                            How do I accept or reject a suggestion?
                                        </a>
                                    </div>
    
                                    <div id="QuestionThreeContent" class="collapse" aria-labelledby="QuestionThree" data-parent="#faq">
                                        <div class="card-body">
                                            <p>Accepting or rejecting a grammar check suggestion is very easy. Just click on the suggested word to accept/apply the correction, or click on the "Thumbs down" icon to reject it.
                                            </p>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="card">
                                    <div class="card-header" id="QuestionFour">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionFourContent" aria-expanded="true"
                                            aria-controls="QuestionFourContent">
                                            Can I switch between US and UK English on Trinka's Word Add-In?
                                        </a>
                                    </div>
    
                                    <div id="QuestionFourContent" class="collapse" aria-labelledby="QuestionFour" data-parent="#faq">
                                        <div class="card-body">
                                            <p>Yes. You can switch between US English and UK English from the Language Check and Settings option on the menu.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="faqContainer">
                                <div class="card">
                                    <div class="card-header" id="QuestionFive">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionFiveContent" aria-expanded="true"
                                            aria-controls="QuestionFiveContent">
                                            Can I select between style guides in Trinka Word Add-In?
                                        </a>
                                    </div>
    
                                    <div id="QuestionFiveContent" class="collapse" aria-labelledby="QuestionFive" data-parent="#faq">
                                        <div class="card-body">
                                            <p>Yes. You can select the preferred style guide from the Settings menu option.
                                            </p>
                                        </div>
                                    </div>
                                </div>
    
    
                                <div class="card">
                                    <div class="card-header" id="QuestionSeven">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionSevenContent" aria-expanded="true"
                                            aria-controls="QuestionSevenContent">
                                            What should I do if I cannot find Trinka's Word Add-In after successful installation?
                                        </a>
                                    </div>
    
                                    <div id="QuestionSevenContent" class="collapse" aria-labelledby="QuestionSeven" data-parent="#faq">
                                        <div class="card-body">
                                            <p>Sometimes, your organization's security can prevent the add-in from being activated. Please contact your system administrator to provide you the access to Trinka Grammar checker Add-In for Word.
                                            </p>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="card">
                                    <div class="card-header" id="QuestionEightSection">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionEightSectionContent" aria-expanded="true"
                                            aria-controls="QuestionEightSectionContent">
                                            What should I do if the Trinka Word Add-In is unable to detect errors?
                                        </a>
                                    </div>
    
                                    <div id="QuestionEightSectionContent" class="collapse" aria-labelledby="QuestionEightSection" data-parent="#faq">
                                        <div class="card-body">
                                            <p>This could be due to multiple issues.</p><Br/>
                                            <ul>
                                                <li>Please check your internet connection. You need to be connected to the internet for Trinka to detect errors and suggest corrections.</li>
                                                <li>Check if the add-in is not disabled because of your system/organization's security settings.
                                                </li>
                                                <li>Hold on for a while or try again later, since our servers might be occupied.</li>
                                                <li>Try removing and re-installing the add-in.</li>
                                                <li>If the issue still persists, please email us at
                                                    <a href="mailto:support@trinka.ai">support@trinka.ai</a> and our team will help you resolve it.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="card">
                                    <div class="card-header" id="QuestionNineSection">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionNineSectionContent" aria-expanded="true"
                                            aria-controls="QuestionNineSectionContent">
                                            Does Trinka's MS Word Add-In offer an Office 365 grammar check?
                                        </a>
                                    </div>
    
                                    <div id="QuestionNineSectionContent" class="collapse" aria-labelledby="QuestionNineSection" data-parent="#faq">
                                        <div class="card-body">
                                            <p>Yes, Trinka's MS Word Add-In includes an Office 365 grammar check feature, ensuring comprehensive and accurate grammar checking within the Office 365 environment.</p>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="card">
                                    <div class="card-header" id="QuestionTenSection">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionTenSectionContent" aria-expanded="true"
                                            aria-controls="QuestionTenSectionContent">
                                            How does Trinka's MS Word Add-In compare to Grammarly for Word in terms of grammar and spelling checking capabilities?
                                        </a>
                                    </div>
    
                                    <div id="QuestionTenSectionContent" class="collapse" aria-labelledby="QuestionTenSection" data-parent="#faq">
                                        <div class="card-body">
                                            <p>Trinka's MS Word Add-In and Grammarly for Word both offer grammar and spelling checking capabilities. Designed specifically for technical and academic writing, Trinka offers features such as style guide selection, document type choice, and technical personalization options. Grammarly, on the other hand, is designed for generic grammar checking purposes.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="otherApps section-pd-sm">
        <div class="trinka_container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="trinka-title text-center">
                        <h3 class="text-white">Use Trinka in Other Apps</h3>
                    </div>
                    <ul>
                        <li>
                            <a [routerLink]="['/trinkacloud']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/Other_apps_TrinkaCloud.svg" alt="icon">
                                <h3>Cloud</h3>
                            </a>
                        </li>
                        <li>
                            <a  [routerLink]="['/windows-desktop-app']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/native-app/windows-icon.svg" alt="windows">
                                <h3>Windows App</h3>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/browser-plugin']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/other_Apps_browser.svg" alt="icon">
                                <h3>Browser Plug-in</h3>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/enterprise']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/Other_Apps_enterprise.svg" alt="icon">
                                <h3>Enterprise <small>(API, JS, & On-Premise)</small></h3>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="wordPluginPopup" tabindex="-1" role="dialog" aria-labelledby="wordPluginLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-body" style="padding: 0;">
                <button type="button" id="closedPopup" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div id="iframeHolder"></div>

                <iframe class="youtubeVideoForTryDemo" id="iframe" width="100%" height="450" src="https://www.youtube.com/embed/cTo16u94rxc"
                    title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
                <!-- <iframe class="youtubeVideoForTryDemo" id="iframe" width="100%" height="450" src="https://www.youtube.com/embed/z-TVcRwVp3A"
                    title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe> -->
            </div>
        </div>
    </div>
</div>