import { Component, OnInit, PLATFORM_ID, Inject, ViewEncapsulation, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewportScroller, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { WebsiteService } from 'src/app/_services/website.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';
import * as $ from 'jquery';
//import * as AOS from 'aos';
import { LinkService } from '../../_services/langhfre.service';
import { WINDOW } from '@ng-toolkit/universal';
import { Clipboard } from "@angular/cdk/clipboard";

declare function ire(string,{}): any;

@Component({
  selector: 'app-academic-phrasebank-browse',
  templateUrl: './academic-phrasebank-browse.component.html',
  styleUrls: ['./academic-phrasebank-browse.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AcademicPhrasebankBrowseComponent implements OnInit {
  objectKeys = Object.keys;
  phraseBankSearchForm: FormGroup;
  navigationData: any;
  topics = {};
  phrases = null;
  currentSelection = {
    topic: '',
    subtopic: ''
  };
  isNavbarVisible = false;
  pageOfItems = {};
  pager = {
    pageSize: 10,
    pageSizeOptions: [],
    maxPages: 14
  };
  inProgress = false;
  private _isFirstLoad: boolean = true;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private networkCall: WebsiteService,
    private fb: FormBuilder,
    private titleService: Title,
    private metaTagService: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
    private linkService: LinkService,
    private el: ElementRef,
    @Inject(WINDOW) private window: Window,
    private clipboard: Clipboard,
    private route: ActivatedRoute,
    private viewportScroller: ViewportScroller
    ) {
      const navigation = this.router.getCurrentNavigation();
      this.navigationData = navigation.extras.state;

      this.phraseBankSearchForm = this.fb.group({
        'sentence': [this.navigationData ? this.navigationData.sentence[0] : '', Validators.required]
      });

      if (this.navigationData) {
        this.phraseBankSearch();
      }
    }

    ngOnInit() {
      // this.titleService.setTitle("Academic Phrasebank - Search");
      // this.metaTagService.updateTag({ name: 'description', content: "Academic Phrasebank - Search; is a resource of invaluable phrases for all the academic writers, university students and researchers which are often used in academic and scientific writing." });
      // this.metaTagService.updateTag({ property: 'og:title', content: "Academic Phrasebank - Search" });
      // this.metaTagService.updateTag({ property: 'og:description', content: "Academic Phrasebank - Search; is a resource of invaluable phrases for all the academic writers, university students and researchers which are often used in academic and scientific writing." });
      // this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/academic-phrasebank-browse/topic/subtopic' });
      // this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
      // this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
      // this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/academic-phrasebank-browse/topic/subtopic' });
      // this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/academic-phrasebank-browse/topic/subtopic' });
      // this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/academic-phrasebank-browse/topic/subtopic' });

      // if (isPlatformBrowser(this.platformId)) {
      //   // $.getScript('../../../assets/js/particles.min.js', function(){});
      //   // document.getElementById('main_header').classList.add('homepageHeader');
      //   $.getScript('../../../assets/js/hubslider.js', function () { });
      //   $.getScript('../../../assets/js/owl.carousel.min.js', function () { });
      //   //$.getScript('../../../assets/js/wisepop.js', function () { });
      //   $.getScript('../../../assets/js/home_client_slider.js', function () { });
      //   //AOS.init();
      // }

      this.route.params.subscribe(async (params) => {
        this.currentSelection.topic = params['topic'];
        this.currentSelection.subtopic = params['subtopic'];
        this.updateMetaTags();
        if(this._isFirstLoad && this.currentSelection.topic && this.currentSelection.subtopic) {
          if (!Object.keys(this.topics).length) {
            await this._waitForTopicsToBeFetched();
          }
          if(this.currentSelection.topic != 'topic' && this.currentSelection.subtopic != 'subtopic') {
            let node = document.querySelector(`#topic__${this.currentSelection.topic}__subtopic__${this.currentSelection.subtopic}`);
            this._scrollSmoothToPosition(node);
          } else if(this.currentSelection.topic != 'topic') {
            let node = document.querySelector(`#topic__${this.currentSelection.topic}`);
            this._scrollSmoothToPosition(node);
          }
        }
        this._isFirstLoad = false;

        if (this.currentSelection.topic && this.currentSelection.subtopic && this.currentSelection.topic != 'topic' && this.currentSelection.subtopic != 'subtopic') {
          if (!Object.keys(this.topics).length) {
            await this._waitForTopicsToBeFetched();
          }

          let topic = '';
          let subtopic = '';
          Object.keys(this.topics).forEach(key => {
            if(key.toLowerCase() === this.currentSelection.topic.split('-').join(' ')) {
              topic = key;
              this.topics[key].forEach(subkey => {
                if(subkey.toLowerCase() === this.currentSelection.subtopic.split('-').join(' ')) {
                  subtopic = subkey;
                }
              });
            }
          });

          this.networkCall.getPhraseBankPhrases({
            "class_name": topic,
            "new_subclass_name": subtopic,
          }).subscribe((response) => {
            this.inProgress = false;
            this.phrases = response;
          }, (error) => {
            console.log('getPhraseBankPhrases error ===> ', error);
          });
        }
      });

      this.getPhraseBankTopics();

      ire('identify', { 'customerId': '', customerEmail: '', customProfileId: '' });
    }

    private updateMetaTags() {
      const url = `https://www.trinka.ai/academic-phrasebank-browse/${this.currentSelection.topic}/${this.currentSelection.subtopic}`;
      const formattedTopic = this.formatString(this.currentSelection.topic);
      const formattedSubtopic = this.formatString(this.currentSelection.subtopic);
      const title = `Academic Phrasebank - ${formattedTopic} - ${formattedSubtopic}`;
      const description = `Explore invaluable phrases for academic writing on ${formattedTopic} and ${formattedSubtopic} in the Academic Phrasebank. Essential for writers, students, and researchers.`;
  
      this.titleService.setTitle(title);
      this.metaTagService.updateTag({ name: 'description', content: description });
      this.metaTagService.updateTag({ property: 'og:title', content: title });
      this.metaTagService.updateTag({ property: 'og:description', content: description });
      this.metaTagService.updateTag({ property: 'og:url', content: url });
      this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
      this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
  
      this.linkService.addTag({ rel: 'canonical', href: url });
      this.linkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: url });
      this.linkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: url });
    }

    private formatString(str: string): string {
      return str.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
    }

    private _scrollSmoothToPosition(node) {
      // setTimeout(() => node?.scrollIntoView({ block: 'start', behavior: 'smooth' }), 700);
      setTimeout(() => {
        const rect = node.getBoundingClientRect();
        const targetScrollTop = window.pageYOffset + rect.top - 250;
        window.scrollTo({
          top: targetScrollTop,
          behavior: 'smooth'
        });
      }, 0);
    }

    private _waitForTopicsToBeFetched() {
      return new Promise((resolve, reject) => {
        let counter = 0;
        const checkInterval = setInterval(() => {
          if (Object.keys(this.topics).length) {
            clearInterval(checkInterval);
            resolve(true);
          }

          counter++;

          if (counter > 10) {
            clearInterval(checkInterval);
            reject();
          }
        }, 2000);
      });
    }

    toggleNavbar() {
      this.isNavbarVisible = !this.isNavbarVisible;
    }

    private getPhraseBankTopics() {
      this.networkCall.getPhraseBankTopics().subscribe((response) => {
        this.topics = response;
      }, (error) => {
        console.log('getPhraseBankTopics error ===> ', error);
      });
    }

    getPhraseBankPhrases(topic, subtopic) {
      this.inProgress = true;
      this.resetForm();
      this.isNavbarVisible = false;
      this.currentSelection.topic = topic.toLocaleLowerCase().split(' ').join('-');
      this.currentSelection.subtopic = subtopic.toLocaleLowerCase().split(' ').join('-');

      const position = this.viewportScroller.getScrollPosition();
      this.router.navigate(['/academic-phrasebank-browse', this.currentSelection.topic, this.currentSelection.subtopic]).then(() => {
        this.viewportScroller.scrollToPosition([position[0], position[1]]);
      });
    }

    phraseBankSearch() {
      if (this.phraseBankSearchForm.valid) {
        this.inProgress = true
        this.networkCall.getPhraseBankSearch({
          sentence: Object.values(this.phraseBankSearchForm.value)
        }).subscribe(response => {
          this.inProgress = false;
          this.currentSelection = {
            topic: '',
            subtopic: ''
          };
          this.collapseAllAccordions();
          this.phrases = response.result[0];
        }, error => {
          console.log('phraseBankSearch error ===> ', error);
        });
      } else {
        this.toastr.warning("Search field can not be empty");
      }
    }

    private resetForm() {
      this.phraseBankSearchForm.reset();
    }

    private collapseAllAccordions() {
      let accordionHeadings = this.el.nativeElement.querySelectorAll('.accordionHeadings, .mobile-accordionHeadings');
      let accordionBodies = this.el.nativeElement.querySelectorAll('.accordionBodies, .mobile-accordionBodies');
      accordionHeadings.forEach(element => {
        element.classList.remove('active');
      });
      accordionBodies.forEach(element => {
        element.classList.remove('show');
        element.classList.add('hide');
      });
    }


    toggleAccordion(view, topic, index) {
      let isOpen = false;
      let accordionHeading = this.el.nativeElement.querySelector(`#${view}-accordionHeading-${index}`);
      let accordionBody = this.el.nativeElement.querySelector(`#${view}-accordionBody-${index}`);
      isOpen = accordionHeading.classList.value.split(' ').includes('active');
      this.collapseAllAccordions();
      const position = this.viewportScroller.getScrollPosition();
      if (isOpen) {
        accordionHeading.classList.remove('active');
        accordionBody.classList.remove('show');
        this.router.navigate(['/academic-phrasebank-browse', 'topic', 'subtopic']).then(() => {
          this.viewportScroller.scrollToPosition([position[0], position[1]]);
        });
      } else {
        accordionHeading.classList.add('active');
        accordionBody.classList.add('show');
        this.router.navigate(['/academic-phrasebank-browse', topic.toLocaleLowerCase().split(' ').join('-'), 'subtopic']).then(() => {
          this.viewportScroller.scrollToPosition([position[0], position[1]]);
        });
      }
    }

    onChangePage(pageOfItems: Array<any>, type: string) {
      this.pageOfItems[type] = pageOfItems;
    }

    copyToClipboard(phrase: string) {
      this.toastr.success("Copied to clipboard!");
      this.clipboard.copy(phrase);
    }
  }
