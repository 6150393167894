import { Component, OnInit, AfterViewInit, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { LinkService } from '../../_services/langhfre.service';
import * as $ from 'jquery';
declare function ire(string,{}): any;
@Component({
  selector: 'app-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.scss']
})
export class BrandComponent implements OnInit, AfterViewInit {

  constructor(private titleService: Title,
    private metaTagService: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
    private LinkService: LinkService
    ) { }

    ngOnInit() {
      this.titleService.setTitle('Trinka AI - Preferred by users from top universities, institutes & publishers.');
      this.metaTagService.updateTag({ name: 'description', content: 'Trinka is extensively used by users across top universities, institutes, publishers and media houses.' });
      this.metaTagService.updateTag({ property: 'og:title', content: 'Trinka AI - Preferred by users from top universities, institutes & publishers.' });
      this.metaTagService.updateTag({ property: 'og:description', content: 'Trinka is extensively used by users across top universities, institutes, publishers and media houses.' });
      this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/brand-list' });
      this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
      this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
      this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/brand-list' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'ja-jp', href: 'https://www.trinka.ai/jp/brand-list'});
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-cn', href: 'https://trinka.enago.cn/brand-list' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-tw', href: 'https://www.trinka.ai/tw/brand-list' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'ko-kr', href: 'https://www.trinka.ai/kr/brand-list' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'es-es', href: 'https://www.trinka.ai/es/brand-list' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'pt-pt', href: 'https://www.trinka.ai/pt/brand-list' });
      if (isPlatformBrowser(this.platformId)){
        // $.getScript('../../../assets/js/particles.min.js', function(){});
        $.getScript('../../../assets/js/owl.carousel.min.js', function () {});
        $.getScript('../../../assets/js/wisepop.js', function(){});
      }

      ire('identify', { 'customerId': '', customerEmail: '', customProfileId: '' });
    }
    ngAfterViewInit(){
      if (isPlatformBrowser(this.platformId)){
        $.getScript('../../../assets/js/branding_logo.js', function () { });
      }
    }

    generateGuid() {
      var result, i, j;
      result = '';
      for(j=0; j<32; j++) {
        if( j == 8 || j == 12 || j == 16 || j == 20)
          result = result + '-';
        i = Math.floor(Math.random()*16).toString(16).toUpperCase();
        result = result + i;
      }
      return result;
    }

}
