import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './Components/home/home.component';
import { ExamplesComponent } from './Components/examples/examples.component';
import { PricingComponent } from './Components/pricing/pricing.component';
import { ContactUsComponent } from './Components/contact-us/contact-us.component';
import { WorkWithUsComponent } from './Components/work-with-us/work-with-us.component';
import { PrivacyPolicyComponent } from './Components/privacy-policy/privacy-policy.component';
import { TermServicesComponent } from './Components/term-services/term-services.component';
import { GcEditorComponent } from './Components/gc-editor/gc-editor.component';
import { PageNotFoundComponent } from './Components/page-not-found/page-not-found.component';
import { ReferralComponent } from './Components/referral/referral.component';
//import { ProfessionalEditingServiceComponent } from './Components/professional-editing-service/professional-editing-service.component';
//import { PowerModeEditingComponent } from './Components/power-mode-editing/power-mode-editing.component';
//import { WebinarOneComponent } from './Components/webinar/webinar-one/webinar-one.component';
//import { AccomplishmentsComponent } from './Components/accomplishments/accomplishments.component';
import { AcademicPhrasebankComponent } from './Components/academic-phrasebank/academic-phrasebank.component';
import { AcademicPhrasebankBrowseComponent } from './Components/academic-phrasebank-browse/academic-phrasebank-browse.component';
import { PunctuationCheckerComponent } from './Components/punctuation-checker/punctuation-checker.component';
import { SpellCheckerComponent } from './Components/spell-checker/spell-checker.component';
import { SentenceCheckerComponent } from './Components/sentence-checker/sentence-checker.component';
// import { GrammarCheckerTestComponent } from './Components/grammar-checker-test/grammar-checker-test.component';
import { ParapharsingToolComponent } from './Components/parapharsing-tool/parapharsing-tool.component';
import { AiDetectorToolComponent } from './Components/ai-detector-tool/ai-detector-tool.component';
//import { DeGrammarCheckerComponent } from './Components/de/de-grammar-checker/de-grammar-checker.component';
// import { FeaturesModule } from './modules/features.module';
// import { EnterpriseModule } from './modules/enterprise.module';
// import { AboutUsModule } from './modules/aboutus.module';
import { FeaturesComponent } from './Components/features/features.component';
import { ConsistencyChecksComponent } from './Components/features/consistency-checks/consistency-checks.component';
import { PersonalDictionaryComponent } from './Components/features/personal-dictionary/personal-dictionary.component';
import { PublicationChecksComponent } from './Components/publication-checks/publication-checks.component';
import { AutoEditComponent } from './Components/auto-edit/auto-edit.component';
import { PlagiarismCheckerComponent } from './Components/features/plagiarism-checker/plagiarism-checker.component';
import { OnlineParaphraserComponent } from './Components/features/online-paraphraser/online-paraphraser.component';
import { CreditsComponent } from './Components/features/credits/credits.component';
import { CitationCheckerComponent } from './Components/features/citation-checker/citation-checker.component';
import { JournalFinderComponent } from './Components/features/journal-finder/journal-finder.component';
import { LatexGrammarCheckerComponent } from './Components/features/latex-grammar-checker/latex-grammar-checker.component';
import { GrammarCheckerFeaturesComponent } from './Components/features/grammar-checker-features/grammar-checker-features.component';
import { InclusiveLanguageReportComponent } from './Components/features/inclusive-language-report/inclusive-language-report.component';
import { LegalWritingComponent } from './Components/features/legal-writing/legal-writing.component';
import { EnterpriseComponent } from './Components/enterprise/enterprise.component';
import { LifeSciencesMedicineAndPharmaComponent } from './Components/life-sciences-medicine-and-pharma/life-sciences-medicine-and-pharma.component';
import { LanguageServicesPublishingAndMediaComponent } from './Components/language-services-publishing-and-media/language-services-publishing-and-media.component';
import { AcademicInstitutionsComponent } from './Components/academic-institutions/academic-institutions.component';
import { K12AndElearningComponent } from './Components/k12-and-elearning/k12-and-elearning.component';
import { TechnologyPlatformsComponent } from './Components/technology-platforms/technology-platforms.component';
import { APIComponent } from './Components/enterprise/api/api.component';
import { DataSensitivePlanComponent } from './Components/enterprise/data-sensitive-plan/data-sensitive-plan.component';
import { wordpluginComponent } from './Components/word-plugin/word-plugin.component';
import { TrinkaCloudComponent } from './Components/trinka-cloud/trinka-cloud.component';
import { BrowserPluginComponent } from './Components/browser-plugin/browser-plugin.component';
import { MacwordpluginComponent } from './Components/macwordplugin/macwordplugin.component';
import { FreeGrammarCheckerComponent } from './Components/free-grammar-checker/free-grammar-checker.component';
import { APILandingPageComponent } from './Components/apilanding-page/apilanding-page.component';
import { ThesisCheckerComponent } from './Components/resource/thesis-checker/thesis-checker.component';
import { EssayCheckerComponent } from './Components/essay-checker/essay-checker.component';
import { ProofreadingToolComponent } from './Components/proofreading-tool/proofreading-tool.component';
import { OfficialInvoiceComponent } from './Components/official-invoice/official-invoice.component';
import { AffiliatesComponent } from './Components/affiliates/affiliates.component';
import { BrandComponent } from './Components/brand/brand.component';
import { TryADemoComponent } from './Components/try-ademo/try-ademo.component';
import { CampusAmbassadorComponent } from './Components/campus-ambassador/campus-ambassador.component';
import { ProfessionalEditingServiceComponent } from './Components/professional-editing-service/professional-editing-service.component';
import { JournalFinderToolsPageComponent } from './Components/journal-finder-tools-page/journal-finder-tools-page.component';
import { WindowsDesktopAppComponent } from './Components/windows-desktop-app/windows-desktop-app.component';
import { PricingV2Component } from './Components/pricing-v2/pricing-v2.component';
import { WindowsAppFeedbackComponent } from './Components/windows-desktop-app/windows-app-feedback/windows-app-feedback.component';
import { CustomAIModelsComponent } from './Components/enterprise/custom-aimodels/custom-aimodels.component';
import { HowWeDoItComponent } from './Components/enterprise/custom-aimodels/how-we-do-it/how-we-do-it.component';
import { ProcessModelComponent } from './Components/enterprise/custom-aimodels/process-model/process-model.component';
import { WhyChooseCustomAIComponent } from './Components/enterprise/custom-aimodels/why-choose-custom-ai/why-choose-custom-ai.component';
import { RLHFModelComponent } from './Components/enterprise/custom-aimodels/rlhfmodel/rlhfmodel.component';
import { ElsevierJournalComponent } from './Components/journal-finder-tools-page/elsevier-journal/elsevier-journal.component';
import { SpringerJournalComponent } from './Components/journal-finder-tools-page/springer-journal/springer-journal.component';
import { WileyJournalComponent } from './Components/journal-finder-tools-page/wiley-journal/wiley-journal.component';
import { TaylorAndFrancisJournalComponent } from './Components/journal-finder-tools-page/taylor-and-francis-journal/taylor-and-francis-journal.component';
import { SageJournalComponent } from './Components/journal-finder-tools-page/sage-journal/sage-journal.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: "features", component: FeaturesComponent },
  { path: "features/consistency-check", component: ConsistencyChecksComponent },
  { path: "features/personal-dictionary", component: PersonalDictionaryComponent },
  { path: "features/technical-checks", component: PublicationChecksComponent },
  { path: "features/proofread-file", component: AutoEditComponent },
  { path: "features/plagiarism-check", component: PlagiarismCheckerComponent },
  { path: "features/paraphraser", component: OnlineParaphraserComponent },
  { path: "features/credits", component: CreditsComponent },
  { path: "features/citation-checker", component: CitationCheckerComponent },
  { path: "features/journal-finder", component: JournalFinderComponent},
  { path: "features/latex-grammar-checker", component: LatexGrammarCheckerComponent },
  { path: "features/grammar-checker", component: GrammarCheckerFeaturesComponent},
  { path: "features/inclusive-language-check", component: InclusiveLanguageReportComponent},
  { path: "features/legal-writing", component: LegalWritingComponent},
  { path: "enterprise", component: EnterpriseComponent },
  { path: "enterprise/life-sciences-medicine-and-pharma", component: LifeSciencesMedicineAndPharmaComponent },
  { path: "enterprise/language-services-publishing-and-media", component: LanguageServicesPublishingAndMediaComponent },
  { path: "enterprise/academic-institutions", component: AcademicInstitutionsComponent },
  { path: "enterprise/k12-and-elearning", component: K12AndElearningComponent },
  { path: "enterprise/technology-platforms", component: TechnologyPlatformsComponent },
  { path: "enterprise/grammar-checker-api", component: APIComponent },
  { path: "enterprise/sensitive-data-plan", component: DataSensitivePlanComponent},
  { path: "enterprise/custom-ai-models/overview", component: CustomAIModelsComponent},
  { path: "enterprise/custom-ai-models/how-we-do-it", component: HowWeDoItComponent},
  { path: "enterprise/custom-ai-models/process", component: ProcessModelComponent},
  { path: "enterprise/custom-ai-models/why-choose-us", component: WhyChooseCustomAIComponent},
  { path: "enterprise/custom-ai-models/rlhf", component: RLHFModelComponent},
  { path: "news",  loadChildren: () => import('./modules/news.module').then(m => m.NewsModule)},
  { path: "podcast",  loadChildren: () => import('./modules/podcast.module').then(m => m.PodcastModule)},
  { path: 'wordplugin', component: wordpluginComponent },
  { path: 'trinkacloud', component: TrinkaCloudComponent },
  { path: 'wordplugin-lite', component: MacwordpluginComponent },
  { path: 'browser-plugin', component: BrowserPluginComponent },
  { path: 'windows-desktop-app', component: WindowsDesktopAppComponent },
  { path: 'windows-desktop-app/feedback', component: WindowsAppFeedbackComponent },
  { path: "trinka-vs-grammarly", loadChildren: () => import('./modules/trinkavsgrammarly.module').then(m => m.TrinkaVsGrammarlyModule)},
  { path: "trinka-vs-ginger", loadChildren: () => import('./modules/trinkavsginger.module').then(m => m.TrinkaVsGingerModule)},
  { path: "trinka-vs-languagetool", loadChildren: () => import('./modules/trinkavslanguage.module').then(m => m.TrinkaVsLanguageModule)},
  { path: "trinka-vs-writefull", loadChildren: () => import('./modules/trinkavswritefull.module').then(m => m.TrinkaVsWritefullModule)},
  { path: "trinka-vs-quillbot", loadChildren: () => import('./modules/trinkavsquillbot.module').then(m => m.TrinkavsQuillbotModule)},
  { path: "data-security", loadChildren: () => import('./modules/datasecurity.module').then(m => m.DataSecurityModule)},
  { path: 'free-grammar-checker', component: FreeGrammarCheckerComponent },
  { path: 'grammar-checker-api', component: APILandingPageComponent },
  { path: "grammar-check-api", loadChildren: () => import('./modules/apilauchpage.module').then(m => m.APILaunchPadModule)},
  { path: 'thesis-checker', component: ThesisCheckerComponent },
  { path: 'essay-checker', component: EssayCheckerComponent },
  { path: "medical-grammar-and-spelling-checker", loadChildren: () => import('./modules/medicalsa.module').then(m => m.MedicalSAModule)},
  { path: 'free-proofreading-tools', component: ProofreadingToolComponent },
  { path: 'ai-editing', loadChildren: () => import('./modules/aiediting.module').then(m => m.AIEditingModule) },
  { path: "trinka-vs-grammarly-vs-language-tool", loadChildren: () => import('./modules/whitepaper.module').then(m => m.WhitepaperModule)},
  { path: "paraphrase-api", loadChildren: () => import('./modules/paraphrasingapimodule').then(m => m.ParaphrasingAPIModule)},
  //{ path: "webinars/using-ai-for-plagiarism-prevention-and-ai-content-detection-in-academic-writing", loadChildren: () => import('./modules/webinars.module').then(m => m.WebinarModule)},
  { path: "aboutus", loadChildren: () => import('./modules/aboutus.module').then(m => m.AboutUsModule)},
  { path: "citation-checker/sample-report", loadChildren: () => import('./modules/citationsamplereport.module').then(m => m.CitationSampleReportModule)},
  { path: 'local-invoice', component: OfficialInvoiceComponent },
  { path: 'affiliates', component: AffiliatesComponent },
  { path: "faqs", loadChildren: () => import('./modules/faq.module').then(m => m.FAQModule)},
  { path: 'brand-list', component: BrandComponent },
  { path: 'try-a-demo', component: TryADemoComponent },
  { path: "university-of-granada-helpdesk", loadChildren: () => import('./modules/grandahelpdesk.module').then(m => m.UniversityOfGranadaHelpdeskModule)},
  { path: "jackson-state-university", loadChildren: () => import('./modules/jsu.module').then(m => m.JSUModule)},
  { path: "institut-teknologi-sepuluh", loadChildren: () => import('./modules/teknologisepuluh.module').then(m => m.TeknologiSepuluhUModule)},
  { path: "istanbul-university", loadChildren: () => import('./modules/istanbuluniversity.module').then(m => m.IstanbulUniversityModule)},
  { path: "universidad-del-rosario", loadChildren: () => import('./modules/universidad-del-rosario.module').then(m => m.UniversidadDelRosarioModule)},
  { path: "sogang-university", loadChildren: () => import('./modules/sogang.module').then(m => m.SogangModule)},
  { path: "demo-university", loadChildren: () => import('./modules/demoUniversity.module').then(m => m.DemoUniversityModule)},
  { path: "yonsei-university", loadChildren: () => import('./modules/yonsei-university.module').then(m => m.YonseiUniversityModule)},
  { path: "yeungnam-university", loadChildren: () => import('./modules/yeungnamuniversity.module').then(m => m.YeungnamUniversityModule)},
  { path: "haci-bayram-veli-university", loadChildren: () => import('./modules/haci-bayram').then(m => m.HaciBayramModule)},
  { path: "university-of-johannesburg", loadChildren: () => import('./modules/university-of-johannesburg.module').then(m => m.UniversityOfJohannesburgModule)},
  { path: "memorial-sloan-kettering-cancer-center", loadChildren: () => import('./modules/memorialSloan.module').then(m => m.MemorialSloanModule)},
  { path: "universitas-islam-indonesia", loadChildren: () => import('./modules/universitas-islam-indonesia.module').then(m => m.UniversitasIslamIndonesiaModule)},
  { path: "assiut-university", loadChildren: () => import('./modules/assiut.module').then(m => m.AssiutUniversityModule)},
  { path: "funs", loadChildren: () => import('./modules/funs.module').then(m => m.FunsUniversidadModule)},
  { path: "institucion-universitaria-itm", loadChildren: () => import('./modules/institucion-universitaria.module').then(m => m.InstitucionUniversitariaModule)},
  { path: "universidad-autonoma-de-occidente", loadChildren: () => import('./modules/universidad-autonoma-occidente.module').then(m => m.UniversidadAutonomaOccidenteModule)},
  { path: "konkuk-university", loadChildren: () => import('./modules/konkuk-university.module').then(m => m.KonkukUniversityModule)},
  { path: "fundacion-universitaria-del-area-andina", loadChildren: () => import('./modules/fundacion-universitaria.module').then(m => m.FundacionUniversitariaModule)},
  { path: "universidad-de-sonora", loadChildren: () => import('./modules/universidad-de-sonoro.module').then(m => m.UniversidadDeSonoraModule)},
  { path: "universitas-indonesia", loadChildren: () => import('./modules/universitas-indonesia.module').then(m => m.UniversitasIndonesiaModule)},
  { path: "northeastern-university", loadChildren: () => import('./modules/northeastern-university.module').then(m => m.NortheasternUniversityModule)},
  { path: "mahidol-university", loadChildren: () => import('./modules/mahidol-university.module').then(m => m.MahidolUniversityModule)},
  { path: "examples", component: ExamplesComponent },
  { path: "pricing", component: PricingComponent },
  { path: "contactus", component: ContactUsComponent },
  { path: "workwithus", component: WorkWithUsComponent },
  { path: "privacypolicy", component: PrivacyPolicyComponent },
  { path: "termsofservices", component: TermServicesComponent },
  { path: "professional-editing-service", component: ProfessionalEditingServiceComponent },
  { path: "referral", component: ReferralComponent },
  // { path: "power-mode-editing", component: PowerModeEditingComponent },
  // { path: "webinar/Three-ways-in-which-you-can-harness-the-power-of-AI-in-scholarly-publishing", component: WebinarOneComponent },
  { path: "grammar-checker", component: GcEditorComponent },
  { path: "academic-phrasebank", component: AcademicPhrasebankComponent },
  { path: "academic-phrasebank-browse/:topic/:subtopic", component: AcademicPhrasebankBrowseComponent },
  { path: "punctuation-checker", component: PunctuationCheckerComponent },
  { path: "spell-checker", component: SpellCheckerComponent },
  { path: "sentence-checker", component: SentenceCheckerComponent },
  // { path: "grammar-checker-test", component: GrammarCheckerTestComponent},
  { path: "paraphrasing-tool", component: ParapharsingToolComponent },
  { path: "ai-content-detector", component: AiDetectorToolComponent},
  //{ path: "de/grammatik-pruefen", component: DeGrammarCheckerComponent},
  { path: "webinars", loadChildren: () => import('./modules/webinars.module').then(m => m.WebinarModule)},
  { path: "videos", loadChildren: () => import('./modules/videos.module').then(m => m.VideosModule)},
  { path: "case-study", loadChildren: () => import('./modules/casestudy.module').then(m => m.CaseStudyModule)},
  { path: "campus-ambassador", component: CampusAmbassadorComponent},
  { path: "journal-finder", component: JournalFinderToolsPageComponent},
  { path: "journal-finder/elsevier", component: ElsevierJournalComponent},
  { path: "journal-finder/springer-nature", component: SpringerJournalComponent},
  { path: "journal-finder/wiley", component: WileyJournalComponent},
  { path: "journal-finder/taylor-and-francis", component: TaylorAndFrancisJournalComponent},
  { path: "journal-finder/sage", component: SageJournalComponent},
  { path: "pricing-plans", component: PricingV2Component},
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    initialNavigation: 'enabled',
    scrollOffset: [0, 200],
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
