import { Component, OnInit, AfterViewInit, PLATFORM_ID, Inject, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';
import * as $ from 'jquery';
import { LinkService } from '../../_services/langhfre.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
declare function ire(string,{}): any;
@Component({
  selector: 'app-free-grammar-checker',
  templateUrl: './free-grammar-checker.component.html',
  styleUrls: ['./free-grammar-checker.component.scss']
})
export class FreeGrammarCheckerComponent implements OnInit {
  compareTrinkaGrammarly: FormGroup;
  disabledSubmitButton: boolean = true;
  userEmailId: any;

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
    private formBuilder: FormBuilder,
    private fb: FormBuilder,
    private LinkService: LinkService
  ) { 
    this.compareTrinkaGrammarly = fb.group({
      'userEmailId': ['', Validators.compose([Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)])]
    });
  }
  @HostListener('input') oninput() {

    if (this.compareTrinkaGrammarly.valid) {
      this.disabledSubmitButton = false;
    }
  }

  ngOnInit(): void {
    this.titleService.setTitle('Free Grammar Checker - AI Writing Assistant - Trinka AI');
    this.metaTagService.updateTag({ name: 'description', content: "Trinka is a free AI-powered English grammar checker and language enhancement writing assistant designed for academic and technical writing." });
    this.metaTagService.updateTag({ property: 'og:title', content: 'Free Grammar Checker - AI Writing Assistant - Trinka AI' });
    this.metaTagService.updateTag({ property: 'og:description', content: 'Trinka is a free AI-powered English grammar checker and language enhancement writing assistant designed for academic and technical writing.' });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ name: 'robots', content: 'noindex, nofollow' });
    if (isPlatformBrowser(this.platformId)) {
      //$.getScript('../../../assets/js/wisepop.js', function () { });
      $(window).scroll(function () {
        if ($(window).scrollTop() >= 100) {
          $('#freeGrammarHeader').addClass('sticky');
        }
        else {
          $('#freeGrammarHeader').removeClass('sticky');
        }
      });
    }
    ire('identify', { 'customerId': '', customerEmail: '', customProfileId: '' });
  }
  onSubmitEmail() {
    if (this.compareTrinkaGrammarly.valid) {
      var CloudURL = "https://cloud.trinka.ai/signup/";
      // var CloudURL = "http://localhost:4201/signup/";
      this.userEmailId = this.compareTrinkaGrammarly.value.userEmailId;
      window.location.href = CloudURL + getEncodedEmailId(this.userEmailId);
    }
  }

  

}

function getEncodedEmailId(userEmailId) {
  return encodeURIComponent(btoa(userEmailId));
}