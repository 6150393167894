import { Component, OnInit, AfterViewInit, PLATFORM_ID, Inject, Renderer2 } from '@angular/core';
import { isPlatformBrowser, isPlatformServer, DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import * as $ from 'jquery';
import { LinkService } from '../../../_services/langhfre.service';
declare function ire(string,{}): any;
@Component({
  selector: 'app-latex-grammar-checker',
  templateUrl: './latex-grammar-checker.component.html',
  styleUrls: ['./latex-grammar-checker.component.scss']
})
export class LatexGrammarCheckerComponent implements OnInit, AfterViewInit {

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private LinkService: LinkService,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('LaTeX Proofreader | Proofread LaTeX File | Trinka AI');
    this.metaTagService.updateTag({ name: 'description', content: "Trinka Proofread File for LaTeX offers a one-click solution to check grammar in LaTeX files, saving you time with its automatic grammar-checking capabilities." });
    this.metaTagService.updateTag({ property: 'og:title', content: 'LaTeX Proofreader | Proofread LaTeX File | Trinka AI' });
    this.metaTagService.updateTag({ property: 'og:description', content: 'Trinka Proofread File for LaTeX offers a one-click solution to check grammar in LaTeX files, saving you time with its automatic grammar-checking capabilities.' });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/features/latex-grammar-checker' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.trinka.ai/assets/images/Latex-banner.jpg' });
    this.metaTagService.updateTag({ name: 'keywords', content: 'latex grammar checker, grammar checker for latex, grammar checker, grammar and syntax checker, verify grammar, grammar grammar checker, check you grammar, check grammar check, grammar check online' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.metaTagService.updateTag({ name: 'twitter:title', content: 'LaTeX Proofreader | Proofread LaTeX File| Powered by Trinka AI' });
    this.metaTagService.updateTag({ name: 'twitter:description', content: "Trinka Proofread File for LaTeX offers a one-click solution to check grammar in LaTeX files, saving you time with its automatic grammar-checking capabilities." });
    this.metaTagService.updateTag({ name: 'twitter:image', content: 'https://www.trinka.ai/assets/images/Latex-banner.jpg' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/features/latex-grammar-checker' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/features/latex-grammar-checker' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/features/latex-grammar-checker' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ja-jp', href: 'https://www.trinka.ai/jp/features/latex-grammar-checker' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-cn', href: 'https://trinka.enago.cn/features/latex-grammar-checker' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-tw', href: 'https://www.trinka.ai/tw/features/latex-grammar-checker' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ko-kr', href: 'https://www.trinka.ai/kr/features/latex-grammar-checker' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'es-es', href: 'https://www.trinka.ai/es/features/latex-grammar-checker' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'pt-pt', href: 'https://www.trinka.ai/pt/features/latex-grammar-checker' });
    if (isPlatformBrowser(this.platformId)) {
      // $.getScript('../../../assets/js/particles.min.js', function(){});
      $.getScript('../../../../assets/js/wisepop.js', function () { });
    }
    let script = this._renderer2.createElement('script');
    script.type = `application/ld+json`;
    script.text = `
    {"@context":"https://schema.org","@graph":[{"@type":"WebPage","@id":"https://www.trinka.ai/features/latex-grammar-checker#webpage","url":"https://www.trinka.ai/features/latex-grammar-checker","name":"LaTeX Proofreader | Proofread LaTeX File| Powered by Trinka AI","isPartOf":{"@id":"https://www.trinka.ai#website"},"datePublished":"2022-07-09T05:22:52+00:00","dateModified":"2023-02-10T15:18:24+00:00","description":"Trinka Proofread File for LaTeX offers a one-click solution to check grammar in LaTeX files, saving you time with its automatic grammar-checking capabilities.","inLanguage":"en-US","potentialAction":[{"@type":"ReadAction","target":["https://www.trinka.ai/features/latex-grammar-checker"]}]},{"@type":"WebSite","@id":"https://www.trinka.ai/#website","url":"https://www.trinka.ai","name":"Trinka AI","description":"Grammar Checker and AI proofreading and editing tool","publisher":{"@id":"https://www.trinka.ai#organization"},"alternateName":"The Complete AI Writing Assistant","inLanguage":"en-US"},{"@type":"Organization","@id":"https://www.trinka.ai/#organization","name":"Trinka AI","url":"https://www.trinka.ai","logo":{"@type":"ImageObject","inLanguage":"en-US","@id":"https://trinka.ai/assets/images/trinka-logo.png","url":"https://trinka.ai/assets/images/trinka-logo.png","contentUrl":"","caption":"Trinka AI"},"image":{"@type":"ImageObject","@id":"https://trinka.ai/assets/images/"},"sameAs":["https://www.facebook.com/thetrinkaai/","https://twitter.com/thetrinkaai","https://www.linkedin.com/company/thetrinkaai/","https://www.youtube.com/@Trinka-ai"]}]}
    `;
    this._renderer2.appendChild(this._document.head, script);

    let script1 = this._renderer2.createElement('script');
    script1.type = `application/ld+json`;
    script1.text = `
    {"@context":"https://schema.org/","@type":"BreadcrumbList","itemListElement":[{"@type":"ListItem","position":1,"name":"Trinka AI","item":"https://www.trinka.ai"},{"@type":"ListItem","position":2,"name":"Latex Grammar Checker","item":"https://www.trinka.ai/features/latex-grammar-checker"}]}
    `;
    this._renderer2.appendChild(this._document.head, script1);

    let script2 = this._renderer2.createElement('script');
    script2.type = `application/ld+json`;
    script2.text = `
    {"@context":"https://schema.org","@type":"Organization","name":"Trinka AI","url":"https://www.trinka.ai","logo":"https://www.trinka.ai/assets/images/trinka-logo.png","address":{"@type":"PostalAddress","streetAddress":"Office No 801, Techniplex – II Off SV Road","addressLocality":"Goregaon - West","addressRegion":"Mumbai","postalCode":"400062","addressCountry":"IN"},"sameAs":["https://www.facebook.com/thetrinkaai/","https://www.instagram.com/thetrinkaai/","https://twitter.com/thetrinkaai/","https://www.youtube.com/c/Trinka-ai","https://www.linkedin.com/company/thetrinkaai/",""]}
    `;
    this._renderer2.appendChild(this._document.head, script2);

    let script3 = this._renderer2.createElement('script');
    script3.type = `application/ld+json`;
    script3.text = `
    {"@context":"https://schema.org","@type":"FAQPage","mainEntity":[{"@type":"Question","name":"How does Trinka LaTeX Proofreader Work?","acceptedAnswer":{"@type":"Answer","text":"You upload your LaTeX file, the uploaded documents will be processed and AI-suggested corrections will be applied to them. In addition to your original LaTeX file with the changes made, you will also get an MS Word file with all the made changes shown using “Track changes” feature of MS Word.
Moreover, you also get detailed information about the number of revisions, type of grammatical errors made, and an overall writing quality score."}},{"@type":"Question","name":"Can I try the Proofread feature for Free?","acceptedAnswer":{"@type":"Answer","text":"Yes. Once you Sign up for a Basic account with Trinka, you will receive 4 free credits. You can utilize these credits to run a free grammar check on your LaTeX file."}},{"@type":"Question","name":"How many credits are consumed for using Proofread File?","acceptedAnswer":{"@type":"Answer","text":"Proofread File uses 1 credit for every 5000 words in your file."}},{"@type":"Question","name":"Can Trinka Proofread be used to edit my LaTeX file in Overleaf?","acceptedAnswer":{"@type":"Answer","text":"No. Trinka is not directly integrated with Overleaf but you can upload your final file from Overleaf and get it Proofread with Trinka in minutes."}},{"@type":"Question","name":"How is Trinka Proofread File different from Language Tool for LaTeX?","acceptedAnswer":{"@type":"Answer","text":"Trinka Proofread is an automatic proofreader, while Language Tool for LaTeX requires manual selection of suggestions. Trinka is particularly tailored for LaTeX grammar checks and is ideally suited for academics and technical writers."}}]}
    `;
    this._renderer2.appendChild(this._document.head, script3);
    ire('identify', { 'customerId': '', customerEmail: '', customProfileId: '' });
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }
  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      $('#closedPopup, #wordPluginPopup').click(function () {
        $('iframe.youtubeVideoForTryDemo').remove()
      });

      $('#VewiVideoSection').click(function () {
        if (!$('#iframe').length) {
          $('#iframeHolder').html('<iframe class="youtubeVideoForTryDemo" id="iframe" src="https://www.youtube.com/embed/joaE-2djPGE" width="100%" height="450"></iframe>');
        }
      });

    }
  }

  
}
