<div class="trinka_container">
    <div class="row justify-content-center">
        <div class="col-lg-8 col-md-10">
            <div class="trinka-title text-center ">
                <h2>Testimonials</h2>
                <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="review-wrap">
                <!-- <div class="testimonal-icon">
                    <img src="../../../assets/images/testimonial.png" alt="grammar-checker-testimonial">
                </div> -->
                <div class="review-slider">
                    <div id="testi" class="carousel slide" data-ride="carousel">
                        <ol class="carousel-indicators">
                            <li *ngFor="let testimonial of testimonials; let i = index" [attr.data-target]="'#testi'" [attr.data-slide-to]="i" [class.active]="i === 0"></li>
                        </ol>
                        <div class="carousel-inner">
                            <div *ngFor="let testimonial of testimonials; let i = index" class="carousel-item" [class.active]="i === 0">
                              <div class="media">
                                <div class="clientPhotos">
                                  <img src="../../../assets/images/{{ testimonial.photo }}" loading="lazy" decoding="async" alt="{{ testimonial.author }} - testimonial" />
                                </div>
                                <div class="review-message">
                                  <img src="../../../assets/images/testimonials-quotes.svg" loading="lazy" decoding="async" alt="quotes">
                                  <p class="start-text">{{ testimonial.message }}</p>
                                  <p class="authorNameText">{{ testimonial.author }}</p>
                                  <span class="authorDesignationText" [innerHTML]="testimonial.designation"></span>
                                </div>
                              </div>
                            </div>
                        </div>
                    </div>
                    <a class="carousel-control-prev" href="#testi" data-slide="prev" aria-label="prev">
                        <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M15 7L10 12L15 17" stroke="#adadad" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                    </a>
                    <a class="carousel-control-next" href="#testi" data-slide="next" aria-label="next">
                        <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" transform="rotate(180)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M15 7L10 12L15 17" stroke="#adadad" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>