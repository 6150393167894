<div class="trinka_cloud_wrap">
    <div id="particles-js"></div>
    <!-- <div class="cloud_top_banners">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="trinka_cloud_banner_text">
                        <h1>Proofread File</h1>
                        <h2>MS Word Files <br />Edited in Minutes</h2>
                        <p>Save time with automatic edits on your MS Word documents. 
                            Download your edited file with track changes and finalize your document superfast!</p>
                        <a href="https://cloud.trinka.ai/signup?_rg=GB" id="autoEditGetStart" target="_blank">Get Started</a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="trinka_cloud_banner_img">
                        <img src="../../../assets/images/AutoEditBanner.jpg" alt="auto edit">
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <section class="mainTopBanner checker-banner">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="topBannerWrapper">
                        <div class="top-banner-left">
                            <h1>Proofread File</h1>
                            <h2>MS Word Files <br />Edited in Minutes</h2>
                            <p>Save time with automatic edits on your MS Word documents. Download your edited file with track changes and finalize your document superfast!</p>
                            <div class="btn-group">
                                <a href="https://cloud.trinka.ai/signup?_rg=GB" class="filled-btn" target="_blank">Get Started</a>
                            </div>
                        </div>
                        <div class="top-banner-right">
                            <!-- <img src="../../../../assets/images/grammar-checker/gc-banner.png" alt="grammar-checker"> -->
                            <div id="banner-subject" class="carousel slide" data-ride="carousel" data-interval="3000">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="carousel-inner">
                                            <div class="carousel-item active">
                                                <img src=".././../../assets/images/AutoEditBanner.jpg" alt="proofread file english" class="img-fluid" />
                                            </div>
                                            <div class="carousel-item">
                                                <img src=".././../../assets/images/AutoEditBanner-spanish.jpg" alt="proofread file spanish" class="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="cs-dots">
                                            <h6>Available Languages</h6>
                                            <ul class="carousel-indicators">
                                                <li data-target="#banner-subject" data-slide-to="0" class="active">English</li>
                                                <li data-target="#banner-subject" data-slide-to="1">Spanish</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <div class="howItWorks section-pd list-wrapper-style-2">
        <div class="trinka_container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="list-wrapper-image">
                        <a href="" data-toggle="modal" data-target="#wordPluginPopup" id="VewiVideoSection">
                            <img src=".././../../assets/images/AutoFileEditThumbnail.png" loading="lazy" decoding="async" alt="proofread for grammar errors" class="img-fluid eposter-img">
                        </a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="list-wrapper-content-2">
                        <div class="trinka-title mb-0">
                            <h2>See How It Works</h2>
                            <div class="HeadingSubLine1"><span></span><span></span><span></span></div>
                            <p>Check out the easy walk-through video to get started with Proofread File in no time.</p>
                            <p>It is a detailed step-by-step tutorial to help you use Trinka to its fullest.</p>
                            <p class="mb-0">Have more questions? Browse through our FAQs <a style="color: #0000ff;" (click)="scroll(target)" class="link text-link">here</a>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="cloud_key_features section-pd list-wrapper-style-2">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-11 col-lg-10">
                    <div class="trinka-title mb-0 text-center">
                        <h2>Key Features of Trinka Proofread File</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                        <p>Avoid the hassle of installing plug-ins. Correct your MS Word file using the Proofread File feature. Trinka automatically edits your document in track changes, which you can download
                            and finalize.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-none">
                        <img src="../../../assets/images/DownloadTrackChangesEdit.svg" loading="lazy" decoding="async" alt="download icon">
                        <h3>Download File With Track Changes</h3>
                        <p>Download and finalize a track changes file showing the various changes you selected in the
                            Trinka editor.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards">
                        <img src="../../../assets/images/RetainOriginalFormatting.png" loading="lazy" decoding="async" alt="retain original formatting">
                        <h3>Retain<br class="d-lg-block d-none"/> Original<br class="d-lg-block d-none"/> Formatting</h3>
                        <p>Your content will be updated with all changes made as track changes, ensuring the original formatting is always retained.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards">
                        <img src="../../../assets/images/languageScore_icon.svg" loading="lazy" decoding="async" alt="language score">
                        <h3>Language<br class="d-lg-block d-none"/> Score<br class="d-lg-block d-none"/>&nbsp;</h3>
                        <p>Get an indicative score of the overall language quality of the document based on the number
                            of revisions by category.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-1">
                        <img src="../../../assets/images/TableofRevisions_icon.svg" loading="lazy" decoding="async" alt="table of revisions">
                        <h3>Table of Revisions</h3>
                        <p>View a detailed table of revisions by language category for a quick understanding of the type
                            of edits made.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-1">
                        <img src="../../../assets/images/style_guide_icon.svg" loading="lazy" decoding="async" alt="style guide preferences">
                        <h3>Style Guide Preferences</h3>
                        <p>Tailor the grammar & word choice of your manuscript to comply with popular academic style
                            guides (AMA 11th, AGU 2017).</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-1">
                        <img src="../../../assets/images/subjectAreaAndDocumentType.svg" loading="lazy" decoding="async" alt="subject area and document type">
                        <h3>Subject Area & Document Type</h3>
                        <p>Customize Trinka to provide you the most relevant suggestions based on your subject area and
                            type of manuscript.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <section class="section-pd pt-0">
        <app-power-mode-section></app-power-mode-section>
    </section>

    <div class="importFiles section-pd-sm">
        <div class="trinka_container">
            <div class="row align-items-center">
                <div class="col-lg-5">
                    <div class="trinka-title mb-0">
                        <h3>Import Files from Your Favorite Cloud Storage and Get them Edited in Minutes!</h3>
                    </div>
                </div>
                <div class="col-lg-7">
                    <ul>
                        <li>
                            <div class="uploadFileIcon">
                                <svg width="50" height="46" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21.6349 15.109C21.6349 15.3108 21.5751 15.5081 21.4629 15.676C21.3508 15.8438 21.1914 15.9746 21.0049 16.0518C20.8184 16.1291 20.6132 16.1493 20.4152 16.1099C20.2173 16.0705 20.0354 15.9733 19.8927 15.8306C19.7499 15.6879 19.6527 15.506 19.6134 15.3081C19.574 15.1101 19.5942 14.9049 19.6714 14.7184C19.7487 14.5319 19.8795 14.3725 20.0473 14.2604C20.2152 14.1482 20.4125 14.0884 20.6143 14.0884C20.885 14.0884 21.1446 14.1959 21.336 14.3873C21.5274 14.5787 21.6349 14.8383 21.6349 15.109V15.109Z" fill="#773795"/>
                                    <path d="M24.9945 10.7444C24.9945 10.5326 25.3093 11.3578 21.1944 1.46144C21.1374 1.32431 21.0409 1.20723 20.9172 1.12507C20.7934 1.04291 20.6481 0.999383 20.4996 1.00001H6.24404C6.10455 0.999291 5.96763 1.03753 5.8487 1.11042C5.72977 1.18332 5.63356 1.28796 5.57089 1.41258C0.685141 11.1841 1.02171 10.4729 1 10.6412C1 10.7118 1 10.1961 1 18.2413C1 18.3397 1.01938 18.4371 1.05703 18.528C1.09467 18.6188 1.14986 18.7014 1.21942 18.771C1.28899 18.8406 1.37157 18.8957 1.46246 18.9334C1.55335 18.971 1.65077 18.9904 1.74915 18.9904H24.2453C24.4421 18.9904 24.631 18.913 24.7712 18.7748C24.9114 18.6367 24.9916 18.4489 24.9945 18.2521C24.9945 18.1273 24.9945 10.7986 24.9945 10.7444V10.7444ZM24.9945 10.6901H1.02171H24.9945Z" stroke="#4f4f4f" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                            <p>Trinka My Drive</p>
                        </li>
                        <li>
                            <div class="uploadFileIcon">
                                <svg width="52" height="46" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.25 0C3.65326 0 3.08097 0.237053 2.65901 0.65901C2.23705 1.08097 2 1.65326 2 2.25V11.25C2 11.8467 2.23705 12.419 2.65901 12.841C3.08097 13.2629 3.65326 13.5 4.25 13.5H19.75C20.3467 13.5 20.919 13.2629 21.341 12.841C21.7629 12.419 22 11.8467 22 11.25V2.25C22 1.65326 21.7629 1.08097 21.341 0.65901C20.919 0.237053 20.3467 0 19.75 0H4.25ZM3.5 2.25C3.5 2.05109 3.57902 1.86032 3.71967 1.71967C3.86032 1.57902 4.05109 1.5 4.25 1.5H19.75C19.9489 1.5 20.1397 1.57902 20.2803 1.71967C20.421 1.86032 20.5 2.05109 20.5 2.25V11.25C20.5 11.4489 20.421 11.6397 20.2803 11.7803C20.1397 11.921 19.9489 12 19.75 12H4.25C4.05109 12 3.86032 11.921 3.71967 11.7803C3.57902 11.6397 3.5 11.4489 3.5 11.25V2.25ZM0.75 14.5C0.551088 14.5 0.360322 14.579 0.21967 14.7197C0.0790175 14.8603 0 15.0511 0 15.25C0 15.4489 0.0790175 15.6397 0.21967 15.7803C0.360322 15.921 0.551088 16 0.75 16H23.25C23.4489 16 23.6397 15.921 23.7803 15.7803C23.921 15.6397 24 15.4489 24 15.25C24 15.0511 23.921 14.8603 23.7803 14.7197C23.6397 14.579 23.4489 14.5 23.25 14.5H0.75Z" fill="#4f4f4f"/>
                                    <path d="M10 6H14" stroke="#773795" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                            <p>My Device</p>
                        </li>
                        <li>
                            <div class="uploadFileIcon">
                                <svg width="51" height="47" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_1393_8871)">
                                    <path d="M5.99953 0L0 3.82228L5.99953 7.64466L12 3.82228L5.99953 0ZM18 0L12 3.82266L18 7.64531L24.0001 3.82266L18 0ZM0 11.4676L5.99953 15.29L12 11.4676L5.99953 7.64531L0 11.4676ZM18 7.64531L12 11.468L18 15.2905L24 11.468L18 7.64531ZM6 16.5723L12.0005 20.3947L18 16.5723L12.0005 12.75L6 16.5723Z" fill="#0061FF"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_1393_8871">
                                    <rect width="24" height="20.4375" fill="white"/>
                                    </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <p >DropBox</p>
                        </li>
                        <li>
                            <div class="uploadFileIcon">
                                <svg width="50" height="46" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.81444 18.3783L2.87287 20.2064C3.09281 20.5914 3.40894 20.8938 3.78009 21.1137C4.84322 19.7643 5.58309 18.7287 6.00009 18.0071C6.42319 17.2749 6.94322 16.1293 7.56019 14.5707C5.89744 14.3518 4.63744 14.2424 3.78019 14.2424C2.95734 14.2424 1.69734 14.3518 0 14.5707C0 14.9968 0.109969 15.4229 0.329906 15.8079L1.81444 18.3783V18.3783Z" fill="#0066DA"/>
                                    <path d="M20.2204 21.1137C20.5917 20.8938 20.9078 20.5914 21.1276 20.2065L21.5675 19.4505L23.6707 15.8079C23.8866 15.4313 24.0003 15.0048 24.0005 14.5707C22.2933 14.3518 21.0357 14.2424 20.2274 14.2424C19.3587 14.2424 18.1009 14.3518 16.4541 14.5707C17.0638 16.1379 17.5769 17.2834 17.9937 18.0071C18.4139 18.7374 19.1562 19.7728 20.2204 21.1137V21.1137Z" fill="#EA4335"/>
                                    <path d="M11.9998 6.87291C13.2298 5.38744 14.0775 4.24191 14.5428 3.4365C14.9175 2.78794 15.3299 1.75238 15.7799 0.329906C15.4088 0.109969 14.9827 0 14.5428 0H9.45685C9.01698 0 8.59098 0.12375 8.21973 0.329906C8.79216 1.96134 9.27798 3.12244 9.67698 3.81309C10.118 4.57641 10.8923 5.59631 11.9998 6.87291Z" fill="#00832D"/>
                                    <path d="M16.4401 14.5706H7.56028L3.78027 21.1136C4.15134 21.3335 4.57743 21.4435 5.01731 21.4435H18.9831C19.4229 21.4435 19.8491 21.3197 20.2202 21.1135L16.4402 14.5706H16.4401Z" fill="#2684FC"/>
                                    <path d="M12.0001 6.87298L8.22009 0.330078C7.84884 0.550016 7.53272 0.852359 7.31278 1.2373L0.329906 13.3336C0.114012 13.7102 0.000285232 14.1366 0 14.5707H7.56019L12.0002 6.87298H12.0001Z" fill="#00AC47"/>
                                    <path d="M20.1788 7.28524L16.6872 1.23705C16.4674 0.852115 16.1512 0.549772 15.78 0.329834L12 6.87284L16.4398 14.5706H23.9863C23.9863 14.1444 23.8763 13.7184 23.6564 13.3334L20.1788 7.28524Z" fill="#FFBA00"/>
                                </svg>
                            </div>
                            <p >Google Drive</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    
    <app-safe-secure></app-safe-secure>

    <div class="sampleReport section-pd pt-0">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-11 col-lg-10">
                    <div class="trinka-title mb-0 text-center">
                        <h2>View a Sample Report</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    </div>
                </div>
                <div class="col-sm-10">
                    <div class="viewSampleReportImg">
                        <div class="btn-group">
                            <a href="../../../assets/resources/AutoEdit_sample_edited.docx" class="filled-btn" target="_blank">View Full Report <i class="fa fa-arrows-alt" aria-hidden="true"></i></a>
                        </div>
                        <img src="../../../assets/images/revised-menuscript.png" loading="lazy" decoding="async" alt="grammar checker sample report">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="trinka-banner section-pd-sm">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-10 col-lg-8">
                    <div class="trinka-title text-center">
                        <h3 class="text-white">Get Your Word Files Edited Automatically</h3>
                    </div>
                    <div class="btn-group justify-content-center">
                        <a href="https://cloud.trinka.ai/signup?_rg=GB" class="white-btn" target="_blank">Get Started</a>
                    </div>
                </div>
            </div>  
        </div>
    </div>

    <div class="trinka-credits section-pd pb-0">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-10 col-lg-8">
                    <div class="trinka-title mb-0 text-center">
                        <h2>Proofread File Uses Credits</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    </div>
                </div>
                <div class="col-md-12 col-lg-10">
                    <div class="credit-section">
                        <div class="shape">
                            <svg xmlns="http://www.w3.org/2000/svg" width="745" height="140" viewBox="0 0 745 140" fill="none">
                                <path d="M210.74 99.3391C92.7255 118.025 21.074 68.6063 0 41.5614V169H721.602C749.822 122.013 775.522 23.2037 652.567 3.86251C498.872 -20.314 358.258 75.9822 210.74 99.3391Z" fill="#F5ECFF"/>
                            </svg>
                        </div>
                        <div class="media">
                            <div class="media-left">
                                <img src="../../../assets/images/new-credits.svg" loading="lazy" decoding="async" alt="credits" />
                            </div>
                            <div class="media-body">
                                <h6>Uses 1 <span>credit for every 5000 words</span> in a file.
                                    Use free credits and buy more as needed.</h6>
                            </div>
                        </div>
                        <div class="image">
                            <img src="../../../assets/images/credits-banner.svg" loading="lazy" decoding="async" alt="credits" />
                        </div>
                    </div>
                    <ul>
                        <li><span>*</span>
                            <p>
                                Credits are like tokens that let you use several Trinka features as needed.<br/>
                                Every month, Premium users get 10 free credits and Basic users get 4 free credits. To know more, <a href="https://trinka.ai/features/credits" class="text-link" target="_blank">click here</a>.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="faqQuestionAnwser section-pd" #target>
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-lg-8 col-md-10">
                    <div class="trinka-title text-center">
                        <h2>Frequently Asked Questions</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    </div>
                </div>
            </div>
            <div class="faqQuestionsList">
                <div class="accordion" id="faq">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="faqContainer">
                                <div class="card">
                                    <div class="card-header" id="QuestionOne">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionOneContent" aria-expanded="true" aria-controls="QuestionOneContent">
                                            What do I get when I upload a file for Proofread File?</a>
                                    </div>

                                    <div id="QuestionOneContent" class="collapse" aria-labelledby="QuestionOne" data-parent="#faq">
                                        <div class="card-body">
                                            <h4>You get the following once you upload your file for Proofread:</h4>
                                            <ol>
                                                <li>The edited file with all changes in track changes. You can review these changes and accept or reject then using Microsoft Word or any other compatible Word processor. This file will also include comments explaining the corrections.</li>
                                                <li>You will also receive a detailed report which incorporates the number of revisions, type of grammatical errors made, and an overall writing quality score.</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="QuestionTwo">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionTwoContent" aria-expanded="true"
                                            aria-controls="QuestionTwoContent">
                                            Can I bulk upload multiple documents at the same time? Is there a limit? </a>
                                    </div>

                                    <div id="QuestionTwoContent" class="collapse" aria-labelledby="QuestionTwo" data-parent="#faq">
                                        <div class="card-body">
                                            <p>You can upload one document at a time that you wish to automatically edit on Trinka. Only 1 credit is used for every 5000 words in your file(s). </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="faqContainer">
                                <div class="card">
                                    <div class="card-header" id="QuestionThree">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionThreeContent" aria-expanded="true"
                                            aria-controls="QuestionThreeContent">
                                            How do I try the Proofread File feature myself?
                                        </a>
                                    </div>

                                    <div id="QuestionThreeContent" class="collapse" aria-labelledby="QuestionThree" data-parent="#faq">
                                        <div class="card-body">
                                            <p>Just <a href="https://cloud.trinka.ai/signup" class="text-link" target="_blank">Sign up</a>  on Trinka. You will find the Proofread File option on the left navigation panel. </p>
                                            <p>Upload a document and click on start editing. Voila! Get your auto-edited document in minutes!</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="card-header" id="QuestionFour">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionFourContent" aria-expanded="true"
                                            aria-controls="QuestionFourContent">
                                            How many credits are consumed for using Proofread File? 
                                        </a>
                                    </div>

                                    <div id="QuestionFourContent" class="collapse" aria-labelledby="QuestionFour" data-parent="#faq">
                                        <div class="card-body">
                                            <p>Basic users get 4 free credits every month while Premium users get 10 free credits every month. Proofread File uses 1 credit for every 5000 words in your file. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-lg-12">
                            <div class="pricingQuestionsList">
                                <div class="accordion" id="faq">
                                    <div class="card">
                                        <div class="card-header" id="QuestionOne">
                                            <a href="#" class="btn btn-header-link" data-toggle="collapse" data-target="#QuestionOneContent" aria-expanded="true" aria-controls="QuestionOneContent">
                                                What do I get when I upload a file for Proofread File</a>
                                        </div>

                                        <div id="QuestionOneContent" class="collapse show" aria-labelledby="QuestionOne" data-parent="#faq">
                                            <div class="card-body">
                                                <h4>You get the following once you upload your file for Proofread:</h4>
                                                <ol>
                                                    <li>The edited file with all changes in track changes. You can review these changes and accept or reject then using Microsoft Word or any other compatible Word processor. This file will also include comments explaining the corrections.</li>
                                                    <li>You will also receive a detailed report which incorporates the number of revisions, type of grammatical errors made, and an overall writing quality score.</li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="QuestionTwo">
                                            <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionTwoContent" aria-expanded="true"
                                                aria-controls="QuestionTwoContent">
                                                Can I bulk upload multiple documents at the same time? Is there a limit? </a>
                                        </div>

                                        <div id="QuestionTwoContent" class="collapse" aria-labelledby="QuestionTwo" data-parent="#faq">
                                            <div class="card-body">
                                                <p>You can upload as many documents as you wish to be automatically edited. There is no limitation on the number of documents that can be uploaded. Only 1 credit is used for every 5000 words in your file(s). </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-header" id="QuestionThree">
                                            <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionThreeContent" aria-expanded="true"
                                                aria-controls="QuestionThreeContent">
                                                How do I try the Proofread File feature myself?
                                            </a>
                                        </div>

                                        <div id="QuestionThreeContent" class="collapse" aria-labelledby="QuestionThree" data-parent="#faq">
                                            <div class="card-body">
                                                <p>Just <a href="https://cloud.trinka.ai/signup" class="text-link" target="_blank">Sign up</a>  on Trinka. You will find the Proofread File option on the left navigation panel. </p>
                                                <p>Upload a document and click on start editing. Voila! Get your auto-edited document in minutes!</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-header" id="QuestionFour">
                                            <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionFourContent" aria-expanded="true"
                                                aria-controls="QuestionFourContent">
                                                How many credits are consumed for using Proofread File? 
                                            </a>
                                        </div>

                                        <div id="QuestionFourContent" class="collapse" aria-labelledby="QuestionFour" data-parent="#faq">
                                            <div class="card-body">
                                                <p>Basic users get 4 free credits every month while Premium users get 10 free credits every month. Proofread File uses 1 credit for every 5000 words in your file. </p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="otherApps section-pd-sm">
        <div class="trinka_container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="trinka-title text-center">
                        <h3 class="text-white">Use Trinka in Other Apps</h3>
                    </div>
                    <ul>
                        <li>
                            <a [routerLink]="['/trinkacloud']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/Other_apps_TrinkaCloud.svg" loading="lazy" decoding="async" alt="trinka cloud">
                                <h3>Cloud</h3>
                            </a>
                        </li>
                        <li>
                            <a  [routerLink]="['/windows-desktop-app']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/native-app/windows-icon.svg" alt="windows">
                                <h3>Windows App</h3>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/wordplugin']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/Others_App_MSWord.svg" loading="lazy" decoding="async" alt="ms word">
                                <h3>MS Word Add-in</h3>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/browser-plugin']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/other_Apps_browser.svg" loading="lazy" decoding="async" alt="browser-plugin">
                                <h3>Browser Plug-in</h3>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/enterprise']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/Other_Apps_enterprise.svg" loading="lazy" decoding="async" alt="enterpise">
                                <h3>Enterprise <small>(API, JS, & On-Premise)</small></h3>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="wordPluginPopup" tabindex="-1" role="dialog" aria-labelledby="wordPluginLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-body" style="padding: 0;">
                <button type="button" id="closedPopup" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div id="iframeHolder"></div>

                <iframe class="youtubeVideoForTryDemo" id="iframe" width="100%" height="450" src="https://www.youtube.com/embed/6Z_PsveCtRQ"
                    title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
                <!-- <iframe class="youtubeVideoForTryDemo" id="iframe" width="100%" height="450" src="https://www.youtube.com/embed/z-TVcRwVp3A"
                    title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe> -->
            </div>
        </div>
    </div>
</div>