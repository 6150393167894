import { Component, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { WebsiteService } from 'src/app/_services/website.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-springer-journal-tool',
  templateUrl: './springer-journal-tool.component.html',
  styleUrls: ['./springer-journal-tool.component.scss']
})
export class SpringerJournalToolComponent implements OnInit {

  page = 1;
  pageSize = 10;

  isSticky = false;
  isCollapsed = false;
  searchText: string = "";
  isProcessingStarted: boolean = false;
  disableButton: boolean = false;
  inputWordCount: number = 0;
  fetchingResult: boolean = false;
  isWordLimit: boolean = false;
  journalFinalResult: any = {
    journal_data: [],
    sample_count: 0
  };
  seePublishArticleData: any = {
    article_data: []
  };

  citeScoreSelectionType: boolean = true;
  citeScore_custom: any = {
    min: 0,
    max: 0
  }
  defaultRangeValue: any = [];

  seePublishArticleProgressShimmer: boolean = false;
  chartType: string = "bar";
  filterList: any = {
    'show_open_access': false,
    'hide_predatory_journals': true,
    'cite_score': {
      'added_by_you': [],
      'detected_by_AI': []
    },
    'h_index': {
      'added_by_you': [],
      'detected_by_AI': []
    },
  };
  filterDisplayList: any;
  seeArticle: boolean = false;
  chartData = {
    chart: {
      theme: 'fusion', showValues: '1', labelFontSize: '11', yaxisname: 'No. of Articles Published', xaxisname: 'Year', chartBottomMargin: '0', xAxisNameFontSize: '13', yAxisNameFontSize: '13', 'yAxisMaxValue': '5'
    },
    data: [
      { label: "Venezuela", value: "290000" },
      { label: "Saudi", value: "260000" },
      { label: "Canada", value: "180000" },
      { label: "Iran", value: "140000" },
      { label: "Russia", value: "115000" },
      { label: "UAE", value: "100000" },
      { label: "US", value: "30000" },
      { label: "China", value: "30000" }
    ]
  };

  impact_factor_custom: any = {
    min: 0,
    max: 0
  }

  hIndexSelectionType: boolean = true;
  hIndex_custom: any = {
    min: 0,
    max: 0
  }

  shimmerLoaderRepeat = [1, 2, 3,];
  applySettingPopup: boolean = false;

  publisherString: string = "springer";
  journalSelection: any;
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private _toast: ToastrService,
    private _appService: WebsiteService,
    private activateRoute: ActivatedRoute,

  ) {
  }

  ngOnInit(): void {
    this.filterDisplayList = {
      'h_index': {
        'added_by_you': [],
        'detected_by_AI': []
      },
      "Cite Score": {
        data: [],
        filter_title: "cite_score",
        default_value: {
          'added_by_you': [],
          'detected_by_AI': []
        },
        type: "array"
      },
      "Show open access only": {
        data: this.filterList.show_open_access,
        filter_title: "show_open_access",
        default_value: false,
        type: "boolean"
      },
      "Hide predatory journals": {
        data: this.filterList.hide_predatory_journals,
        filter_title: "hide_predatory_journals",
        default_value: true,
        type: "boolean"
      }
    }

    this.filterList = {
      'show_open_access': false,
      'hide_predatory_journals': true,
      'cite_score': {
        'added_by_you': [],
        'detected_by_AI': []
      }
    }
    // this.activateRoute.paramMap.subscribe(params => {
    //   this.publisherString = params.get("publisher");
    // })
  }

  onInputEditorChange(txt: string) {
    this.inputWordCount = this._wordCount(txt);
  }

  private _wordCount(text) {
    return text.split(/(\s+)/).filter(x => x.trim().length > 0).length
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    try {
      const element = this.el.nativeElement.querySelector('.resultSection');
      const rect = element.getBoundingClientRect();

      if (rect.top <= 0) {
        // Add your CSS class when the element touches the top of the page
        this.renderer.addClass(element, 'sticky-class');
      } else {
        // Remove the CSS class when the element is not touching the top of the page
        this.renderer.removeClass(element, 'sticky-class');
      }
    } catch (error) {

    }
  }

  toggleAccordion(event: Event): void {
    event.preventDefault();
    this.isCollapsed = !this.isCollapsed;

    const accordions = document.querySelectorAll('.collapse');
    const collapses = document.querySelectorAll('.cd-collapse');

    accordions.forEach((accordion: Element) => {
      if (this.isCollapsed) {
        accordion.classList.remove('show');
      } else {
        accordion.classList.add('show');
      }
    });

    collapses.forEach((collapse: Element) => {
      if (this.isCollapsed) {
        collapse.classList.add('collapsed');
      } else {
        collapse.classList.remove('collapsed');
      }
    });
  }

  setDefaultRange(arr) {
    var returnobj = { 'filterlist': [], 'filterdisplaylist': [] };
    var obj = [];
    for (var i = 0; i < arr.length; ++i) {
      var current = { 'id': arr[i].min + " - " + arr[i].max, 'status': false };
      returnobj['filterlist'].push(current);
      var displaycurrent = { 'id': arr[i].min + " - " + arr[i].max, 'status': false };
      returnobj['filterdisplaylist'].push(displaycurrent);
    }
    console.log("returnobj ", returnobj)
    return returnobj;
  }

  returnSourceData(journal: any) {
    this.chartData.data = journal.graphdata.data;
    return this.chartData;
  }

  searchJournalSubmit() {
    console.log("Search button submitted")
    var payload = {
      "publisher_name": this.publisherString,
      "text": this.searchText
    }

    if (this.searchText != "" && this._wordCount(this.searchText) > 50) {
      this.isWordLimit = false;
      this.isProcessingStarted = true;
      this._appService.submitJournalFinderAPICall(payload).subscribe({
        next: (response: any) => {
          console.log(response)
          if (response.status) {
            // this.isProcessingStarted = false;
            console.log(response.file_id);
            this.fetchJournalResult(response.file_id);
          } else {
            // this.isProcessingStarted = false;
            this._toast.error("Failed to process the request!");
          }
        },
        complete: () => {
          // this.isProcessingStarted = false;
        },
        error: (err: any) => {
          this.isProcessingStarted = false;
          this._toast.error("Failed to submit request!");
        }
      })
    } else {
      if (this._wordCount(this.searchText) < 50) {
        this.isWordLimit = true;
      } else {
        this._toast.error("Please type or paste text")
      }
    }
  }

  fetchJournalResult(JournalId: string) {
    var stopFetch = false;
    const intervalId = setInterval(() => {
      if (!stopFetch) {
        this.fetchingResult = true;
        this._appService.fetchJournalFinderAPICall("eWFkYXYuYWJoaWplZXRAY3JpbXNvbmkuY29t", JournalId).subscribe({
          next: (response: any) => {
            console.log(response)
            if (response.status && response.data[0].file_status == 'SUCCESS') {
              this.fetchingResult = false;
              this.isProcessingStarted = false;
              stopFetch = true;
              this.journalFinalResult = JSON.parse(response.data[0].processed_report);
              console.log(this.journalFinalResult)
              var scrollDiv = document.getElementById("resultSection").offsetTop;
              window.scrollBy({
                top: scrollDiv - 40,  // Scroll down 200 pixels
                left: 0,
                behavior: 'smooth'  // Smooth scrolling
              });
              // document.getElementById("resultSection").scrollIntoView();
            }
          },
          complete: () => {
          },
          error: (err: any) => {
            stopFetch = true;
            this.fetchingResult = false;
            this.isProcessingStarted = false;
            this._toast.error("Failed to submit request!");
          }
        })
      } else {
        clearInterval(intervalId); // Stop the interval when flag is false
      }
    }, 2000);
  }

  seePublishArticle(journal: any) {
    this.seePublishArticleData = {
      article_data: []
    }
    window.scrollBy({
      top: 0,  // Scroll down 200 pixels
      behavior: 'smooth'  // Smooth scrolling
    });
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera

    this.seeArticle = true;
    this.seePublishArticleProgressShimmer = true;
    var payload = {
      "text": this.searchText,
      "journal_name": [
        journal.journal_title
      ],
      "sample_count": this.journalFinalResult.sample_count
    }
    this.journalSelection = journal.journal_title;
    this._appService.seePublishArticleAPICall(payload).subscribe({
      next: (response: any) => {
        console.log(response)
        if (response.status) {
          this.seePublishArticleData = response.data;
          this.seePublishArticleData.article_data = response.data.article_data.map(item => {
            return {
              ...item,            // Spread the existing properties
              viewAbstract: false,       // Add flag1
              abstractLoader: true,
              abstract: null         // Add flag2
            };
          });
        }
        this.seePublishArticleProgressShimmer = false;

      },
      complete: () => {
      },
      error: (err: any) => {
        this.seePublishArticleProgressShimmer = false;
      }
    })
  }


  get paginatedItems() {
    const start = (this.page - 1) * this.pageSize;
    const end = start + this.pageSize;
    return this.seePublishArticleData.article_data.slice(start, end);
  }

  onPageChange(page: number) {
    this.page = page;
  }

  mathCeil(a, b){
    return Math.ceil(a/b)
  }

  seePublishArticleByTitle(title: string) {
    this.seePublishArticleData = {
      article_data: []
    }
    this.seeArticle = true;
    this.seePublishArticleProgressShimmer = true;
    var payload = {
      "text": this.searchText,
      "journal_name": [
        title
      ],
      "sample_count": this.journalFinalResult.sample_count
    }
    this.journalSelection = title;
    window.scrollBy({
      top: 0,  // Scroll down 200 pixels
      behavior: 'smooth'  // Smooth scrolling
    });
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera

    this._appService.seePublishArticleAPICall(payload).subscribe({
      next: (response: any) => {
        console.log(response)
        if (response.status) {
          console.log("response.data ", response.data)
          this.seePublishArticleData = response.data;
          this.seePublishArticleData.article_data = response.data.article_data.map(item => {
            return {
              ...item,            // Spread the existing properties
              viewAbstract: false,       // Add flag1
              abstractLoader: true,
              abstract: null         // Add flag2
            };
          });
        }
        this.seePublishArticleProgressShimmer = false;
      },
      complete: () => {
      },
      error: (err: any) => {
        this.seePublishArticleProgressShimmer = false;
      }
    })
  }

  clearResult() {
    this.isSticky = false;
    this.isCollapsed = false;
    this.searchText = "";
    this.isProcessingStarted = false;
    this.fetchingResult = false;
    this.seeArticle = false;
    this.seePublishArticleData = {
      article_data: []
    }
    this.journalFinalResult = {
      journal_data: [],
      sample_count: 0
    };

    this.citeScore_custom = { min: 0, max: 0 };
    this.hIndex_custom = { min: 0, max: 0 };
    this.impact_factor_custom = { min: 0, max: 0 };

  }

  resetFilter(filterName){
    if(filterName == 'cite')
      this.citeScore_custom = { min: 0, max: 0 };
    if(filterName == 'hindex')
      this.hIndex_custom = { min: 0, max: 0 };
    if(filterName == 'impact')
      this.impact_factor_custom = { min: 0, max: 0 };
  }
  changeChartType(type) {
    this.chartType = type
  }

  onjournalSelectionOptionChange($event) {
    const selectedValue = $event.target.value;
    this.seePublishArticleData = {
      article_data: []
    }
    this.seePublishArticleByTitle(selectedValue);
  }



  openAbstract(article, index) {
    if (article.abstract == null || article.abstract == undefined) {
      this._appService.fetchArticleAbstract(article.doc_id).subscribe((resp) => {
        console.log(resp)
        article.abstract = resp['response']['response']['text']
      });
    }
    article.viewAbstract = !article.viewAbstract;       // Add flag1
    article.abstractLoader = false;
  }

  changeCheckboxFilter(filterListItem, displayFilterListItem) {
    this.filterList[filterListItem] = !this.filterList[filterListItem];
    this.filterDisplayList[displayFilterListItem].data = !this.filterDisplayList[displayFilterListItem].data;
  }
  isNumber(val){
    return typeof(val);
  }
  calculateYearsOfData(journal){
    return parseInt(journal.graphdata.filter_components[journal.graphdata.filter_components.length-1]) - parseInt(journal.graphdata.filter_components[0])
  }
  get isAllSimilarArticles() {
    return this.paginatedItems.slice(0, 3).length === 3;
  }

}
