<div class="four_zero_four_page">
    <div class="page_wrap_sections">
        <div class="left_side_secitons">
            <div class="circle_sections">
                <h2>404</h2>
                <p>Page Not Found</p>
            </div>
        </div>
        <div class="right_side_secitons">
            <h2>Oh no!</h2>
            <h3>We looked everywhere but could not find the page you are looking for.</h3>
            <p>The link you followed is either outdated, inaccurate, or perhaps you do not have access to this page.</p>
            <button [routerLink]="['/']">Get back Home</button> 
        </div>
    </div>
</div>
