<section class="section-pd customAIBanner">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-12 col-lg-12">
                <div class="customAIContent">
                    <h1>Building Your Custom AI Model: The Step-by-step Process</h1>
                    <p class="mt-4">From Data to Deployment—How We Build AI That You Own.</p>
                    <div class="btn-group justify-content-center mt-40">
                        <button data-toggle="modal" data-target="#EnterPriseContactPopup" type="button" class="white-btn">Let's Build Your Own AI Model</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="custom-shape">
        <img src="/assets/images/custom-ai/custom-ai-banner.png" width="2000" height="667" alt="custom ai" />
    </div>
</section>

<section class="section-pd">
    <div class="trinka_container container">
        <div class="row justify-content-center">
            <div class="col-md-12 col-lg-9">
                <div class="trinka-title text-center">
                    <h2>The Step-by-step Process</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center match-box">
            <div class="col-lg-12 dflex">
                <div class="process-step gradient-bg data-collection c-card">
                    <span class="bg-number">01</span>
                    <div class="row">
                        <div class="col-md-8">
                            <h3>Data Collection and Cleaning</h3>   
                            <ul>
                                <li>We begin by working closely with your enterprise to gather the data needed to train your custom AI model.</li>
                                <li>In most cases, enterprise data is unstructured and may require extensive cleaning. </li>
                                <li>Our team specializes in preparing datasets that are accurate, consistent, and relevant for model training.</li>
                            </ul>
                        </div>
                    </div>
                    <div class="ds-image">
                        <img src="/assets/images/custom-ai/data-collection.svg" class="img-fluid" alt="data collection" />
                    </div>  
                </div>
            </div>
            <div class="col-sm-6 dflex">
                <div class="process-step gradient-bg data-augmentation c-card">
                    <span class="bg-number bg-number2">02</span>
                    <div class="row">
                        <div class="col-md-12">
                            <h3>Data Augmentation</h3>   
                            <ul>
                                <li>For organizations with limited data, we use data augmentation techniques to generate synthetic data that enhances the volume and diversity of the training set. </li>
                                <li>This helps in avoiding overfitting and ensures the model generalizes well, even on limited datasets.</li>
                            </ul>
                        </div>
                    </div> 
                </div>
            </div>
            <div class="col-sm-6 dflex">
                <div class="process-step c-card">
                    <span class="bg-number bg-number3">03</span>
                    <div class="row">
                        <div class="col-md-12">
                            <h3>Model Selection <br/>& Fine-Tuning</h3>   
                            <ul>
                                <li>Using cutting-edge techniques like LoRA and QLoRA, we fine-tune a pre-trained open-source model that fits your use case. </li>
                                <li>Fine-tuning is a critical step where we ensure that the model aligns with your specific tasks, delivering accurate, domain-specific outputs.</li>
                            </ul>
                        </div>
                    </div> 
                </div>
            </div>
            <div class="col-md-7 dflex">
                <div class="process-step gradient-bg data-collection c-card">
                    <span class="bg-number">04</span>
                    <div class="row">
                        <div class="col-md-8">
                            <h3>Reinforcement Learning with Human Feedback </h3>   
                            <ul>
                                <li>Our specialty lies in RLHF, where we continuously train the model using feedback from your team. </li>
                                <li>This allows the AI to align more closely with your business processes, enhancing accuracy and reducing errors. </li>
                            </ul>
                        </div>
                    </div>
                    <div class="ds-image">
                        <img src="/assets/images/custom-ai/rlhf.svg" class="img-fluid" alt="rlhf" />
                    </div>  
                </div>
            </div>
            <div class="col-md-5 dflex">
                <div class="process-step c-card">
                    <span class="bg-number bg-number3">05</span>
                    <div class="row">
                        <div class="col-md-12">
                            <h3>Evaluation & Deployment</h3>   
                            <ul>
                                <li>Once trained, we rigorously evaluate the model using both automated tests and expert reviews.  </li>
                                <li>Only after ensuring that it meets all of your business requirements do we move to deployment. </li>
                                <li>The model is then deployed securely on your preferred infrastructure—on-premise or via a virtual private cloud.</li>
                            </ul>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    </div>
</section>

<section class="section-pd position-relative bg-light">
    <div class="trinka_container container">
        <div class="row justify-content-center">
            <div class="col-md-12 col-lg-10">
                <div class="trinka-title text-center">
                    <h2>Why This Approach Works</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    <p class="w-100">Our comprehensive approach ensures that your AI is built on a foundation of clean, enriched data, and is fine-tuned for your specific needs.  </p>
                    <p class="w-100">This ensures that the model doesn’t just solve generic problems but addresses the challenges that matter most to your business. And the best part—you own it.</p>
                </div>
                <div class="btn-group justify-content-center mt-40">
                    <button data-toggle="modal" data-target="#EnterPriseContactPopup" class="filled-btn nowrap" type="button" class="filled-btn">Contact Us</button>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="customAI_LearnMore hideCard3">
    <app-learn-more-section></app-learn-more-section>
</section>

<section class="section-pd custom-ai-gradient-light">
    <div class="trinka_container container">
        <div class="row justify-content-center">
            <div class="col-md-12 col-lg-10">
                <div class="trinka-title text-center">
                    <h2>Curious about how your business can benefit from a custom AI model?</h2>
                </div>
                <div class="btn-group justify-content-center mt-40">
                    <button data-toggle="modal" data-target="#EnterPriseContactPopup" class="filled-btn nowrap" type="button" class="filled-btn">Let's Talk</button>
                </div>
            </div>
        </div>
    </div>
</section>

<app-enterprise-modal></app-enterprise-modal>
