<div class="content_wrap_section">
    <div class="wrap_header_sectons">
        <div class="top_header_overlay"></div>
        <h1>Work with Us</h1>
    </div>

    <div class="about_us_wrap">
        <div class="container">
            <div class="row">
                <div class="con-md-12">
                    <div class="about_us_right_contents padding_left_side work_with_us_contents">
                        <h3>We keep pushing the limits</h3>
                        <p>We are a team of 20 passionate individuals who believe in going beyond the state of the art
                            and never say no to a challenge. We have made several advances in natural language
                            processing and AI technology for language enhancement. And we are not slowing down. If you
                            are up for a challenge, <a href="https://www.crimsoni.ai/work-with-us.htm" target="_blank">join us!</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>