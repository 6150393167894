import { Component, OnInit, AfterViewInit, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import * as $ from 'jquery';
import { LinkService } from '../../_services/langhfre.service';
declare function ire(string,{}): any;
@Component({
  selector: 'app-term-services',
  templateUrl: './term-services.component.html',
  styleUrls: ['./term-services.component.scss']
})
export class TermServicesComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
    private LinkService: LinkService
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Terms of Services - Trinka');
    this.metaTagService.updateTag({ name: 'description', content: 'Trinka’s free english grammar checker and writing assistant helps every person and organization to improve their writing.' });
    this.metaTagService.updateTag({ name: 'keywords', content: 'check grammar mistake online, grammar checker for essay, grammar fixer, check grammar for free' });
    this.metaTagService.updateTag({ property: 'og:title', content: 'Terms of Services - Trinka' });
    this.metaTagService.updateTag({ property: 'og:description', content: 'Trinka’s free english grammar checker and writing assistant helps every person and organization to improve their writing.' });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/termsofservices' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/termsofservices' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/termsofservices' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/termsofservices' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'ja-jp', href: 'https://www.trinka.ai/jp' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-cn', href: 'https://trinka.enago.cn' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-tw', href: 'https://www.trinka.ai/tw' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'ko-kr', href: 'https://www.trinka.ai/kr' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'es-es', href: 'https://www.trinka.ai/es' });
    // this.LinkService.addTag({ rel: 'alternate', hreflang: 'pt-pt', href: 'https://www.trinka.ai/pt' });
    if (isPlatformBrowser(this.platformId)) {
      //$.getScript('../../../assets/js/wisepop.js', function () { });
    }
    ire('identify', { 'customerId': '', customerEmail: '', customProfileId: '' });
  }


  
}
