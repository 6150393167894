<section class="banner-section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <div class="banner-title">
                    <div class="title"><h1>Free Online Spell Checker</h1></div>
                    <p>Correct your spelling errors in real-time with Trinka’s free spell checker.<br/> Identify spelling errors, including contextual spellings, and get subject area-based corrections with Trinka.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="multiTabSection">
                    <ul class="nav nav-tabs" role="tablist">
                    </ul>
                </div>
                <app-english-grammar-checker></app-english-grammar-checker>
            </div>
        </div>
    </div>
    <span class="shape-1"></span>
    <span class="shape-2"></span>
    <span class="shape-3"></span>
</section>

<div class="trinka-banner section-pd-sm d-xl-block d-none" id="real-time">
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-md-10 col-lg-8">
                <div class="trinka-title text-center">
                    <h3 class="text-white">Advanced contextual spell check anywhere you write!</h3>
                </div>
                <div class="btn-group justify-content-center">
                    <a  id="GCChromeButton" href="https://chrome.google.com/webstore/detail/trinka-ai-for-chrome/bbcmnbnmngpeofmpcdlcfalbniefegbp" target="_blank" class="white-btn"><img src="../../../assets/images/NewChromeIcon.svg" width="32" height="32" alt="chrome"> Download for Chrome</a>
                    <a  id="GCFirFoxButton" href="https://addons.mozilla.org/en-US/firefox/addon/trinka-ai-firefox-browser/" target="_blank" class="white-btn"><img src="../../../assets/images/NewFirefoxIcon.svg" width="32" height="32" alt="firefox "> Download for Firefox</a>
                    <a  id="GCEdgeButton" href="https://microsoftedge.microsoft.com/addons/detail/trinka-ai-for-edge/kjhkcgjfbpppkbhhaocagkmfkkiibeap" target="_blank" class="white-btn"><img src="../../../assets/images/EdgeWhite_BGAndOutline.svg" width="32" height="32" alt="edge"> Download for Edge</a>
                </div>
            </div>
        </div>  
    </div>
</div>


<div class="signupmobile trinka-banner section-pd-sm">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="trinka-title mb-0 text-center">
                    <h3 class="text-white mb-4">Your free Trinka account</h3>
                    <p class="text-white">Check your writing for up to 5000 words every month and notice an all-round improvement in your language and style.</p>
                    <a href="https://cloud.trinka.ai/signup?_rg=GB" target="_blank">Sign up NOW!</a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="the_best_grammer_sections section-pd">
    <div class="trinka_container">
        <div class="row">
            <div class="col-sm-12">
                <div class="trinka-title mb-0">
                    <h2>Best Free Online Spell Checker</h2>
                    <div class="HeadingSubLine1"><span></span><span></span><span></span></div>
                    <p>Trinka's spell checker identifies and provides suggestions for even the most complex spelling errors in your writing. </p>
                    <p>Trinka helps fix the most common and serious grammar and spelling mistakes with a high level of precision that will improve your writing skills and overall document quality.</p>
                    <div class="most_suited_point">
                        <ul>
                            <li>Advanced contextual spell check</li>
                            <li>Advanced Grammar check</li>
                            <li>Enjoy it for free</li>
                            <li>High-quality data security</li>
                        </ul>
                    </div>
                    <p>Trinka's online spell checker detects spelling errors and provides suggestions along with explanations for better understanding.</p>
                    <p>Sign up to unlock more features for free!</p>
                    <div class="btn-group">
                        <a href="https://cloud.trinka.ai/signup?_rg=GB" target="_blank" class="filled-btn">Get Started</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="trinka-banner section-pd-sm">
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-lg-12 col-lg-12">
                <div class="trinka-title mb-0 text-center">
                    <h3 class="text-white mb-4">Who can use this Spell Checker?</h3>
                    <p class="text-white">Trinka is ideal for anyone looking to improve their English writing skills. Trinka's free spell checker tool is trusted by academicians, students, researchers, copywriters, marketers, product teams, bloggers, content writers, self-published authors, and many others.</p>
                </div>
            </div>
        </div>  
    </div>
</div>


<div class="the_best_grammer_sections section-pd">
    <div class="trinka_container">
        <div class="row">
            <div class="col-md-12 col-lg-12">
                <div class="trinka-title mb-0 text-center">
                    <h2>Key Features of Trinka Spell Checker</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                </div>
            </div>
        </div>
        <div class="row match-box">
            <div class="col-lg-6 dflex">
                <div class="best_grammer_cards c-card">
                    <h3>Advanced Spell Check </h3>
                    <div class="content_body_best_grammer">
                        <div class="best_content_text">
                            <p>Trinka’s free spell checker understands the context of your writing and helps you choose the right word for your topic. No more awkward spelling errors! </p>
                        </div>
                        <div class="mini_card_grammer">
                            <!-- <h4>Technical word choice</h4> -->
                            <p>The <span class="wrong_text">Bonferoni</span> <span
                                class="right_text"> Bonferroni</span> test was used to check for
                            statistical significance.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 dflex">
                <div class="best_grammer_cards c-card">
                    <h3>Works on all subjects</h3>
                    <div class="content_body_best_grammer">
                        <div class="best_content_text">
                            <p>Customize Trinka to provide you the most relevant suggestions based on your subject area and type of content.<br/>&nbsp; </p>
                        </div>
                        <div class="mini_card_grammer">
                            <!-- <h4>Sensitive language</h4> -->
                            <p>The physical properties of <span class="wrong_text">kytoplasm</span> <span
                                class="right_text"> cytoplasm</span> differ considerably from dilute aqueous solutions.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 dflex">
                <div class="best_grammer_cards c-card">
                    <h3>Personal Dictionary</h3>
                    <div class="content_body_best_grammer">
                        <div class="best_content_text">
                            <p>Trinka remembers all the words you add to your dictionary and automatically syncs it across all platforms to give you a personalized writing experience, everywhere.</p>
                        </div>
                        <div class="mini_card_grammer min_auto">
                            <!-- <h4>Syntax</h4> -->
                            <div class="toolbox_img_content publication_checks_section pd_after">
                                <div class="personal_dictionary_section">
                                    <div class="personal_dic_left_text">
                                        <h2>Spelling
                                            <span>
                                                <i class="fa fa-plus"></i>Add to dictionary</span>
                                        </h2>
                                        <div class="personal_dic_text_section">
                                            <p class="personal_wrong_text">nanotubol</p>
                                            <img src="../../../assets/images/spacingArrow.png" alt="arrow-icon" />
                                            <p class="personal_right_text">nanotubes</p>
                                        </div>
                                        <p>Please check the spelling</p>
                                    </div>
                                    <div class="consistency_left_side">
                                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                                        <ul>
                                            <li>
                                                <img src="../../../assets/images/Consistency_delete_icon.svg" alt="delete" title="Delete" style="width: 22px;">
                                            </li>
                                            <li>
                                                <img src="../../../assets/images/consistency_dislike_icon.svg" alt="dislike" title="Incorrect suggestions" style="width: 20px;">
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 dflex">
                <div class="best_grammer_cards c-card">
                    <h3>Accept and Ignore All </h3>
                    <div class="content_body_best_grammer">
                        <div class="best_content_text">
                            <p>Need to correct a spelling throughout your paper? Don't want to see a suggestion again and again? Trinka makes it easy! Just accept or ignore all such suggestions with a single click.</p>
                        </div>
                        <div class="mini_card_grammer min_auto">
                            <!-- <h4>Advance Spelling</h4> -->
                            <div class="toolbox_img_content publication_checks_section pd_after">
                                <div class="personal_dictionary_section">
                                    <div class="personal_dic_left_text">
                                        <h2>Spelling
                                            <b>
                                                <i class="fa fa-plus"></i>Add to dictionary</b>
                                        </h2>
                                        <div class="personal_dic_text_section">
                                            <p class="personal_wrong_text">nanotubol</p>
                                            <img src="../../../assets/images/spacingArrow.png" alt="arrow-icon" />
                                            <p class="personal_right_text">nanotubes</p>
                                        </div>
                                        <div class="acceptAll_buttons_sections">
                                            <p>Accept for all similar alerts</p>
                                            <i>
                                                <img src="../../../assets/images/info.svg" alt="icon">
                                            </i>
                                        </div>
                                        <p>Please check the spelling</p>
                                    </div>
                                    <div class="consistency_left_side">
                                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                                        <ul>
                                            <li>
                                                <img src="../../../assets/images/Consistency_delete_icon.svg" alt="delete" title="Delete" style="width: 22px;">
                                            </li>
                                            <li class="acceptAll_rejectAll">
                                                <img src="../../../assets/images/AcceptAll_consistencyCheks.svg" alt="accept all" title="Ignore for all similar aterls" style="width: 22px;">
                                            </li>
                                            <li>
                                                <img src="../../../assets/images/consistency_dislike_icon.svg" alt="dislike" title="Incorrect suggestions" style="width: 20px;">
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
        </div>
    </div>
</div>

<div class="use_trinka_other_app section-pd-sm">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="trinka-title mb-0 text-center">
                    <h3 class="text-white">Get Trinka Where You Need It</h3>
                </div>
                <div class="use_trinka_content">
                    <div class="others_app_name">
                        <ul>
                            <li>
                                <a>
                                    <div class="app_name_content" [routerLink]="['/trinkacloud']">
                                        <div class="app_name_content_overlay"></div>
                                        <img src="../../../assets/images/Other_apps_TrinkaCloud.svg" alt="Free grammar checker tool">
                                        <h3>Cloud</h3>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a>
                                    <div class="app_name_content" [routerLink]="['/wordplugin']">
                                        <div class="app_name_content_overlay"></div>
                                        <img src="../../../assets/images/wordAdd-in1.svg" alt="word - Trinka AI grammar checker ">
                                        <h3>MS Word</h3>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a>
                                    <div class="app_name_content" [routerLink]="['/browser-plugin']">
                                        <div class="app_name_content_overlay"></div>
                                        <img src="../../../assets/images/NewChromeIcon.svg" alt="chrome">
                                        <h3>Chrome</h3>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a>
                                    <div class="app_name_content" [routerLink]="['/browser-plugin']">
                                        <div class="app_name_content_overlay"></div>
                                        <img src="../../../assets/images/NewFirefoxIcon.svg" alt="firefox ">
                                        <h3>Firefox</h3>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a>
                                    <div class="app_name_content" [routerLink]="['/browser-plugin']">
                                        <div class="app_name_content_overlay"></div>
                                        <img src="../../../assets/images/EdgeWhite_BGAndOutline.svg" alt="edge">
                                        <h3>Edge</h3>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a>
                                    <div class="app_name_content" [routerLink]="['/browser-plugin']">
                                        <span class="safariComingSoonTag">Coming Soon</span>
                                        <div class="app_name_content_overlay"></div>
                                        <img src="../../../assets/images/safari_logo.png" alt="safari - Trinka AI grammar checker">
                                        <h3>Safari</h3>
                                    </div>
                                </a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="section-pd list-wrapper-style-2">
    <div class="trinka_container">
        <div class="row align-items-center flex-row-reverse">
            <div class="col-md-6">
                <div class="list-wrapper-content-2">
                    <h3>How does Trinka's free spell checker work?</h3>
                    <p>Trinka's spell checker detects and corrects not only spelling mistakes but also grammar errors. Trinka detects spelling mistakes ranging from incorrectly spelled verb forms, confusing words with similar spellings, terminology, and much more.</p>
                    <p>With Trinka, correct all types of spelling mistakes to get your message across clearly and confidently.</p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="list-wrapper-image">
                    <img src="../../../assets/images/WritingMoreEffective.png" alt="free grammar checker app">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="section-pd list-wrapper-style-2 pt-0">
    <div class="trinka_container">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div class="list-wrapper-content-2 pr-80">
                    <h3>Free your writing from spelling errors</h3>
                    <p>Having been developed with the latest Machine Learning (ML) and Natural Language (NPL) techniques Trinka's AI powered spell checker identifies spelling errors for the most common and complex words.</p>
                    <p>Want to spell check your research papers, academic papers, emails, or essays? Trinka’s got you covered. Not just spell check, but we also help you fix grammar errors with ease.</p>
                
                </div>
            </div>
            <div class="col-md-6">
                <div class="list-wrapper-image features-img">
                    <img src="../../../assets/images/LearnGrammarwithTrinka.png" alt="Best grammar checker tool ">
                </div>
            </div>
        </div>
    </div>
</section>

<div class="testimonial-section text-center section-pd">
    <app-testimonial></app-testimonial>
</div>

<div class="trinka-banner section-pd-sm">
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-md-10 col-lg-8">
                <div class="trinka-title mb-0 text-center">
                    <h3 class="text-white mb-4">Advanced Grammar and Spell Check</h3>
                    <div class="btn-group justify-content-center">
                        <a href="https://cloud.trinka.ai/signup?_rg=GB" target="_blank" class="white-btn" id="grammerSignUpButton">
                            <!-- <i class="fa fa-sign-in"></i> -->
                            <span>Register for Free</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>  
    </div>
</div>

<div class="faqQuestionAnwser section-pd" #target>
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-lg-12 col-md-12">
                <div class="trinka-title text-center">
                    <h2>Trinka’s Spell Checker FAQ</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                </div>
            </div>
        </div>
        <div class="faqQuestionsList">
            <div class="accordion" id="faq">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="faqContainer">
                            <div class="card">
                                <div class="card-header" id="QuestionGCOne">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCOneContent" aria-expanded="true"
                                        aria-controls="QuestionGCOneContent">
                                        What is a spell checker?</a>
                                </div>
    
                                <div id="QuestionGCOneContent" class="collapse"
                                    aria-labelledby="QuestionGCOne" data-parent="#faq">
                                    <div class="card-body">
                                        <p>A spell checker is a tool that helps identify misspelled words or typographical errors in your documents and replace them with their correct versions based on the context.</p>
                                    </div>
                                </div>
                            </div>        
                            
                            <div class="card">
                                <div class="card-header" id="QuestionGCTwo">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCTwoContent" aria-expanded="true"
                                        aria-controls="QuestionGCTwoContent">How does Trinka check for spelling errors?</a>
                                </div>
    
                                <div id="QuestionGCTwoContent" class="collapse"
                                    aria-labelledby="QuestionGCTwo" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Just paste your content in the editor and click on "Check my writing". Trinka will highlight all the spelling errors and provide the best contextual spelling suggestions.</p>
                                    </div>
                                </div>
                            </div>

                            
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="faqContainer">
                            <div class="card">
                                <div class="card-header" id="QuestionGCThree">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCThreeContent" aria-expanded="true"
                                        aria-controls="QuestionGCThreeContent"> Is Trinka Spell Checker free to use?</a>
                                </div>
    
                                <div id="QuestionGCThreeContent" class="collapse"
                                    aria-labelledby="QuestionGCThree" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Absolutely, just use the editor available on this page to check your content. If you want to check longer documents, <a href="https://cloud.trinka.ai/signup?_rg=GB" target="_blank">sign up</a> here and get more features for free.</p>
                                    </div>
                                </div>
                            </div>
    
                            <div class="card">
                                <div class="card-header" id="QuestionGCFour">
                                    <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                        data-target="#QuestionGCFourContent" aria-expanded="true"
                                        aria-controls="QuestionGCFourContent">Can I check for spelling on different websites?</a>
                                </div>
    
                                <div id="QuestionGCFourContent" class="collapse"
                                    aria-labelledby="QuestionGCFour" data-parent="#faq">
                                    <div class="card-body">
                                        <p>Yes, just install our <a  [routerLink]="['/browser-plugin']" class="text-link">browser plug-in</a> for Chrome, Firefox or Edge and use Trinka's spell checker on any website.</p>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>