
<div class="trinka_cloud_wrap">   
   <!-- <div class="cloud_top_banners">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-7">
                    <div class="trinka_cloud_banner_text">
                        <h1>If <span>currencies</span> are different,<br class="d-none d-xl-block"/> why should <span>invoices</span> be the same?</h1>
                        <p>Get Local Invoices | Bank Account Payment | Centralised Invoicing</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-5 no-gutter">
                    <div class="trinka_cloud_banner_img">
                        <img src="../../../assets/images/local-inovice-element-new1.png" alt="Local invoice banner" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <section class="mainTopBanner bg-light-purple">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-12">
                    <div class="topBannerWrapper align-items-center">
                        <div class="top-banner-left">
                            <h1 class="singleTag">Various Invoices and Payment Options for Individuals and Enterprises.</h1>
                        </div>
                        <div class="top-banner-right">
                            <img src="../../../assets/images/local-inovice-element-new1.png" alt="Local invoice banner" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- <section class="section-md notification-form">
        <div class="trinka-container">
            <div class="row">
                <div class="col-md-12">
                    <div class="spellcheck_heading">
                        <div class="row">
                            <div class="col-12">
                                <p style="margin-bottom: 40px;">At Trinka, we understand the importance of a smooth invoicing and payment process for your organisation. That's why we offer the option to issue invoices in local currencies, making it easier for you to handle international transactions. Our invoicing system is designed to streamline the reimbursement process and meet the specific requirements of organisations.
                                    Our dedicated team is well-versed in handling international invoicing and compliance with institutional requirements, ensuring that your invoices are accurate, compliant, and meet all necessary guidelines.</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12  col-12">
                                <div class="row">
                                    <div class="col-12">
                                        <h2>We offer </h2>
                                        <div class="HeadingSubLine"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row match-box">
                            <div class="col-md-6 col-lg-4 dflex">
                                <div class="c-card listing-box">
                                    <ul class="icon-list">
                                        <li><span>Local company invoices available,
                                            Seikyu-sho in Japan, Fapiao in China, Yeongeumjeung in South Korea, Fatura in Turkey, Invoices in the USA and India.</span></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-4 dflex">
                                <div class="c-card listing-box">
                                    <ul class="icon-list">
                                        <li><span>Payment options in your currency (JPY, CNY, BRL, TRY, KRW, INR, USD and TWD) as well as bank account options in your country.</span></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-4 dflex">
                                <div class="c-card listing-box">
                                    <ul class="icon-list">
                                        <li><span>Clubbing of invoices for multiple accounts for your organisation for central invoicing and payment plans.</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </section> -->

    <section class="section-pd">
        <div class="trinka_container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="trinka-title text-center">
                        <h2>Global Invoicing at Trinka</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                        <p>We offer local company invoices in various countries and local payment options, simplifying payments worldwide.</p>
                    </div>
                </div>
            </div>
            <div class="row match-box">
                <div class="col-md-12 col-lg-6 dflex">
                    <div class="trinka-title mb-0">
                        <ul class="bullets-box">

                            <li><p>Invoices in:</p> </li>
                            <li>
                                <img src="../../../assets/images/flags/japan.png" alt="japan" loading="lazy" />
                                Japan (JPY, Seikyu-sho)
                            </li>
                            <li>
                                <img src="../../../assets/images/flags/china.png" alt="china" loading="lazy" />
                                China (RMB, Fapiao)
                            </li>
                            <li>
                                <img src="../../../assets/images/flags/south-korea.png" alt="south-korea" loading="lazy" />
                                South Korea (KRW, Yeongeumjeung)
                            </li>
                            <li>
                                <img src="../../../assets/images/flags/turkey.png" alt="turkey" loading="lazy" />
                                Turkey (TRY, Fatura)
                            </li>
                            <li>
                                <img src="../../../assets/images/flags/brazil.png" alt="brazil" loading="lazy" />
                                Brazil (BRL, Boleto)
                            </li>
                            <li>
                                <img src="../../../assets/images/flags/india.png" alt="india" loading="lazy" />
                                India (INR, GST)
                            </li>
                            <li>
                                <img src="../../../assets/images/flags/united-kingdom.png" alt="uk" loading="lazy" />
                                UK (GBP)
                            </li>
                            <li>
                                <img src="../../../assets/images/flags/taiwan.png" alt="taiwan" loading="lazy" />
                                Taiwan (TWD)
                            </li>
                            <li>
                                <img src="../../../assets/images/flags/united-states-of-america.png" alt="usa" loading="lazy" />
                                USA (USD)
                            </li>
                        </ul>
                        <!-- <p class="mb-0">Local payment options are also available.</p> -->
                    </div>
                </div>
                <div class="col-md-12 col-lg-6 dflex">
                    <div class="c-card local-options">
                        <p>Payment options in:</p>
                        <ul>
                            <li>
                                <img src="../../../assets/images/bank-transfer/paypal.png" alt="paypal" loading="lazy" />
                            </li>
                            <li>
                                <img src="../../../assets/images/bank-transfer/bank-of-america.png" alt="bank-of-america" loading="lazy" />
                            </li>
                            <li>
                                <img src="../../../assets/images/bank-transfer/stripe.png" alt="stripe payments" loading="lazy" />
                            </li>
                            <li>
                                <img src="../../../assets/images/bank-transfer/alipay.png" alt="alipay" loading="lazy" />
                            </li>
                            <li>
                                <img src="../../../assets/images/bank-transfer/wechatpaylogo.png" alt="wechat" loading="lazy" />
                            </li>
                            <li>
                                <img src="../../../assets/images/bank-transfer/mufg-bank.png" alt="mufg" loading="lazy" />
                            </li>
                            <li>
                                <img src="../../../assets/images/bank-transfer/citibank.png" alt="citi bank" loading="lazy" />
                            </li>
                            <li>
                                <img src="../../../assets/images/bank-transfer/kotak.png" alt="kotak" loading="lazy" />
                            </li>
                            <li>
                                <img src="../../../assets/images/bank-transfer/Razorpay_logo.svg" alt="razorpay" loading="lazy" />
                            </li>
                            <li>
                                <img src="../../../assets/images/bank-transfer/visa.png" alt="visa" loading="lazy" />
                            </li>
                            <li>
                                <img src="../../../assets/images/bank-transfer/mastercard.png" alt="mastercard" loading="lazy" />
                            </li>
                            <li>
                                <img src="../../../assets/images/bank-transfer/American-Express.png" alt="american express" loading="lazy" />
                            </li>
                            <li>
                                <img src="../../../assets/images/bank-transfer/Clover.png" alt="clover" loading="lazy" />
                            </li>
                            <li>
                                <img src="../../../assets/images/bank-transfer/kginics.jpeg" alt="kginics" loading="lazy" />
                            </li>
                            <li>
                                <img src="../../../assets/images/bank-transfer/Shinhan_Bank_logo.png" alt="shinhan bank" loading="lazy" />
                            </li>
                            <li>
                                <img src="../../../assets/images/bank-transfer/payoneer.jpg" alt="payoneer" loading="lazy" />
                            </li>
                            <li>
                                <img src="../../../assets/images/bank-transfer/2checkout.png" alt="2checkout" loading="lazy" />
                            </li>
                            <li>
                                <img src="../../../assets/images/bank-transfer/PagBrasil.png" alt="PagBrasil" loading="lazy" />
                            </li>

                            <li>
                                <img src="../../../assets/images/bank-transfer/logo-boleto-bancario.png" alt="boleto-bancario" loading="lazy" />
                            </li>
                            <li>
                                <img src="../../../assets/images/bank-transfer/open-pay.png" alt="openpay" loading="lazy" />
                            </li>
                            
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="section-pd bg-grey notification-form">
        <div class="trinka_container">
            <div class="row">
                <div class="col-md-12">
                    <div class="spellcheck_heading">
                        <div class="row">
                            <div class="col-lg-12  col-12">
                                <!-- <div class="row">
                                    <div class="col-md-12 col-lg-12">
                                        <div class="trinka-title mb-0 text-center">
                                            <h2>Choose your country to know the payment modes available</h2>
                                            <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="row">
                                    <div class="col-12">
                                        <div class="sm-card">
                                            <div id="tile-1">
                                                <ul class="nav nav-pills nav-justified" role="tablist">
                                                    <li class="nav-item" role="presentation">
                                                        <a class="nav-link active" (click)="onTabClick($event)" data-toggle="pill" href="#enterprise" role="tab" aria-selected="true">Enterprise</a>
                                                    </li>
                                                    <li class="nav-item" role="presentation">
                                                        <a class="nav-link" (click)="onTabClick($event)" data-toggle="pill" href="#individual" role="tab" aria-selected="false">Individual</a>
                                                    </li>
                                                    <div class="slider"></div>
                                                </ul>
                                            </div>
                                            <div class="tab-content">
                                                <div id="enterprise" class="tab-pane active">
                                                    <div class="card-form">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p class="tb-title">For enterprises, we have special plans and post-payment options depending on the usage.</p>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="form-group">
                                                                    <label for="email"><b>Select Country with Tax Type:</b></label>
                                                                    <div class="in-form-select">
                                                                        <select class="form-control" id="currencyselector">
                                                                            <option value="brl">Brazil</option>
                                                                            <option value="cny">China (With VAT)</option>
                                                                            <option selected="selected" value="jpy">Japan (With Consumption Tax)</option>
                                                                            <option value="jpy-w">Japan (Without Consumption Tax)</option>
                                                                            <option value="inr-gst">India (With GST)</option>
                                                                            <!-- <option value="inr-gst-w">India (Without GST)</option> -->
                                                                            <option value="krw">South Korea (With VAT)</option>
                                                                            <option value="twd-vat">Taiwan (With VAT)</option>
                                                                            <option value="twd-vat-w">Taiwan (Without VAT)</option>
                                                                            <option value="try">Turkey (With VAT)</option>
                                                                            <option value="try-w">Turkey (Without VAT)</option>
                                                                            <!-- <option value="euro">UK</option> -->
                                                                            <option value="usd">USA and Others</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <!-- BRL -->
                                                                <div class="currency-container" id="brl" style="display: none;">
                                                                    <div class="row">
                                                                        <div class="col-md-4 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Currency Type:</label>
                                                                                <div class="input-control">BRL</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-8 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Tax Rates and Type:</label>
                                                                                <div class="input-control">Not Applicable</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-4 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Bank Account Location:</label>
                                                                                <div class="input-control">USA</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-3 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Invoice Type:</label>
                                                                                <div class="input-control">NA</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-5 col-sm-12">
                                                                            <div class="form-group">
                                                                                <label>Payment Options Available:</label>
                                                                                <div class="input-control">Bank Account Transfer</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
        
                                                                <!-- CNY -->
                                                                <div class="currency-container" id="cny" style="display: none;">
                                                                    <div class="row">
                                                                        <div class="col-md-4 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Currency Type:</label>
                                                                                <div class="input-control">CNY</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-8 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Tax Rates:</label>
                                                                                <div class="input-control">6% - VAT</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-4 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Bank Account Location:</label>
                                                                                <div class="input-control">China</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-3 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Invoice Type:</label>
                                                                                <div class="input-control">Fapiao</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-5 col-sm-12">
                                                                            <div class="form-group">
                                                                                <label>Payment Options Available:</label>
                                                                                <div class="input-control">Bank Account Transfer, Alipay and Wechat</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
        
                                                                <!-- JPY -->
                                                                <div class="currency-container" id="jpy">
                                                                    <div class="row">
                                                                        <div class="col-md-4 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Currency Type:</label>
                                                                                <div class="input-control">JPY</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-8 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Tax Rates and Type:</label>
                                                                                <div class="input-control">10% - Consumption Tax (Shouhizei/消費税)</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-4 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Bank Account Location:</label>
                                                                                <div class="input-control">Japan</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-3 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Invoice Type:</label>
                                                                                <div class="input-control">Seikyū-sho</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-5 col-sm-12">
                                                                            <div class="form-group">
                                                                                <label>Payment Options Available:</label>
                                                                                <div class="input-control">Bank Account Transfer and Paypal</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
        
                                                                <!-- JPY-W -->
                                                                <div class="currency-container" id="jpy-w" style="display: none;">
                                                                    <div class="row">
                                                                        <div class="col-md-4 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Currency Type:</label>
                                                                                <div class="input-control">JPY</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-8 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Tax Rates and Type:</label>
                                                                                <div class="input-control">without VAT</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-4 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Bank Account Location:</label>
                                                                                <div class="input-control">Japan</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-3 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Invoice Type:</label>
                                                                                <div class="input-control">Seikyū-sho</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-5 col-sm-12">
                                                                            <div class="form-group">
                                                                                <label>Payment Options Available:</label>
                                                                                <div class="input-control">Bank Account Transfer and Paypal</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
        
                                                                <!-- inr-gst -->
                                                                <div class="currency-container" id="inr-gst" style="display: none;">
                                                                    <div class="row">
                                                                        <div class="col-md-4 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Currency Type:</label>
                                                                                <div class="input-control">INR</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-8 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Tax Rates and Type:</label>
                                                                                <div class="input-control">18% - GST</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-4 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Bank Account Location:</label>
                                                                                <div class="input-control">India</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-3 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Invoice Type:</label>
                                                                                <div class="input-control">Invoice</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-5 col-sm-12">
                                                                            <div class="form-group">
                                                                                <label>Payment Options Available:</label>
                                                                                <div class="input-control">Bank Account Transfer and Razorpay</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
        
                                                                <!-- KRW -->
                                                                <div class="currency-container" id="krw" style="display: none;">
                                                                    <div class="row">
                                                                        <div class="col-md-4 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Currency Type:</label>
                                                                                <div class="input-control">KRW</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-8 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Tax Rates and Type:</label>
                                                                                <div class="input-control">10% - VAT</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-4 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Bank Account Location:</label>
                                                                                <div class="input-control">South Kora</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-3 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Invoice Type:</label>
                                                                                <div class="input-control">Yeongeumjeung</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-5 col-sm-12">
                                                                            <div class="form-group">
                                                                                <label>Payment Options Available:</label>
                                                                                <div class="input-control">Bank Account Transfer and KGINICS</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
        
                                                                <!-- twd-vat -->
                                                                <div class="currency-container" id="twd-vat" style="display: none;">
                                                                    <div class="row">
                                                                        <div class="col-md-4 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Currency Type:</label>
                                                                                <div class="input-control">TWD</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-8 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Tax Rates and Type:</label>
                                                                                <div class="input-control">5% - VAT</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-4 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Bank Account Location:</label>
                                                                                <div class="input-control">Taiwan</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-3 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Invoice Type:</label>
                                                                                <div class="input-control">Fapiao</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-5 col-sm-12">
                                                                            <div class="form-group">
                                                                                <label>Payment Options Available:</label>
                                                                                <div class="input-control">Bank Account Transfer</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
        
                                                                <!-- twd-vat-w -->
                                                                <div class="currency-container" id="twd-vat-w" style="display: none;">
                                                                    <div class="row">
                                                                        <div class="col-md-4 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Currency Type:</label>
                                                                                <div class="input-control">TWD</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-8 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Tax Rates and Type:</label>
                                                                                <div class="input-control">Not Applicable</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-4 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Bank Account Location:</label>
                                                                                <div class="input-control">USA</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-3 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Invoice Type:</label>
                                                                                <div class="input-control">Fapiao</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-5 col-sm-12">
                                                                            <div class="form-group">
                                                                                <label>Payment Options Available:</label>
                                                                                <div class="input-control">Openpay and Paypal</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
        
                                                                <!-- try-->
                                                                <div class="currency-container" id="try" style="display: none;">
                                                                    <div class="row">
                                                                        <div class="col-md-4 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Currency Type:</label>
                                                                                <div class="input-control">TRY</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-8 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Tax Rates and Type:</label>
                                                                                <div class="input-control">18% - KDV</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-4 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Bank Account Location:</label>
                                                                                <div class="input-control">Turkey</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-3 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Invoice Type:</label>
                                                                                <div class="input-control">Fatura</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-5 col-sm-12">
                                                                            <div class="form-group">
                                                                                <label>Payment Options Available:</label>
                                                                                <div class="input-control">Bank Account Transfer</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
        
                                                                <!-- try-w-->
                                                                <div class="currency-container" id="try-w" style="display: none;">
                                                                    <div class="row">
                                                                        <div class="col-md-4 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Currency Type:</label>
                                                                                <div class="input-control">TRY</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-8 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Tax Rates and Type:</label>
                                                                                <div class="input-control">Not Applicable</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-4 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Bank Account Location:</label>
                                                                                <div class="input-control">USA</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-3 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Invoice Type:</label>
                                                                                <div class="input-control">Fatura</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-5 col-sm-12">
                                                                            <div class="form-group">
                                                                                <label>Payment Options Available:</label>
                                                                                <div class="input-control">2checkout and Stripe</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
        
                                                                <!-- euro-->
                                                                <!-- <div class="currency-container" id="euro" style="display: none;">
                                                                    <div class="row">
                                                                        <div class="col-md-4 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Currency Type:</label>
                                                                                <div class="input-control">Pound</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-8 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Tax Rates and Type:</label>
                                                                                <div class="input-control">NA</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-4 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Bank Account Location:</label>
                                                                                <div class="input-control">USA</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-3 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Invoice Type:</label>
                                                                                <div class="input-control">Invoice</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-5 col-sm-12">
                                                                            <div class="form-group">
                                                                                <label>Payment Options Available:</label>
                                                                                <div class="input-control">Bank Account Transfer, Paypal and Stripe</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> -->
        
                                                                <!-- usd-->
                                                                <div class="currency-container" id="usd" style="display: none;">
                                                                    <div class="row">
                                                                        <div class="col-md-4 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Currency Type:</label>
                                                                                <div class="input-control">USD</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-8 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Tax Rates and Type:</label>
                                                                                <div class="input-control">Not Applicable</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-4 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Bank Account Location:</label>
                                                                                <div class="input-control">USA</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-3 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label>Invoice Type:</label>
                                                                                <div class="input-control">Invoice</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-5 col-sm-12">
                                                                            <div class="form-group">
                                                                                <label>Payment Options Available:</label>
                                                                                <div class="input-control">Bank Account Transfer, Paypal and Stripe</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
        
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="sm-bottom">
                                                        <p>Need assistance with payment in your local currency? We're here to help.  Email us at <a href="mailto:support@trinka.ai">support@trinka.ai</a>.</p>
                                                    </div>
                                                </div>
                                                <div id="individual" class="tab-pane">
                                                    <div class="card-form">
                                                        <div class="contact_forms">
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <p class="tb-title">Payment for purchases is in USD* by default. If you require an invoice in another currency, please request it by filling the form below before making your purchase. 
                                                                    </p>
                                                                    <p class="tb-title">For already purchased subscription or credits, the invoice is available in your account section, under manage subscription and invoices.</p>
                                                                    <p class="tb-title"><em>*This is valid if the payment is made via Stripe.
                                                                    </em></p>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>    
                                            
                                            
                                        </div>
                                    </div>
                                    <!-- <div class="col-12">
                                        <div class="pro-top">
                                            <div class="pro-tip-content">
                                                <span><img src="../../../assets/images/tip.png" alt="tip"/></span>
                                                <p>Paying in your local currency can reduce currency and conversion charges, making it a cost-effective option.</p>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="section-pd notification-form">
        <div class="trinka_container">
            <div class="row">
                <div class="col-md-12">
                    <div class="spellcheck_heading">
                        <div class="row">
                            <div class="col-lg-12  col-12">
                                <div class="row">
                                    <div class="col-md-12 col-lg-12">
                                        <div class="trinka-title mb-0 text-center">
                                            <h2>Fill the Form to Get an Invoice</h2>
                                            <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row justify-content-center">
                                    <div class="col-lg-10 col-md-12">
                                        <div class="hero-frame">
                                            <iframe
                                            id="JotFormIFrame-230892642781059"
                                            title="Form"
                                            onload="window.parent.scrollTo(0,0)"
                                            allowtransparency="true"
                                            allowfullscreen="true"
                                            allow="geolocation; microphone; camera"
                                            src="https://form.jotform.com/231023594520043"
                                            frameborder="0"
                                            style="min-width:100%;max-width:100%;border:none;"
                                            scrolling="yes"
                                            >
                                            </iframe>
                                        </div>
                                        <!-- <div class="contactus_contents">
                                            <div class="contact_forms">
                                                <form [formGroup]="contactForm" (ngSubmit)="processForm()">
                                                    <div class="row">
                                                        
                                                        <div class="col-sm-6">
                                                            <div class="form-group"
                                                                [ngClass]="(!contactForm.get('contactFormName').valid && contactForm.get('contactFormName').dirty) || (contactForm.get('contactFormName').touched && contactForm.get('contactFormName').pristine) ? 'TextFiledError' : '' ">
                                                                <mat-form-field appearance="outline">
                                                                    <mat-label>Your name *</mat-label>
                                                                    <input id="contactUserName" matInput placeholder="" formControlName="contactFormName"
                                                                        class="form-control">
                                                                </mat-form-field>
                                                            </div>
                                                        </div>
                        
                                                        <div class="col-sm-6">
                                                            <div class="form-group"
                                                                [ngClass]="(!contactForm.get('contactFormEmail').valid && contactForm.get('contactFormEmail').dirty) || (contactForm.get('contactFormEmail').touched && contactForm.get('contactFormEmail').pristine) ? 'TextFiledError' : '' ">
                                                                <mat-form-field appearance="outline">
                                                                    <mat-label>Email *</mat-label>
                                                                    <input id="contactUserEmail" matInput placeholder="" formControlName="contactFormEmail"
                                                                        class="form-control">
                                                                </mat-form-field>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-6">
                                                            <div class="form-group"
                                                                [ngClass]="(!contactForm.get('contactFormContactNumber').valid && contactForm.get('contactFormContactNumber').dirty) || (contactForm.get('contactFormContactNumber').touched && contactForm.get('contactFormContactNumber').pristine) ? 'TextFiledError' : '' ">
                                                                <mat-form-field appearance="outline">
                                                                    <mat-label>Contact Number* (including ISD code)</mat-label>
                                                                    <input id="contactUserSubject" matInput placeholder="" formControlName="contactFormContactNumber"
                                                                        class="form-control">
                                                                </mat-form-field>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <div class="form-group"
                                                                [ngClass]="(!contactForm.get('contactFormOrgTitle').valid && contactForm.get('contactFormOrgTitle').dirty) || (contactForm.get('contactFormOrgTitle').touched && contactForm.get('contactFormOrgTitle').pristine) ? 'TextFiledError' : '' ">
                                                                <mat-form-field appearance="outline">
                                                                    <mat-label>Organization/Title *</mat-label>
                                                                    <input id="contactUserSubject" matInput placeholder="" formControlName="contactFormOrgTitle"
                                                                        class="form-control">
                                                                </mat-form-field>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="form-group select-group" [ngClass]="(contactForm.get('contactFormInquiryType').value === '' && contactForm.get('contactFormInquiryType').touched) ? 'TextFiledError' : ''">
                                                                <mat-form-field>
                                                                    <mat-label>Inquiry Type</mat-label>
                                                                    <select matNativeControl required formControlName="contactFormInquiryType">
                                                                        <option value="Billing/Invoice Support" selected>Billing/Invoice Support</option>
                                                                        <option value="Sales Enquiry">Sales Enquiry</option>
                                                                        <option value="Technical Support">Technical Support</option>
                                                                        <option value="General Inquiry">General Inquiry</option>
                                                                        <option value="Request a Demo">Request a Demo</option>
                                                                        <option value="License Enquiry">License Enquiry</option>
                                                                    </select>
                                                                </mat-form-field>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <div class="form-group textareaBox"
                                                                [ngClass]="(!contactForm.get('contactFormMessage').valid && contactForm.get('contactFormMessage').dirty) || (contactForm.get('contactFormMessage').touched && contactForm.get('contactFormMessage').pristine) ? 'TextFiledError' : '' ">
                                                                <mat-form-field appearance="outline">
                                                                    <mat-label>Your Message *</mat-label>
                                                                    <textarea id="contactUserMessage" matInput formControlName="contactFormMessage" placeholder=""
                                                                        class="form-control"></textarea>
                                                                </mat-form-field>
                                                            </div>
                                                        </div>
                        
                                                        <div class="col-sm-12">
                                                            <div class="form-group">
                                                                <button type="submit" id="contactUserSendMessage" class="mb-0">Send Message</button>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </form>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</div>