import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'predatoryFilter',
    pure: false
  })
  export class PredatoryFilterPipe implements PipeTransform {
    transform(items: any, filter: any): any {
      let currentfilter = filter["Hide predatory journals"].data;

      if (!items) return [];

      if (!currentfilter) return items;

      return items.filter(result => {return (result.predatory_journal != currentfilter.toString() || result.predatory_journal != currentfilter || result.predatory_journal == null)});
    }
  }
