import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-learn-more-section',
  templateUrl: './learn-more-section.component.html',
  styleUrls: ['./learn-more-section.component.scss']
})
export class LearnMoreSectionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
