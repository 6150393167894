<div class="limitExhaustPopup">
    <div class="limitExhaustPopupContainer">
        <div class="header-section">
            <div class="trinka-logo-n-cross">
                <img src="../../../assets/images/trinka-new-logo.svg" alt="trinka-ai-detector">
                <button mat-icon-button aria-label="close dialog" mat-dialog-close>
                    <img src="../../../assets/icons/times.svg" alt="close" />
                </button>
            </div>
            <h5>You have exhausted your daily limit.</h5>
        </div>
        <div class="body-section">
            Sign up for free to explore Trinka's wide variety of features such as <b>Grammar Checker, Proofread File, Paraphraser, Plagiarism Checker,</b> and many more!
        </div>
        <div class="footer-section">
            <button (click)="redirectToSignupPage()">Sign up</button>
        </div>
    </div>
</div>