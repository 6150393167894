import { Component, OnInit } from '@angular/core';
import { WebsiteService } from 'src/app/_services/website.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { $ } from 'protractor';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  email:any;
  footerFroms : FormGroup;
  subscribePopup: boolean = false;
  latestYear : number = new Date().getFullYear();
  constructor(
    private networkCall: WebsiteService,
    private toastr: ToastrService,
    private fb: FormBuilder,) {
      this.footerFroms = fb.group({
        'footeremailform': ['', Validators.compose([Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)])],
      })
     }

  ngOnInit() {
    // $("#emailSubcribeButton").removeClass('show');
  }
  dismissSubcribePopup(){
    this.subscribePopup = false;   
  }
  
  subscribeNow() {
    if (this.footerFroms.valid) {
      this.networkCall.postSubscribe(this.footerFroms.value.footeremailform).subscribe(result => {
        // this.toastr.success("Subscription successful.");
        this.subscribePopup = true;
        this.footerFroms.reset();
      }, error => {
        // this.shareService.errorHandller(error);
      })
    } else {
      // this.toastr.warning("Please enter the email address.");
      this.validateAllFormFields(this.footerFroms);
    }
  }
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }

}
