<section class="list-wrapper-style-2">
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-lg-12 col-md-12">
                <div class="trinka-title text-center mb-0">
                    <h2>Editing Modes to Suit Your Writing Needs</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <!-- <div class="LanguageToggleButton justify-content-center">
                    <div class="language-toggle-btn">
                        <p>English</p>
                        <label class="switch" style="margin:0 8px;">
                            <input type="checkbox" disabled>
                            <span class="slider-rd round"></span>
                        </label>
                        <p>Spanish <span class="coming-soon">Coming Soon</span></p>
                    </div>
                </div> -->
                <small class="d-block text-center" style="font-size: 12px;">*Available only for English</small>
            </div>
        </div>
        <div class="row align-items-center flex-row-reverse section-pd-sm">
            <div class="col-md-6">
                <div class="list-wrapper-content-2">
                    <h3>
                        Power Mode
                        <svg width="16" height="28" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.01611 1.20061C2.04048 1.11638 2.09107 1.04244 2.16029 0.989856C2.22951 0.937275 2.31364 0.908884 2.40008 0.908936H5.59988C5.66322 0.908984 5.72565 0.924312 5.78201 0.953656C5.83838 0.983001 5.88708 1.02552 5.92411 1.07772C5.96113 1.12992 5.98542 1.19031 5.99497 1.2539C6.00453 1.3175 5.99907 1.38249 5.97905 1.44353L4.55514 5.78365H7.59975C7.67465 5.78359 7.74806 5.8049 7.81162 5.84515C7.87519 5.88539 7.92635 5.94296 7.95927 6.01128C7.9922 6.07961 8.00558 6.15595 7.99788 6.23163C7.99018 6.30729 7.96172 6.37925 7.91573 6.43929L2.31609 13.7514C2.25872 13.8266 2.17716 13.879 2.08562 13.8995C1.99409 13.9199 1.8984 13.907 1.81525 13.8631C1.73211 13.8192 1.66678 13.747 1.63068 13.6592C1.59458 13.5713 1.59 13.4734 1.61773 13.3825L3.05844 8.62723H0.400209C0.338024 8.62726 0.276688 8.61257 0.221071 8.58432C0.165454 8.55608 0.117088 8.51505 0.0798152 8.46449C0.0425419 8.41394 0.0173879 8.35526 0.0063507 8.2931C-0.00468647 8.23095 -0.00130278 8.16704 0.0162332 8.10644L2.01611 1.20061Z" fill="#FDB933"/>
                        </svg>
                    </h3>
                    <p>Corrects advanced grammar and technical spelling mistakes, and provides language enhancements to bring clarity and fluency to your writing.</p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="grammar-checker-eg">
                    <div class="powermode">
                        <div class="media">
                            <div class="media-body">
                                <p>In recent years, air <span class="wrongText">pollutions</span> <span>pollution</span> <span class="wrongText">are</span>  <span>has become</span> <span class="wrongText">more and more</span> <span>increasingly</span> serious <span class="wrongText">with</span> <span>because of</span> <span class="wrongText">the development of</span> <span>advances in</span> technology and industry.</p>
                            </div>
                        </div>
                        <small><span>*</span> Corrections and enhancements</small>
                    </div>
                </div>
            </div>
        </div>
        <div class="row align-items-center section-pd-sm pb-0 pt-0">
            <div class="col-md-6">
                <div class="list-wrapper-content-2 pr-80">
                    <h3>
                        Lite Mode 
                        <svg width="22" height="22" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 13.1811L2.36198 11.8191L3.72395 13.1811L5.08593 11.8191L6.4479 13.1811L7.80988 11.8191L9.17186 13.1811L10.8743 11.8191L12.2363 13.1811" stroke="#647087" stroke-width="0.680988" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12.2366 3.72861L11.5556 3.04762L10.1937 1.69282M10.1937 1.69282L4.06478 7.85267L3.38379 10.5385L6.10774 9.8575L12.5771 3.38811C12.7113 3.25398 12.8177 3.09474 12.8903 2.91947C12.963 2.7442 13.0003 2.55635 13.0003 2.36663C13.0003 2.17692 12.963 1.98906 12.8903 1.81379C12.8177 1.63852 12.7113 1.47928 12.5771 1.34515L12.4872 1.26275C12.2108 1.02961 11.8568 0.908942 11.4955 0.924711C11.1341 0.940481 10.792 1.09153 10.5369 1.34787L10.1937 1.69282Z" stroke="#647087" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </h3>
                    <p>Focuses on correcting basic grammar, spelling, and punctuation mistakes to keep your writing accurate.</p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="grammar-checker-eg">
                    <div class="lightmode">
                        <div class="media">
                            <div class="media-body">
                                <p>In recent years, air <span class="wrongText">pollutions</span> <span>pollution</span> <span class="wrongText">are</span>  <span>has become</span> more and more serious with the development of technology and industry.</p>
                            </div>
                        </div>
                        <small><span>*</span> Only corrections</small>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>