import { Directive, Input, ElementRef } from '@angular/core';

@Directive({
  selector: '[appHighlightKeywords]'
})

export class HighlightKeywordsDirective {
  @Input() phrase: string;
  @Input() keywords: any;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.el.nativeElement.innerHTML = this.highlight(this.phrase, this.keywords);
  }

  private highlight(phrase, matchKeywords) {
    if(!matchKeywords.length) {
      return phrase;
    }

    matchKeywords.slice().reverse().forEach(keyword => {
      phrase = [
        phrase.slice(0, keyword.start_index),
        '<span class="purple-highlighted">',
        phrase.slice(keyword.start_index, keyword.end_index),
        '</span>',
        phrase.slice(keyword.end_index, phrase.length)
      ].join('');
    });

    return phrase;
  }
}
