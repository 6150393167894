import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { WebsiteService } from 'src/app/_services/website.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-feedback-modal',
  templateUrl: './feedback-modal.component.html',
  styleUrls: ['./feedback-modal.component.scss']
})
export class FeedbackModalComponent {
  feedbackText: string = '';
  ratingFeedback: number = 0;
  ratings: number[] = [1, 2, 3, 4, 5];
  feedbackForm!: FormGroup;
  feedbackSubmitted: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<FeedbackModalComponent>,
    private _appService: WebsiteService,
    private spinner: NgxSpinnerService,
    private _toast: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.feedbackForm = new FormGroup({
      feedbackText: new FormControl('')
    });

    if(!this.data) {
      this.data = {};
    }
    if(!this.data.feature) {
      this.data.feature = 'paraphraser';
    }
  }

  ngOnInit(): void { }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  onSubmitClick(): void {
    if (this.feedbackForm.valid) {
      const payload = {
        "feedback_text": this.feedbackForm.get('feedbackText').value,
        "rating": this.ratingFeedback
      };

      this._appService.submitFeedbackCall(this.data.feature, payload, {
        language: this.data.language ? this.data.language : null
      }).subscribe({
        next: (response: any) => {
          if (response.msg) {
            this.feedbackSubmitted = true;
          } else {
            this.feedbackSubmitted = false;
          }
        },
        complete: () => { },
        error: (err: any) => {
          this.feedbackSubmitted = false;
          this._toast.error("Failed to submit feedback!");
        }
      })
    } else {
      this.validateAllFormFields(this.feedbackForm);
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  cancelEvent() { }
}
