<div class="trinka_cloud_wrap">
    <div id="particles-js"></div>
    <div class="cloud_top_banners">
        <!-- <div class="container">
            <div class="row">
                <div class="col-lg-8">
                    <div class="trinka_cloud_banner_text">
                        <h1>Trinka AI Understands
                            <br />Medical Writing</h1>
                        <p>Trinka's powerful AI recognizes the subtleties and technical precision required in medical and scientific
                            writing and transforms your writing to make it more engaging, formal, and publish ready.
                        </p>
                        <button style="margin: 0; padding: 0;" (click)="sectionValue('Enterprise Form')" id="salesContactButtons" data-toggle="modal"
                            data-target="#EnterPriseContactPopup" class="plansButtons" type="submit">Get a Quote</button>
                            <a href="https://calendly.com/trinkaai/meet" target="_blank"  id="autoEditGetStart" class="cta-outline-btn m25">Book a Demo</a>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="trinka_cloud_banner_img">
                        <img src="../../../assets/images/medical.png" alt="Trinka enterprise" class="img-fluid">
                    </div>
                </div>
            </div>
        </div> -->
        <section class="mainTopBanner">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="topBannerWrapper">
                            <div class="top-banner-left">
                                <h1>Life Sciences, Medicine & Pharma</h1>
                                <h2>Trinka AI Understands Medical Writing</h2>
                                <p>Trinka's powerful AI recognizes the subtleties and technical precision required in medical and scientific
                                    writing and transforms your writing to make it more engaging, formal, and publish ready.
                                </p>
                                    <div class="btn-group">
                                        <button style="margin: 0; padding: 0;" (click)="sectionValue('Enterprise Form')" id="salesContactButtons" data-toggle="modal"
                                        data-target="#EnterPriseContactPopup" class="filled-btn" type="submit">Contact Us</button>
                                        <a href="https://calendly.com/trinkaai/meet" target="_blank"  id="autoEditGetStart" class="outline-btn">Book a Demo</a>
                                    </div>
                            </div>
                            <div class="top-banner-right">
                                <img src="../../../assets/images/medical.png" alt="Trinka enterprise" class="img-fluid md50">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

    <section class="section-pd trinka-key-features">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-11 col-lg-10">
                    <div class="trinka-title mb-0 text-center">
                        <h2>Tailored Solutions for Organizations in</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="gt-features three-coloum">
                        <span class="hr-line d-sm-block d-none"></span>
                        <span class="vr-line l25"></span>
                        <span class="vr-line l50 d-sm-none"></span>
                        <span class="vr-line l75"></span>
                        <div class="gt-wrapper c-card">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <circle cx="10" cy="10" r="10" fill="#763393"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8277 6.67085C15.0574 6.89866 15.0574 7.26801 14.8277 7.49581L8.94536 13.3291C8.71564 13.557 8.34319 13.557 8.11347 13.3291L5.17229 10.4125C4.94257 10.1847 4.94257 9.81533 5.17229 9.58752C5.40201 9.35972 5.77446 9.35972 6.00418 9.58752L8.52941 12.0917L13.9958 6.67085C14.2255 6.44305 14.598 6.44305 14.8277 6.67085Z" fill="white"/>
                            </svg>
                            <h6>Medical devices & Pharmaceuticals
                            </h6>
                        </div>
                        <div class="gt-wrapper c-card">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <circle cx="10" cy="10" r="10" fill="#763393"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8277 6.67085C15.0574 6.89866 15.0574 7.26801 14.8277 7.49581L8.94536 13.3291C8.71564 13.557 8.34319 13.557 8.11347 13.3291L5.17229 10.4125C4.94257 10.1847 4.94257 9.81533 5.17229 9.58752C5.40201 9.35972 5.77446 9.35972 6.00418 9.58752L8.52941 12.0917L13.9958 6.67085C14.2255 6.44305 14.598 6.44305 14.8277 6.67085Z" fill="white"/>
                            </svg>
                            <h6>Biotechnology & <br/>Life sciences</h6>
                        </div>
                        <div class="gt-wrapper c-card">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <circle cx="10" cy="10" r="10" fill="#763393"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8277 6.67085C15.0574 6.89866 15.0574 7.26801 14.8277 7.49581L8.94536 13.3291C8.71564 13.557 8.34319 13.557 8.11347 13.3291L5.17229 10.4125C4.94257 10.1847 4.94257 9.81533 5.17229 9.58752C5.40201 9.35972 5.77446 9.35972 6.00418 9.58752L8.52941 12.0917L13.9958 6.67085C14.2255 6.44305 14.598 6.44305 14.8277 6.67085Z" fill="white"/>
                            </svg>
                            <h6>Outsourced<br/> Research</h6>
                        </div>
                        <div class="gt-wrapper c-card">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <circle cx="10" cy="10" r="10" fill="#763393"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8277 6.67085C15.0574 6.89866 15.0574 7.26801 14.8277 7.49581L8.94536 13.3291C8.71564 13.557 8.34319 13.557 8.11347 13.3291L5.17229 10.4125C4.94257 10.1847 4.94257 9.81533 5.17229 9.58752C5.40201 9.35972 5.77446 9.35972 6.00418 9.58752L8.52941 12.0917L13.9958 6.67085C14.2255 6.44305 14.598 6.44305 14.8277 6.67085Z" fill="white"/>
                              </svg>
                            <h6>Medical & Scientific Publishing</h6>
                        </div>
                        <div class="gt-wrapper c-card">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <circle cx="10" cy="10" r="10" fill="#763393"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8277 6.67085C15.0574 6.89866 15.0574 7.26801 14.8277 7.49581L8.94536 13.3291C8.71564 13.557 8.34319 13.557 8.11347 13.3291L5.17229 10.4125C4.94257 10.1847 4.94257 9.81533 5.17229 9.58752C5.40201 9.35972 5.77446 9.35972 6.00418 9.58752L8.52941 12.0917L13.9958 6.67085C14.2255 6.44305 14.598 6.44305 14.8277 6.67085Z" fill="white"/>
                              </svg>
                            <h6>Regulation & Pharmacovigilance</h6>
                        </div>
                        <div class="gt-wrapper c-card">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <circle cx="10" cy="10" r="10" fill="#763393"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8277 6.67085C15.0574 6.89866 15.0574 7.26801 14.8277 7.49581L8.94536 13.3291C8.71564 13.557 8.34319 13.557 8.11347 13.3291L5.17229 10.4125C4.94257 10.1847 4.94257 9.81533 5.17229 9.58752C5.40201 9.35972 5.77446 9.35972 6.00418 9.58752L8.52941 12.0917L13.9958 6.67085C14.2255 6.44305 14.598 6.44305 14.8277 6.67085Z" fill="white"/>
                              </svg>
                            <h6>Medical Communications & Marketing</h6>
                        </div>
                    </div>            
                </div>
            </div>
        </div>
    </section>

    <div class="brandLogo_sections">
        <div class="container">
            <div class="row">
                <div class="col-sm-2">
                    <h2>Trusted by Global Leaders</h2>
                </div>
                <div class="col-sm-10">
                    <div class="cover_wrapper">
                        <div id="client-logos-enterprise" class="owl-carousel">
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/j-j.png" alt="leaders">
                                </div>
                            </div>

                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/cigna.png" alt="leaders">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/novartis.png" alt="leaders">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/merck.png" alt="leaders">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/abbvie.png" alt="leaders">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/amgen.png" alt="leaders">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/biogen.png" alt="leaders">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/who.png" alt="leaders">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/accelera.png" alt="leaders">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/client_logos/pfizer.png" alt="leaders">
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="section-pd list-wrapper-style-2">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-11 col-lg-10">
                    <div class="trinka-title mb-0 text-center">
                        <h2>High-quality Writing Assistance<br class="d-lg-block d-none"/> for Your Teams</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                        <p>Trinka assists your teams in creating critical documents efficiently while ensuring quality and compliance
                            with scientific writing conventions.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards flex-title  bt-none">
                        <div class="features_cards_icon">
                            <img src="../../../assets/images/MedicalSpellCheckicon.svg" alt="medical spell" srcset="">
                        </div>
                        <div class="features_cards_desc">
                            <h3>Medical Spell Check and Dictionary</h3>
                            <p>Trinka's in-built medical dictionary has thousands of terms from all the fields of medicine.
                                You can also add your organization's lexicon and new terms to stay on brand across
                                all communications & personalize your writing assistant.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards flex-title">
                        <div class="features_cards_icon">
                            <img src="../../../assets/images/StyleGuides.png" alt="style guide" srcset="style guide">
                        </div>
                        <div class="features_cards_desc">
                            <h3>In-built and Custom Style Guides</h3>
                            <p>Trinka makes complying with technical style guides, like AMA & APA, a breeze with corrections
                                for all writing preferences in a few clicks. Standardize all your communication across
                                teams with custom brand style guides too.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards flex-title  bt-1">
                        <div class="features_cards_icon">
                            <img src="../../../assets/images/PublicationReadiness.svg" alt="publication readiness" srcset="">
                        </div>
                        <div class="features_cards_desc">
                            <h3>Technical Checks</h3>
                            <p>Trinka checks research papers against 25+ publication readiness requirements to help
                                your teams publish faster, letting you strengthen your brand’s research portfolio.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards flex-title  bt-1">
                        <div class="features_cards_icon">
                            <img src="../../../assets/images/ppc_icon.png" width="40" height="40" alt="ppc" srcset="">
                        </div>
                        <div class="features_cards_desc">
                            <h3>Plagiarism Checker </h3>
                            <p>Highest quality of <a [routerLink]="['/features/plagiarism-check']" class="text-link">plagiarism check</a> with the most advanced text similarity detection algorithm, iThenticate and the  largest paid publication database.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards flex-title  bt-1">
                        <div class="features_cards_icon">
                            <img src="../../../assets/images/BulkEditingOperations.svg" alt="bulk editing" srcset="">
                        </div>
                        <div class="features_cards_desc">
                            <h3>Bulk Editing<br class="d-lg-block d-none"/>Operations</h3>
                            <p>Upload your documents in MS Word format and sit back while Trinka edits them in minutes.
                                Just download the edited files with all the suggestions applied in tracked changes
                                to review and finalize.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards flex-title  bt-1">
                        <div class="features_cards_icon">
                            <img src="../../../assets/images/ComprehensiveAnalytics.svg" alt="comprehensive" srcset="">
                        </div>
                        <div class="features_cards_desc">
                            <h3>Comprehensive Analytics</h3>
                            <p>See how your teams use Trinka or deep-dive into each user’s activity with rich analytics
                                for effective decision making.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards flex-title  bt-1">
                        <div class="features_cards_icon">
                            <img src="../../../assets/images/EasyUserManagement.svg" alt="easy use" srcset="">
                        </div>
                        <div class="features_cards_desc">
                            <h3>Easy User Management</h3>
                            <p>Your team account allows you to add or remove members easily. Manage credits and usage limits in real-time. Access management is easy and secure with Trinka’s granular controls – be it your teams or outsourced talent.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards flex-title  bt-1">
                        <div class="features_cards_icon">
                            <img src="../../../assets/images/DedicatedSupport.svg" alt="dedicated support" srcset="">
                        </div>
                        <div class="features_cards_desc">
                            <h3>Dedicated<br class="d-lg-block d-none"/>Support</h3>
                            <p>A dedicated Success Manager is assigned to support your team to get the most out of Trinka.
                                This includes a detailed on-boarding call, help documentation, and video walk-throughs.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
                    

        </div>
    </div>
</div>

<div class="whiteListing section-pd">
    <div class="trinka_container">
        <div class="row align-items-center" style="margin-bottom: 40px;">
            <div class="col-md-8 col-sm-8">
                <div class="trinka-title mb-0">
                    <h2>IP Whitelisting</h2>
                    <div class="HeadingSubLine1"><span></span><span></span><span></span></div>
                    <p>IP whitelisting is a security feature to help protect sensitive data and systems from unauthorized access. It is beneficial for your business as it can enhance security by controlling who can view those resources, complying with regulations, improving productivity, and saving money. IP whitelisting is achieved by limiting access only to specific IP addresses from a trusted source.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-4">
                <div class="benefitImage">
                    <img src="../../../assets/images/the-benefit-whitepaper.png" width="200" alt="The benefits" class="img-fluid" />
                </div>
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-8 col-sm-12">
                <div class="trinka-title mb-0">
                    <h3 class="mb-2">Benefits</h3>
                    <p>Our IP whitelisting security feature allows access to Trinka only from specified IP addresses and below are the benefits.</p>
                </div>
            </div>
            
        </div>
        <div class="row match-box">
            <div class="col-sm-6 dflex">
                <div class="media">
                    <div class="media-body">
                        <h4>Enhanced security <span><img src="../../../assets/images/icons/cyber-security.png" alt="Enhanced security" /></span></h4>
                        <p>Trinka can be accessed only from trusted IP addresses, reducing the risk of unauthorized access and cyber attacks</p>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 dflex">
                <div class="media">
                    <div class="media-body">
                        <h4>Controlled Access <span><img src="../../../assets/images/icons/browser.png" alt="Controlled Access" /></span></h4>
                        <p>Administrators have granular control of who can access Trinka by quickly blocking or unblocking access to specific IP addresses</p>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 dflex">
                <div class="media">
                    <div class="media-body">
                        <h4>Reduced downtime <span><img src="../../../assets/images/icons/clock.png" alt="Reduced downtime" /></span></h4>
                        <p>IP whitelisting controls and reduces the risk of denial-of-service (DoS) attacks and stoppages that can affect genuine users</p>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 dflex">
                <div class="media">
                    <div class="media-body">
                        <h4>Better compliance <span><img src="../../../assets/images/icons/growth.png" alt="Better compliance" /></span></h4>
                        <p>Allows organizations to meet strict industry regulatory compliances, especially where IP whitelisting is a mandate.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="list-wrapper-style-2">
    <div class="trinka_container">
        <div class="row">
            <div class="col-12">
                <div class="section-usecases section-purple rounded-sm">
                    <div class="row justify-content-center">
                        <div class="col-md-11 col-lg-10">
                            <div class="trinka-title mb-0 text-center">
                                <h2>Assistance for All Use Cases</h2>
                                <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                                <p>Discover the true power of Trinka for medical writing with the help of use cases tailored to specific
                                    industries and roles.</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="list-wrapper-content-2 list-style-cards  bt-none">
                                <h3>Research Paper<br class="d-lg-block d-none"/>Writing</h3>
                                <p>Trinka’ s writing assistance is tailored for research papers and communications.</p>
                                <ul>
                                    <li>Subject-specific terminology corrections</li>
                                    <li>Style guide preferences</li>
                                    <li>Publication readiness checks</li>
                                    <li>Journal scope match</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="list-wrapper-content-2 list-style-cards">
                                <h3>Scientific Dictionary & Brand Lexicon</h3>
                                <p>Trinka helps your organization standardize communications and stay on brand. </p>
                                <ul>
                                    <li>Personalized team or organization dictionaries</li>
                                    <li>Accurate error detection & correction for scientific terms</li>

                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="list-wrapper-content-2 list-style-cards bt-1">
                                <h3>Effective Brand Communication </h3>
                                <p>Help your teams reach the target audience with ease while staying on brand.</p>
                                <ul>
                                    <li>Improves tone and clarity</li>
                                    <li>Technical phrasing improvements</li>
                                    <li>Eliminates fragmented writing</li>
                                    <li>Customized brand style guides</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="list-wrapper-content-2 list-style-cards bt-1">
                                <h3>Reviewing Research Papers</h3>
                                <p>Build your research portfolio with ease with publication-focused features.</p>
                                <ul>
                                    <li>Language quality checks</li>
                                    <li>Consistency checks</li>
                                    <li>Publication readiness checks</li>
                                    <li>Bulk editing options</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<div class="cloud_key_features section-pd list-wrapper-style-2 tailoredSolutions">
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-md-11 col-lg-10">
                <div class="trinka-title mb-0 text-center">
                    <h2>Integrate with Your Proprietary Software and Workflows</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    <p>Trinka's easy integrations let your teams enjoy all the benefits right within your existing software, making
                        effective writing productive and seamless.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards bt-none">
                    <h3>JavaScript</h3>

                    <p>
                        Enable Trinka for all your team members by
                        <span>simply inserting a small JavaScript (JS) code snippet</span> into your browser or any application.
                    </p>

                    <a  [routerLink]="['/enterprise']" fragment="javascript" class="text-link">
                        Read More
                        <i class="fa fa-arrow-right"></i>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards">
                    <h3>API</h3>
                    <p>
                        Trinka’s powerful API brings Trinka
                        <span>right into your existing applications.</span> With a simple API architecture, it's easy to integrate.</p>

                    <a [routerLink]="['/enterprise/grammar-checker-api']" class="text-link">
                        Read More
                        <i class="fa fa-arrow-right"></i>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards">
                    <h3>On Premise</h3>
                    <p>Ensure effective writing and maximum confidentiality with
                        <span>Trinka hosted on your enterprise infrastructure, be it local or cloud servers.</span>
                    </p>

                    <a [routerLink]="['/enterprise']" fragment="onpremise" class="text-link">
                        Read More
                        <i class="fa fa-arrow-right"></i>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards bt-1" style="min-height: 290px;">
                    <h3>Cloud Editor</h3>
                    <p>
                        Use Trinka's full potential anywhere, with the
                        <span>secured web-based application.</span>
                    </p>
                    <a [routerLink]="['/trinkacloud']" class="text-link">
                        Read More
                        <i class="fa fa-arrow-right"></i>
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards bt-1" style="min-height: 290px;">
                    <h3>MS Word Add-in</h3>
                    <p>
                        Increase your teams’ productivity with writing suggestions delivered
                        <span>within MS Word.</span>
                    </p>
                    <a  [routerLink]="['/wordplugin']" class="text-link">
                        Read More
                        <i class="fa fa-arrow-right"></i>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="list-wrapper-content-2 list-style-cards bt-1" style="min-height: 290px;">
                    <h3>Browser Plug-ins</h3>
                    <p>
                        Real-time writing assistance on any website through
                        <span>plugins for all your favorite browsers.</span>
                    </p>
                    <a  [routerLink]="['/browser-plugin']" class="text-link">
                        Read More
                        <i class="fa fa-arrow-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="section-pd section-purple">
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-md-12 col-lg-12">
                <div class="trinka-title text-center">
                    <h2>Personalized Pricing that Works for You</h2>
                    <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    <p>Trinka offers flexible per-user or usage based pricing to suit your business needs.
                        <br>Switch users and apply usage restrictions in real time, at no extra cost.
                        <br>We'll work with you to create a hassle-free custom contract and single payment option via PO/invoice.</p>
                    <div class="btn-group justify-content-center mt-4">
                        <button style="margin: 0; padding: 0;" (click)="sectionValue('Enterprise Form')" id="salesContactButtons" data-toggle="modal"
                        data-target="#EnterPriseContactPopup" class="filled-btn" type="submit">Get a Quote</button>
                        <a href="https://calendly.com/trinkaai/meet" target="_blank"  id="autoEditGetStart" class="outline-btn">Book a Demo</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="trinka-banner section-pd-sm">
    <div class="trinka_container">
        <div class="row align-items-center justify-content-center">
            <div class="col-md-6 col-lg-6">
                <div class="trinka-title">
                    <h3 class="text-white mb-4">Trinka Simplifies Invoices with Support for Local Currency.</h3>
                    <p class="text-white">Trinka offers seamless invoices  (Seikyu-sho, Fapiao, Yeongeumjeung, Fatura) in multiple local currencies of JPY, CNY, BRL, TRY, KRW, INR, USD, TWD. Organizations can also ease the invoicing process  by  clubbing multiple accounts into a single invoice in their own local currency.  </p>
                </div>
                <div class="btn-group">
                    <a [routerLink]="['/local-invoice']" class="white-btn">Check Invoice Options</a>
                </div>
            </div>
            <div class="col-md-6 col-lg-6">
                <div class="text-center">
                    <img src="../../../assets/images/local-inovice-element.png" alt="local invoice" class="img-fluid" />
                </div>
            </div>
        </div>  
    </div>
</div>

<section class="section-pd list-wrapper-style-2">
    <div class="trinka_container">
        <div class="row align-items-center flex-row-reverse">
            <div class="col-md-6">
                <div class="list-wrapper-content-2">
                    <h3>Safe, Secure, and Trusted</h3>
                        <p>Trinka puts data safety and privacy at the forefront. All your data is strongly encrypted and securely
                            stored - no one else has access to your data. We offer unique plans that completely eliminate
                            saving any data once you receive writing suggestions.
                        </p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="list-wrapper-image">
                    <img src="../../../assets/images/safe-secure.png" alt="safe" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="section-pd list-wrapper-style-2 pt-0">
    <div class="trinka_container">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div class="list-wrapper-content-2 pr-80">
                    <h3>About Trinka</h3>
                    <p>Trinka uses the latest Machine Learning (ML) and Natural Language Processing (NLP) technologies to
                        correct grammar errors and suggest language enhancements. Our AI is trained on millions of well-written
                        papers and articles covering 1300+ subject areas including medical, life sciences, physical sciences,
                        engineering, humanities, business, and arts to give you the most relevant suggestions.</p>
                    <p>Trinka is developed by a team of linguists, editors, data scientists, and engineers with a deep desire
                        to create a future with barrier-free communication.
                    </p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="list-wrapper-image">
                    <img src="../../../assets/images/about-ai.png" alt="safe" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
</section>


<div class="trinka-banner section-pd-sm">
    <div class="trinka_container">
        <div class="row justify-content-center">
            <div class="col-md-10 col-lg-8">
                <div class="trinka-title text-center">
                    <h3 class="text-white">Transform Your Business Communication with Trinka</h3>
                </div>
                <div class="btn-group justify-content-center">
                    <button (click)="sectionValue('Enterprise Form')" id="salesContactButtons" data-toggle="modal" data-target="#EnterPriseContactPopup"
                    class="white-btn" type="submit">Get a Quote</button>
                </div>
            </div>
        </div>  
    </div>
</div>



<div class="modal custom_modal_popup enterprisePopup" id="EnterPriseContactPopup">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-body">
                <div class="closed_icons">
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="contactPopupHeading">
                            <h2>Enterprise Inquiry</h2>
                            <p *ngIf="formSection">Whom are you inquiring for?</p>
                        </div>
                    </div>
                    <div class="inquiryTypeSection">
                        <ul class="nav nav-tabs" role="tablist" *ngIf="formSection">
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#IndividualType" role="tab">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                                        <defs>
                                            <style>
                                                .cls-1 {
                                                    fill: none;
                                                    stroke: #783694;
                                                    stroke-linecap: round;
                                                    stroke-linejoin: round;
                                                    stroke-width: 2px;
                                                }
                                            </style>
                                        </defs>
                                        <title>TrinkaIcons-new</title>
                                        <g id="Individual">
                                            <path class="cls-1" d="M44.83,45.13V39.82a4.65,4.65,0,0,0-1.7-3.6,62.85,62.85,0,0,0-10.89-7V22.5a4.4,4.4,0,0,0,1.56-3.36V11.39A7.9,7.9,0,0,0,25.91,3.5H24.24a7.9,7.9,0,0,0-7.89,7.89v7.75a4.32,4.32,0,0,0,1.54,3.36h0v6.68A62.22,62.22,0,0,0,7,36.22a4.62,4.62,0,0,0-1.7,3.6v5.31"
                                            />
                                        </g>
                                    </svg>
                                    <div class="text-left">
                                        <h3>Individual</h3>
                                        <span>(Single user)</span>
                                    </div>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#OrganizationType" role="tab">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                                        <defs>
                                            <style>
                                                .cls-1 {
                                                    fill: none;
                                                    stroke: #783694;
                                                    stroke-linecap: round;
                                                    stroke-linejoin: round;
                                                    stroke-width: 2px;
                                                }
                                            </style>
                                        </defs>
                                        <title>TrinkaIcons-new</title>
                                        <g id="Organization">
                                            <path class="cls-1" d="M42.87,45.13V40.06a4.45,4.45,0,0,0-1.62-3.44,60,60,0,0,0-10.41-6.72V23.51a4.2,4.2,0,0,0,1.49-3.21V12.89a7.55,7.55,0,0,0-7.54-7.54H23.19a7.55,7.55,0,0,0-7.54,7.54V20.3a4.13,4.13,0,0,0,1.47,3.21h0v6.38A59.44,59.44,0,0,0,6.71,36.62a4.42,4.42,0,0,0-1.62,3.44v5.07"
                                            />
                                            <path class="cls-1" d="M14.34,30.75a24.56,24.56,0,0,0-3.32-2V26.18a1.66,1.66,0,0,0,.59-1.27V22a3,3,0,0,0-3-3H8a3,3,0,0,0-3,3v2.93a1.63,1.63,0,0,0,.58,1.27h0v2.53a23.54,23.54,0,0,0-4.12,2.66,1.75,1.75,0,0,0-.64,1.36v2"
                                            />
                                            <path class="cls-1" d="M34.29,30.75a24.56,24.56,0,0,1,3.32-2V26.18A1.66,1.66,0,0,1,37,24.91V22a3,3,0,0,1,3-3h.63a3,3,0,0,1,3,3v2.93A1.63,1.63,0,0,1,43,26.18h0v2.53a23.54,23.54,0,0,1,4.12,2.66,1.75,1.75,0,0,1,.64,1.36v2"
                                            />
                                        </g>
                                    </svg>
                                    <div class="text-left">
                                        <h3>Organization</h3>
                                        <span>(Multiple users)</span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content">
                            <div class="tab-pane" id="IndividualType" role="tabpanel" *ngIf="formSection">
                                <div class="IndividualTypeSection">
                                    <p>Trinka is free for individual users.
                                        <br /> You can simply register with your email address.</p>
                                    <a href="https://cloud.trinka.ai/signup" target="_blank">Register for Free</a>
                                </div>
                            </div>
                            <div class="tab-pane" id="OrganizationType" role="tabpanel" *ngIf="formSection">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="contact_us_form">
                                            <form [formGroup]="contactForm" (ngSubmit)="sendContactUsForm()">
                                                <h2>Share your requirements with us and our team will respond to you promptly.</h2>
                                                <div class="row">
                                                    <div class="col-md-6 rightPaddingRemove">
                                                        <div class="textbox_content form-group" [ngClass]="(!contactForm.get('NatureBusinessType').valid && contactForm.get('NatureBusinessType').dirty) || (contactForm.get('NatureBusinessType').touched && contactForm.get('NatureBusinessType').pristine) ? 'TextFiledError' : '' ">
                                                            <mat-form-field appearance="outline">
                                                                <mat-label>Nature of business*</mat-label>
                                                                <input matInput placeholder="" formControlName="NatureBusinessType" class="form-control">
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 leftPaddingRemove">
                                                        <div class="textbox_content form-group" [ngClass]="(!contactForm.get('contactFormSubjects').valid && contactForm.get('contactFormSubjects').dirty) || (contactForm.get('contactFormSubjects').touched && contactForm.get('contactFormSubjects').pristine) ? 'TextFiledError' : '' ">
                                                            <mat-form-field appearance="outline">
                                                                <mat-label>Organization name*</mat-label>
                                                                <input matInput placeholder="" formControlName="contactFormSubjects" class="form-control">
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="textbox_content form-group textareaBox" [ngClass]="(!contactForm.get('contactFormMessage').valid && contactForm.get('contactFormMessage').dirty) || (contactForm.get('contactFormMessage').touched && contactForm.get('contactFormMessage').pristine) ? 'TextFiledError' : '' ">
                                                            <label for="">Tell us a bit about your requirement</label>
                                                            <textarea formControlName="contactFormMessage" class="form-control" placeholder="E.g. approximare number of users, monthly word count volume per user, customizations needed, etc."></textarea>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6 rightPaddingRemove">
                                                        <div class="textbox_content form-group" [ngClass]="(!contactForm.get('contactFormName').valid && contactForm.get('contactFormName').dirty) || (contactForm.get('contactFormName').touched && contactForm.get('contactFormName').pristine) ? 'TextFiledError' : '' ">
                                                            <mat-form-field appearance="outline">
                                                                <mat-label>Name*</mat-label>
                                                                <input matInput placeholder="" formControlName="contactFormName" class="form-control">
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 leftPaddingRemove">
                                                        <div class="textbox_content form-group" [ngClass]="(!contactForm.get('DesignationTypes').valid && contactForm.get('DesignationTypes').dirty) || (contactForm.get('DesignationTypes').touched && contactForm.get('DesignationTypes').pristine) ? 'TextFiledError' : '' ">
                                                            <mat-form-field appearance="outline">
                                                                <mat-label>Designation*</mat-label>
                                                                <input matInput placeholder="" formControlName="DesignationTypes" class="form-control">
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 rightPaddingRemove">
                                                        <div class="textbox_content form-group" [ngClass]="(!contactForm.get('contactFormEmail').valid && contactForm.get('contactFormEmail').dirty) || (contactForm.get('contactFormEmail').touched && contactForm.get('contactFormEmail').pristine) ? 'TextFiledError' : '' ">
                                                            <mat-form-field appearance="outline">
                                                                <mat-label>Email*</mat-label>
                                                                <input matInput placeholder="" formControlName="contactFormEmail" class="form-control">
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 leftPaddingRemove">
                                                        <div class="textbox_content form-group" [ngClass]="(!contactForm.get('contactphoneNo').valid && contactForm.get('contactphoneNo').dirty) || (contactForm.get('contactphoneNo').touched && contactForm.get('contactphoneNo').pristine) ? 'TextFiledError' : '' ">
                                                            <mat-form-field appearance="outline">
                                                                <mat-label>Contact Number* (incl. ISD code)</mat-label>
                                                                <input type="number" matInput placeholder="" formControlName="contactphoneNo" class="form-control">
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group text-center">
                                                    <button type="submit" [disabled]="isSubmitting">Submit</button>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="thank_section contact_us_form" *ngIf="thankSection">
                                <div class="form-group">
                                    <h2>Thank You!</h2>
                                    <p>We will contact you soon.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>