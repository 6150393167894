import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'impactFactorFilter',
  pure: false
})
export class ImpactFactorPipe implements PipeTransform {

  // transform(items: any, filter: any): any {

  //   let currentfilter = filter["Impact Factor"].data;

  //   if (!items) return [];

  //   if (currentfilter.length == 0) return items;

  //   let start, end;
  //   var data = [];
  //   for(let i=0;i<currentfilter.length; i++)
  //   {
  //     start = Number(currentfilter[i].split("-",2)[0].trim());
  //     end = Number(currentfilter[i].split("-",2)[1].trim());

  //     items.filter(result => {
  //         if(result.impact_factor>= start && result.impact_factor<=end){
  //           data.push(result);
  //           return result;
  //         }
  //       })
  //   }
  //   return [...data];
  // }

  transform(array: any[], min: number, max: number): any[] {
    if (!array || array.length === 0) {
      return [];
    }
    if (min === 0 && max === 0) {
      return array;
    }

    return array.filter(item => item.impact_factor >= min && item.impact_factor <= max);
  }
}
