<div class="content_wrap_section">
    <div class="wrap_header_sectons contact_us_background">
        <div class="top_header_overlay"></div>
        <h1>Contact Us</h1>
    </div>

    <div class="contactus_contents">
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <div class="office_address">
                        <div class="office_contents">
                            <h4>Headquarter</h4>
                            <span style="font-size: 18px;  font-family: var(--tx-poppins-600);
 font-weight: 600;">Crimson AI Pvt. Ltd.</span>
                            <p>8A, 8th Floor, Techniplex II, SV Road,<br />
                                Goregaon West, Mumbai,<br />
                                Maharashtra 400062, India</p>
                        </div>
                        <!-- <div class="office_contents">
                            <h4>Regional Office</h4>
                            <p>2F, No. 40, Ln 91, Sec.1,
                                Neihu Road, Neihu Dist., Taipei City 11441, Taiwan.</p>
                        </div> -->
                        <div class="office_contents">
                            <h4>Contact</h4>
                            <p>Email: <a href="mailto:support@trinka.ai">support@trinka.ai</a></p>
                        </div>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="contact_forms">
                        <form [formGroup]="contactForm" (ngSubmit)="processForm()">
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group"
                                        [ngClass]="(!contactForm.get('contactFormName').valid && contactForm.get('contactFormName').dirty) || (contactForm.get('contactFormName').touched && contactForm.get('contactFormName').pristine) ? 'TextFiledError' : '' ">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Your name *</mat-label>
                                            <input id="contactUserName" matInput placeholder="" formControlName="contactFormName"
                                                class="form-control">
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="col-sm-6">
                                    <div class="form-group"
                                        [ngClass]="(!contactForm.get('contactFormEmail').valid && contactForm.get('contactFormEmail').dirty) || (contactForm.get('contactFormEmail').touched && contactForm.get('contactFormEmail').pristine) ? 'TextFiledError' : '' ">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Email *</mat-label>
                                            <input id="contactUserEmail" matInput placeholder="" formControlName="contactFormEmail"
                                                class="form-control">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group"
                                        [ngClass]="(!contactForm.get('contactFormContactNumber').valid && contactForm.get('contactFormContactNumber').dirty) || (contactForm.get('contactFormContactNumber').touched && contactForm.get('contactFormContactNumber').pristine) ? 'TextFiledError' : '' ">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Contact Number* (including ISD code)</mat-label>
                                            <input id="contactUserSubject" matInput placeholder="" formControlName="contactFormContactNumber"
                                                class="form-control">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group"
                                        [ngClass]="(!contactForm.get('contactFormOrgTitle').valid && contactForm.get('contactFormOrgTitle').dirty) || (contactForm.get('contactFormOrgTitle').touched && contactForm.get('contactFormOrgTitle').pristine) ? 'TextFiledError' : '' ">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Organization/Title *</mat-label>
                                            <input id="contactUserSubject" matInput placeholder="" formControlName="contactFormOrgTitle"
                                                class="form-control">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group select-group" [ngClass]="(contactForm.get('contactFormInquiryType').value === '' && contactForm.get('contactFormInquiryType').touched) ? 'TextFiledError' : ''">
                                        <mat-form-field>
                                            <mat-label>Inquiry Type</mat-label>
                                            <select matNativeControl required formControlName="contactFormInquiryType" (change)="displayInvoiceEmail($event)">
                                                <option value="" selected></option>
                                                <option value="Sales Enquiry">Sales Enquiry</option>
                                                <option value="Technical Support">Technical Support</option>
                                                <option value="Billing/Invoice Support">Billing/Invoice Support</option>
                                                <option value="General Inquiry">General Inquiry</option>
                                                <option value="Request a Demo">Request a Demo</option>
                                                <option value="License Enquiry">License Enquiry</option>
                                            </select>
                                        </mat-form-field>
                                    </div>
                                    <div class="invoiceTextSection" *ngIf="billingInvoiceText">
                                        <p>For invoicing-related queries, please visit <a href="https://www.trinka.ai/local-invoice" target="_blank">https://www.trinka.ai/local-invoice</a>.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group textareaBox"
                                        [ngClass]="(!contactForm.get('contactFormMessage').valid && contactForm.get('contactFormMessage').dirty) || (contactForm.get('contactFormMessage').touched && contactForm.get('contactFormMessage').pristine) ? 'TextFiledError' : '' ">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Your Message *</mat-label>
                                            <textarea id="contactUserMessage" matInput formControlName="contactFormMessage" placeholder=""
                                                class="form-control"></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <button type="submit" id="contactUserSendMessage" [disabled]="isSubmitting">Send Message</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="global_offices">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <h2>Our Global Presence</h2>
                    <div class="HeadingSubLine"></div>
                    <p>We have a presence all across the world to facilitate the availability of our services to the
                        research community.</p>
                        <div class="global-presence-wrap mart-20">
                            <div class="global-maps" style="position: relative;">
                                <div class="row">
                                    <div class="col-md-3 col-xs-12 col-sm-4 map-wrap usa marr-10" id="usa" onclick="viewlocation(id)">
                                        <div class="map-box"></div>
                                        <div class="location-name">New Jersey,<br/> USA</div>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12 global-location-wrap usa padb-20" id="usaview">
                                        <div class="row">
                                        <div class="col-md-12 col-xs-12 global-location">
                                            <div class="location-wrap" style="margin-left: 50px;">
                                                <h3>New Jersey, USA</h3>
                                                <p class="marb-10"><br/>&nbsp;
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
    
    
                                    <div class="col-md-3 col-xs-12 col-sm-4 map-wrap korea marr-20" id="korea" onclick="viewlocation(id)">
    
                                        <div class="map-box"></div>
                                        <div class="location-name">Seoul, Korea </div>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12 global-location-wrap korea padb-20" id="koreaview">
                                        <div class="col-md-12  col-sm-12 col-xs-12 global-location">
                                            <div class="location-wrap">
                                                <h3>Seoul, Korea</h3>
                                                <p>Crimson Interactive Korea Co., Ltd.<br>
                                                    #803-74, 1-Dong, 775, Gyeongin-ro, Yeongdeungpo-gu,
                                                    Seoul, Republic of Korea    
                                                    <br>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
    
    
                                    <div class="col-md-3 col-xs-12 col-sm-4 map-wrap china marr-20" id="china" onclick="viewlocation(id)">
                                        <div class="map-box"></div>
                                        <div class="location-name">Beijing / Shanghai, China</div>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12 global-location-wrap china padb-20" id="chinaview">
                                        <div class="row">
                                            <div class="col-md-6  col-sm-6  col-xs-12 global-location">
                                                <div class="location-wrap"  style="margin-left: 50px;">
                                                    <h3>Beijing, China</h3>
                                                    <p class="marb-10">Room 607, Attempt Center, No. A6 Zhongguancun South
                                                        Street,
                                                        Haidian District, Beijing, China - 100086<br>
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-md-6  col-sm-6 col-xs-12  global-location">
                                                <div class="location-wrap" style="margin-left: 50px;">
                                                    <h3>Shangai, China</h3>
                                                    <p class="marb-10">Room 101, No. 110, Tianlinshiyicun, Xuhui District,
                                                        Shanghai
                                                        200233<br>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
    
    
                                    <div class="col-md-3 col-xs-12 col-sm-4 map-wrap japan marr-20" id="japan" onclick="viewlocation(id)">
                                        <div class="map-box"></div>
                                        <div class="location-name">Tokyo, Japan</div>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12 global-location-wrap japan padb-20" id="japanview">
                                        <div class="col-md-12  col-sm-12 col-xs-12 global-location">
                                            <div class="location-wrap">
                                                <h3>Tokyo, Japan</h3>
                                                <p class="marb-10">Crimson Interactive K.K.<br>
                                                    4th Daini-Denpa Building, 2-14-10 Soto-Kanda, Chiyoda-ku, Tokyo,
                                                    101-0021,
                                                    Japan<br>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
    
    
                                    <div class="col-md-3 col-xs-12 col-sm-4 map-wrap india active" id="india" onclick="viewlocation(id)">
                                        <div class="map-box"></div>
                                        <div class="location-name">Mumbai, India</div>
                                    </div>
    
                                    <div class="col-md-12 col-sm-12 col-xs-12 global-location-wrap india padb-20 show" id="indiaview">
                                        <div class="col-md-12  col-sm-12 col-xs-12 global-location">
                                            <div class="location-wrap">
                                                <h3>Mumbai, India</h3>
                                                <p class="marb-10">Crimson Interactive<br>
                                                    1001, 10th Floor, Techniplex - II, Veer Savarkar Flyover, S. V. Road,
                                                    Goregaon (W), Mumbai 400062, India<br>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
    
    
                                    <div class="col-md-3 col-xs-12 col-sm-4 map-wrap uk" id="uk" onclick="viewlocation(id)">
                                        <div class="map-box"></div>
                                        <div class="location-name">London, United Kingdom</div>
                                    </div>
    
                                    <div class="col-md-12 col-sm-12 col-xs-12 global-location-wrap uk padb-20" id="ukview">
                                        <div class="col-md-12  col-sm-12 col-xs-12 global-location">
                                            <div class="location-wrap">
                                                <h3>London, United Kingdom</h3>
                                                <p class="marb-10">Woodberry House, 2 Woodberry Grove, Finchley, London, N12
                                                    0DR, United Kingdom<br>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
    
                                    <div class="col-md-3 col-xs-12 col-sm-4 map-wrap turkey" id="turkey" onclick="viewlocation(id)">
                                        <div class="map-box"></div>
                                        <div class="location-name">Istanbul,<br />Turkey</div>
                                    </div>
    
                                    <div class="col-md-12 col-sm-12 col-xs-12 global-location-wrap turkey padb-20" id="turkeyview">
                                        <div class="col-md-12  col-sm-12 col-xs-12 global-location">
                                            <div class="location-wrap">
                                                <h3>Istanbul, Turkey</h3>
                                                <p class="marb-10">Levent Mah. Ulgen Sok. No:52 Besiktas Istanbul,
                                                    TURKEY<br>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
    
                                    <div class="col-md-3 col-xs-12 col-sm-4 map-wrap taiwan" id="taiwan" onclick="viewlocation(id)">
                                        <div class="map-box"></div>
                                        <div class="location-name">Taipei,<br> Taiwan</div>
                                    </div>
    
                                    <div class="col-md-12 col-sm-12 col-xs-12 global-location-wrap taiwan padb-20" id="taiwanview">
                                        <div class="col-md-12  col-sm-12 col-xs-12 global-location">
                                            <div class="location-wrap">
                                                <h3>Taipei, Taiwan</h3>
                                                <p class="marb-10">
                                                    2F, No. 40, Ln 91, Sec.1, <br>
                                                    Neihu Road, Neihu Dist., Taipei City 11441, Taiwan.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
       
                                </div>    
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </div>
</div>