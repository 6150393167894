<div class="trinka_cloud_wrap">
    <div id="particles-js"></div>
    <div class="cloud_top_banners">
        <section class="mainTopBanner">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="topBannerWrapper">
                            <div class="top-banner-left">
                                <h1>Browser Plug-Ins</h1>
                                <h2>Trinka Browser <br class="d-lg-block d-none"/> Plug-Ins</h2>
                                <p>Experience the power of Trinka Word Add-In Lite straight from your Windows or Mac. It checks your writing and provides corrections as you write on your familiar MS Word app.</p>
                                <p>Trinka Browser plug-ins enhance your writing on all your favorite websites in real time. Communicate
                                    effectively everywhere!</p>
                                <div id="safariBrowser">
                                    <button title="Coming soon for Safari">
                                        <img src="../../../assets/images/safari_logo.png" alt="safari">
                                        Coming soon</button>
                                    <div class="downloadForSafari">
                                        <h2>Download for:</h2>
                                        <ul>
                                            <li>
                                                <a href="https://chrome.google.com/webstore/detail/trinka-ai-for-chrome/bbcmnbnmngpeofmpcdlcfalbniefegbp" target="_blank" title="Download for Chrome">
                                                    <img src="../../../assets/images/NewChromeFicon.svg" alt="chrome">
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://addons.mozilla.org/en-US/firefox/addon/trinka-ai-firefox-browser/" title="Download for Firefox">
                                                    <img src="../../../assets/images/NewFirefoxFicon.svg" alt="firefox">
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://microsoftedge.microsoft.com/addons/detail/trinka-ai-for-edge/kjhkcgjfbpppkbhhaocagkmfkkiibeap" title="Download for Edge">
                                                    <img src="../../../assets/images/EdgeWhite_BGAndOutline.svg" alt="edge">
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div id="BrowserGoogleChromeSection">
                                    <div class="browserPluginButton">
                                        <a href="https://chrome.google.com/webstore/detail/trinka-ai-for-chrome/bbcmnbnmngpeofmpcdlcfalbniefegbp" target="_blank"
                                            class="buttonGoogleChrome" id="BrowserChromeButton">
                                            <b>
                                                <img src="../../../assets/images/NewChromeIcon.svg" alt="Grammar checker for Chrome">
                                            </b>
                                            <i>Install for Chrome</i>
                                        </a>
                                    </div>
                                    <br />
                                    <div class="commingSoonSeciton">
                                        <p class="FirefoxAvalibale">
                                            <a href="https://addons.mozilla.org/en-US/firefox/addon/trinka-ai-firefox-browser/" target="_blank">
                                                <img style="width: 32px;" src="../../../assets/images/NewFirefoxIcon.svg" alt="Grammar checker for Firefox"> Download for Firefox
                                            </a>
                                        </p>
                                        <p class="SafariComingSoon EdgeBrowserPlugin">
                                            <a href="https://microsoftedge.microsoft.com/addons/detail/trinka-ai-for-edge/kjhkcgjfbpppkbhhaocagkmfkkiibeap" target="_blank">
                                                <img style="width: 30px;" src="../../../assets/images/EdgeWhite_BGAndOutline.svg" alt="Grammar checker for Chrome"> Download for Edge
                                            </a>
                                        </p>
                                    </div>
                                </div>
                                <div id="BrowserFirefoxSection">
                                    <div class="browserPluginButton">
                                        <a href="https://addons.mozilla.org/en-US/firefox/addon/trinka-ai-firefox-browser/" target="_blank" class="buttonFireFox" id="BrowserFireFoxButton">
                                            <b>
                                                <img src="../../../assets/images/NewFirefoxIcon.svg" alt="Grammar checker for Firefox">
                                            </b>
                                            <i>Install for Firefox</i>
                                        </a>
                                    </div>
                                    <br />
                                    <div class="commingSoonSeciton">
                                        <p class="FirefoxAvalibale">
                                            <a href="https://chrome.google.com/webstore/detail/trinka-ai-for-chrome/bbcmnbnmngpeofmpcdlcfalbniefegbp" target="_blank">
                                                <img style="width: 32px;" src="../../../assets/images/NewChromeIcon.svg" alt="Grammar checker for Firefox"> Download for Chrome
                                            </a>
                                        </p>
                                        <p class="SafariComingSoon EdgeBrowserPlugin">
                                            <a href="https://microsoftedge.microsoft.com/addons/detail/trinka-ai-for-edge/kjhkcgjfbpppkbhhaocagkmfkkiibeap" target="_blank">
                                                <img style="width: 30px;" src="../../../assets/images/EdgeWhite_BGAndOutline.svg" alt="Grammar checker for Chrome"> Download for Edge
                                            </a>
                                        </p>
                                    </div>
                                </div>
                                <div id="BrowserEdgeBrowserSection">
                                    <div class="browserPluginButton">
        
                                        <a href="https://microsoftedge.microsoft.com/addons/detail/trinka-ai-for-edge/kjhkcgjfbpppkbhhaocagkmfkkiibeap" target="_blank"
                                            class="buttonEdgeBrowser buttonGoogleChrome" id="BrowserEdgeButton">
                                            <b>
                                                <img src="../../../assets/images/EdgeWhite_BGAndOutline.svg" alt="Grammar checker for Firefox">
                                            </b>
                                            <i>Install for Edge</i>
                                        </a>
                                    </div>
                                    <br />
                                    <div class="commingSoonSeciton">
                                        <p class="FirefoxAvalibale">
                                            <a href="https://chrome.google.com/webstore/detail/trinka-ai-for-chrome/bbcmnbnmngpeofmpcdlcfalbniefegbp" target="_blank">
                                                <img style="width: 32px;" src="../../../assets/images/NewChromeIcon.svg" alt="Grammar checker for Firefox"> Download for Chrome
                                            </a>
                                        </p>
                                        <p class="SafariComingSoon EdgeBrowserPlugin">
                                            <a href="https://addons.mozilla.org/en-US/firefox/addon/trinka-ai-firefox-browser/" target="_blank">
                                                <img style="width: 30px;" src="../../../assets/images/NewFirefoxIcon.svg" alt="Grammar checker for Chrome"> Download for Firefox
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="top-banner-right">
                                <img src="../../../assets/images/BrowserPlugin_banner.png" class="img-fluid md50" alt="plugin">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

    <div class="brandLogo_sections section-pd-sm">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="brandlogo_heading">
                        <h2>Trinka's plug-ins work on</h2>
                    </div>
                    <div class="cover_wrapper">
                        <div id="client-logos" class="owl-carousel">
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/google-docs.png" alt="googl">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client_inners">
                                    <img src="../../../assets/images/authorea.png" alt="authorea">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/researchgate.png" alt="researchgate">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client_inners">
                                    <img src="../../../assets/images/medium.png" alt="medium">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/writing-cooprative.png" alt="writing">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/evernote.png" alt="evernote">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/notion.png" alt="notion">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/linked-in.png" alt="linked">
                                </div>
                            </div>
                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/twitter.png" alt="twitter">
                                </div>
                            </div>

                            <div class="item">
                                <div class="client-inners">
                                    <img src="../../../assets/images/gmail.png" alt="gmail">
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="viewfullList">
                        <!-- <a routerLink="/brand-list">View Full List</a> -->
                        ... and many more
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="cloud_key_features section-pd list-wrapper-style-2">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-12 col-lg-12">
                    <div class="trinka-title mb-0 text-center">
                        <h2>Key Features of Trinka Browser Plug-in</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                        <p>Trinka is available as an extension for your favorite browser to help you write better anywhere,
                            anytime. Get advanced English grammar corrections and suggestions in real-time. Even better,
                            it automatically checks all your changes so you don't miss anything.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-none">
                        <img src="../../../assets/images/realTimeEditing_icon.svg" loading="lazy" decoding="async" alt="icon">
                        <h3>Real-Time Editing</h3>
                        <p>No need to wait for the editor to suggest corrections. Trinka makes corrections as you write.
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards">
                        <img src="../../../assets/images/AddtoDictionary.svg" loading="lazy" decoding="async" alt="icon">
                        <h3>Add to Dictionary</h3>
                        <p>Add your own words to "My Dictionary" and Trinka will not flag them as spelling errors in your future
                            writing.
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards">
                        <img src="../../../assets/images/DataSecurityIcon.svg" loading="lazy" decoding="async" alt="icon">
                        <h3>Data<br class="d-lg-block d-none"/> Security</h3>
                        <p>Whatever you type is securely transmitted and analyzed. Your data is never saved with us.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="writeToolboxs_section section-pd pb-0 pt-0">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-lg-12 col-md-12">
                    <div class="trinka-title mb-0 text-center">
                        <h2>Trinka Browser Plug-In: Works Where You Do</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    </div>
                </div>
            </div>
        </div>

        <section class="section-pd list-wrapper-style-2 ">
            <div class="trinka_container">
                <div class="row align-items-center flex-row-reverse">
                    <div class="col-md-6">
                        <div class="list-wrapper-content-2">
                            <h3>Grammar correction on Google Docs</h3>
                            <p>Do you want to write better on Google Docs and correct all the grammatical errors without any
                                hassle?
                            </p>
                            <p>Add Trinka's browser extension and get real-time grammar and spelling corrections, and language
                                enhancements on Google Docs.</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="list-wrapper-image features-img">
                            <img src="../../../assets/images/google-doc-ss.jpg" loading="lazy" decoding="async" alt="google doc" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="section-pd list-wrapper-style-2 pt-0">
            <div class="trinka_container">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div class="list-wrapper-content-2 pr-80">
                            <h3>How to write better on Authorea?</h3>
                            <p>Do you use Authorea for your writing needs? If yes, then a grammar correction tool to improve
                                the quality of your writing is a must-have!</p>
                            <p>Use Trinka's browser plug-in to fix grammar errors in real-time and write better on Authorea.</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="list-wrapper-image features-img">
                            <img src="../../../assets/images/authorea-ss.jpg" loading="lazy" decoding="async" alt="authorea" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="section-pd list-wrapper-style-2 pt-0">
            <div class="trinka_container">
                <div class="row align-items-center flex-row-reverse">
                    <div class="col-md-6">
                        <div class="list-wrapper-content-2">
                            <h3>Correct your writing on Evernote</h3>
                            <p>Enhance the quality of your writing on Evernote. Trinka, our AI-powered writing tool, corrects
                                the grammar and makes language enhancements in real-time.</p>
                            <p>Trinka's browser extension is fully compatible with Evernote, and with this easy-to-use plug-in,
                                you can effortlessly review and edit your writing.</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="list-wrapper-image features-img">
                            <img src="../../../assets/images/evernote-ss.jpg" loading="lazy" decoding="async" alt="evernote" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="section-pd list-wrapper-style-2 pt-0">
            <div class="trinka_container">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div class="list-wrapper-content-2 pr-80">
                            <h3>Write better on Medium with Trinka</h3>
                            <p>Do you write blogs on Medium? Then, do not miss out on the <a href="/grammar-checker" class="text-link">editing features</a> provided by Trinka's
                                free browser plug-in.</p>
                            <p>Trinka corrects your writing for grammar, tone, punctuation, sentence structure, etc., and enhances
                                the overall writing quality that will impress the readers on Medium. </p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="list-wrapper-image features-img">
                            <img src="../../../assets/images/medium-ss.jpg" loading="lazy" decoding="async" alt="medium" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

    <div class="browser_plugin_cta">
        <div class="trinka_container">
            <div class="row">
                <div class="col-lg-12" id="BrowserGoogleChromeSection_cta">
                    <div class="trinka_cloud_banner_text">

                        <div class="browserPluginButton">
                            <a href="https://chrome.google.com/webstore/detail/trinka-ai-for-chrome/bbcmnbnmngpeofmpcdlcfalbniefegbp" target="_blank"
                                class="buttonGoogleChrome" id="BrowserChromeButton">
                                <b>
                                    <img src="../../../assets/images/NewChromeIcon.svg" alt="Grammar checker for Chrome">
                                </b>
                                <i>Install for Chrome</i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12" id="BrowserFirefoxSection_cta">
                    <div class="trinka_cloud_banner_text">
                        <div class="browserPluginButton">
                            <a href="https://addons.mozilla.org/en-US/firefox/addon/trinka-ai-firefox-browser/" target="_blank" class="buttonFireFox" id="BrowserFireFoxButton_cta">
                                <b>
                                    <img src="../../../assets/images/NewFirefoxIcon.svg" alt="Grammar checker for Firefox">
                                </b>
                                <i>Install for Firefox</i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12" id="BrowserEdgeBrowserSection_cta">
                    <div class="trinka_cloud_banner_text">
                        <div class="browserPluginButton">

                            <a href="https://microsoftedge.microsoft.com/addons/detail/trinka-ai-for-edge/kjhkcgjfbpppkbhhaocagkmfkkiibeap" target="_blank"
                                class="buttonEdgeBrowser buttonGoogleChrome" id="BrowserEdgeButton">
                                <b>
                                    <img src="../../../assets/images/EdgeWhite_BGAndOutline.svg" alt="Grammar checker for Firefox">
                                </b>
                                <i>Install for Edge</i>
                            </a>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="otherApps section-pd-sm">
        <div class="trinka_container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="trinka-title text-center">
                        <h3 class="text-white">Use Trinka in Other Apps</h3>
                    </div>
                    <ul>
                        <li>
                            <a [routerLink]="['/trinkacloud']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/Other_apps_TrinkaCloud.svg" alt="icon">
                                <h3>Cloud</h3>
                            </a>
                        </li>
                        <li>
                            <a  [routerLink]="['/windows-desktop-app']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/native-app/windows-icon.svg" alt="windows">
                                <h3>Windows App</h3>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/wordplugin']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/Others_App_MSWord.svg" alt="icon">
                                <h3>MS Word Add-in</h3>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/enterprise']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/Other_Apps_enterprise.svg" alt="icon">
                                <h3>Enterprise <small>(API, JS, & On-Premise)</small></h3>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>