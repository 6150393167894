<div class="trinka_cloud_wrap">
    <div id="particles-js"></div>
    <!-- <div class="cloud_top_banners">
        <div class="container">
            <div class="row">
                <div class="col-lg-7">
                    <div class="trinka_cloud_banner_text">
                        <h1>
                            <img src="../../../../assets/images/credits_icon.png" style="width: 15px;margin-right: 5px;" />Credits on Trinka</h1>
                        <h2>Flexibility to Use Trinka
                            <br>Features as You Need
                        </h2>
                        <p>Credits are like tokens that offer the flexibility to use different Trinka features as needed. You
                            get free credits every month too!
                        </p>
                        <a href="https://cloud.trinka.ai/signup?_rg=GB" id="autoEditGetStart" target="_blank">Get Started</a>
                    </div>
                </div>
                <div class="col-lg-5">
                    <div class="trinka_cloud_banner_img">
                        <img src="../../../assets/images/CreditInformation.png" alt="credit info">
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <section class="mainTopBanner">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="topBannerWrapper">
                        <div class="top-banner-left">
                            <h1>
                                <img src="../../../../assets/images/credits_icon.png" alt="icon" style="width: 15px;margin-right: 5px;" />Credits on Trinka</h1>
                            <h2>Flexibility to Use Trinka
                                Features as You Need
                            </h2>
                            <p>Credits are like tokens that offer the flexibility to use different Trinka features as and when needed. Premium users get 10 and Basic users get 4 free credits every month!
                            </p>
                            <div class="btn-group">
                                <a href="https://cloud.trinka.ai/signup?_rg=GB" class="filled-btn" target="_blank">Get Started</a>
                            </div>
                        </div>
                        <div class="top-banner-right">
                            <img src="../../../assets/images/CreditInformation.png" alt="credit info" class="md50">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div class="cloud_key_features section-pd pt-0">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-11 col-lg-10">
                    <div class="trinka-title mb-0 text-center">
                        <h2>Use Credits on Your Favorite Features</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="credit_feature">
                        <table class="table">
                            <thead>
                                <tr class="odd">
                                    <th>Feature</th>
                                    <th class="text-center">
                                        <sup>
                                            <img style="width: 20px;" src="../../../../assets/images/credits_icon.png" alt="icon" />
                                        </sup> Credits Consumed
                                    </th>
                                </tr>
                            </thead>
                            <tr>
                                <td>
                                    <h5>Trinka Plagiarism Check</h5>
                                    <p>Get highest quality of plagiarism check against Internet sources and some Open Access publications, powered by iThenticate.

                                    </p>
                                </td>
                                <td>
                                    <p class="text-center" style="font-size: 16px;">12 credits for every report
                                    </p>
                                </td>
                            </tr>

                            <tr>
                                <td class="odd">
                                    <h5>iThenticate Plagiarism Check</h5>
                                    <p>
                                        Get highest quality of plagiarism check against Internet, Open Access, and paid publications, powered by iThenticate.
                                    </p>
                                </td>
                                <td class="odd">
                                    <p class="text-center" style="font-size: 16px;">18 credits for every report
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h5>Proofread File</h5>
                                    <p>Automatically edit MS Word files in minutes and download a tracked changed file with
                                        all changes.
                                    </p>
                                </td>
                                <td>
                                    <p class="text-center" style="font-size: 16px;">1 credit for every 5000 words
                                        <br /> in a file
                                    </p>
                                </td>
                            </tr>

                            <tr>
                                <td class="odd">
                                    <h5>Technical Checks</h5>
                                    <p>Evaluates your document for publication readiness against 20+ checkpoints - Journal Selection,
                                        Ethical Compliance, and more.

                                    </p>
                                </td>
                                <td class="odd">
                                    <p class="text-center" style="font-size: 16px;">1 credit for every report
                                    </p>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <h5>Citation Checker </h5>
                                    <p>Identify citations that can make your research argumentation weaker and cite credible, strong citations for your paper.
                                    </p>
                                </td>
                                <td>
                                    <p class="text-center" style="font-size: 16px;">1 credit for up to 30 citations
                                    </p>
                                </td>
                            </tr>


                            <!-- <tr>
                                <td>
                                    <h5>Plagiarism Checker</h5>

                                    <p>Avoid unintentional plagiarism in your content. Choose from multiple plagisrism checkers,
                                        including iThenticate.
                                    </p>
                                </td>
                                <td>
                                    <p class="text-center"  style="font-size: 16px;">Starts from 3 credits for<br />every check
                                    </p>
                                </td>
                            </tr>

                            <tr>
                                <td class="odd">
                                    <h5>Referrence Quality Check</h5>
                                    <p>Avoid using poor-quality citations and strengthen your argumentation with multiple quality
                                        checks for your reference list.
                                    </p>
                                </td>
                                <td class="odd">
                                    <p class="text-center"  style="font-size: 16px;">3 credits for every file</p>
                                </td>
                            </tr> -->

                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="creadits_availability section-pd">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-12 col-lg-11">
                    <div class="trinka-title mb-0 text-center">
                        <h2>Credits Availability</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                        <p>All Trinka users get free credits every 30 days. Unused credits lapse and are reset every 30 days.</p>
                        <p>Purchase additional credits when you need. Purchased credits never expire. To know more about credit
                            packs,
                            <a class="text-link" (click)="scroll(target)">click here.</a>
                        </p>
                    </div>
                </div>
            </div>
            <div class="row">
                <!-- <div class="col-sm-12">
                    <div class="key_features_heading">
                        <h2>
                            <sup>
                                <img style="width: 22px;" src="../../../../assets/images/credits_icon.png" />
                            </sup> Credits Availability</h2>
                        <div class="HeadingSubLine"></div>
                        <p style="margin: 0;">All Trinka users get free credits every 30 days. Unused credits lapse and are reset every 30 days.</p>
                        <p>Purchase additional credits when you need. Purchased credits never expire. To know more about credit
                            packs,
                            <a  (click)="scroll(target)">click here.</a>
                        </p>
                    </div>
                </div> -->
                <div class="col-lg-6">
                    <div class="creaditsCardSection first">
                        <div class="creaditsCards boxShadowNone">
                            <div class="creaditsBoxHeader">
                                <h2>Basic</h2>
                                <span>Free Users</span>
                            </div>
                            <div class="creaditsToggleButton">
                                <h3>4</h3>
                                <p>(free credits every 30 days)</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="creaditsCardSection">
                        <div class="creaditsCards boxShadowNone">
                            <div class="creaditsBoxHeader">
                                <h2>Premium</h2>
                                <span>Paid Users</span>
                            </div>
                            <div class="creaditsToggleButton">
                                <h3>10</h3>
                                <p>(free credits every 30 days)</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="how_it_works section-pd">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-12 col-lg-12">
                    <div class="trinka-title text-center">
                        <h2>How It Works</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                        <p>Let us consider a use case of an individual who is on a Basic plan and has registered on the 5th
                            of January.
                        </p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="credit_case">
                        <div class="table table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th style="text-decoration: underline;" colspan="5">Case 1</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style=" font-family: var(--tx-poppins-600);font-weight: 600; background-color: #f8f6f9;">Date</td>
                                        <td style=" font-family: var(--tx-poppins-600);font-weight: 600; background-color: #f8f6f9;">Particulars</td>
                                        <td style=" font-family: var(--tx-poppins-600);font-weight: 600; background-color: #f8f6f9;">Debit</td>
                                        <td style=" font-family: var(--tx-poppins-600);font-weight: 600; background-color: #f8f6f9;">Credit</td>
                                        <td style=" font-family: var(--tx-poppins-600);font-weight: 600; background-color: #f8f6f9;">Balance</td>
                                    </tr>
                                    <tr>
                                        <td>05-Jan</td>
                                        <td>Opening free Credit Balance</td>
                                        <td></td>
                                        <td></td>
                                        <td>4</td>
                                    </tr>
                                    <tr>
                                        <td>10-Jan</td>
                                        <td>Used 1 credit for Proofread File</td>
                                        <td style="color: red;">-1</td>
                                        <td></td>
                                        <td>3</td>
                                    </tr>
                                    <tr>
                                        <td>05-Feb</td>
                                        <td>Free Credit Balance Reset *</td>
                                        <td style="color: red;"></td>
                                        <td style="color: green;">+4</td>
                                        <td>4</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="credit_case">
                        <div class="table table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th style="text-decoration: underline;" colspan="5">Case 2 (Basic User)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style=" font-family: var(--tx-poppins-600);font-weight: 600; background-color: #f8f6f9;">Date</td>
                                        <td style=" font-family: var(--tx-poppins-600);font-weight: 600; background-color: #f8f6f9;">Particulars</td>
                                        <td style=" font-family: var(--tx-poppins-600);font-weight: 600; background-color: #f8f6f9;">Debit</td>
                                        <td style=" font-family: var(--tx-poppins-600);font-weight: 600; background-color: #f8f6f9;">Credit</td>
                                        <td style=" font-family: var(--tx-poppins-600);font-weight: 600; background-color: #f8f6f9;">Balance</td>
                                    </tr>
                                    <tr>
                                        <td>05-Jan</td>
                                        <td>Opening free Credit Balance</td>
                                        <td></td>
                                        <td></td>
                                        <td>4</td>
                                    </tr>
                                    <tr>
                                        <td>10-Jan</td>
                                        <td>Used 2 credits for Proofread File</td>
                                        <td style="color: red;">-2</td>
                                        <td></td>
                                        <td>2</td>
                                    </tr>
                                    <tr>
                                        <td>25-Jan</td>
                                        <td>Purchased 2-credit pack</td>
                                        <td></td>
                                        <td style="color: green;">+2</td>
                                        <td>4</td>
                                    </tr>
                                    <tr>
                                        <td>05-Feb</td>
                                        <td>Free Credit Balance Reset (4 Free + 2 Paid) *</td>
                                        <td></td>
                                        <td style="color: green;">+4</td>
                                        <td>6</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="credit_case">
                        <div class="table table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th style="text-decoration: underline;" colspan="5">Case 3 (Premium User)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style=" font-family: var(--tx-poppins-600);font-weight: 600; background-color: #f8f6f9;">Date</td>
                                        <td style=" font-family: var(--tx-poppins-600);font-weight: 600; background-color: #f8f6f9;">Particulars</td>
                                        <td style=" font-family: var(--tx-poppins-600);font-weight: 600; background-color: #f8f6f9;">Debit</td>
                                        <td style=" font-family: var(--tx-poppins-600);font-weight: 600; background-color: #f8f6f9;">Credit</td>
                                        <td style=" font-family: var(--tx-poppins-600);font-weight: 600; background-color: #f8f6f9;">Balance</td>
                                    </tr>
                                    <tr>
                                        <td>05-Jan</td>
                                        <td>Opening free Credit Balance</td>
                                        <td></td>
                                        <td></td>
                                        <td>10</td>
                                    </tr>
                                    <tr>
                                        <td>10-Jan</td>
                                        <td>Purchased 2-credit pack</td>
                                        <td></td>
                                        <td style="color: green;">+2</td>
                                        <td>12</td>
                                    </tr>
                                    <tr>
                                        <td>25-Jan</td>
                                        <td>Used 4 credits for Proofread File</td>
                                        <td style="color: red;">-4</td>
                                        <td></td>
                                        <td>8</td>
                                    </tr>
                                    <tr>
                                        <td>05-Feb</td>
                                        <td>Free Credit Balance Reset (10 Free + 2 Paid) *</td>
                                        <td></td>
                                        <td style="color: green;">+10</td>
                                        <td>12</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <small style="position: absolute; bottom: -35px; left: 0;">* Free credits expire every month</small>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="credit_balance section-pd">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-10 col-lg-8">
                    <div class="trinka-title mb-0 text-center">
                        <h2>Credit Balance</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                        
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="credit_balance_wrapper">
                        <div class="credit_balance_inner">
                            <img src="../../../../assets/images/credits-banner1.png" alt="credit-banner" class="img-fluid" />
                        </div>
                        <div class="credit_balance_text">
                            <div class="credit_table">
                                <h3>Check Your Credit Balance & Buy Credits</h3>
                                <p>Your Credit balance is shown on all relevant pages. Clicking on the value lets you see more
                                    details and buy more credits.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="credit_balance_wrapper">
                        <div class="credit_balance_inner">
                            <img src="../../../../assets/images/credits-banner2.png" alt="credit-banner" class="img-fluid" />
                        </div>
                        <div class="credit_balance_text">
                            <div class="credit_table">
                                <h3>Credits Needed to Use Trinka Features</h3>
                                <p>When you use a credit-based feature, the number of credits needed to use that feature will
                                    be displayed to you, before you use it.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="buying-credit section-pd" #target>
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-12 col-lg-12">
                    <div class="trinka-title mb-0 text-center">
                        <h2>Buying Credits</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                        <p>You can buy Credit packs whenever you need. Credit packs let you buy several credits at once
                            and use them anytime.</p>
                        <p>Purchased credits never expire. You get great discounts too! Even better, free credits are always
                            used first automatically!</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="credit_pack">
                        <div class="buyCreditTable table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th style="font-size: 24px;color: #000000; font-weight: bold;">Credit Packs</th>
                                        <th style="font-size: 24px;color: #000000; font-weight: bold;">
                                            <span>Basic</span>
                                            <br />
                                            <!-- <span class="comingSoonText">(Credit purchase will be available soon)</span> -->
                                        </th>
                                        <th style="font-size: 24px;color: #000000; font-weight: bold;">Premium</th>
                                    </tr>
                                </thead>
                                <tr>
                                    <td style="font-size: 20px;color: #4f4f4f;  font-family: var(--tx-poppins-600);font-weight: 600;">Free monthly Credits</td>
                                    <td>4</td>
                                    <td>10</td>
                                </tr>
                                <tr>
                                    <td style="font-size: 20px;color: #4f4f4f;  font-family: var(--tx-poppins-600);font-weight: 600;">{{decodedData[0].text}}</td>
                                    <td>
                                        <div class="credit_info">
                                            <sup style="font-size: 13px;">{{decodedData[0].currency_code}}</sup> {{decodedData[0].amount / 2 | number:'1.2-2'}}
                                            <i>per credit</i>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="credit_info">
                                            <span class="strikethrough">{{decodedData[0].currency_code}}{{decodedData[0].amount / 2}}</span>
                                            <sup style="font-size: 13px;">{{decodedData[0].currency_code}}</sup> {{calculatePlan(premiumDecodedData[0].final_amount, premiumDecodedData[0].months)}}
                                            <span class="yellow-btn">~{{calculateDiscount(decodedData[3].amount,decodedData[3].final_amount, decodedData[3].months)}}% off</span>
                                            <i>{{premiumDecodedData[0].amount_suffix}}</i>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="font-size: 20px;color: #4f4f4f;  font-family: var(--tx-poppins-600);font-weight: 600;">{{decodedData[1].text}}</td>
                                    <td>
                                        <div class="credit_info">
                                            <span class="strikethrough">{{decodedData[1].currency_code}}{{decodedData[1].amount / 2}}</span>
                                            <sup style="font-size: 13px;">{{decodedData[1].currency_code}}</sup> {{calculatePlan(decodedData[1].final_amount, decodedData[1].months)}}
                                            <span class="yellow-btn">~{{calculateDiscount(decodedData[1].amount,decodedData[1].final_amount, decodedData[1].months)}}% off</span>
                                            <i>{{decodedData[1].amount_suffix}}</i>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="credit_info">
                                            <span class="strikethrough">{{decodedData[1].currency_code}}{{decodedData[1].amount / 2}}</span>
                                            <sup style="font-size: 13px;">{{premiumDecodedData[1].currency_code}}</sup> {{calculatePlan(premiumDecodedData[1].final_amount, premiumDecodedData[1].months)}}
                                            <span class="yellow-btn">~{{calculateDiscount(premiumDecodedData[1].amount,premiumDecodedData[1].final_amount, premiumDecodedData[1].months)}}% off</span>
                                            <i>{{premiumDecodedData[1].amount_suffix}}</i>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="font-size: 20px;color: #4f4f4f;  font-family: var(--tx-poppins-600);font-weight: 600;">{{decodedData[2].text}}</td>
                                    <td>
                                        <div class="credit_info">
                                            <span class="strikethrough">{{decodedData[2].currency_code}}{{decodedData[2].amount / 2}}</span>
                                            <sup style="font-size: 13px;">{{decodedData[2].currency_code}}</sup> {{calculatePlan(decodedData[2].final_amount, premiumDecodedData[2].months)}}
                                            <span class="yellow-btn">~{{calculateDiscount(decodedData[2].amount,decodedData[2].final_amount, decodedData[2].months)}}% off</span>
                                            <i>{{decodedData[2].amount_suffix}}</i>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="credit_info">
                                            <span class="strikethrough">{{decodedData[2].currency_code}}{{decodedData[2].amount / 2}}</span>
                                            <sup style="font-size: 13px;">{{premiumDecodedData[2].currency_code}}</sup> {{calculatePlan(premiumDecodedData[2].final_amount, premiumDecodedData[2].months)}}
                                            <span class="yellow-btn">~{{calculateDiscount(premiumDecodedData[2].amount,premiumDecodedData[2].final_amount, premiumDecodedData[2].months)}}% off</span>
                                            <i>{{premiumDecodedData[2].amount_suffix}}</i>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="font-size: 20px;color: #4f4f4f;  font-family: var(--tx-poppins-600);font-weight: 600;">{{decodedData[3].text}}</td>
                                    <td>
                                        <div class="credit_info">
                                            <span class="strikethrough">{{decodedData[3].currency_code}}{{decodedData[3].amount / 2}}</span>
                                            <sup style="font-size: 13px;">{{decodedData[3].currency_code}}</sup> {{calculatePlan(decodedData[3].final_amount, premiumDecodedData[3].months)}}
                                            <span class="yellow-btn">~{{calculateDiscount(decodedData[3].amount,decodedData[3].final_amount, decodedData[3].months)}}% off</span>
                                            <i>{{decodedData[3].amount_suffix}}</i>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="credit_info">
                                            <span class="strikethrough">{{decodedData[3].currency_code}}{{decodedData[3].amount / 2}}</span>
                                            <sup style="font-size: 13px;">{{premiumDecodedData[3].currency_code}}</sup> {{calculatePlan(premiumDecodedData[3].final_amount, premiumDecodedData[3].months)}}
                                            <span class="yellow-btn">~{{calculateDiscount(premiumDecodedData[3].amount,premiumDecodedData[3].final_amount, premiumDecodedData[3].months)}}% off</span>
                                            <i>{{premiumDecodedData[3].amount_suffix}}</i>
                                        </div>
                                    </td>
                                </tr>
                                <!-- <tr *ngFor="let planMode of paymentModeOption;let i = index;">
                                    <td style="font-size: 20px;color: #4f4f4f;  font-family: var(--tx-poppins-600);font-weight: 600;">{{planMode.text}}</td>
                                    <td>{{planMode.currency}}{{planMode.final_amount| number:'1.2-2'}}</td>
                                    <td> {{planMode.currency}}{{calculatePlan(planMode.final_amount, planMode.months)}}
                                        <span class="yellow-btn">~{{calculateDiscount(planMode.amount,planMode.final_amount, planMode.months)}}%</span>
                                        <i>{{planMode.amount_suffix}}</i></td>
                                </tr> -->
                                
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="trinka-banner section-pd-sm">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-11 col-lg-10">
                    <div class="trinka-title text-center">
                        <h3 class="text-white">Credits for Teams and Enterprise</h3>
                        <p class="text-white mt-20">Trinka's Enterprise plans let you buy credits for each team or for the organization, per your
                            need. Enterprise plans also allow flexible credit distribution and bulk credit purchases.</p>
                    </div>
                    <div class="btn-group justify-content-center">
                        <a [routerLink]="['/enterprise']" routerLinkActive="active" class="white-btn">Contact Us</a>
                    </div>
                </div>
            </div>  
        </div>
    </div>


    <!-- <div class="use_trinka_other_app">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="use_trinka_content">
                        <div class="use_trinka_heading">
                            <div class="howItWorkHeading lineWhiteColor">
                                <h2>
                                    Credits for Teams
                                    <br>and Enterprise</h2>
                            </div>
                        </div>
                        <div class="others_app_name">
                            <p>Trinka's Enterprise plans let you buy credits for each team or for the organization, per your
                                need. Enterprise plans also allow flexible credit distribution and bulk credit purchases.
                            </p>
                            <a [routerLink]="['/enterprise']" routerLinkActive="active">Contact Us</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <div class="faqQuestionAnwser section-pd" #target>
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-lg-8 col-md-10">
                    <div class="trinka-title text-center">
                        <h2>Frequently Asked Questions</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    </div>
                </div>
            </div>
            <div class="faqQuestionsList">
                <div class="accordion" id="faq">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="faqContainer">
                                <div class="card">
                                    <div class="card-header" id="QuestionOne">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionOneContent" aria-expanded="true" aria-controls="QuestionOneContent">
                                            Are credit purchases refundable?</a>
                                    </div>
    
                                    <div id="QuestionOneContent" class="collapse" aria-labelledby="QuestionOne" data-parent="#faq">
                                        <div class="card-body">
                                            <p>Credit purchases cannot be refunded. Purchased credits remain with you until you
                                                use them.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="QuestionTwo">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionTwoContent" aria-expanded="true"
                                            aria-controls="QuestionTwoContent">What happens to my free credits if I upgrade to Premium?</a>
                                    </div>
    
                                    <div id="QuestionTwoContent" class="collapse" aria-labelledby="QuestionTwo" data-parent="#faq">
                                        <div class="card-body">
                                            <p>When you upgrade to Premium, your credit balance will reset to 10, regardless of unused
                                                free credits.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="faqContainer">
                                <div class="card">
                                    <div class="card-header" id="QuestionThree">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionThreeContent" aria-expanded="true"
                                            aria-controls="QuestionThreeContent">
                                            What happens to my purchased credits if I upgrade to Premium?
                                        </a>
                                    </div>
    
                                    <div id="QuestionThreeContent" class="collapse" aria-labelledby="QuestionThree" data-parent="#faq">
                                        <div class="card-body">
                                            <p>Your purchased credits remain with you when you upgrade, and you can use them any
                                                time you want.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="QuestionFour">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionFourContent" aria-expanded="true"
                                            aria-controls="QuestionFourContent">
                                            Is there a limit on credit purchase?
                                        </a>
                                    </div>
    
                                    <div id="QuestionFourContent" class="collapse" aria-labelledby="QuestionFour" data-parent="#faq">
                                        <div class="card-body">
                                            <p>You can buy as many credits as you want, there is no limit on credit purchases.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="wordPluginPopup" tabindex="-1" role="dialog" aria-labelledby="wordPluginLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-body" style="padding: 0;">
                <button type="button" id="closedPopup" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div id="iframeHolder"></div>

                <iframe class="youtubeVideoForTryDemo" id="iframe" width="100%" height="450" src="https://www.youtube.com/embed/5KsBh4PQUPI"
                    title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
                <!-- <iframe class="youtubeVideoForTryDemo" id="iframe" width="100%" height="450" src="https://www.youtube.com/embed/z-TVcRwVp3A"
                    title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe> -->
            </div>
        </div>
    </div>
</div>