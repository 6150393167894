<div class="pagination-desc">
  <nav aria-label="Page navigation example">
    <ul class="pagination justify-content-center">
      <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
        <a class="page-link" href="javascript:void(0)" aria-label="First" (click)="setPage(1)">
          <span aria-hidden="true">&laquo;</span>
          <span class="sr-only">First</span>
        </a>
      </li>

      <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
        <a class="page-link" aria-label="Previous" href="javascript:void(0)" (click)="setPage(pager.currentPage - 1)">
          <span aria-hidden="true">&lsaquo;</span>
          <span class="sr-only">Previous</span>
        </a>
      </li>

      <li class="page-item" [ngClass]="{active:pager.currentPage === page}" *ngFor="let page of pager.pages">
        <a class="page-link" aria-label="Current Page" href="javascript:void(0)" (click)="setPage(page)">{{page}}</a>
      </li>

      <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
        <a class="page-link" aria-label="Next" href="javascript:void(0)" (click)="setPage(pager.currentPage + 1)">
          <span aria-hidden="true">&rsaquo;</span>
          <span class="sr-only">Next</span>
        </a>
      </li>

      <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
        <a class="page-link" aria-label="Last" href="javascript:void(0)" (click)="setPage(pager.totalPages)">
          <span aria-hidden="true">&raquo;</span>
          <span class="sr-only">Last</span>
        </a>
      </li>
    </ul>
  </nav>
</div>
