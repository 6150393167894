<div class="about_us_wrap">
    <div class="our_mission_sections">
        <div class="our_mission_overlay"></div>
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="mission_content">
                        <h1>Try a Demo to See Trinka in Action</h1>
                        <span>Instantly correct your English writing with the help of AI-powered free online grammar-checking tool. With Trinka, writing is now stress-free.</span>
                        <!-- <a href="https://demo.trinka.ai/" target="_blank">Try it Now</a> -->
                        <a href="https://cloud.trinka.ai/signup?utm_source=try-a-demo&utm_medium=en&utm_campaign=signup" target="_blank">Try it Now</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="brandLogo_sections">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="brandlogo_heading">
                    <h2>Preferred by users from</h2>
                </div>
                <div class="cover_wrapper">
                    <div id="client-logos" class="owl-carousel">
                        <div class="item">
                            <div class="client_inners">
                                <img src="../../../assets/images/MIT.jpg" alt="universities">
                            </div>
                        </div>
                        <div class="item">
                            <div class="client-inners">
                                <img src="../../../assets/images/StanfordUniversity.jpg" alt="universities">
                            </div>
                        </div>
                        <div class="item">
                            <div class="client-inners">
                                <img src="../../../assets/images/HarvardMedicalSchool.jpg" alt="universities">
                            </div>
                        </div>
                        <div class="item">
                            <div class="client-inners">
                                <img src="../../../assets/images/UCL.jpg" alt="universities">
                            </div>
                        </div>
                        <div class="item">
                            <div class="client_inners">
                                <img src="../../../assets/images/ImperialCollegeLondon.jpg" alt="universities">
                            </div>
                        </div>
                        <div class="item">
                            <div class="client-inners">
                                <img src="../../../assets/images/UniversityOfManchester.jpg" alt="universities">
                            </div>
                        </div>
                        <div class="item">
                            <div class="client-inners">
                                <img src="../../../assets/images/Duke.jpg" alt="universities">
                            </div>
                        </div>
                        <div class="item">
                            <div class="client-inners">
                                <img src="../../../assets/images/PrincetonUniversity.jpg" alt="universities">
                            </div>
                        </div>

                    </div>
                </div>
                <div class="viewfullList">
                    <!-- routerLink="/brand-list" -->
                    <a href="/brand-list" >View Full List</a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="the_best_grammer_sections">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="top_best_grammer_heading">
                    <h2>The Best Grammar and Language Correction AI Tool for Academic and Technical Writing</h2>
                    <p>Enhance and check your English writing instantly with Trinka's Cloud base <a href="/grammar-checker">online grammar checker</a>. Trinka's advanced
                        checking feature highlights mistakes and provides contextual suggestions that make your writing
                        rise and shine.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <div class="best_grammer_cards">
                    <h3>Checks Complex Errors that Others Don’t</h3>
                    <div class="content_body_best_grammer">
                        <div class="best_content_text">
                            <p>Trinka goes beyond simple grammar checks. It finds difficult errors unique to academic
                                writing, scientific tone, and style.</p>
                        </div>
                        <div class="mini_card_grammer">
                            <h4>Technical word choice</h4>
                            <p>Yield strength values, defined from <span class="wrong_text"> cycling</span> <span
                                    class="right_text"> cyclic</span> aging tests, are used for the parameterization of
                                the model.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="best_grammer_cards">
                    <h3>Made for Academic & Technical Writing</h3>
                    <div class="content_body_best_grammer">
                        <div class="best_content_text">
                            <p>Built specially to help academics and technical writers, Trinka corrects 3000+ complex
                                grammar errors and awkward sentence structure.</p>
                        </div>
                        <div class="mini_card_grammer">
                            <h4>Syntax</h4>
                            <p>The virus <span class="wrong_text"> is not requiring</span> <span class="right_text">
                                    does not require</span> us to <span class="wrong_text">remain</span> <span
                                    class="right_text"> stay</span> away from our communities.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="best_grammer_cards">
                    <h3>Suggests Corrections Relevant to Your Subject</h3>
                    <div class="content_body_best_grammer">
                        <div class="best_content_text">
                            <p>An Al-powered grammar checker that has learned from millions of best written research
                                papers and articles to give you the best suggestions.</p>
                        </div>
                        <div class="mini_card_grammer">
                            <h4>Advance Spelling</h4>
                            <p>Grind tissue in <span class="wrong_text">Trizol reagent</span> <span class="right_text">
                                    TRIzol® Reagent</span> using a disposable <span class="wrong_text">pestel</span>
                                <span class="right_text"> pestle</span> and centrifuge for 10 min at 4°C.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="best_grammer_cards">
                    <h3>Goes Beyond Standard Grammar Correction</h3>
                    <div class="content_body_best_grammer">
                        <div class="best_content_text">
                            <p>Trinka draws on the expertise of highly experienced editors and linguists. The result is
                                simple—the finest language enhancements.</p>
                        </div>
                        <div class="mini_card_grammer">
                            <h4>Sensitive language</h4>
                            <p>Eighty-eight percent were male and <span class="wrong_text">committed</span> <span
                                    class="right_text"> died by</span> suicide at a mean age of 25.6 years.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="ready_to_levelUp">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="TrinkaCorrectErro">
                    <h3>Trinka Corrects Errors that Other Tools Don't</h3>
                    <p>Trinka finds difficult errors unique to academic writing that other tools don't. From subject specific grammar errors to scientific tone and style, Trinka checks it all!</p>
                    <!-- <a href="https://demo.trinka.ai/" target="_blank">Try it Now</a> -->
                    <a href="https://cloud.trinka.ai/signup?utm_source=try-a-demo&utm_medium=en&utm_campaign=signup" target="_blank">Try it Now</a>
                </div>
            </div>
            <div class="col-md-6">
                <div class="ErrorCorrectionSection">
                    <a href="" data-toggle="modal" data-target="#tryAdemoPopup" id="VewiVideoSection">
                        <img src="../../../assets/images/ErrorCorrection_img.jpg" alt="error correct">
                        <!-- <img src="https://i.ytimg.com/vi/uNNpxOtvJ80/maxresdefault.jpg" alt=""> -->
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="tryAdemoPopup" tabindex="-1" role="dialog" aria-labelledby="tryAdemoPopupLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-body" style="padding: 0;">
            <button type="button" id="closedPopup" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <div id="iframeHolder"></div>
            <iframe class="youtubeVideoForTryDemo" id="iframe" width="100%" height="450" src="https://www.youtube.com/embed/8dmdUUvVj3Q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>

<div class="testimonail_demo_page">
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                <div class="testimonail_heading">
                    <h2>Testimonials</h2>
                    <p>Check what users are saying about Trinka</p>
                </div>
            </div>
        </div>
        <div class="row">
                <div class="col-lg-4 col-md-6">
                        <div class="testimonals_cards">
                            <div class="icons_secion">
                                <img src="../../../assets/images/quote_icons.png" alt="icon">
                            </div>
                            <p>Writing texts with correct English grammar and style is not an easy task for people who speak other languages. I tried Trinka AI as a tool of automatic correction and was quite glad with the suggested corrections and the feedback. With the feedback you understand why the suggested change must be taken into account. </p>
                            <div class="testimonals_footer">
                                <p>PhD Scholar, Mexico</p>
                            </div>
                        </div>
                    </div>
            <div class="col-lg-4 col-md-6">
                <div class="testimonals_cards">
                    <div class="icons_secion">
                        <img src="../../../assets/images/quote_icons.png" alt="icon">
                    </div>
                    <p>The grammar checking tool exceeds Grammarly! I re-checked some documents that I had checked
                        earlier with Grammarly. I was pleasantly surprised to see such a product from India!</p>
                    <div class="testimonals_footer">
                        <p>Radiologist, India</p>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-12">
                <div class="testimonals_cards testimonals_cards_last">
                    <div class="icons_secion">
                        <img src="../../../assets/images/quote_icons.png" alt="icon">
                    </div>
                    <p>I have tested Trinka and am impressed with its fine capabilities. It did better than the grammar
                        checker I use. Well done! I have told my colleagues in my research lab to use Trinka. Hope it is
                        widely used in academia.</p>
                    <div class="testimonals_footer">
                        <p>PhD Scholar, UK</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="ready_to_levelUp">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="level_up_heading">
                    <h3>Ready to level up your writing skills?</h3>
                    <a href="https://cloud.trinka.ai/signup?_rg=GB" target="_blank" id="grammerSignUpButton">
                        <!-- <i class="fa fa-sign-in"></i> -->
                        <span>Register for Free</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>