<div class="contactUsPopup">
    <form [formGroup]="feedbackForm" (submit)="onSubmitClick()" *ngIf="!feedbackSubmitted">
      <div class="contactUsHeader">
        <h2>Was the result accurate?</h2>
        <span class="like-dislike-container">
          <span [ngClass]="[isLiked ? 'selected-btn' : '', 'feedback-like-icon']" (click)="isLiked = true"></span>
          <span [ngClass]="[!isLiked ? 'selected-btn' : '', 'feedback-dislike-icon']" (click)="isLiked = false"></span>
        </span>
      </div>
      <div class="contactUsBody">
        <h3>Could you please tell us about the input text you used?</h3>
        <ul>
          <li *ngFor="let option of options; index as index">
            <span [ngClass]="[selectedOptionNumber === index ? 'selected-option' : '', 'feedback-option']" (click)="selectedOptionNumber = index">
                <span class="feedback-option-checkbox"></span>
                <span class="feedback-option-txt">{{option.text}}</span>
            </span>
          </li>
        </ul>
        <textarea matInput formControlName="feedbackText" placeholder="Do you have any other feedback or suggestions..."
        class="feedback-textarea"></textarea>
      </div>
      <div class="contactUsFooter">
        <button class="contactusCloseButton" mat-button mat-dialog-close>Cancel</button>
        <button type="submit" class="contactUsSubmitButton" [disabled]="selectedOptionNumber === -1">Submit</button>
      </div>
    </form>
    <div class="feedbackThankYou" *ngIf="feedbackSubmitted">
      <div class="SVGwrapper">
        <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"> <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/> <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/></svg>
      </div>
      <h2 class="animate__animated animate__fadeInUp animate__delay-1s">Thank you for sharing your feedback!</h2>
      <button class="contactusCloseButton animate__animated animate__fadeInUp animate__delay-1s" class="contactusCloseButton" mat-button mat-dialog-close>Close</button>
    </div>
  </div>
  