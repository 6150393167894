import { Component, OnInit, HostListener, PLATFORM_ID, Inject } from '@angular/core';
import { WebsiteService } from 'src/app/_services/website.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import * as $ from 'jquery';
import { LinkService } from '../../_services/langhfre.service';
// import { AmplitudeService } from 'src/app/_services/amplitude.service';
import { Router } from '@angular/router';
import { LoadScriptService } from 'src/app/_services/load-script.service';

declare function ire(string,{}): any;

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  contactForm: FormGroup;
  disabledSubmitButton: boolean = true;
  billingInvoiceText: boolean = false;
  isSubmitting: boolean = false;
  @HostListener('input') oninput() {

    if (this.contactForm.valid) {
      this.disabledSubmitButton = false;
    }
  }

  constructor(
    private fb: FormBuilder,
    private networkCall: WebsiteService,
    private toastr: ToastrService,
    private titleService: Title,
    private metaTagService: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
    private LinkService: LinkService,
    private router: Router,
    public loadScript: LoadScriptService
    // public amplitudeService: AmplitudeService

  ) {
    this.contactForm = fb.group({
      'contactFormName': ['', Validators.required],
      'contactFormEmail': ['', Validators.compose([Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)])],
      'contactFormContactNumber': [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(10),
          Validators.pattern(/^\d{10,}$/)
        ])
      ],
      'contactFormOrgTitle': ['', Validators.required],
      'contactFormInquiryType': ['', Validators.required],
      'contactFormMessage': ['', Validators.required]
    });
  }

  ngOnInit() {
    // this.amplitudeService.logAmplitude('home_page_contact_view', {});
    this.titleService.setTitle('Contact Us | Trinka');
    this.metaTagService.updateTag({ name: 'description', content: 'Contact our team for any help, request additional information, or business and sales opportunities. Fill in this form or write to us at support@trinka.ai' });
    this.metaTagService.updateTag({ property: 'og:title', content: 'Contact Us | Trinka' });
    this.metaTagService.updateTag({ property: 'og:description', content: 'Contact our team for any help, request additional information, or business and sales opportunities. Fill in this form or write to us at support@trinka.ai' });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/contactus' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/contactus' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/contactus' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/contactus' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ja-jp', href: 'https://www.trinka.ai/jp/contactus' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-cn', href: 'https://trinka.enago.cn/contactus' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-tw', href: 'https://www.trinka.ai/tw/contactus' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ko-kr', href: 'https://www.trinka.ai/kr/contactus' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'es-es', href: 'https://www.trinka.ai/es/contactus' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'pt-pt', href: 'https://www.trinka.ai/pt/contactus' });
    if (isPlatformBrowser(this.platformId)) {
      //$.getScript('../../../assets/js/wisepop.js', function () { });
      $.getScript('../../../assets/js/about_us_testimonial_silders.js', function () { });
    }
    ire('identify', { 'customerId': '', customerEmail: '', customProfileId: '' });
  }

  processForm() {
    // console.log("gggg")
    // if(this.contactForm.value.contactFormEmail && this.contactForm.value.contactFormName && this.contactForm.value.contactFormSubjects && this.contactForm.value.contactFormMessage){
    if (this.contactForm.valid) {
      this.isSubmitting = true;
      if (localStorage.getItem("country-trinka") == null || localStorage.getItem("country-trinka") == "") {
        this.loadGeoAPIScript();
      }
      console.log("vlaidated")
      const selectedInquiryType = this.contactForm.value.contactFormInquiryType;
      // Create the subject with the selected inquiry type
      const subject = `Inquiry Type: ${selectedInquiryType}`;
      var query = this.contactForm.value.contactFormName + "||" + this.contactForm.value.contactFormMessage + "||" + this.contactForm.value.contactFormContactNumber + "||" + this.contactForm.value.contactFormOrgTitle + "||" + this.contactForm.value.contactFormInquiryType
      this.networkCall.postContactUs(this.contactForm.value.contactFormEmail, query, this.router.url).subscribe(result => {
        this.toastr.success("Thanks! We will contact you shortly.")
        this.contactForm.reset();
        this.billingInvoiceText = false;
      }, error => {
        // this.shareService.errorHandller(error);
      }).add(() => {
        this.isSubmitting = false;
      });
    } else {
      // this.toastr.warning("Please fill in the necessary details on the form.")
      this.validateAllFormFields(this.contactForm);
    }
    // const allInfo = `My name is ${this.name}. My email is ${this.email}. My message is ${this.message}`;
    // alert(allInfo);
  }

  loadGeoAPIScript() {
    if (isPlatformBrowser(this.platformId)) {
      var scriptName = "geoIP"
      this.loadScript.loadScript(scriptName, '//geoip-js.com/js/apis/geoip2/v2.1/geoip2.js')
        .then(() => {
          console.log(`${scriptName} loaded successfully`);
          var onSuccess = function (location) {
            localStorage.setItem("country-trinka", location['country']['iso_code'])
          };

          var onError = function (error) {
            localStorage.setItem("country-trinka", "IN")
          };
          try {
            //@ts-ignore
            geoip2.city(onSuccess, onError);
          } catch (error) {
            console.log(error)
          }
        })
        .catch(() => {
          console.error(`Failed to load ${scriptName}`);
        });
    }
  }


  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }
  displayInvoiceEmail(event){
    if(event.target.value == "Billing/Invoice Support"){
      this.billingInvoiceText = true;
    }else{
      this.billingInvoiceText = false;
    }
    
  }

  
}
