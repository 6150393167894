<div class="trinka_cloud_wrap">
    <div id="particles-js"></div>
    <div class="professional_header">
        <div class="container">
            <div class="row">
                <div class="col-lg-8">
                    <div class="professional_heading">
                        <h1>Professional Editing Service</h1>
                        <!-- <img src="../../../assets/images/proofreaional_poweredby_img.png" alt="proofreaional_poweredby"> -->
                    </div>
                    <div class="professional_contents">
                        <p>Trinka helps you eliminate language errors from your paper.  Eliminating language errors is critical to make your paper publication-ready. Journals look for logical argumentation, scientific merit, robust methodology, and much more in deciding whether to publish a paper. </p>
                        <p>Enago’s proofreading services go beyond language and grammar. Your paper is edited by an expert in your subject for logical flow, structure, and journal guidelines. Enago offers pre-submission peer-reviews too.</p>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="professional_img">
                        <img src="../../../assets/images/searching_for_proofreading.png" alt="searching_for_proofreading">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="profressional_pricing">
        <div class="profressional_pricing_overlay"></div>
        <div class="container">
            <div class="row">
                <div class="col-lg-4">
                    <div class="profressional_cards">
                        <div class="prof_card_heading">
                            <h2><img src="../../../assets/images/Proofreading_icons.png" alt="icon"> Proofreading</h2>
                            <p>Ideal for authors who are looking for a grammar and consistency check on their manuscripts, just before journal submission.</p>
                        </div>
                        <div class="prof_card_bodysection">
                            <ul>
                                <li>Grammar and Syntax Corrections</li>
                                <li>Punctuation Checks</li>
                                <li>Spelling and Tenses Checks</li>
                                <li>Writing Style and Consistency</li>
                                <li>Single Editor matching</li>
                                <li>Unlimited editor Q&A for up to 1 year</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 paddingSectionRemove">
                    <div class="profressional_cards">
                        <div class="prof_card_heading">
                            <h2><img src="../../../assets/images/CopyEditing_Icon.png" style="width: 40px;" alt="icon"> Copy Editing</h2>
                            <p>Ideal for authors who need a quality editing service that includes sentence-level English editing, grammar and syntax corrections.</p>
                        </div>
                        <div class="prof_card_bodysection">
                            <div class="additional_services">
                                <p><img src="../../../assets/images/Proofreading_icons.png" alt="icon" style="width: 20px;"> Everything in Proofreading</p>
                                <span><i class="fa fa-plus"></i></span>
                            </div>
                            <ul>
                                <li>Language Enhancement (Clarity & Readability)</li>
                                <li>Advanced Grammar Checks</li>
                                <li>Word and Phrase Choices</li>
                                <li>Sentence Structure</li>
                                <li>Basic Formatting</li>
                                <li>10% Word Count Reduction</li>
                                <li>Subject-area based two-editor matching with native English-speaking editors</li>
                                <li><strong>1-page</strong> Manuscript Evaluation Report 
                                    <span class="prof_tooltipSection"><img src="../../../assets/images/info.svg" alt="icon"></span>
                                    <div class="tooltiptext_section CopyEditingSection">
                                        <p>A detailed report containing a full analysis, including all the comments and critiques put forward for your manuscript.</p>
                                    </div>
                                </li>
                                <li><strong>120 days</strong> Unlimited Editing* 
                                    <span class="prof_tooltipSection"><img src="../../../assets/images/info.svg" alt="icon"></span>
                                    <div class="tooltiptext_section">
                                        <p>Reformatting based on the new/existing journal and unlimited rounds of editing.</p>
                                        <img src="../../../assets/images/PaperIcons.png" alt="paper icon">
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="profressional_cards">
                        <div class="prof_card_heading">
                            <h2> <img src="../../../assets/images/SubstantiveEditing_Icon.png"  style="width: 40px;" alt="icon"> Substantive Editing</h2>
                            <p>Ideal for authors who require editing beyond sentence level editing to improve logical flow, structure and content organization.</p>
                        </div>
                        <div class="prof_card_bodysection">
                            <div class="additional_services">
                                <p><img src="../../../assets/images/CopyEditing_Icon.png" style="width: 30px;" alt="icon"> Everything in Copy Editing</p>
                                <span><i class="fa fa-plus"></i></span>
                            </div>
                            <ul>
                                <li>Content Enhancement</li>
                                <li>Technical Accuracy</li>
                                <li>Logical Flow</li>
                                <li>Manuscript Structure</li>
                                <li>Advanced Formatting & Presentation</li>
                                <li>20% Word Count Reduction</li>
                                <li>Cover Letter</li>
                                <li><strong>4-page</strong> Advance Manuscript Evaluation Report <span class="prof_tooltipSection"><img src="../../../assets/images/info.svg" alt="icon"></span>
                                    <div class="tooltiptext_section SubstantiveSection">
                                        <p>A detailed report containing a full analysis, including all the comments and critiques put forward for your manuscript.</p>
                                    </div>                                
                                </li>
                                <li><strong>365 days</strong> Unlimited Editing <span class="prof_tooltipSection"><img src="../../../assets/images/info.svg" alt="icon"></span>
                                    <div class="tooltiptext_section">
                                        <p>Reformatting based on the new/existing journal and unlimited rounds of editing.</p>
                                        <img src="../../../assets/images/PaperIcons.png" alt="paper icon">
                                    </div>
                                </li>
                                <li>
                                    <strong>365 days</strong> Rejection Shield* <span class="prof_tooltipSection"><img src="../../../assets/images/info.svg" alt="icon"></span>
                                    <div class="tooltiptext_section">
                                        <p> Ensures that your paper is ready for resubmission after facing rejection.</p>
                                        <img src="../../../assets/images/Rejections_Shield.png" alt="rejections" style="margin-left: -20px;">
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="paidSection">
                        <p>*Paid Add-on</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>