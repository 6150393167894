import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { WebsiteService } from 'src/app/_services/website.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { LoadScriptService } from 'src/app/_services/load-script.service';

@Component({
  selector: 'app-enterprise-modal',
  templateUrl: './enterprise-modal.component.html',
  styleUrls: ['./enterprise-modal.component.scss']
})
export class EnterpriseModalComponent implements OnInit {

  email: any;
  // emailEnterprise : FormGroup;
  contactForm: FormGroup;
  formSection: boolean = true;
  thankSection: boolean = false;
  country: string;
  isSubmitting: boolean = false;
  constructor(
    private toastr: ToastrService,
    private networkCall: WebsiteService,
    private fb: FormBuilder,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private _document: Document,
    private router: Router,
    public loadScript: LoadScriptService
  ) {
    this.contactForm = fb.group({
      'contactFormName': ['', Validators.required],
      'contactFormEmail': ['', Validators.compose([Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)])],
      'contactFormSubjects': ['', Validators.required],
      'contactFormMessage': ['', Validators.required],
      'contactphoneNo': ['', Validators.required],
      'NatureBusinessType': ['', Validators.required],
      'DesignationTypes': ['', Validators.required],
    });
  }

  ngOnInit() {
  }
  sectionValue(section: string) {
    this.formSection = true;
    this.thankSection = false;
  }

  sendContactUsForm() {
    if (this.contactForm.valid) {
      this.isSubmitting = true;
      if (localStorage.getItem("country-trinka") == null || localStorage.getItem("country-trinka") == "") {
        this.loadGeoAPIScript();
      }
      this.country = localStorage.getItem("country-trinka") ? localStorage.getItem("country-trinka") : "en";
      var query = this.contactForm.value.NatureBusinessType + "||" + this.contactForm.value.contactFormSubjects + "||" + this.contactForm.value.contactFormMessage + "||" + this.contactForm.value.contactFormName + "||" + this.contactForm.value.DesignationTypes + "||" + this.contactForm.value.contactphoneNo
      this.networkCall.postContactUs(this.contactForm.value.contactFormEmail, query, this.router.url).subscribe(result => {
        this.formSection = false;
        this.thankSection = true;
        this.country;
        this.toastr.success("Thanks! We will contact you shortly.")
        this.contactForm.reset();
      }, error => {
        this.toastr.warning("Something went wrong!")
      }).add(() => {
        this.isSubmitting = false;
      });
    } else {
      this.validateAllFormFields(this.contactForm)
    }
  }

  loadGeoAPIScript() {
    if (isPlatformBrowser(this.platformId)) {
      var scriptName = "geoIP"
      this.loadScript.loadScript(scriptName, '//geoip-js.com/js/apis/geoip2/v2.1/geoip2.js')
        .then(() => {
          console.log(`${scriptName} loaded successfully`);
          var onSuccess = function (location) {
            localStorage.setItem("country-trinka", location['country']['iso_code'])
          };

          var onError = function (error) {
            localStorage.setItem("country-trinka", "IN")
          };
          try {
            //@ts-ignore
            geoip2.city(onSuccess, onError);
          } catch (error) {
            console.log(error)
          }
        })
        .catch(() => {
          console.error(`Failed to load ${scriptName}`);
        });
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }

}
