import { Component, OnInit} from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { LinkService } from '../../../_services/langhfre.service';
declare function ire(string,{}): any;

@Component({
  selector: 'app-custom-aimodels',
  templateUrl: './custom-aimodels.component.html',
  styleUrls: ['./custom-aimodels.component.scss']
})
export class CustomAIModelsComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private LinkService: LinkService,
  ) {
  }

  ngOnInit() {
    this.titleService.setTitle('Build Custom AI Models for Enterprises | Own Your AI with Trinka');
    this.metaTagService.updateTag({ name: 'description', content: "Create custom AI models tailored to your enterprise with Trinka AI. Gain control, protect your intellectual property, and leverage proprietary data for precision." });
    this.metaTagService.updateTag({ property: 'og:title', content: "Build Custom AI Models for Enterprises | Own Your AI with Trinka" });
    this.metaTagService.updateTag({ property: 'og:description', content: "Create custom AI models tailored to your enterprise with Trinka AI. Gain control, protect your intellectual property, and leverage proprietary data for precision." });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/enterprise/custom-ai-models/overview' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.trinka.ai/assets/images/Trinka.jpg' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/enterprise/custom-ai-models/overview' });
    this.metaTagService.updateTag({ name: 'twitter:title', content: 'Build Custom AI Models for Enterprises | Own Your AI with Trinka' });
    this.metaTagService.updateTag({ name: 'twitter:description', content: "Create custom AI models tailored to your enterprise with Trinka AI. Gain control, protect your intellectual property, and leverage proprietary data for precision." });
    this.metaTagService.updateTag({ name: 'twitter:image', content: 'https://www.trinka.ai/assets/images/Trinka.jpg' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/enterprise/custom-ai-models/overview' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/enterprise/custom-ai-models/overview' });

    ire('identify', { 'customerId': '', customerEmail: '', customProfileId: '' });
    // console.log("Country is", this.country);
  }
  

}
