<div class="example_outer_section">
    <div class="example_top_heading">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <h1>Trinka Offers All-Round Writing Enhancement</h1>
                    <p>Designed to help academics and technical writers, Trinka checks your writing not just for grammar but also for advanced writing needs that are critical to effective communication.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="exapmle_cards_heading">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <h3>Advanced English grammar checker to improve your writing quality</h3>
                    <p>Academic and technical writing present peculiar writing errors that other tools don’t catch. Trinka comes to your rescue!</p>
                </div>
            </div>
        </div>
    </div>

    <div class="example_boxs">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="example_box_contents">
                        <h4>Subject-verb disagreement</h4>
                        <p>Therefore, these results suggest that the hypersecretion of CRH and endorphin
                            <span>suppress</span>
                            <span class="right_word">suppresses</span> the secretion of LH and FSH from the pituitary gland.
                        </p>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="example_box_contents">
                        <h4>Word choice</h4>
                        <p>The statistical analyses were performed <span>with</span> <span class="right_word">using</span> a statistical analysis software (SAS).</p>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="example_box_contents">
                        <h4>Preposition</h4>
                        <p>Recent years have seen an increase <span>of</span> <span class="right_word">in</span> exudation of various organic acids.</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="example_box_contents">
                        <h4>Adjective use</h4>
                        <p>The group receiving the placebo treatment had the <span>most</span> highest improvement in sleep scores.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="exapmle_cards_heading background_color_changes">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <h3>Enhancements for publication-standard writing</h3>
                    <p>With checks that enhance tone, style, and adherence to standard style guides, Trinka helps you make your writing fit for the academic audience.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="example_boxs boxs_margin background_color_changes">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="example_box_contents">
                        <h4>Conciseness</h4>
                        <p>Utilities would continue to <span>have the obligation</span> <span class="right_word">be
                                obliged</span> to serve retail customers.</p>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="example_box_contents">
                        <h4>Formal tone</h4>
                        <p>The <span>average</span> <span class="right_word">mean</span> age of the study group was 54.2 years (range: 8-90 years).</p>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="example_box_contents">
                        <h4>Word choice</h4>
                        <p>The assembly <span>abrogated</span> <span class="right_word">arrogated</span> to itself the right to make changes.</p>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="example_box_contents">
                        <h4>Style guide</h4>
                        <p>Substance <span>abuse</span> <span class="right_word">use disorder</span> has been a major public health concern.</p>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="exapmle_cards_heading">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <h3>Subject-specific checks for precise corrections</h3>
                    <p>Trinka knows that every subject area has a unique expression. It ensures that corrections do not tamper with the style specific to your subject area.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="example_boxs boxs_margin">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="example_box_contents">
                        <h4>Computer science</h4>
                        <p>In our study, 90% of the users expressed the need for a <span>dialogue</span> <span class="right_word">dialog</span> box to alert them to a pending update.</p>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="example_box_contents">
                        <h4>Physics</h4>
                        <p>By contrast, the <span>effect</span> <span class="right_word">effects</span> of the Mach number and <span class="">heat</span> specific <span class="right_word">heat</span> ratio contribute little to the aerodynamic performance.
                        </p>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="example_box_contents">
                        <h4>Medicine</h4>
                        <p>Our study matched for age, <span>gender</span> <span class="right_word">sex</span>, ethnicity, and BMI, marijuana use was associated with higher abdominal visceral fat.
                        </p>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="example_box_contents">
                        <h4>Economics</h4>
                        <p>The estimated natural rate, based on the <span>past</span> history of the data, plummets by nearly 2
                            <span>percent</span> <span class="right_word">percentage</span> points.
                        </p>
                    </div>
                </div>

            </div>
        </div>
    </div>

</div>