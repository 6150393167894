
<div class="trinka_cloud_wrap"> 
 
    <section class="section-pd notification-form">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="spellcheck_heading">
                        <div class="row">
                            <div class="col-lg-12  col-12">
                                <div class="row justify-content-center">
                                    <div class="col-lg-8 col-md-10">
                                       <div class="trinka-title">
                                           <h2>We are sorry to see you go! </h2>
                                           <p class="mt-10">We would appreciate your input to help us improve. Please take a moment to share your feedback.</p>
                                           
                                       </div>
                                   </div>
                                </div>
                                <div class="row justify-content-center">
                                    <div class="col-lg-8 col-md-10">
                                        <div class="hero-frame">
                                           <iframe
                                               id="JotFormIFrame-243190609464459"
                                               title="Windows Desktop App"
                                               onload="window.parent.scrollTo(0,0)"
                                               allowtransparency="true"
                                               allow="geolocation; microphone; camera; fullscreen"
                                               src="https://www.jotform.com/form/243190609464459"
                                               frameborder="0"
                                               style="min-width:100%;max-width:100%;height:539px;border:none;"
                                               scrolling="no"
                                             >
                                             </iframe>                                              
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</div>