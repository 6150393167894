import { Component, OnInit} from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { LinkService } from '../../../../_services/langhfre.service';
declare function ire(string,{}): any;

@Component({
  selector: 'app-rlhfmodel',
  templateUrl: './rlhfmodel.component.html',
  styleUrls: ['./rlhfmodel.component.scss']
})
export class RLHFModelComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private LinkService: LinkService,
  ) {
  }

  ngOnInit() {
    this.titleService.setTitle('Reinforcement Learning with Human Feedback (RLHF) | Transforming AI for Enterprises');
    this.metaTagService.updateTag({ name: 'description', content: "Discover how RLHF fine-tunes AI models for precision and human-like responses. Leverage human feedback to create custom, accurate, and adaptive AI for your enterprise." });
    this.metaTagService.updateTag({ property: 'og:title', content: "Reinforcement Learning with Human Feedback (RLHF) | Transforming AI for Enterprises" });
    this.metaTagService.updateTag({ property: 'og:description', content: "Discover how RLHF fine-tunes AI models for precision and human-like responses. Leverage human feedback to create custom, accurate, and adaptive AI for your enterprise." });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/enterprise/custom-ai-models/rlhf' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.trinka.ai/assets/images/Trinka.jpg' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/enterprise/custom-ai-models/rlhf' });
    this.metaTagService.updateTag({ name: 'twitter:title', content: 'Reinforcement Learning with Human Feedback (RLHF) | Transforming AI for Enterprises' });
    this.metaTagService.updateTag({ name: 'twitter:description', content: "Discover how RLHF fine-tunes AI models for precision and human-like responses. Leverage human feedback to create custom, accurate, and adaptive AI for your enterprise." });
    this.metaTagService.updateTag({ name: 'twitter:image', content: 'https://www.trinka.ai/assets/images/Trinka.jpg' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/enterprise/custom-ai-models/rlhf' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/enterprise/custom-ai-models/rlhf' });

    ire('identify', { 'customerId': '', customerEmail: '', customProfileId: '' });
    // console.log("Country is", this.country);
  }

}
