import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import * as $ from 'jquery';
import { LinkService } from '../../_services/langhfre.service';
declare function ire(string,{}): any;
@Component({
  selector: 'app-examples',
  templateUrl: './examples.component.html',
  styleUrls: ['./examples.component.scss']
})
export class ExamplesComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
    private LinkService: LinkService
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Enhance Your Writing with the Best Online Grammar Checker - Trinka');
    this.metaTagService.updateTag({ name: 'description', content: 'Improve the quality of your writing with the best free online grammar checker. Improve the quality of your content and convey your intent accurately and clearly with Trinka.' });
    this.metaTagService.updateTag({ property: 'og:title', content: 'Enhance Your Writing with the Best Online Grammar Checker - Trinka' });
    this.metaTagService.updateTag({ property: 'og:description', content: 'Improve the quality of your writing with the best free online grammar checker. Improve the quality of your content and convey your intent accurately and clearly with Trinka.' });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/examples' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/examples' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/examples' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/examples' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ja-jp', href: 'https://www.trinka.ai/jp/examples' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-cn', href: 'https://trinka.enago.cn/examples' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-tw', href: 'https://www.trinka.ai/tw/examples' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ko-kr', href: 'https://www.trinka.ai/kr/examples' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'es-es', href: 'https://www.trinka.ai/es/examples' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'pt-pt', href: 'https://www.trinka.ai/pt/examples' });
    if (isPlatformBrowser(this.platformId)) {
      //$.getScript('../../../assets/js/wisepop.js', function () { });
    }
    ire('identify', { 'customerId': '', customerEmail: '', customProfileId: '' });
  }

  

}
