import { Component, OnInit, AfterViewInit, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import * as $ from 'jquery';
import { LinkService } from '../../_services/langhfre.service';
declare function ire(string,{}): any;
@Component({
  selector: 'app-browser-plugin',
  templateUrl: './browser-plugin.component.html',
  styleUrls: ['./browser-plugin.component.scss']
})
export class BrowserPluginComponent implements OnInit {
  documentMode: any;
  navigator: any;

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
    private LinkService: LinkService
  ) { }


  ngOnInit() {
    this.titleService.setTitle('Free Grammar Check Browser Plugin - Chrome, Firefox, Edge - Trinka');
    this.metaTagService.updateTag({ name: 'description', content: "Trinka free addon for Chrome, Firefox and Edge Browsers makes effective writing easier than ever! Get professional grammar checks and suggestions in realtime." });
    this.metaTagService.updateTag({ property: 'og:title', content: 'Free Grammar Check Browser Plugin - Chrome, Firefox, Edge - Trinka' });
    this.metaTagService.updateTag({ property: 'og:description', content: '​Trinka free addon for Chrome, Firefox and Edge Browsers makes effective writing easier than ever! Get professional grammar checks and suggestions in realtime.​' });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.trinka.ai/browser-plugin' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.trinka.ai/assets/images/BrowserPlugin_banner.png' });
    this.metaTagService.updateTag({ name: 'language', content: 'en-us' });
    this.metaTagService.updateTag({ name: 'twitter:title', content: 'Free Grammar Check Browser Plugin - Chrome, Firefox, Edge - Trinka' });
    this.metaTagService.updateTag({ name: 'twitter:description', content: "Trinka free addon for Chrome, Firefox and Edge Browsers makes effective writing easier than ever! Get professional grammar checks and suggestions in realtime." });
    this.metaTagService.updateTag({ name: 'twitter:image', content: 'https://www.trinka.ai/assets/images/BrowserPlugin_banner.png' });
    this.LinkService.addTag({ rel: 'canonical', href: 'https://www.trinka.ai/browser-plugin' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'x-default', href: 'https://www.trinka.ai/browser-plugin' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'en-us', href: 'https://www.trinka.ai/browser-plugin' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ja-jp', href: 'https://www.trinka.ai/jp/browser-plugin' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-cn', href: 'https://trinka.enago.cn/browser-plugin' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'zh-tw', href: 'https://www.trinka.ai/tw/browser-plugin' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'ko-kr', href: 'https://www.trinka.ai/kr/browser-plugin' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'es-es', href: 'https://www.trinka.ai/es/browser-plugin' });
    this.LinkService.addTag({ rel: 'alternate', hreflang: 'pt-pt', href: 'https://www.trinka.ai/pt/browser-plugin' });
    if (isPlatformBrowser(this.platformId)) {
      // document.getElementById('main_header').classList.add('homepageHeader');
      // $.getScript('../../../assets/js/particles.min.js', function(){});
      $.getScript('../../../assets/js/hubslider.js', function () { });
      $.getScript('../../../assets/js/owl.carousel.min.js', function () { });
      //$.getScript('../../../assets/js/wisepop.js', function () { });
      $.getScript('../../../assets/js/browser.js', function () { });
      const userAgent = window.navigator.userAgent;
      // console.log(this.myBrowser());
    }
    // myBrowser() {
      if (isPlatformBrowser(this.platformId)) {
        if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
          $("#BrowserGoogleChromeSection").css("display", "none");
          $("#BrowserFirefoxSection").css("display", "none");
          $("#BrowserEdgeBrowserSection").css("display", "none");
          $("#BrowserGoogleChromeSection_cta").css("display", "none");
          $("#BrowserFirefoxSection_cta").css("display", "none");
          $("#BrowserEdgeBrowserSection_cta").css("display", "none");
          $("#safariBrowser").css("display", "none");
          return 'Opera';
  
        } else if (navigator.userAgent.indexOf("Chrome") != -1) {
          $("#BrowserGoogleChromeSection").css("display", "block");
          $("#BrowserFirefoxSection").css("display", "none");
          $("#BrowserEdgeBrowserSection").css("display", "none");
          $("#BrowserGoogleChromeSection_cta").css("display", "block");
          $("#BrowserFirefoxSection_cta").css("display", "none");
          $("#BrowserEdgeBrowserSection_cta").css("display", "none");
          $("#safariBrowser").css("display", "none");
          return 'Chrome';
  
        } else if (navigator.userAgent.indexOf("Safari") != -1) {
          $("#BrowserGoogleChromeSection").css("display", "none");
          $("#BrowserFirefoxSection").css("display", "none");
          $("#BrowserEdgeBrowserSection").css("display", "none");
          $("#BrowserGoogleChromeSection_cta").css("display", "none");
          $("#BrowserFirefoxSection_cta").css("display", "none");
          $("#BrowserEdgeBrowserSection_cta").css("display", "none");
          
          return 'Safari';
  
        } else if (navigator.userAgent.indexOf("Firefox") != -1) {
          $("#BrowserGoogleChromeSection").css("display", "none");
          $("#BrowserFirefoxSection").css("display", "block");
          $("#BrowserEdgeBrowserSection").css("display", "none");
          $("#BrowserGoogleChromeSection_cta").css("display", "none");
          $("#BrowserFirefoxSection_cta").css("display", "block");
          $("#BrowserEdgeBrowserSection_cta").css("display", "none");
          $("#safariBrowser").css("display", "none");
          return 'Firefox';
  
        }
        else if ((navigator.userAgent.indexOf("MSIE") != -1)) {
          $("#BrowserGoogleChromeSection").css("display", "none");
          $("#BrowserFirefoxSection").css("display", "none");
          $("#BrowserEdgeBrowserSection").css("display", "block");
          $("#BrowserGoogleChromeSection_cta").css("display", "none");
          $("#BrowserFirefoxSection_cta").css("display", "none");
          $("#BrowserEdgeBrowserSection_cta").css("display", "block");
          $("#safariBrowser").css("display", "none");
          return 'IE';
        }
        else {
          return 'unknown';
        }
      }
      ire('identify', { 'customerId': '', customerEmail: '', customProfileId: '' });
    // }
  }
  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      $.getScript('../../../assets/js/home_client_slider.js', function () { });
    }
  }
  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      //document.getElementById('main_header').classList.remove('homepageHeader');
    }
  }

  
}
