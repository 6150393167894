import { Component, HostListener, Inject, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { DetectionLimitExhaustModalComponent } from '../detection-limit-exhaust-modal/detection-limit-exhaust-modal.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay'
import { FeedbackModalComponent } from 'src/app/Components/feedback-modal/feedback-modal.component';
import { isPlatformBrowser } from '@angular/common';

declare var $: any;
import * as $ from 'jquery';

@Component({
  selector: 'app-english-grammar-checker',
  templateUrl: './english-grammar-checker.component.html',
  styleUrls: ['./english-grammar-checker.component.scss']
})
export class EnglishGrammarCheckerComponent implements OnInit {
  public minLength = 0;
  public maxLength = 300;
  private isMobile: boolean = false;
  
  editorConfig: any = {
    endpoint: environment.server_url.multilingual_server,
    lang: 'en',
    minHeight: 360,
    sessionLimit: 3
  };
  constructor(
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (window.matchMedia("(max-width: 767px)").matches) {
        // Code for screens below 767px
        $('.left-side .card-footer button').click(function(){
          $('.left-side .editor-wrapper').css({
            'min-height': '120px',
            'max-height': '120px'
          });
          $('.left-side .form-group').css({
            'min-height': '120px'
          });
          $('.left-side').addClass('resultAdjust');
        });
      
        $(".left-side .editor-wrapper").on("click", function() {
          $('.left-side .editor-wrapper').css({
            'min-height': '200px',
            'max-height': '280px'
          });
          $('.left-side.resultAdjust .form-group').css({
            'min-height': '200px'
          });
          $('.left-side').removeClass('resultAdjust');
        });

        $(".right-side .editor-wrapper").on("click", function() {
          $('.left-side .editor-wrapper').css({
            'min-height': '120px',
            'max-height': '120px'
          });
          $('.left-side .form-group').css({
            'min-height': '120px'
          });
        });
      }
    }
  }

  showSnackBar($event: any) {
    console.log('showSnackBar ===> ', $event);
    this._snackBar.open($event, '', {
      duration: 3000
    });
  }
  sessionExhausted($event: any) {
    console.log('limitExhausted ===> ', $event);
    this._dialog.open(DetectionLimitExhaustModalComponent, {
      backdropClass: 'custom-dialog-background',
      width: '490px',
      height: 'auto',
      scrollStrategy: new NoopScrollStrategy(),
      panelClass: "border-radius16",
    });
  }
  openFeedbackModal($event: any) {
    console.log('openFeedbackModal ===> ', $event);
    const dialogRef = this._dialog.open(FeedbackModalComponent, {
      width: '464px',
      height: 'auto',
      disableClose: true,
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        feature: 'multilingual',
        language: this.editorConfig.lang
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed', result);
    });
  }
}
