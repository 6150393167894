import { Component, OnInit, Inject, PLATFORM_ID, HostListener } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LoadScriptService } from 'src/app/_services/load-script.service';

declare var $: any;
declare const sourceMediumData: any;

import * as $ from 'jquery';
// import * as AOS from 'aos';
import { WINDOW } from '@ng-toolkit/universal';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  notSticky: boolean = false;
  isMenuActive: boolean = false;
  isBackdropActive: boolean = false;
  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(PLATFORM_ID) private platformId: Object,
    public loadScript: LoadScriptService,
  ) {
    
   }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      $(".dropdown-toggle").hover(function(){
        $('.dropdown-menu').css("opacity", "1");
        $('.dropdown-menu').css("visibility", "visible");
      });
      $('.menu-card a').click(function(){
        $('.dropdown-menu').css("opacity", "0");
        $('.dropdown-menu').css("visibility", "hidden");
      });
    }
  }

  closeCanvasMenu() {
    this.isBackdropActive = false;
    const offCanvasMenu = document.querySelector('.navbar-collapse.offcanvas');
    if (offCanvasMenu) {
      offCanvasMenu.classList.add('hiding');
      setTimeout(() => {
        this.isMenuActive = false;
        offCanvasMenu.classList.remove('hiding', 'show');
      }, 300);
    }
  }
  
  toggleOffCanvasMenu() {
    const offCanvasMenu = document.querySelector('.navbar-collapse.offcanvas');
    this.isBackdropActive = true;
    if (!this.isMenuActive) {
      offCanvasMenu?.classList.add('showing');
      setTimeout(() => {
        this.isMenuActive = true;
        offCanvasMenu?.classList.remove('showing');
        offCanvasMenu?.classList.add('show');
      }, 300);
    } else {
      this.closeCanvasMenu();
    }
  }

  toggleClass(event: Event): void {
    const allPTags = document.querySelectorAll('.nav-link.dropdown-toggle');
    
    // Remove 'show' class from all <p> tags
    allPTags.forEach((pTag) => {
      pTag.classList.remove('show');
    });
  
    // Add 'show' class to the clicked <p> tag
    const clickedPTag = event.target as HTMLElement;
    clickedPTag.classList.toggle('show');
  }

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.notSticky = window.pageYOffset >= 100;
  }

  generateLinkWithSourceMedium(isSignupLink: boolean = true): string {
    //TODO: Change uat.cloud.trinka.ai to cloud.trinka.ai when going live
    let redirectLink = `https://cloud.trinka.ai/${isSignupLink ? 'signup' : 'signin'}?_rg=GB`;
    // if (sourceMediumData.source) {
    try {
      redirectLink += `&source=${sourceMediumData.source}`;
      redirectLink += `&medium=${sourceMediumData.medium}`;
    } catch (e) {
      redirectLink += `&source=''`;
      redirectLink += `&medium=''`;
    }

    // }
    // if (sourceMediumData.medium) {
      // redirectLink += `&medium=${sourceMediumData.medium}`;
    // }
    return redirectLink;
  }

}
