<section class="section-pd">
    <div class="trinka_container">
        <div class="secure-wrapper">
            <div class="row align-items-center match-box">
                <div class="col-md-6 col-lg-6 dflex">
                    <div class="trinka-title mb-0">
                        <h2>Safe, Secure, and Trusted</h2>
                        <div class="HeadingSubLine1"><span></span><span></span><span></span></div>
                        <p class="mb-2">Trinka puts data safety and privacy at the forefront. All your data is strongly encrypted and securely stored - no one else has access to your data. You can delete your dashboard data at any-time.</p>
                        <p class="mb-30">The data of premium users is automatically deleted from all systems every 90 days. To further enhance security and meet compliance requirements, various options, such as Sensitive Data Plans, offer additional features to safeguard your private data.</p>
                        <div class="btn-group">
                            <a href="/data-security" class="filled-btn">Know More</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 dflex ">
                    <div class="text-center">
                        <!-- <picture>
                            <source srcset="../../../assets/images/homepage/data-security.webp" type="image/webp">
                            <img src="../../../assets/images/homepage/data-security.jpg" loading="lazy" decoding="async" alt="data-security">
                        </picture> -->
                        <img src="../../../assets/images/homepage/data-security.svg" class="img-fluid" loading="lazy" decoding="async" alt="data-security">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>