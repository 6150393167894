<div class="trinka_cloud_wrap">
    <div id="particles-js"></div>
    <!-- <div class="cloud_top_banners">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="trinka_cloud_banner_text">
                        <h1>Technical Checks</h1>
                        <h2>Make Your Manuscript<br />
                            Publication Ready</h2>
                        <p>Evaluate your manuscript against 20+ standard publication requirements to get through journal screening faster. Trinka even lets you see how well your manuscript matches your target journal’s scope.</p>
                        <a href="https://cloud.trinka.ai/signup?_rg=GB" id="autoEditGetStart" target="_blank">Get Started</a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="toolbox_img_content publication_checks_icons publication_checks_section">
                        <ul>
                            <li>
                                <div class="checks_icons">
                                    <img src="../../../assets/images/JournalScope.svg" alt="jorunal scope">
                                </div>
                                <h3>Journal Scope Match</h3>
                                <span>2 Checks</span>
                            </li>

                            <li>
                                <div class="checks_icons">
                                    <img src="../../../assets/images/TechnicalCompliance.svg" alt="technical">
                                </div>
                                <h3>Technical Compliances</h3>
                                <span>3 Checks</span>
                            </li>

                            <li>
                                <div class="checks_icons">
                                    <img src="../../../assets/images/EthicalCompliance.svg" alt="EthicalCompliance">
                                </div>
                                <h3>Ethical Compliance</h3>
                                <span>5 Checks</span>
                            </li>

                            <li>
                                <div class="checks_icons">
                                    <img src="../../../assets/images/AuthorshipDetails.svg" alt="author ship">
                                </div>
                                <h3>Authorship Details</h3>
                                <span>4 Checks</span>
                            </li>
                            <li>
                                <div class="checks_icons">
                                    <img src="../../../assets/images/ReferenceDetails.svg" alt="reference">
                                </div>
                                <h3>Reference Details</h3>
                                <span>1 Check</span>
                            </li>

                            <li>
                                <div class="checks_icons">
                                    <img src="../../../assets/images/FirgureTable.svg" alt="figure table">
                                </div>
                                <h3>Figures & Tables</h3>
                                <span>2 Checks</span>
                            </li>

                            <li>
                                <div class="checks_icons">
                                    <img src="../../../assets/images/ClinicalTrials.svg" alt="clinical trails">
                                </div>
                                <h3>Clinical Trial Information</h3>
                                <span>1 Check</span>
                            </li>

                            <li>
                                <div class="checks_icons">
                                    <img src="../../../assets/images/KeywordsAndSummary.svg" alt="keywords">
                                </div>
                                <h3>Keywords & Summary</h3>
                                <span>2 Checks</span>
                            </li>
                            <i></i>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <section class="mainTopBanner">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="topBannerWrapper">
                        <div class="top-banner-left">
                            <h1>Technical Checks</h1>
                            <h2>Get Your Paper Submission-Ready!</h2>
                            <p>With over 25+ checks, make sure your manuscript is publication ready, instantly! Get published with just one submission! </p>
                            <div class="btn-group">
                                <a href="https://cloud.trinka.ai/signup?_rg=GB" class="filled-btn" target="_blank">Get Started</a>
                            </div>
                        </div>
                        <div class="top-banner-right">
                            <div class="toolbox_img_content publication_checks_icons publication_checks_section">
                                <ul>
                                    <li>
                                        <div class="checks_icons">
                                            <img src="../../../assets/images/JournalScope.svg" class="auto-height" alt="jorunal scope">
                                        </div>
                                        <h3>Journal Scope Match</h3>
                                        <span>2 Checks</span>
                                    </li>
        
                                    <li>
                                        <div class="checks_icons">
                                            <img src="../../../assets/images/TechnicalCompliance.svg" class="auto-height" alt="technical">
                                        </div>
                                        <h3>Technical Compliances</h3>
                                        <span>3 Checks</span>
                                    </li>
        
                                    <li>
                                        <div class="checks_icons">
                                            <img src="../../../assets/images/EthicalCompliance.svg" class="auto-height" alt="EthicalCompliance">
                                        </div>
                                        <h3>Ethical Compliance</h3>
                                        <span>5 Checks</span>
                                    </li>
        
                                    <li>
                                        <div class="checks_icons">
                                            <img src="../../../assets/images/AuthorshipDetails.svg" class="auto-height" alt="author ship">
                                        </div>
                                        <h3>Authorship Details</h3>
                                        <span>4 Checks</span>
                                    </li>
                                    <li>
                                        <div class="checks_icons">
                                            <img src="../../../assets/images/ReferenceDetails.svg" class="auto-height" alt="reference">
                                        </div>
                                        <h3>Reference Details</h3>
                                        <span>1 Check</span>
                                    </li>
        
                                    <li>
                                        <div class="checks_icons">
                                            <img src="../../../assets/images/FirgureTable.svg" class="auto-height" alt="figure table">
                                        </div>
                                        <h3>Figures & Tables</h3>
                                        <span>2 Checks</span>
                                    </li>
        
                                    <li>
                                        <div class="checks_icons">
                                            <img src="../../../assets/images/ClinicalTrials.svg" class="auto-height" alt="clinical trails">
                                        </div>
                                        <h3>Clinical Trial Information</h3>
                                        <span>1 Check</span>
                                    </li>
        
                                    <li>
                                        <div class="checks_icons">
                                            <img src="../../../assets/images/KeywordsAndSummary.svg" class="auto-height" alt="keywords">
                                        </div>
                                        <h3>Keywords & Summary</h3>
                                        <span>2 Checks</span>
                                    </li>
                                    <i></i>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div class="how_it_work_section" style="display: none;">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="how_it_work_content">
                        <div class="howItWorkHeading">
                            <h2>How It Works</h2>
                            <div class="HeadingSubLine"></div>
                        </div>
                        <ul>
                            <li>
                                <div class="login_circle_section">
                                    <img src="../../../assets/images/RegisterAndLogin.png" alt="register">
                                </div>
                                <h3>Register / Login</h3>
                            </li>
                            <li>
                                <div class="login_circle_section">
                                    <img src="../../../assets/images/TypeOrImportFile_icon.png" alt="import">
                                </div>
                                <h3>Open / import your file & <br />select Publication Checks</h3>
                            </li>
                            <li>
                                <div class="login_circle_section">
                                    <img src="../../../assets/images/improveYourWriting_icon.png" alt="writing">
                                </div>
                                <h3>Get a detailed<br /> evaluation report</h3>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="howItWorks section-pd list-wrapper-style-2" #viewVideo>
        <div class="trinka_container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="list-wrapper-image">
                        <a href="" data-toggle="modal" data-target="#wordPluginPopup" id="VewiVideoSection">
                            <img src=".././../../assets/images/PublicationCheckThumbnail.png" loading="lazy" decoding="async" alt="publication-check" class="img-fluid eposter-img">
                        </a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="list-wrapper-content-2">
                        <div class="trinka-title mb-0">
                            <h2>See How It Works</h2>
                            <div class="HeadingSubLine1"><span></span><span></span><span></span></div>
                            <p>Learn how you can use the 25+ Technical Checks and get published faster by watching this short walk-through video.</p>
                            <p>It is a detailed step-by-step tutorial to help you use Trinka to its fullest.</p>
                            <p class="mb-0">Have more questions? Browse through our FAQs <a style="color: #0000ff;" (click)="scroll(target)" class="link">here</a>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="cloud_key_features section-pd list-wrapper-style-2">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-11 col-lg-11">
                    <div class="trinka-title mb-0 text-center">
                        <h2>Key Features of Technical Checks</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                        <p>With Trinka's Technical Checks, you can evaluate your document on 25+ checks and make corrections to increase your chances of getting published. </p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-none">
                        <img src="../../../assets/images/JournalScope.svg" loading="lazy" decoding="async" alt="journal scope">
                        <h3>Journal Scope Match</h3>
                        <p>Helps you avoid rejection by checking whether your manuscript matches the scope of the target journal and suggests additional matching journals.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards">
                        <img src="../../../assets/images/TechnicalCompliance.svg" loading="lazy" decoding="async" alt="tecnical compliance">
                        <h3>Technical Compliance</h3>
                        <p>Trinka Checks publication requirements like manuscript length, headings and format to ensure compliance.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards">
                        <img src="../../../assets/images/EthicalCompliance.svg" loading="lazy" decoding="async" alt="ethical">
                        <h3>Ethical Compliance</h3>
                        <p>Completes all mandatory declarations like informed consent, ethical approval, financial disclosures, conflicts of interest for smooth editorial desk screening.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-1">
                        <img src="../../../assets/images/AuthorshipDetails.svg" loading="lazy" decoding="async" alt="author ship">
                        <h3>Authorship Detail</h3>
                        <p>Checks for authorship details such as author name, affiliation, correspondence, and contribution statements, which are required by most journals.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-1">
                        <img src="../../../assets/images/ReferenceDetails.svg" loading="lazy" decoding="async" alt="reference" width="26" height="34">
                        <h3>References<br class="d-lg-block d-none"/>&nbsp;</h3>
                        <p>Checks your end list references and alerts for the usage of low quality, obsolete and retracted references.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-1">
                        <img src="../../../assets/images/FirgureTable.svg" loading="lazy" decoding="async" alt="figure table">
                        <h3>Figures &<br class="d-lg-block d-none"/> Tables</h3>
                        <p>Trinka Checks all your figure and table captions as well as their citations to ensure you meet all submission requirements.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-1">
                        <img src="../../../assets/images/ClinicalTrials.svg" loading="lazy" decoding="async" alt="clinical" width="29" height="35">
                        <h3>Clinical Trial Information</h3>
                        <p>Trinka Checks whether you have mentioned any clinical trials ID and fetches information on the trial so you are always sure of providing the right details.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="list-wrapper-content-2 list-style-cards bt-1">
                        <img src="../../../assets/images/KeywordsAndSummary.svg" loading="lazy" decoding="async" alt="keywords">
                        <h3>Keywords & Summary</h3>
                        <p>Trinka's AI generates a summary of your paper, highlighting the important topics and concepts. It also suggests possible keywords for your manuscript.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-safe-secure></app-safe-secure>

    <div class="section-pd pt-0">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-11 col-lg-10">
                    <div class="trinka-title mb-0 text-center">
                        <h2>View a Sample Report</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="viewSampleReportImg">
                        <img src="../../../assets/images/PublicationReportImg.png" loading="lazy" decoding="async" alt="publication report">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="trinka-banner section-pd-sm">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-10 col-lg-8">
                    <div class="trinka-title text-center">
                        <h3 class="text-white">Check Your Manuscript for Publication Readiness</h3>
                    </div>
                    <div class="btn-group justify-content-center">
                        <a href="https://cloud.trinka.ai/signup?_rg=GB" class="white-btn" target="_blank">Get Started</a>
                    </div>
                </div>
            </div>  
        </div>
    </div>

    <div class="trinka-credits section-pd">
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-md-10 col-lg-8">
                    <div class="trinka-title mb-0 text-center">
                        <h2>Technical Checks Uses Credits</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    </div>
                </div>
                <div class="col-md-12 col-lg-10">
                    <div class="credit-section">
                        <div class="shape">
                            <svg xmlns="http://www.w3.org/2000/svg" width="745" height="140" viewBox="0 0 745 140" fill="none">
                                <path d="M210.74 99.3391C92.7255 118.025 21.074 68.6063 0 41.5614V169H721.602C749.822 122.013 775.522 23.2037 652.567 3.86251C498.872 -20.314 358.258 75.9822 210.74 99.3391Z" fill="#F5ECFF"/>
                            </svg>
                        </div>
                        <div class="media">
                            <div class="media-left">
                                <img src="../../../assets/images/new-credits.svg" loading="lazy" decoding="async" alt="credits" />
                            </div>
                            <div class="media-body">
                                <h6>Each report <span>generation uses 1 credit</span>. Use free credits and buy more as needed.</h6>
                            </div>
                        </div>
                        <div class="image">
                            <img src="../../../assets/images/credits-banner.svg" loading="lazy" decoding="async" alt="credits" />
                        </div>
                    </div>
                    <ul>
                        <li><span>*</span>
                            <p>
                                Credits are like tokens that let you use several Trinka features as needed.<br/>
                                Every month, Premium users get 10 free credits and Basic users get 4 free credits. To know more, <a href="https://trinka.ai/features/credits" class="text-link" target="_blank">click here</a>.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="faqQuestionAnwser section-pd pt-0" #target>
        <div class="trinka_container">
            <div class="row justify-content-center">
                <div class="col-lg-8 col-md-10">
                    <div class="trinka-title text-center">
                        <h2>Frequently Asked Questions</h2>
                        <div class="HeadingSubLine1 justify-content-center"><span></span><span></span><span></span></div>
                    </div>
                </div>
            </div>
            <div class="faqQuestionsList">
                <div class="accordion" id="faq">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="faqContainer">
                                <div class="card">
                                    <div class="card-header" id="QuestionOne">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionOneContent" aria-expanded="true" aria-controls="QuestionOneContent">
                                            How many credits does the Technical Checks use?</a>
                                    </div>
    
                                    <div id="QuestionOneContent" class="collapse" aria-labelledby="QuestionOne" data-parent="#faq">
                                        <div class="card-body">
                                            <p>Every time you run  Technical Checks, 1 credit is consumed. </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="QuestionTwo">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionTwoContent" aria-expanded="true"
                                            aria-controls="QuestionTwoContent">
                                            Can I download the Publication Readiness report? </a>
                                    </div>
    
                                    <div id="QuestionTwoContent" class="collapse" aria-labelledby="QuestionTwo" data-parent="#faq">
                                        <div class="card-body">
                                            <p>Currently you cannot download the Publication Readiness report. However, your report is always available on Trinka Cloud. You will receive a link to each report on your registered email ID too. </p>
                                        </div>
                                    </div>
                                </div>
    
                                
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="faqContainer">
                                <div class="card">
                                    <div class="card-header" id="QuestionThree">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionThreeContent" aria-expanded="true"
                                            aria-controls="QuestionThreeContent">
                                            Will I get the Technical Checks feature in Trinka's Basic plan?
                                        </a>
                                    </div>
    
                                    <div id="QuestionThreeContent" class="collapse" aria-labelledby="QuestionThree" data-parent="#faq">
                                        <div class="card-body">
                                            <p>Yes. This feature uses credits. Users on the Basic subscription receive 4 free credits every month which can be used to get the Publication Readiness report. </p>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="card">
                                    <div class="card-header" id="QuestionFour">
                                        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#QuestionFourContent" aria-expanded="true"
                                            aria-controls="QuestionFourContent">
                                            How can I use the Publication Readiness report?
                                        </a>
                                    </div>
    
                                    <div id="QuestionFourContent" class="collapse" aria-labelledby="QuestionFour" data-parent="#faq">
                                        <div class="card-body">
                                            <p>You can use the Publication Readiness report as a checklist to easily compare with your target journal’s requirements and assess your paper’s readiness easily. We have prepared a <a style="color: #0000ff;" (click)="scroll(viewVideo)" class="link">video</a> to help you understand the report too. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="otherApps section-pd-sm">
        <div class="trinka_container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="trinka-title text-center">
                        <h3 class="text-white">Use Trinka in Other Apps</h3>
                    </div>
                    <ul>
                        <li>
                            <a [routerLink]="['/trinkacloud']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/Other_apps_TrinkaCloud.svg" loading="lazy" decoding="async" alt="icon">
                                <h3>Cloud</h3>
                            </a>
                        </li>
                        <li>
                            <a  [routerLink]="['/windows-desktop-app']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/native-app/windows-icon.svg" alt="windows">
                                <h3>Windows App</h3>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/wordplugin']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/Others_App_MSWord.svg" loading="lazy" decoding="async" alt="icon">
                                <h3>MS Word Add-in</h3>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/browser-plugin']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/other_Apps_browser.svg" loading="lazy" decoding="async" alt="icon">
                                <h3>Browser Plug-in</h3>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/enterprise']">
                                <div class="app_name_content_overlay"></div>
                                <img src="../../../assets/images/Other_Apps_enterprise.svg" loading="lazy" decoding="async" alt="icon">
                                <h3>Enterprise <small>(API, JS, & On-Premise)</small></h3>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="wordPluginPopup" tabindex="-1" role="dialog" aria-labelledby="wordPluginLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-body" style="padding: 0;">
                <button type="button" id="closedPopup" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div id="iframeHolder"></div>

                <iframe class="youtubeVideoForTryDemo" id="iframe" width="100%" height="450" src="https://www.youtube.com/embed/c8yyiW2tEjE"
                    title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
                <!-- <iframe class="youtubeVideoForTryDemo" id="iframe" width="100%" height="450" src="https://www.youtube.com/embed/z-TVcRwVp3A"
                    title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe> -->
            </div>
        </div>
    </div>
</div>